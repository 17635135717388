import {
  PropsWithChildren,
  RefObject, useCallback,
  useEffect,
  useRef,
  useState
} from "react";
import {ReactComponent as InfoIcon} from "./../../../assets/icons/info.svg";
import {ReactComponent as WarningIcon} from "../../../assets/icons/warning-triangle.svg";
import {ReactComponent as CloseAltIcon} from "./../../../assets/icons/close-alt.svg";
import {ReactComponent as NotifyIcon} from "../../../assets/icons/notify.svg";
import "./modal.scss";
import {CSSTransition} from "react-transition-group";

export enum IModalType {
  DEFAULT = 'default',
  ERROR = 'error',
  NOTIFY = 'notify'
}

export enum IModalBtns {
  SUCCESS = 'success',
  ERROR = 'error',
  DEFAULT = 'default',
  MUTE = 'mute',
  SHORT = 'short',
  DISABLED = 'disabled'
}

export interface IModalFooterBtns {
  id: number,
  type?: IModalBtns[],
  title: string,
  actions: () => void
}

export interface IModal {
  customClass?: string,
  title: string,
  customBtns?: any,
  footerBtns?: IModalFooterBtns[];
  toggleVisibility: boolean,
  type?: IModalType,
  onToggle?: (state: boolean) => void
}

export default function Modal({children, customClass, title, onToggle, toggleVisibility, type, footerBtns, customBtns}: PropsWithChildren<IModal>) {
  const [isVisible, setIsVisible] = useState(false);

  const modalType = determineType();
  const iconType = determineIconType();

  const element: RefObject<HTMLDivElement> = useRef(null);

  useEffect(() => {
    if (onToggle !== undefined &&
        !toggleVisibility &&
        toggleVisibility !== isVisible) {
      onToggle(toggleVisibility);
    }

    setIsVisible(toggleVisibility);
  }, [isVisible, onToggle, toggleVisibility]);

  const handleEsc = useCallback((event: KeyboardEvent) => {
    if (event.key === 'Escape') {
      if (onToggle) {
        onToggle(false);
      }
      setIsVisible(false);
    }
  }, [onToggle]);

  useEffect(() => {
    window.addEventListener('keydown', handleEsc);

    return () => {
      window.removeEventListener('keydown', handleEsc);
    }
  }, [handleEsc]);

  return (
    <CSSTransition
      timeout={450}
      in={isVisible}
      nodeRef={element}
      classNames="animate-modal"
      unmountOnExit
    >
      <div ref={element} className={`modal ${modalType} ${customClass ? customClass : ''}`} contentEditable={false}>
        <aside className="modal__content">
          <header className="modal__header">
            <span className="modal__icon">
              {iconType}
            </span>

            <h3 className="modal__title">
              {title}
            </h3>

            <button type="button" className="modal__close btn--reset" onClick={handleClose}>
              <CloseAltIcon />
            </button>
          </header>

          <div className="modal__entry">
            {children}
          </div>

          {
            !customBtns && footerBtns && footerBtns.length ?
              <div className="modal__footer d-flex">
                {
                  footerBtns.map(({id, actions, title, type}) => (
                    <button key={id}
                            type="button"
                            className={`modal__footer-action btn ${type && type.length ? type.map(item => 'btn--' + item).join(' ') : ''}`}
                            onClick={actions}>
                      {title}
                    </button>
                  ))
                }
              </div> : null
          }

          {
            customBtns && !footerBtns ? customBtns : null
          }

        </aside>

        <button type="button" className="modal__mask btn--reset" onClick={handleClose}/>
      </div>
    </CSSTransition>
  )

  function determineIconType () {
    switch (type) {
      case IModalType.ERROR: {
        return <WarningIcon />;
      }
      case IModalType.NOTIFY: {
        return <NotifyIcon />;
      }
      default: {
        return <InfoIcon />;
      }
    }
  }

  function determineType() {
    switch (type) {
      case IModalType.ERROR: {
        return 'modal--error';
      }

      case IModalType.NOTIFY: {
        return 'modal--notify';
      }

      default: return 'modal--default';
    }
  }

  function handleClose() {
    if (onToggle) {
      onToggle(!isVisible);
    }

    return setIsVisible(!isVisible);
  }
}
