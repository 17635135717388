import {ReactComponent as CloseIcon} from "./../../../../assets/icons/close-alt.svg";
import {CSSTransition} from "react-transition-group";
import {useAppDispatch} from "../../../../redux/hooks";
import {removeNotification} from "../../../../redux/core/core";
import {useRef} from "react";
import NotificationIcon from "../icon/NotificationIcon";
import "./notification.item.scss";

export enum INotificationType {
  SUCCESS = 'success',
  WARNING = 'warning',
  ERROR = 'error',
  INFO = 'info'
}

export interface INotificationData {
  type: INotificationType,
  title: string,
  description?: string
}

export interface INotification {
  id: number,
}

export default function NotificationItem({type, description, title, id, ...rest}: INotification & INotificationData) {
  const dispatch = useAppDispatch();
  const node = useRef(null);

  return (
    <CSSTransition
      {...rest}
      key={id}
      timeout={350}
      nodeRef={node}
      unmountOnExit
      classNames="animate-notification">
      <div ref={node} className={`notification notification--${type}`}>
        <div className="notification__container">
          <div className="notification__icon">
            <NotificationIcon type={type} />
          </div>

          <div className="notification__content">
            <h5 className="notification__title">
              {title}
            </h5>

            {
              description && description.length ?
                <p>
                  { description }
                </p> : null
            }
          </div>

          <button type="button" className="notification__close btn--reset" onClick={handleClose}>
            <CloseIcon />
          </button>

          <div className="notification__progress" onAnimationEnd={handleClose}/>
        </div>
      </div>
    </CSSTransition>
  )

  function handleClose() {
    dispatch(removeNotification(id));
  }
}
