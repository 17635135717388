import { IUseQueryVoucherHistoryPayload } from "../useQueryVoucherHistory";
import { useMutation } from "@tanstack/react-query";
import { queryClient } from "../../../App";
import { startAddNotification } from "../../core/core";
import { INotificationType } from "../../../components/ui/notifications/item/NotificationItem";
import { useAppDispatch } from "../../hooks";
import { exportVoucherHistory } from "../../user/saga/vendor/export-voucher-history";
import FileSaver from "file-saver";

export const IUseMutationExportVoucherHistory =
  "IUseMutationExportVoucherHistory";

export interface IUseMutationExportVoucherHistoryPayload
  extends IUseQueryVoucherHistoryPayload {
  items: string[];
}

export function useMutationExportVoucherHistory() {
  const dispatchStore = useAppDispatch();

  return useMutation({
    mutationKey: [IUseMutationExportVoucherHistory],
    mutationFn: (params: IUseMutationExportVoucherHistoryPayload) =>
      exportVoucherHistory(params),
    onMutate: async () => {
      await queryClient.cancelQueries({
        queryKey: [IUseMutationExportVoucherHistory],
      });

      await queryClient.invalidateQueries({
        queryKey: [IUseMutationExportVoucherHistory],
      });

      return {};
    },
    onError: (err, newItem, context) => {
      if (!context) {
        return;
      }

      dispatchStore(
        startAddNotification({
          title: "Error",
          description: `Unable to export data`,
          type: INotificationType.ERROR,
        })
      );
    },
    onSuccess: async (dataOutput) => {
      let filename = "";
      const disposition = dataOutput.headers["content-disposition"];
      const contentType = dataOutput.headers["content-type"];

      if (disposition && disposition.indexOf("attachment") !== -1) {
        const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
        const matches = filenameRegex.exec(disposition);

        if (matches != null && matches[1]) {
          filename = matches[1].replace(/['"]/g, "");
        }
      }

      let blobData = new Blob([dataOutput.data], {
        type:
          contentType ||
          "vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8",
      });

      filename = filename || "supplierportal.xlsx";

      FileSaver.saveAs(blobData, filename);
    },
  });
}
