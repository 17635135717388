import {
  IGenericAuthResponse,
} from "../../../user";
import axios, {AxiosResponse} from "axios";
import {APIS} from "../../../../static/apis";

export async function deleteDeviceService (deviceUuid: string): Promise<AxiosResponse<IGenericAuthResponse<object>>> {
  return axios.delete(
    APIS.BASE_AUTH_MS_URL + APIS.REMOVE_DEVICE,
    {
      params: {
        deviceUuid
      }
    },
  )
}