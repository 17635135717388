import { updateUserRoleService } from "../../user/saga/auth/update-user-role";
import { useAppDispatch } from "../../hooks";
import { useMutation } from "@tanstack/react-query";
import { queryClient } from "../../../App";
import { AxiosResponse } from "axios";
import { IGenericAuthResponse, IUser, IUserRole } from "../../user/user";
import { startAddNotification } from "../../core/core";
import { INotificationType } from "../../../components/ui/notifications/item/NotificationItem";
import { IUseMutationUpdatePayee } from "../useMutationUpdatePayee";
import { IUseQueryUsers } from "../useQueryUsers";

export interface IUseMutationUpdateUserRolePayload {
  email: string;
  role: IUserRole;
  uuid: string;
}

export function useMutationUpdateUserRole() {
  const dispatchStore = useAppDispatch();

  return useMutation({
    mutationKey: [IUseMutationUpdatePayee],
    mutationFn: ({ email, role, uuid }: IUseMutationUpdateUserRolePayload) =>
      updateUserRoleService({ email, role, uuid }),
    onMutate: async ({ email, role, uuid }) => {
      await queryClient.cancelQueries({ queryKey: [IUseQueryUsers] });

      const previousUsers = queryClient.getQueryData<
        AxiosResponse<IGenericAuthResponse<IUser[]>>
      >([IUseQueryUsers]);

      queryClient.setQueryData<AxiosResponse<IGenericAuthResponse<IUser[]>>>(
        [IUseQueryUsers],
        (prevData) => {
          if (!prevData) {
            return;
          }

          const items = prevData?.data.data.map((dataItem) => {
            if (uuid === dataItem.uuid) {
              return {
                ...dataItem,
                role,
              };
            }

            return {
              ...dataItem,
            };
          });

          return {
            ...prevData,
            data: {
              ...prevData.data,
              data: items,
            },
          };
        }
      );

      dispatchStore(
        startAddNotification({
          title: "Success",
          description: `User role has been updated`,
          type: INotificationType.SUCCESS,
        })
      );

      return { previousUsers };
    },
    onError: (err, newItem, context) => {
      if (!context) {
        return;
      }

      queryClient.setQueryData([IUseQueryUsers], context.previousUsers);

      dispatchStore(
        startAddNotification({
          title: "Error",
          description: `User updates rolled back`,
          type: INotificationType.ERROR,
        })
      );
    },
    onSettled: () => {
      return queryClient.invalidateQueries({ queryKey: [IUseQueryUsers] });
    },
  });
}
