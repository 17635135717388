export const STATIC_STRINGS = {
  CONTACT_US: {
    PAGE_TITLE: "Contact us",
    CONTACT_FORM: {
      FORM_TITLE: "Get in touch with the developers",
      INPUT_PLACEHOLDERS: {
        name: "Name...",
        email: "*Email address...",
        enquiry: "*Tell us your feedback or features you would like to see ...",
      },
      ERROR_MESSAGES: {
        min: "Please insert at least 4 characters.",
        max: "Maximum characters allowed are ",
        noValue: "Please insert a value.",
        email: "Please use a valid email address.",
        phone: "Please use a valid telephone number.",
      },

      NOTIFICATIONS: {
        CONTACT_FORM_ERROR_TITLE: "API Error",
        CONTACT_FORM_ERROR_MESSAGE: "Unable to send email.",

        CONTACT_FORM_SUCCESS_TITLE: "Success",
        CONTACT_FORM_SUCCESS_MESSAGE: "Email sent successfully.",

        CONTACT_CARDS_ERROR_TITLE: "API Error",
        CONTACT_CARDS_ERROR_MESSAGE: "Contact cards error.",

        CONTACT_CARDS_GENERIC_ERROR: "Contact Cards | Could not fetch data",
      },
    },
  },

  CLAUSE_TYPE: {
    PAGE_TITLE: "Clause Type",
    NOTIFICATIONS: {
      MISMATCH_ERROR_TITLE: "Navigation Error",
      MISMATCH_ERROR_MESSAGE: "Mismatched clause type format used",

      NAVIGATION_ERROR_TITLE: "Navigation Error",
      NAVIGATION_ERROR_MESSAGE:
        "Attempted to navigate to unknown clause type format",

      TAGS_ERROR_TITLE: "Api Error",
      TAGS_ERROR_MESSAGE: "Unable to load Clause Type data",

      API_ERROR_TITLE: "Error",
      SEARCH_ERROR_MESSAGE: "Error occurred in clause types search",

      MATRIX_ERROR_TITLE: "Warning",
      MATRIX_ERROR_MESSAGE: "No data available for selected clause type",

      ENTRIES_ERROR_TITLE: "Api Error",
      ENTRIES_ERROR_MESSAGE: "Unable to load Clause Type data",

      LETTERS_ERROR_TITLE: "Api Error",
      LETTERS_ERROR_MESSAGE: "Unable to load Clause Type data",

      PROMISE_REJECT_MESSAGE:
        "[Api][Clause Types] Invalid response received from api call for fetching block letter",
    },
  },

  CLAUSE_FINDER: {
    PAGE_TITLE: "Clause Finder",
  },

  FACULTATIVE: {
    PAGE_TITLE: "Facultative",
    NOTIFICATIONS: {
      TAGS_ERROR_TITLE: "Api Error",
      TAGS_ERROR_MESSAGE: "Unable to load Facultative data",

      SEARCH_ERROR_TITLE: "Error",
      SEARCH_ERROR_MESSAGE: "Error occurred in Facultative search",

      PAGE_ERROR_TITLE: "Error",
      PAGE_ERROR_MESSAGE: "Unable to load Facultative Api",

      NAVIGATION_ERROR_TITLE: "Navigation Error",
      NAVIGATION_ERROR_MESSAGE: "Mismatched facultative format used",

      ENTRIES_ERROR_TITLE: "Api Error",
      ENTRIES_ERROR_MESSAGE: "Unable to load Facultative data",

      LETTER_ERROR_TITLE: "Api Error",
      LETTER_ERROR_MESSAGE: "Unable to load Facultative data",

      PROMISE_REJECT_MESSAGE:
        "[Api][Facultative] Invalid response received from api call for fetching block letter",
    },
  },

  GLOSSARY: {
    PAGE_TITLE: "Glossary",
    NOTIFICATIONS: {
      TAGS_ERROR_TITLE: "Api Error",
      TAGS_ERROR_MESSAGE: "Unable to load Glossary data",

      SEARCH_ERROR_TITLE: "Error",
      SEARCH_ERROR_MESSAGE: "Error occurred in Glossary search",

      PAGE_ERROR_TITLE: "Error",
      PAGE_ERROR_MESSAGE: "Unable to load Glossary",

      LETTER_ERROR_TITLE: "Error",
      LETTER_ERROR_MESSAGE: "Unable to load Glossary Api",

      NAVIGATION_ERROR_TITLE: "Navigation Error",
      NAVIGATION_ERROR_MESSAGE: "Mismatched glossary format used",

      API_ERROR_TITLE: "Error",

      ENTRIES_ERROR_TITLE: "Api Error",
      ENTRIES_ERROR_MESSAGE: "Unable to load Glossary data",

      PROMISE_REJECT_MESSAGE:
        "[Api][Glossary] Invalid response received from api call for fetching block letter",
    },
  },

  HOME: {
    PAGE_TITLE: "Welcome",
    SIDEBAR: [
      {
        id: 1,
        title: "13004Termination (31 March 2008)",
      },
      {
        id: 2,
        title: "13005None (31 March 2008)",
      },
      {
        id: 3,
        title: "13386Special Termination Clause (30 November 2009)",
      },
      {
        id: 4,
        title: "13057Intermediary (30 November 2008)",
      },
      {
        id: 5,
        title: "13058None (30 November 2008)",
      },
    ],
    PARAGRAPHS: [
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Aliquet nec ullamcorper sit amet risus nullam. Eget felis eget nunc lobortis mattis aliquam. Ut aliquam purus sit amet luctus venenatis lectus magna. Vestibulum rhoncus est pellentesque elit ullamcorper dignissim cras tincidunt lobortis. Cras fermentum odio eu feugiat pretium. Ac tincidunt vitae semper quis lectus. Et netus et malesuada fames. Quis lectus nulla at volutpat diam ut venenatis tellus. Auctor eu augue ut lectus arcu. Facilisis leo vel fringilla est. Venenatis tellus in metus vulputate eu scelerisque felis imperdiet proin. Nec sagittis aliquam malesuada bibendum arcu. Egestas tellus rutrum tellus pellentesque eu tincidunt tortor aliquam nulla. Dui nunc mattis enim ut tellus. Sodales neque sodales ut etiam. Leo a diam sollicitudin tempor id eu. Rhoncus mattis rhoncus urna neque viverra justo nec ultrices. Nunc eget lorem dolor sed viverra. Egestas maecenas pharetra convallis posuere.",
      "Dictum sit amet justo donec. Senectus et netus et malesuada fames ac. Sem fringilla ut morbi tincidunt augue interdum velit euismod in. Quis risus sed vulputate odio ut enim blandit. Nisl tincidunt eget nullam non nisi. Aliquet porttitor lacus luctus accumsan tortor posuere ac. Vel turpis nunc eget lorem dolor. Massa eget egestas purus viverra accumsan in nisl. Porta lorem mollis aliquam ut porttitor leo a diam sollicitudin. Morbi tempus iaculis urna id volutpat. Vitae et leo duis ut diam quam. Viverra nam libero justo laoreet sit amet cursus. Senectus et netus et malesuada fames ac. At in tellus integer feugiat scelerisque varius. Viverra accumsan in nisl nisi scelerisque eu ultrices. Condimentum mattis pellentesque id nibh tortor id aliquet. Convallis posuere morbi leo urna molestie at elementum. Tristique sollicitudin nibh sit amet commodo nulla. Imperdiet massa tincidunt nunc pulvinar sapien et ligula ullamcorper.",
    ],
  },

  OVERVIEW: {
    PAGE_TITLE: "Overview",

    NOTIFICATIONS: {
      TAGS_ERROR_TITLE: "Api Error",
      TAGS_ERROR_MESSAGE: "Unable to load Overview data",

      SEARCH_ERROR_TITLE: "Error",
      SEARCH_ERROR_MESSAGE: "Error occurred in Overview search",

      PAGE_ERROR_TITLE: "Api Error",
      PAGE_ERROR_MESSAGE: "Unable to load Overview data",

      NAVIGATION_ERROR_TITLE: "Navigation Error",
      NAVIGATION_ERROR_MESSAGE: "Mismatched overview format used",

      LETTER_ERROR_TITLE: "Api Error",
      LETTER_ERROR_MESSAGE: "Unable to load Overview data",
      LETTER_ERROR_MESSAGE_API: "Unable to load Overview Api",

      ENTRIES_ERROR_TITLE: "Api Error",
      ENTRIES_ERROR_MESSAGE: "Unable to load Overview data",

      API_ERROR_TITLE: "Error",

      PROMISE_REJECT_MESSAGE:
        "[Api][Overview] Invalid response received from api call for fetching block letter",
    },
  },

  FOREIGN_LAW: {
    PAGE_TITLE: "Foreign Law",

    NOTIFICATIONS: {
      TAGS_ERROR_TITLE: "Api Error",
      TAGS_ERROR_MESSAGE: "Unable to load Foreign Law data",

      SEARCH_ERROR_TITLE: "Error",
      SEARCH_ERROR_MESSAGE: "Error occurred in Foreign Law search",

      PAGE_ERROR_TITLE: "Api Error",
      PAGE_ERROR_MESSAGE: "Unable to load Foreign Law data",

      NAVIGATION_ERROR_TITLE: "Navigation Error",
      NAVIGATION_ERROR_MESSAGE: "Mismatched Foreign Law format used",

      LETTER_ERROR_TITLE: "Api Error",
      LETTER_ERROR_MESSAGE: "Unable to load Foreign Law data",
      LETTER_ERROR_MESSAGE_API: "Unable to load Foreign Law Api",

      ENTRIES_ERROR_TITLE: "Api Error",
      ENTRIES_ERROR_MESSAGE: "Unable to load Foreign Law data",

      API_ERROR_TITLE: "Error",

      PROMISE_REJECT_MESSAGE:
        "[Api][ForeignLaw] Invalid response received from api call for fetching block letter",
    },
  },

  OVERLAY: {
    NOTIFICATIONS: {
      ERROR_TITLE: "Api Error",
      SUCCESS: "Success",
      SUCCESS_CONTENT_GENERIC: "Entry successfully updated",
      ERROR_MESSAGE: ["Unable to load ", "Clause Type data."],
      GENERIC_ERROR_MESSAGE: "Generic error occurred for overlay item",
      ERROR_URL: "[Overlay][Api] Invalid URL supplied for overlay item fetch",
    },
  },

  WORDINGS: {
    PAGE_TITLE: "Wordings",
    NOTIFICATIONS: {
      TAGS_ERROR_TITLE: "Api Error",
      TAGS_ERROR_MESSAGE: "Unable to load Wordings data",

      SEARCH_ERROR_TITLE: "Error",
      SEARCH_ERROR_MESSAGE: "Error occurred in wordings search",

      ENTRIES_ERROR_TITLE: "Error",
      ENTRIES_ERROR_MESSAGE: "Unable to load Wordings Api",

      NAVIGATION_ERROR_TITLE: "Navigation Error",
      NAVIGATION_ERROR_MESSAGE: "Mismatched wordings format used",

      PAGE_ERROR_TITLE: "Api Error",
      PAGE_ERROR_MESSAGE: "Unable to load Wordings data",

      LETTER_ERROR_TITLE: "Api Error",
      LETTER_ERROR_MESSAGE: "Unable to load Wordings data",
      LETTER_ERROR_MESSAGE_API: "Unable to load Wordings Api",

      BASE_ERROR_TITLE: "Error",
      BASE_ERROR_MESSAGE: "Unable to load Wordings data",

      API_ERROR_TITLE: "Error",

      PROMISE_REJECT_MESSAGE:
        "[Api][Wordings] Invalid response received from api call for fetching block letter",
    },
  },

  LOGIN: {
    LOGIN_FORM: {
      EMAIL_ADDRESS_PLACEHOLDER: "Email Address",
      USERNAME_PLACEHOLDER: "Username",
      PASSWORD_PLACEHOLDER: "Password",
      LOGIN_BUTTON: "Sign in",
      FINISH_SETUP: "Finish Setup",
      FORGOT_BUTTON: "Forgot sign in details?",
      NEW_ACCOUNT: "Create New Account",
      PASSWORD: "Password",
      PASSWORD_REPEAT: "Repeat Password",
      EMAIL_ADDRESS: "Email address",
      CAPTCHA: "What is",
      TWO_FA_CODE: "Two Factor Code",
      RECOVERY_KEY: "Recovery Key",
    },
    FOOTER: {
      COMPANY: "CMS Cameron McKenna Nabaro Olswang LLP",
      COPYRIGHT_TEXT:
        "This material contains and/or is the product of privileged legal advice and is confidential, legally privileged and protected by copyright.",
      ACCESS_KEYS: "Access keys",
      TERMS: "User License Terms",
      PHONE1: "",
      PHONE2: "",
      EMAIL: "",
    },
  },

  ERROR_COMPONENT: {
    INTERNAL: {
      PAGE_TITLE: "Error 500 - Internal Server Error",
      PAGE_CONTENT: [
        "Oooops, looks like something went wrong with our servers.",
        "If this problem persists, please contact an administrator.",
      ],
      PAGE_BUTTON_BACK: "Back Home",
      PAGE_BUTTON_RESTART: "Restart Application",
    },
    EMPTY: {
      PAGE_TITLE: "No entries available",
      PAGE_CONTENT: [
        "There are no entries to display at this point in time. Try again at some later time or refresh the app.",
        "If this problem persists, please contact an administrator.",
      ],
      PAGE_BUTTON_BACK: "Back Home",
      PAGE_BUTTON_RESTART: "Restart Application",
    },
  },

  SEARCH_MESSAGES: {
    SEARCHING_FOR: "Searching for",
    SEARCH_DONE: ["Found ", "search results for ", "keyword."],

    ERRORS: {
      MIN: "Minimum search must include 3 chars",
      MAX: "Maximum allowed search is 30 chars",
    },
  },

  GLOBAL_SEARCH: {
    NOTIFICATIONS: {
      ERROR_TITLE: "Error",
      ERROR_MESSAGE: "Unable to load search results",
    },
  },

  USER_MESSAGES: {
    ERRORS: {
      LOGOUT_TITLE: "Logout",
      LOGOUT_CONTENT: "A unknown error has occurred",
    },
    SIGN_IN: {
      LOGIN_TITLE: "Success!",
      WELCOME: "Welcome back",
    },
  },

  HOME_MESSAGE: {
    ERROR: {
      GENERIC_TITLE: "Error",
      GENERIC_ERROR: "Unexpected error occurred",
    },
  },

  PROFILE: {
    PAGE_TITLE: "Manage Profile",
    NOTIFICATION: {
      SUCCESS_TITLE: "Success",
      ACCOUNT_ADDED: "Account successfully added",
      SUCCESS_ACTIVATED: "Profile successfully activated",
      SUCCESS_CONTENT: "Profile successfully updated",
      SUCCESS_REGISTER:
        "A email confirmation will be sent with further instructions",
      SUCCESS_CONTENT_RESET_PASS:
        "A email will be sent with further instructions",
      SUCCESS_DEACTIVATED: "Profile successfully deactivated",
      SUCCESS_INVITE: "Successfully send invitation",
      ERROR_TITLE: "Error",
      ERROR_ACCOUNT_ADDED: "Unable to add new account",
      ERROR_ACCOUNT_SEND_INVITE: "Unable to add new account",
      ERROR_LOGIN: "Unable to run authentication",
      ERROR_LOGIN_MS: "Microsoft Account not found",
      ERROR_REGISTER: "Unable to register new account",
      ERROR_CONTENT: "Unable to update profile",
      ERROR_CONTENT_RESET_PASS: "A error has occurred",
      WARNING_PROFILE_TITLE: "Profile Setup",
      WARNING_PROFILE_CONTENT: "Finish setting up your account",
    },
  },
  USERS: {
    PAGE_TITLE: "Manage Users",
  },
  WELCOME: {
    INTRO: "Welcome back",
    SIGN_OUT: "Sign Out",
    HOME: "Home",
  },
  SIGN_IN: {
    FORM: {
      EMAIL_ADDRESS_PLACEHOLDER: "Email Address",
      PASSWORD_PLACEHOLDER: "Password",
      CURRENT_PASSWORD_PLACEHOLDER: "Current Password",
      PASSWORD_RETYPE_PLACEHOLDER: "Repeat Password",
      SIGN_IN_BTN: "Sign In",
      RESET_PASS: "Reset Password",
      NEW_ACCOUNT: "New Account",
      SIGN_IN_MICROSOFT: "Sign in with CMS Account",
    },
    METHOD: {
      MAGIC_LINK: {
        TITLE: "Magic Link Authentication",
        CONTENT: [
          'Using the "Magic Link" authentication method, you will be sent a email which contains your unique "Magic Link". Access the Magic Link from the email we sent, in order to authenticate.',
          'The generated "Magic Link" is meant to be used only once, in the same browser as you\'re currently using, and is available for 90 seconds, after which, it will no longer be available. A new "Magic Link" would have to be generated to restart the process.',
        ],
      },
      BIOMETRIC: {
        TITLE: "Biometrics Authentication",
        CONTENT:
          "If the device you're currently using, supports biometric authentication and has been previously registered with your account, you can use it to authenticate.",
      },
      BIOMETRIC_ERROR: {
        TITLE: "Biometric Error",
        CONTENT: "Biometric error occurred on authentication",
      },
      MAGIC_LINK_ACTION: {
        TITLE: "Send Magic Link",
      },
      BIOMETRIC_ACTION: {
        TITLE: "Authenticate using biometrics",
        NOTE: "This feature is not available for the current device or browser, but a different device can be added from My Account page.",
      },
      EXPIRED: {
        TITLE: "Warning",
        CONTENT: "A new magic link should be generated for authentication",
      },
      VALID: {
        TITLE: "Success",
        CONTENT:
          "Magic link has been sent to email inbox with further information",
      },
      INVALID: {
        TITLE: "Error",
        CONTENT: "Unable to generate magic link",
      },
    },
  },
  NEW_ACCOUNT: {
    FORM: {
      EMAIL_ADDRESS_PLACEHOLDER: "Email Address",
      NAME_PLACEHOLDER: "Name",
      CAPTCHA_PLACEHOLDER: "What is",
      CREATE_ACCOUNT: "Create new account",
      CREATE_MICROSOFT_ACCOUNT: "Create account with Microsoft",
      INTRO:
        "Register a new account with NetZeroPortal Services. After the data is submitted you will receive further information on the email account used.",
    },
  },
  REGISTER: {
    FORM: {
      FINISH_ACCOUNT_ACTION: "Finish Account Setup",
      ADD_BIOMETRIC:
        "Enable biometric authentication for current device & browser",
      EXTRA_BIOMETRIC: "Register new biometric device",
      MISSING_BIOMETRIC:
        "*This feature is not available for the current device or browser, but a different device can be added from My Account page.",
    },
  },
  NOTIFICATIONS: {
    STATUS: {
      SUCCESS: {
        TITLE: "Success",
        CONTENT: "Status successfully updated",
      },
      ERROR: {
        TITLE: "Error",
        CONTENT: "Error occurred on status change",
      },
    },
    USER_DELETE: {
      SUCCESS: {
        TITLE: "Success",
        CONTENT: "User successfully removed",
      },
      ERROR: {
        TITLE: "Error",
        CONTENT: "Error occurred on user remove",
      },
    },
    ROLE: {
      SUCCESS: {
        TITLE: "Success",
        CONTENT: "Role successfully updated",
      },
      ERROR: {
        TITLE: "Error",
        CONTENT: "Error occurred on role change",
      },
    },
    AUTH: {
      ERROR: {
        TITLE: "Error",
        CONTENT: "Invalid account provided",
      },
    },
    RECOVERY: {
      ERROR: {
        TITLE: "ERROR",
        CONTENT: "Unable to send recovery key",
      },
    },
    BIOMETRIC: {
      NEW: {
        TITLE: "Success",
        CONTENT: "New biometric device registration added",
      },
      EXPIRED: {
        TITLE: "Warning",
        CONTENT: "New biometric registration expired",
      },
      ADD_NEW_ERROR: {
        TITLE: "Error",
        CONTENT: "Unable to register new biometric device request",
      },
      ADD_NEW_SUCCESS: {
        TITLE: "Success",
        CONTENT: "Successfully registered new biometric device request",
      },
    },
    REGISTER: {
      SUCCESS: {
        TITLE: "Success",
        CONTENT:
          "New account created, a email will be sent with further instructions",
      },
      ERROR: {
        TITLE: "Error",
        CONTENT: "Unable to create new account",
      },
      SUCCESS_NEW_USER: {
        TITLE: "Success",
        CONTENT: "New account created",
      },
      ERROR_NEW_USER: {
        TITLE: "Error",
        CONTENT: "Unable to create new account",
      },
      SUCCESS_NEW_INVITE: {
        TITLE: "Success",
        CONTENT: "Invitation successfully sent",
      },
      ERROR_NEW_INVITE: {
        TITLE: "Error",
        CONTENT: "Unable to send invitation to user",
      },
    },
    ACTIVATION: {
      SUCCESS: {
        TITLE: "Success",
        CONTENT: "New account successfully activated",
      },
      ERROR: {
        TITLE: "Error",
        CONTENT: "Unable to activate account",
      },
      BIOMETRIC_SUCCESS: {
        TITLE: "Success",
        CONTENT: "Biometric device successfully added",
      },
    },
    DELETE_DEVICE: {
      SUCCESS: {
        TITLE: "Success",
        CONTENT: "Device successfully deleted",
      },
      ERROR: {
        TITLE: "Error",
        CONTENT: "Unable to delete device",
      },
    },
  },
};
