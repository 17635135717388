import { v4 as uuidv4 } from "uuid";

export const options = new Map();

const invoiceRelatedOptions = [
  "Missing/incorrect information",
  "Coding",
  "Other",
].map((item) => ({
  id: uuidv4(),
  content: item,
}));

const paymentRelatedOptions = [
  "Overdue payment",
  "Incorrect amount paid",
  "Other",
].map((item) => ({
  id: uuidv4(),
  content: item,
}));

const vendorRelatedOptions = [
  "Bank Details",
  "Contact Information",
  "Other",
].map((item) => ({
  id: uuidv4(),
  content: item,
}));

const itRelatedOptions = ["New user setup", "Portal Issues", "Other"].map(
  (item) => ({
    id: uuidv4(),
    content: item,
  })
);

export const queryTypeOptions = [
  "Invoice Related",
  "Payment Related",
  "Supplier Related",
  "IT Related",
].map((item, index) => {
  const id = uuidv4();

  options.set(
    id,
    index === 0
      ? invoiceRelatedOptions
      : index === 1
      ? paymentRelatedOptions
      : index === 2
      ? vendorRelatedOptions
      : itRelatedOptions
  );

  return {
    id,
    content: item,
  };
});

export type ISetValueAction = {
  name: ISetValueActionPayload;
  email: ISetValueActionPayload;
  phone: ISetValueActionPayload;
  type: ISetValueActionPayload;
  subType: ISetValueActionPayload;
  query: ISetValueActionPayload;
};

export const initialState: ISetValueAction = {
  name: {
    id: "name",
    value: "",
    isValid: false,
    label: "Name",
    dirty: false,
  },
  email: {
    id: "email",
    value: "",
    isValid: false,
    label: "Email",
    dirty: false,
  },
  phone: {
    id: "phone",
    value: "",
    isValid: true,
    label: "Phone (optional)",
    dirty: true,
  },
  type: {
    id: "type",
    value: "",
    isValid: false,
    label: "Select Main Query Type",
    dirty: false,
  },
  subType: {
    id: "subType",
    value: "",
    isValid: false,
    label: "Select Sub Query Type",
    dirty: false,
  },
  query: {
    id: "query",
    value: "",
    isValid: false,
    label: "Query",
    dirty: false,
  },
};

export interface ISetValueActionPayload {
  id: keyof ISetValueAction;
  value: string;
  label: string;
  isLoading?: boolean;
  errorMessage?: string;
  isOld?: boolean;
  isCmsContactSelected?: boolean;
  isSuggestionSelected?: boolean;
  isValid: boolean;
  dirty: boolean;
}

export interface ISetPreloadedValues {
  name: string;
  email: string;
}

export type Action =
  | { type: "resetState" }
  | { type: "setValue"; payload: ISetValueActionPayload }
  | { type: "preloadUserValue"; payload: ISetPreloadedValues }
  | { type: "setTypeValue"; payload: ISetValueActionPayload };

export function reducer(
  state: ISetValueAction,
  action: Action
): ISetValueAction {
  switch (action.type) {
    case "resetState": {
      return {
        ...initialState,
        query: {
          ...state.query,
          value: "",
        },
      };
    }
    case "preloadUserValue": {
      return {
        ...state,
        name: {
          ...state.name,
          value: action.payload.name,
          isValid: true,
          dirty: true,
        },
        email: {
          ...state.email,
          value: action.payload.email,
          isValid: true,
          dirty: true,
        },
      };
    }

    case "setValue": {
      return {
        ...state,
        [action.payload.id]: {
          ...state[action.payload.id],
          dirty:
            action.payload.dirty !== undefined ? action.payload.dirty : true,
          value: action.payload.value,
          isValid: action.payload.isValid,
          errorMessage: action.payload.errorMessage,
          isLoading:
            "isLoading" in action.payload ? action.payload.isLoading : false,
        },
      };
    }

    case "setTypeValue": {
      return {
        ...state,
        subType: {
          ...state.subType,
          value: "",
          dirty: false,
          isValid: false,
          errorMessage: "",
        },
        [action.payload.id]: {
          ...state[action.payload.id],
          dirty:
            action.payload.dirty !== undefined ? action.payload.dirty : true,
          value: action.payload.value,
          isValid: action.payload.isValid,
          errorMessage: action.payload.errorMessage,
          isLoading:
            "isLoading" in action.payload ? action.payload.isLoading : false,
        },
      };
    }

    default:
      return {
        ...state,
      };
  }
}
