import axios, {AxiosResponse} from "axios";
import {APIS} from "../../../../static/apis";

export interface IContactUs {
  name: string,
  phone?: string,
  email: string,
  category: string,
  subCategory: string,
  message: string
}

export function contactUs (params: IContactUs): Promise<AxiosResponse<{ data: boolean }>> {
  return axios.post(
    APIS.BASE_AUTH_MS_URL + APIS.CONTACT_US, {
      ...params
    }, {
      withCredentials: true
    }
  );
}
