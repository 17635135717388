import axios, { AxiosResponse } from "axios";
import { IGenericAuthResponse } from "../../../user";
import { APIS } from "../../../../static/apis";

export interface IUpdateUsernameService {
  email: string;
  uuid: string | undefined | null;
  newUsername: string;
}

export function updateUsernameService({
  email,
  uuid,
  newUsername,
}: IUpdateUsernameService): Promise<
  AxiosResponse<IGenericAuthResponse<boolean>>
> {
  return axios.patch(
    APIS.BASE_AUTH_MS_URL + APIS.UPDATE_USERNAME,
    {
      email,
      uuid,
      newUsername,
    },
    {
      withCredentials: true,
    }
  );
}
