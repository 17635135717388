import {updatePayeeService} from "../../user/saga/vendor/update-payee";
import {useMutation} from "@tanstack/react-query";
import {AxiosResponse} from "axios";
import {IGenericAuthResponse} from "../../user/user";
import {IPayeeAccounts} from "../../companies/companies";
import {queryClient} from "../../../App";
import {startAddNotification} from "../../core/core";
import {INotificationType} from "../../../components/ui/notifications/item/NotificationItem";
import {useAppDispatch} from "../../hooks";
import {IUseQueryPayees} from "../useQueryPayees";
import {IUseMutationDeleteCompany} from "../useMutationDeleteCompany";
import {IUseCompanyQuery} from "../useQueryCompanies";

export const IUseMutationUpdatePayee = 'IUseMutationUpdatePayee';

export interface IUseMutationUpdatePayeePayload {
  id: number,
  name: string,
  payeeNumber: string,
  companyId: string
}

export function useMutationUpdatePayee() {
  const dispatchStore = useAppDispatch();

  return useMutation({
    mutationKey: [IUseMutationUpdatePayee],
    mutationFn: ({id, name, payeeNumber}: IUseMutationUpdatePayeePayload) => updatePayeeService(id, name, payeeNumber),
    onMutate: async ({id, name, payeeNumber, companyId}) => {
      await queryClient.cancelQueries({ queryKey: [IUseCompanyQuery, IUseMutationUpdatePayee, IUseQueryPayees, IUseMutationDeleteCompany] });

      await queryClient.invalidateQueries({
        queryKey: [IUseCompanyQuery, IUseQueryPayees]
      });

      const previousPayees = queryClient.getQueryData<AxiosResponse<IGenericAuthResponse<IPayeeAccounts[]>>>([IUseQueryPayees, parseInt(companyId)]);

      queryClient.setQueryData<AxiosResponse<IGenericAuthResponse<IPayeeAccounts[]>>>([IUseQueryPayees, parseInt(companyId)], (prevData) => {
        if (!prevData) {
          return
        }

        const items = prevData?.data.data.map(dataItem => {
          if (id === dataItem.id) {
            return {
              ...dataItem,
              id,
              name,
              payeeNumber
            }
          }

          return {
            ...dataItem
          }
        });

        return {
          ...prevData,
          data: {
            ...prevData.data,
            data: items
          }
        };
      })

      dispatchStore(startAddNotification({
        title: "Success",
        description: `Company successfully updated`,
        type: INotificationType.SUCCESS
      }));

      return { previousPayees, companyId };
    },
    onError: (err, newItem, context) => {
      if (!context) {
        return;
      }

      queryClient.setQueryData([IUseQueryPayees, parseInt(context.companyId)], context.previousPayees)

      dispatchStore(startAddNotification({
        title: "Error",
        description: `Company updates rolled back`,
        type: INotificationType.ERROR
      }));
    },
    onSettled: (_, __, context) => {
      return queryClient.invalidateQueries({ queryKey: [IUseQueryPayees, parseInt(context.companyId)] })
    },
  })
}
