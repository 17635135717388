import "./company-profile.scss";

export default function CompanyProfile() {

  return (
    <div className="company-profile">
      <div className="content__wrapper">
        <h1 className="company-profile__title">
          Manage Company Profile
        </h1>

        <section className="company-profile__section company-profile__section--company-details">
          <h2 className="company-profile__section-title">Company Details</h2>

          <dl className="d-flex f-wrap f-column-xs">
            <dt>Address:</dt>
            <dd>1234 Generic Drive
              London
            </dd>

            <dt>VAT number:</dt>
            <dd>GB123456789</dd>
          </dl>
        </section>

        <section className="company-profile__section company-profile__section--bank-details">
          <h2 className="company-profile__section-title">Bank Details</h2>

          <div className="d-flex f-column-sm">
            <div className="company-profile__bank-details">
              <dl className="d-flex f-wrap f-column-xs" onClick={() => {}}>
                <dt>Account number:</dt>
                <dd>1234 5678</dd>

                <dt>Sort code:</dt>
                <dd>12/34/56</dd>

                <dt>Name:</dt>
                <dd>Name</dd>

                <dt>Reference:</dt>
                <dd>Reference</dd>
              </dl>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
}
