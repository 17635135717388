import axios, {
  AxiosError,
  AxiosInstance,
  AxiosRequestConfig,
  AxiosResponse,
} from "axios";
import store from "../../redux/store";
import { IStatus } from "../../redux/types";
import { getAccessTokenService } from "../../redux/user/saga/auth/get-access-token";
import { updatePendingState } from "../../redux/core/core";
import to from "await-to-js";
import { IHeaderAccessToken } from "../../redux/static/static";
import { updateUserAccessToken } from "../../redux/user/user";

const onRequest = (config: AxiosRequestConfig): AxiosRequestConfig => {
  if (
    config &&
    config.url &&
    !config.url.includes("/api") &&
    !config.url.includes("/auth")
  ) {
    return {
      ...config,
    };
  }

  const { email, name, uuid } = store.getState().user;

  if (!(email && name && uuid)) {
    return config;
  }

  const accessToken = store.getState().user.accessToken;

  let userHeaders = {};

  if (accessToken) {
    const { email, uuid, accessToken } = store.getState().user;

    userHeaders = {
      ...userHeaders,
      Authorization: "Bearer " + (accessToken || undefined),
      "X-UUID": uuid || undefined,
      "X-EMAIL": email || undefined,
    };
  }

  return {
    ...config,
    headers: {
      ...config.headers,
      ...userHeaders,
    },
  };
};

const onRequestError = (error: AxiosError): Promise<AxiosError> => {
  return Promise.reject(error);
};

const onResponse = (response: AxiosResponse): AxiosResponse => {
  return response;
};

const onResponseError = async (
  error: AxiosError<{ error: string }>
): Promise<AxiosError> => {
  const defaultError =
    error &&
    error.response &&
    error.response.data &&
    error.response.data.error &&
    error.response.status &&
    (error.response.status === 403 || error.response.status === 401) &&
    error.response.data.error.toLowerCase().includes("expired access token");

  const blobError =
    error &&
    error.response &&
    error.response.data &&
    error.response.status &&
    (error.response.status === 403 || error.response.status === 401) &&
    error.response.data instanceof Blob;

  if (defaultError || blobError) {
    const { pending } = store.getState().core;

    if (pending !== IStatus.RENEW_ACCESS_TOKEN) {
      store.dispatch(updatePendingState(IStatus.RENEW_ACCESS_TOKEN));
      const [error, response] = await to(getAccessTokenService());

      if (
        error ||
        !response ||
        !response.headers ||
        !(IHeaderAccessToken in response.headers)
      ) {
        store.dispatch(updatePendingState(IStatus.IDLE));
      } else {
        store.dispatch(
          updateUserAccessToken(response.headers[IHeaderAccessToken])
        );
      }
    }

    await waitForAccessToken();

    store.dispatch(updatePendingState(IStatus.IDLE));

    return axios.request(error.config);
  }

  return Promise.reject(error);
};

export function setupInterceptors(axiosInstance: AxiosInstance): AxiosInstance {
  axiosInstance.interceptors.request.use(
    (request) => onRequest(request),
    onRequestError
  );
  axiosInstance.interceptors.response.use(onResponse, (response) =>
    onResponseError(response)
  );

  return axiosInstance;
}

async function waitForAccessToken(): Promise<undefined> {
  const { pending } = store.getState().core;

  if (!pending) {
    return;
  }

  if (pending !== IStatus.RENEW_ACCESS_TOKEN) {
    await new Promise((resolve) => {
      setTimeout(() => {
        resolve(true);
      }, 500);
    });

    return waitForAccessToken();
  }

  return;
}
