import {getVoucherHistory} from "../../user/saga/vendor/get-voucher-history";
import {useQuery} from "@tanstack/react-query";

export const IUseQueryVoucherHistory = 'IUseQueryVoucherHistory';

export interface IUseQueryVoucherHistoryPayloadApi extends IUseQueryVoucherHistoryPayload {
  condition: boolean,
  page: number
}

export interface IUseQueryVoucherHistoryPayload {
  payeeNumber: string,
  currencies: string,
  statuses: string,
  fromDate: string,
  toDate: string,
  searchTerm: string,
}

export function useQueryVoucherHistory ({payeeNumber, condition, fromDate, toDate, searchTerm, page, statuses, currencies}: IUseQueryVoucherHistoryPayloadApi) {
  return useQuery(
    [IUseQueryVoucherHistory, payeeNumber, {
      filters: {
        fromDate, toDate, searchTerm, page, statuses, currencies
      }
    }],
    () => getVoucherHistory({
      payeeNumber,
      toDate,
      fromDate,
      searchTerm,
      statuses,
      currencies,
      page
    }), {
    enabled: condition,
    keepPreviousData: true
  });
}

