import InputInlineError from "../../../components/ui/inputs/inline-error/Input.inline-error";
import Input from "../../../components/ui/inputs/Input";
import { useEffect, useMemo, useReducer } from "react";
import { initialState, options, queryTypeOptions, reducer } from "./state";
import { emailRegex, phoneRegex } from "../../../tools/regex";
import Select from "../../../components/ui/select/Select";
import "./contact-us-content.scss";
import TextArea from "../../../components/ui/text-area/TextArea";
import { ReactComponent as SendIcon } from "../../../assets/icons/send.svg";
import { useMutationContactUs } from "../../../redux/hooks/useMutationContactUs";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { startAddNotification } from "../../../redux/core/core";
import { INotificationType } from "../../../components/ui/notifications/item/NotificationItem";
import { selectUserEmail, selectUserName } from "../../../redux/user/selectors";

export default function ContactUsContent() {
  const dispatchStore = useAppDispatch();

  const userName = useAppSelector(selectUserName);
  const userEmail = useAppSelector(selectUserEmail);

  const [state, dispatch] = useReducer(reducer, {
    ...initialState,
  });

  const isFormValid = useMemo(() => {
    return Object.values(state).every((item) => item.isValid);
  }, [state]);

  const isSubTypeAvailable = useMemo(() => {
    return state.type.value.length > 0;
  }, [state.type.value]);

  const getSubTypeOptions = useMemo(() => {
    if (!isSubTypeAvailable) {
      return [];
    }

    return options.get(state.type.value) as { id: string; content: string }[];
  }, [isSubTypeAvailable, state.type.value]);

  const { mutate } = useMutationContactUs();

  useEffect(() => {
    if (userName && userName.length > 0 && userEmail && userEmail.length > 0) {
      dispatch({
        type: "preloadUserValue",
        payload: {
          email: userEmail,
          name: userName,
        },
      });
    }
  }, [userName, userEmail]);

  return (
    <div className="contact-us__wrapper">
      <p>Fill in the form below to get in you touch with the team.</p>

      <form className="contact-us__form">
        <div className="contact-us__row">
          <Input
            id={state.name.id}
            customClass="invoice-input"
            placeholder={state.name.label}
            autocomplete={false}
            requestBlur={(event) => {
              if (state.name.dirty) {
                return;
              }

              return dispatch({
                type: "setValue",
                payload: {
                  ...state.name,
                  dirty: true,
                  value: event,
                  isValid: event.trim().length > 2 && event.trim().length < 21,
                  errorMessage:
                    event.trim().length > 2 && event.trim().length < 21
                      ? ""
                      : !event.trim().length
                      ? "This field is mandatory"
                      : event.trim().length > 20
                      ? "Maximum name field length is 20 characters"
                      : "Minimum name field length is 3 characters",
                },
              });
            }}
            requestInput={(event) => {
              return dispatch({
                type: "setValue",
                payload: {
                  ...state.name,
                  dirty: true,
                  value: event,
                  isValid: event.trim().length > 2 && event.trim().length < 21,
                  errorMessage:
                    event.trim().length > 2 && event.trim().length < 21
                      ? ""
                      : !event.trim().length
                      ? "This field is mandatory"
                      : event.trim().length > 20
                      ? "Maximum name field length is 20 characters"
                      : "Minimum name field length is 3 characters",
                },
              });
            }}
            value={state.name.value as string}
            showLabel={true}
            error={state.name.dirty && !state.name.isValid}
          >
            <InputInlineError
              isVisible={state.name.dirty && !state.name.isValid}
              message={state.name.errorMessage || ""}
            />
          </Input>
        </div>

        <div className="contact-us__row">
          <Input
            id={state.phone.id}
            customClass="invoice-input"
            placeholder={state.phone.label}
            autocomplete={false}
            requestBlur={(event) => {
              if (state.phone.dirty) {
                return;
              }

              const isPhoneValid = !event.length
                ? true
                : phoneRegex.test(event);

              return dispatch({
                type: "setValue",
                payload: {
                  ...state.phone,
                  dirty: true,
                  value: event,
                  isValid: isPhoneValid,
                  errorMessage: isPhoneValid
                    ? ""
                    : "Please use a valid phone number",
                },
              });
            }}
            requestInput={(event) => {
              const isPhoneValid = !event.length
                ? true
                : phoneRegex.test(event);

              return dispatch({
                type: "setValue",
                payload: {
                  ...state.phone,
                  dirty: true,
                  value: event,
                  isValid: isPhoneValid,
                  errorMessage: isPhoneValid
                    ? ""
                    : "Please use a valid phone number",
                },
              });
            }}
            value={state.phone.value as string}
            showLabel={true}
            error={state.phone.dirty && !state.phone.isValid}
          >
            <InputInlineError
              isVisible={state.phone.dirty && !state.phone.isValid}
              message={state.phone.errorMessage || ""}
            />
          </Input>
        </div>

        <div className="contact-us__row">
          <Input
            id={state.email.id}
            customClass="invoice-input"
            placeholder={state.email.label}
            autocomplete={false}
            requestBlur={(event) => {
              if (state.email.dirty) {
                return;
              }

              const isEmailValid = emailRegex.test(event);

              return dispatch({
                type: "setValue",
                payload: {
                  ...state.email,
                  dirty: true,
                  value: event,
                  isValid: isEmailValid,
                  errorMessage: isEmailValid ? "" : "Email field is required",
                },
              });
            }}
            requestInput={(event) => {
              const isEmailValid = emailRegex.test(event);

              return dispatch({
                type: "setValue",
                payload: {
                  ...state.email,
                  dirty: true,
                  value: event,
                  isValid: isEmailValid,
                  errorMessage: isEmailValid ? "" : "Email field is required",
                },
              });
            }}
            value={state.email.value as string}
            showLabel={true}
            error={state.email.dirty && !state.email.isValid}
          >
            <InputInlineError
              isVisible={state.email.dirty && !state.email.isValid}
              message={state.email.errorMessage || ""}
            />
          </Input>
        </div>

        <div className="contact-us__row">
          <div className="invoice-select__container">
            <div className="input__title">Query Type</div>

            <div className="invoice-select__wrapper">
              <Select
                id={state.type.id}
                altAppearance
                requestClick={() => {
                  if (state.type.dirty) {
                    return;
                  }
                }}
                value={state.type.value}
                customClassName={`invoice-select ${
                  !state.type.isValid && state.type.dirty
                    ? "invoice-select--error"
                    : ""
                }`}
                placeholder={state.type.label}
                data={queryTypeOptions.map((item) => ({
                  id: item.id,
                  content: item.content,
                }))}
                requestChange={(event) => {
                  return dispatch({
                    type: "setTypeValue",
                    payload: {
                      ...state.type,
                      dirty: true,
                      value: event,
                      isValid: event.length > 0,
                      errorMessage:
                        event.length > 0 ? "" : "This field is required",
                    },
                  });
                }}
              />
            </div>

            <InputInlineError
              isVisible={state.type.dirty && !state.type.isValid}
              message={state.type.errorMessage || ""}
            />
          </div>

          <div className="invoice-select__container">
            <div className="input__title">Query Type</div>

            <div className="invoice-select__wrapper">
              <Select
                id={state.subType.id}
                altAppearance
                requestClick={() => {
                  if (state.subType.dirty) {
                    return;
                  }
                }}
                isDisabled={!isSubTypeAvailable}
                value={(state.subType.value as string) || ""}
                customClassName={`invoice-select ${
                  !state.subType.isValid && state.subType.dirty
                    ? "invoice-select--error"
                    : ""
                }`}
                placeholder={state.subType.label}
                data={getSubTypeOptions.map((item) => ({
                  id: item.id,
                  content: item.content,
                }))}
                requestChange={(event) => {
                  return dispatch({
                    type: "setValue",
                    payload: {
                      ...state.subType,
                      dirty: true,
                      value: event,
                      isValid: event.length > 0,
                      errorMessage:
                        event.length > 0 ? "" : "This field is required",
                    },
                  });
                }}
              />
            </div>

            <InputInlineError
              isVisible={state.subType.dirty && !state.subType.isValid}
              message={state.subType.errorMessage || ""}
            />
          </div>
        </div>

        <div className="contact-us__row">
          <div className="invoice-select__container">
            <div className="input__title">Query</div>

            <div className="invoice-select__wrapper">
              <TextArea
                customClass={`invoice-input ${
                  !state.query.isValid && state.query.dirty
                    ? "invoice-input--error"
                    : ""
                }`}
                id={state.query.id}
                placeholder={state.query.label}
                value={state.query.value}
                requestInput={(event) => {
                  return dispatch({
                    type: "setValue",
                    payload: {
                      ...state.query,
                      dirty: true,
                      value: event,
                      isValid: event.length > 10 && event.length < 1000,
                      errorMessage: !event.length
                        ? "This field is required"
                        : event.length < 10
                        ? "Minimum field length is 10 chars"
                        : event.length > 1000
                        ? "Maximum query length is 1000 chars"
                        : "",
                    },
                  });
                }}
              >
                <>
                  <div
                    className={`contact-us__counter ${
                      !state.query.isValid ? "contact-us__counter--error" : ""
                    }`}
                  >
                    {state.query.value.length} / 1000
                  </div>
                  <InputInlineError
                    isVisible={state.query.dirty && !state.query.isValid}
                    message={state.query.errorMessage || ""}
                  />
                </>
              </TextArea>
            </div>
          </div>
        </div>

        <button
          type="reset"
          onClick={handleFormSubmit}
          className="invoice-upload__send btn btn--default"
          disabled={!isFormValid}
        >
          Send Message <SendIcon />
        </button>
      </form>
    </div>
  );

  function handleFormSubmit() {
    const category = queryTypeOptions.find(
      (item) => item.id === state.type.value
    );
    const subCategory = getSubTypeOptions.find(
      (item) => item.id === state.subType.value
    );

    mutate({
      name: state.name.value,
      email: state.email.value,
      phone: state.phone.value,
      category: category ? category.content : "",
      subCategory: subCategory ? subCategory.content : "",
      message: state.query.value,
    });

    dispatchStore(
      startAddNotification({
        title: "Success",
        description: "Message successfully sent",
        type: INotificationType.SUCCESS,
      })
    );

    dispatch({
      type: "resetState",
    });

    return dispatch({
      type: "preloadUserValue",
      payload: {
        name: state.name.value,
        email: state.email.value,
      },
    });

    // return dispatch({
    //   type: "resetState",
    // });
  }
}
