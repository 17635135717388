import './textArea.scss'
import {ForwardedRef, forwardRef, PropsWithChildren} from "react";

interface TextAreaComponentProps {
  id: string,
  placeholder: string,
  value: string,
  rows?:number,
  columns?:number,
  maxChar?:number,
  requestInput: (value: string) => void,
  customClass?: string,
  defaultValue?: string
  autocomplete?: boolean
  requestClear?: () => void,
  disabled?:boolean,
  error?: boolean
}

function TextArea({id, placeholder, children, customClass, defaultValue, requestInput, autocomplete, value, rows, columns, maxChar, requestClear, disabled, error}: PropsWithChildren<TextAreaComponentProps>, ref: ForwardedRef<HTMLTextAreaElement>) {
  return (
    <label className={`textArea textArea__label ${customClass && customClass.length > 0 ? customClass : ''}`} htmlFor={id}>
      <textarea
        ref={ref}
        autoComplete={autocomplete !== undefined ? 'off' : ''}
        id={id}
        className={`textArea__element 
                    ${value.length > 0 ? 'textArea__element--has-value' : ''}
                    ${error ? 'textArea__element--error' : ''}
                    ${disabled ? 'textArea__element--disabled' : ''}`}
        placeholder={placeholder || ''}
        value={value}
        rows={rows}
        cols={columns}
        maxLength={maxChar}
        disabled={disabled}
        onChange={e => {
          if (!requestInput) {
            return;
          }

          return requestInput(e.target.value);
        }}/>
      {
        children ? children : null
      }
    </label>
  )
}

export default forwardRef<HTMLTextAreaElement, PropsWithChildren<TextAreaComponentProps>>(TextArea);
