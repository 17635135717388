import {IAddUserModalButtons} from "../../../../users/add-user-modal/addUserModalButtons";
import {IProfilePasswordModalAction} from "../../../../users/types";
import "./bank-detail-item.modal-buttons.scss";

export default function BankDetailItemModalButtons({isLoading, requestClick, isDisabled}: IAddUserModalButtons) {
  return (
    <div className="change-pass-modal-footer modal__footer d-flex invoice-modal__buttons">
      <button type="button"
              disabled={isLoading}
              className={`${isLoading ? 'change-pass-modal-footer__action--disabled' : ''} change-pass-modal-footer__action modal__footer__action btn btn--mute`}
              onClick={() => {
                if (isLoading) {
                  return;
                }

                return requestClick(IProfilePasswordModalAction.CANCEL);
              }}>
        Cancel
      </button>

      <button type="button"
              disabled={isDisabled || isLoading}
              className={`${isDisabled || isLoading ? 'invoice-modal__action-submit--disabled' : ''} invoice-modal__action-submit modal__footer__action btn btn--default`}
              onClick={() => {
                if (isDisabled || isLoading) {
                  return;
                }

                return requestClick(IProfilePasswordModalAction.SAVE);
              }}>
        Update Bank Details
      </button>
    </div>
  )
}
