import { Link, useLocation, useNavigate } from "react-router-dom";
import { ReactComponent as Logo } from "../../../assets/icons/logo.svg";
import { ReactComponent as CardIcon } from "../../../assets/icons/card.svg";
import HeaderMeta from "./meta/Header.meta";
import HeaderNav from "./nav/Header.nav";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import {
  isPayeeSelected,
  selectCurrentPayee,
} from "../../../redux/user/selectors";
import "./header.scss";
import RemovePayeeModal from "./remove-payee-modal/RemovePayeeModal";
import { IProfilePasswordModalAction } from "../../../pages/users/types";
import { useRef, useState } from "react";
import { updateUserSelectedPayeeAccount } from "../../../redux/user/user";
import useClickOutside from "../../../tools/hooks/useClickOutside";
import { ReactComponent as PayeeIcon } from "../../../assets/icons/exchange.svg";

export default function Header() {
  const isPayeeSelectedState = useAppSelector(isPayeeSelected);
  const currentPayeeState = useAppSelector(selectCurrentPayee);
  const navigate = useNavigate();
  const location = useLocation();

  const element = useRef(null);
  const [isMobilePayeeVisible, setIsMobilePayeeVisible] = useState(false);

  const [isModalVisible, setModalVisibility] = useState(false);

  const dispatchStore = useAppDispatch();

  useClickOutside(element, () => {
    if (isMobilePayeeVisible) {
      setIsMobilePayeeVisible(false);
    }
  });

  return (
    <>
      <header role="banner" className="app-header">
        <div className="animated-max-width container-fluid app-header--full">
          <div className="row">
            <div className="col d-flex justify-content-space-between align-items-center">
              <div className="d-flex">
                <Link
                  to="/"
                  onClick={(event) => {
                    if (location.pathname !== "/") {
                      return;
                    }

                    return event.preventDefault();
                  }}
                  className="app-logo d-flex"
                >
                  <Logo />
                </Link>

                <div className="app-nav--fs d-flex">
                  <HeaderNav />
                </div>
              </div>

              <div className="d-flex align-items-center">
                <div className="header__payee">
                  {!isPayeeSelectedState || !currentPayeeState ? (
                    <strong
                      className={`header__payee--content header__payee--missing`}
                    >
                      <CardIcon />
                      No Payee Account activated
                    </strong>
                  ) : null}

                  {isPayeeSelectedState && currentPayeeState ? (
                    <button
                      type="button"
                      className="header__payee--card btn--reset"
                      onClick={() =>
                        handlePayeeModalActions(
                          IProfilePasswordModalAction.OPEN
                        )
                      }
                    >
                      <CardIcon />

                      {currentPayeeState.name +
                        " - " +
                        currentPayeeState.payeeCurrency}

                      <span className="header__payee--card-trash">
                        <PayeeIcon />
                      </span>
                    </button>
                  ) : null}
                </div>

                <div className="mobile-payee" ref={element}>
                  <button
                    type="button"
                    className="mobile-payee__action header-action"
                    onClick={() => {
                      return setIsMobilePayeeVisible(!isMobilePayeeVisible);
                    }}
                  >
                    <CardIcon />
                  </button>

                  {isMobilePayeeVisible && (
                    <div className="mobile-payee__content">
                      {!isPayeeSelectedState || !currentPayeeState ? (
                        <strong
                          className={`header__payee--content header__payee--missing`}
                        >
                          No Payee Account activated
                        </strong>
                      ) : null}

                      {isPayeeSelectedState && currentPayeeState ? (
                        <button
                          type="button"
                          className="mobile-payee__action--card header__payee--card btn--reset"
                          onClick={() =>
                            handlePayeeModalActions(
                              IProfilePasswordModalAction.OPEN
                            )
                          }
                        >
                          <CardIcon />

                          {currentPayeeState.name}

                          <span className="header__payee--card-trash">
                            <PayeeIcon />
                          </span>
                        </button>
                      ) : null}
                    </div>
                  )}
                </div>

                <HeaderMeta />
              </div>
            </div>
          </div>

          <RemovePayeeModal
            isVisible={isModalVisible}
            requestClick={handlePayeeModalActions}
            onToggle={() =>
              handlePayeeModalActions(IProfilePasswordModalAction.CANCEL)
            }
          />
        </div>
      </header>

      {isPayeeSelectedState && currentPayeeState ? (
        <button
          type="button"
          className="header__payee header__payee--card header__payee--card--movable btn--reset"
          onClick={() =>
            handlePayeeModalActions(IProfilePasswordModalAction.OPEN)
          }
        >
          <CardIcon />

          {currentPayeeState.name}

          <span className="header__payee--card-trash">
            <PayeeIcon />
          </span>
        </button>
      ) : null}
    </>
  );

  function handlePayeeModalActions(event: IProfilePasswordModalAction) {
    switch (event) {
      case IProfilePasswordModalAction.SAVE:
        setModalVisibility(false);

        navigate("/");

        return dispatchStore(updateUserSelectedPayeeAccount(null));

      case IProfilePasswordModalAction.OPEN:
        return setModalVisibility(true);

      default:
        return setModalVisibility(false);
    }
  }
}
