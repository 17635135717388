import axios, {AxiosResponse} from "axios";
import {APIS} from "../../../../static/apis";

export interface IGetVoucherHistory {
  data: IGetVoucherHistoryData[],
  totalResults: number,
  activePage: number,
  pages: number
}

export interface IGetVoucherHistoryData {
  amount: number,
  currency: string
  date: string,
  invNumber: string,
  status: string,
}

export interface IGetVoucherHistoryPayload {
  payeeNumber: string
  fromDate?: string,
  toDate?: string,
  searchTerm?: string,
  page?: number,
  statuses?: string
  currencies?: string
}

export function getVoucherHistory ({payeeNumber, toDate, fromDate, searchTerm, page, statuses, currencies}: IGetVoucherHistoryPayload): Promise<AxiosResponse<{data : IGetVoucherHistory}>> {
  return axios.get(
    APIS.BASE_AUTH_MS_URL + APIS.GET_VOUCHER_HISTORY + '/' + payeeNumber,
    {
      params: {
        toDate,
        fromDate,
        page,
        searchTerm,
        statuses,
        currencies
      }
    }
  );
}
