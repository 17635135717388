import Header from "../header/Header";
import {Outlet, useLocation} from "react-router-dom";
import Footer from "../footer/Footer";
import NavItems from "../../../redux/static/navItems";

export default function PrimaryLayout()  {
  const location = useLocation();
  const currentPage = NavItems.find(ni => ni.path === location.pathname)
  return (
    <div className='page page--primary d-flex f-column' data-title={currentPage?.name}>
      <Header />

      <main role="main">
        <Outlet />
      </main>

      <Footer />
    </div>
  )
}
