import {useMutation} from "@tanstack/react-query";
import {sendInvitationService} from "../../user/saga/auth/send-invitation";
import {startAddNotification} from "../../core/core";
import {INotificationType} from "../../../components/ui/notifications/item/NotificationItem";
import {useAppDispatch} from "../../hooks";

export const IUseQuerySendInvite = 'IUseQuerySendInvite';

export interface IUseQuerySendInvitePayload {
  email: string,
  inviterEmail: string,
  uuid: string
}

export function useQuerySendInvite () {
  const dispatchStore = useAppDispatch();

  return useMutation({
    mutationKey:  [IUseQuerySendInvite],
    mutationFn: ({email, inviterEmail, uuid}: IUseQuerySendInvitePayload) => sendInvitationService(email, inviterEmail, uuid),
    onMutate: () => {
      dispatchStore(startAddNotification({
        title: "Success",
        description: `Successfully sent user invitation`,
        type: INotificationType.SUCCESS
      }));
    },
    onError: (err, newItem, context) => {
        dispatchStore(startAddNotification({
          title: "Error",
          description: `User invite revoked, api error occurred`,
          type: INotificationType.ERROR
        }));
      },
    }
  )
}
