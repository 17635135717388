import {
  IGenericAuthResponse,
} from "../../../user";
import axios, {AxiosResponse} from "axios";
import {APIS} from "../../../../static/apis";
import {IPayeeAccounts} from "../../../../companies/companies";

export async function getAvailablePayees (): Promise<AxiosResponse<IGenericAuthResponse<IPayeeAccounts[]>>> {
  return axios.post(
    APIS.BASE_AUTH_MS_URL + APIS.AUTH_GET_ACCESS_TOKEN,
    {},
    {
      withCredentials: true,
    }
  )
}

