import axios, { AxiosResponse } from "axios";
import { APIS } from "../../../../static/apis";
import { IPayeeAccounts } from "../../../../companies/companies";

export function addPayeeToCompanyService(
  name: string,
  payeeNumber: string,
  companyId: string,
  payeeCurrency: string
): Promise<AxiosResponse<{ data: IPayeeAccounts }>> {
  return axios.put(APIS.BASE_AUTH_MS_URL + APIS.ADD_PAYEE_TO_COMPANY, {
    name,
    payeeNumber,
    companyId,
    payeeCurrency,
  });
}
