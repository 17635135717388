import axios, { AxiosResponse } from "axios";
import { IGenericAuthResponse } from "../../../user";
import { APIS } from "../../../../static/apis";

export interface IUpdatePasswordService {
  password: string,
  newPassword: string,
}

export function updatePasswordService ({password, newPassword}: IUpdatePasswordService): Promise<AxiosResponse<IGenericAuthResponse<boolean>>> {
  return axios.patch(
    APIS.BASE_AUTH_MS_URL + APIS.UPDATE_PASSWORD,
    {
      newPassword,
      password
    },
    {
      withCredentials: true
    }
  );
}
