import axios, {AxiosResponse} from "axios";
import {APIS} from "../../../../static/apis";

export function updatePayeeService (id: number, name: string, payeeNumber: string): Promise<AxiosResponse<{data : boolean}>> {
  return axios.put(
    APIS.BASE_AUTH_MS_URL + APIS.UPDATE_PAYEE, {
      id,
      name,
      payeeNumber
    }
  );
}
