import {useMutation} from "@tanstack/react-query";
import {startAddNotification} from "../../core/core";
import {INotificationType} from "../../../components/ui/notifications/item/NotificationItem";
import {useAppDispatch} from "../../hooks";
import { IUpdatePasswordService, updatePasswordService } from "../../user/saga/auth/update-password";

export const IUseMutationUpdatePassword = 'IUseMutationUpdatePassword';

export function useMutationUpdatePassword() {
  const dispatchStore = useAppDispatch();

  return useMutation({
    mutationKey: [IUseMutationUpdatePassword],
    mutationFn: (data: IUpdatePasswordService) => updatePasswordService(data),
    onSuccess: async () => {
      dispatchStore(startAddNotification({
        title: "Success",
        description: `Password successfully updated`,
        type: INotificationType.SUCCESS
      }));

      return {};
    },
    onError: () => {
      dispatchStore(startAddNotification({
        title: "Error",
        description: `Unable to set new password`,
        type: INotificationType.ERROR
      }));
    }
  })
}
