import TextField from '@mui/material/TextField';
import {DatePicker} from "@mui/x-date-pickers";
import moment from "moment";
import {ReactComponent as CloseIcon} from "../../../assets/icons/close-alt.svg";
import "./history-dates.scss";

export interface IHistoryDate {
  fromDate: moment.Moment | null,
  toDate: moment.Moment | null,
  setFromDate: (state: moment.Moment | null) => void
  setToDate: (state: moment.Moment | null) => void
}

export default function HistoryDatesForm({fromDate, setFromDate, toDate, setToDate}: IHistoryDate) {
  return (
    <div className="history__dates-form">
      <div className="d-flex invoice-upload history-datepicker">
        <span className="input__title">
          From Date
        </span>

        <DatePicker
          className="invoice-datepicker"
          inputFormat="DD/MM/YYYY"
          disableFuture
          value={fromDate}
          desktopModeMediaQuery={'@media (min-width: 1px)'}
          onChange={setFromDate}
          maxDate={toDate || undefined}
          renderInput={(params: any) => <TextField {...params} />}
        />

        {
          fromDate !== null &&
          <button type="button"
                  className="history-clear-date btn--reset"
                  onClick={() => setFromDate(null)}>
            <CloseIcon/>
          </button>
        }
      </div>

      <div className="d-flex invoice-upload history-datepicker">
        <span className="input__title">
          To Date
        </span>

        <DatePicker
          className="invoice-datepicker"
          inputFormat="DD/MM/YYYY"
          desktopModeMediaQuery={'@media (min-width: 1px)'}
          disableFuture
          value={toDate}
          onChange={setToDate}
          minDate={fromDate || undefined}
          renderInput={(params: any) => <TextField {...params} />}
        />

        {
          toDate !== null &&
          <button type="button"
                  className="history-clear-date btn--reset"
                  onClick={() => setToDate(null)}>
            <CloseIcon/>
          </button>
        }
      </div>
    </div>
  );
}
