import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ICompany, IPayeeAccounts } from "../companies/companies";
import { IUserDarkMode } from "../core/core";

export enum IUserRole {
  admin = "admin",
  user = "user",
  //contributor = "contributor",
  supervisor = "supervisor",
}

export enum IUserStatus {
  PENDING = "pending",
  PENDING_2FA = "pending_2fa",
  ACTIVATED = "active",
  DISABLED = "disabled",
}

export enum IUserProvider {
  DEFAULT = "default",
  MICROSOFT = "microsoft",
}

export interface IUpdateUserRole {
  uuid: string;
  email: string;
  role: IUserRole;
}

export interface IUpdateUserStatus {
  uuid: string;
  email: string;
  status: IUserStatus;
}
export interface IManualRegistration {
  email: string;
  name: string;
}

export interface IStartAccountActivationResponse {
  uuid: string;
  challenge: string;
  provider: string;
  qrCode?: string;
}

export interface IUser {
  uuid: string | null;
  email: string | null;
  createdOn: string | null;
  devices: any[] | null;
  name: string | null;
  userAvatar: string | null;
  provider: IUserProvider | null;
  status: IUserStatus | null;
  role: IUserRole | null;
  vendorId: string | null;
  vendorName: string | null;
  currentPayee?: IPayeeAccounts | null;
  availablePayees: IPayeeAccounts[] | null;
  payees?: IPayeeAccounts[] | null;
  company?: ICompany | null;
  darkMode?: IUserDarkMode | null;
}

export interface ICheckNewBiometricDevice {
  provider: string;
  uuid: string;
  challenge: string;
  challengeMakeCred: any;
}

export interface IVerifyNewBiometricDevice {
  id: string;
  rawId: string;
  challenge: string;
  transports: any;
  response: {
    attestationObject: string;
    clientDataJSON: string;
  };
  type: string;
}

export interface IUserDefaultState {
  accessToken: string | null;
  accountHasBiometricDevices: boolean;
}

const initialState: IUser & IUserDefaultState = {
  uuid: null,
  email: null,
  createdOn: null,
  devices: null,
  name: null,
  userAvatar: null,
  provider: null,
  status: null,
  role: null,
  accessToken: null,
  accountHasBiometricDevices: false,
  vendorName: null,
  vendorId: null,
  availablePayees: [],
  company: null,
  payees: null,
  darkMode: IUserDarkMode.NOT_SET,
};

export interface ICreateMsAccount {
  name: string;
  email: string;
  idToken: string;
  accessToken: string;
}

export interface IStartBiometricRegistration {
  uuid: string;
  registrationUuid: string;
  challenge: string;
}

export interface IFinishAuth {
  id: string;
  code: string;
  verifier: string;
  email: string;
}

export interface IStartAuth {
  email: string;
  code_challenge: string;
}

export interface IAuth {
  createdOn: string | null;
  devices: any[] | null;
  email: string | null;
  name: string | null;
  provider: IUserProvider | null;
  role: IUserRole | null;
  userAvatar: string | null;
  uuid: string | null;
  status: IUserStatus | null;
  vendorId: string | null;
  vendorName: string | null;
  availablePayees: IPayeeAccounts[];
}

export interface IGetPkceCombination {
  code_verifier: string;
  code_challenge: string;
}

export interface IFinishAccountActivation {
  registrationUuid: string;
  uuid: string;
  challenge: string;
  password?: string;
  qrCode?: string;
}

export interface IFinishBiometricRegistration {
  payload: any;
  challenge: any;
  uuid: string;
  biometricRegistrationUuidData: string;
}

export interface IGenericAuthResponse<T> {
  data: T;
}

// bootstrap stuff
const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    clearUserData(state) {
      state.email = null;
      state.name = null;
      state.uuid = null;
      state.provider = null;
      state.role = null;
      state.userAvatar = null;
      state.status = null;
      state.createdOn = null;
      state.devices = null;
      state.accessToken = null;
      state.accountHasBiometricDevices = false;
      state.currentPayee = null;
    },
    updateAccountHasBiometricDevices(state, action: PayloadAction<boolean>) {
      state.accountHasBiometricDevices = action.payload;
    },
    updateUserAccessToken(state, action: PayloadAction<string>) {
      state.accessToken = action.payload;
    },
    startLogout() {},
    updateUserCredentialsData(state: IUser, action: PayloadAction<IUser>) {
      for (const [key, value] of Object.entries(action.payload)) {
        state[key as keyof typeof state] = value;
      }
    },
    updateUserAvailablePayeeAccounts(
      state: IUser,
      action: PayloadAction<IPayeeAccounts[]>
    ) {
      state.availablePayees = action.payload;
    },
    updateUserSelectedPayeeAccount(
      state: IUser,
      action: PayloadAction<IPayeeAccounts | null>
    ) {
      state.currentPayee = action.payload;
    },
    updateUserEmail(state, action: PayloadAction<string>) {
      state.email = action.payload;
    },
    updateUserName(state, action: PayloadAction<string>) {
      state.name = action.payload;
    },
    updateDarkMode(state, action: PayloadAction<IUserDarkMode>) {
      state.darkMode = action.payload;
    },
  },
});

// prepare actions
export const {
  updateUserEmail,
  updateUserAvailablePayeeAccounts,
  updateDarkMode,
  updateUserSelectedPayeeAccount,
  updateAccountHasBiometricDevices,
  updateUserAccessToken,
  startLogout,
  clearUserData,
  updateUserCredentialsData,
  updateUserName,
} = userSlice.actions;

// prepare reducers
export default userSlice.reducer;
