import Modal, {IModalType} from "../../../components/ui/modal/Modal";
import UpdateStatusModalButtons from "./updateStatusModalButtons";
import {IProfilePasswordModalAction} from "../types";
import Input from "../../../components/ui/inputs/Input";
import {CSSTransition} from "react-transition-group";
import {useEffect, useRef, useState} from "react";
/*import {useAppSelector} from "../../../redux/hooks";
import {isAppDeactivatingAccount} from "../../../redux/core/selectors";*/
import {IUserStatus} from "../../../redux/user/user";
import Select from "../../../components/ui/select/Select";

export interface IUsersTableDeactivateModal {
  isVisible: boolean,
  emailToCheck: string,
  statusToCheck: IUserStatus | undefined,
  requestClick: (event: IProfilePasswordModalAction, payload?: string) => void
}

export default function UsersTableUpdateStatusModal({isVisible, requestClick, emailToCheck, statusToCheck}: IUsersTableDeactivateModal) {
  const errorRef = useRef<HTMLSpanElement>(null);

  /*const isAppDeactivatingAccountState = useAppSelector(isAppDeactivatingAccount);*/

  const [userEmail, setUserEmail] = useState('');

  const [isValid, setIsValid] = useState(false);

  const [error, setErrorMessage] = useState('');

  const [selectedOption, setSelectedOption] = useState('');

  useEffect(() => {
    setIsValid(userEmail === emailToCheck && statusToCheck !== selectedOption);
    setErrorMessage(!userEmail || !userEmail.length ? '' :
      userEmail !== emailToCheck ? 'Email address is incorrect' :
        statusToCheck === selectedOption ? 'Status not changed' : '');

  }, [userEmail, emailToCheck, statusToCheck, selectedOption]);

  useEffect(() => {
    if (statusToCheck) {
      setSelectedOption(statusToCheck);
    }
  }, [statusToCheck])

  useEffect(() => {
    return () => {
      if (isVisible) {
        setErrorMessage('');
        setUserEmail('');
        setIsValid(false);
        setSelectedOption('');
      }
    }
  }, [isVisible])

  return (
    <Modal title={'Update Account Status'}
           type={IModalType.ERROR}
           customClass={'password-modal deactivate-modal'}
           toggleVisibility={isVisible}
           customBtns={ <UpdateStatusModalButtons requestClick={(event) => {
             switch (event) {
               case IProfilePasswordModalAction.SAVE:
                 return requestClick(IProfilePasswordModalAction.SAVE, selectedOption);

               case IProfilePasswordModalAction.CANCEL:
                 return requestClick(IProfilePasswordModalAction.CANCEL);

               default: return;
             }
           }}
           isDisabled={!isValid}
           isLoading={/*isAppDeactivatingAccountState*/false} /> }
           onToggle={(event) => {
             requestClick(IProfilePasswordModalAction.CANCEL)
           }}>

      <p className="register-form__intro">
        This action will update a user account status, meaning that for the following:

        <br />
        <br />
      </p>

      <h4 className="role-modal__heading">
        Disabled Status
      </h4>

      <ul className="list list--standard role-modal__list">
        <li>
          User data will be preserved
        </li>
        <li>
          User will NOT be able to login and / or access any part of the application
        </li>
        <li>
          User can be re-activated at any time, after which, the access to the application will return to normal, as defined by <strong>user role access</strong>
        </li>
      </ul>

      <h4 className="role-modal__heading">
        Activated Status
      </h4>

      <ul className="list list--standard role-modal__list">
        <li>
          User can access parts of the application, depending on the role assigned
        </li>
      </ul>

      <br />

      <p className="register-form__intro">
        Current user role: <strong>{statusToCheck}</strong>

        <br />
      </p>

      <Select id={'status_select'}
              customClassName={'role-modal__select'}
              defaultValue={statusToCheck}
              data={[IUserStatus.ACTIVATED, IUserStatus.DISABLED]}
              requestChange={setSelectedOption}
              selectedItem={selectedOption}
      />

      <p>
        Please confirm this action by adding the users email address below

        <br />
        <br />
      </p>

      <Input id={'current_email'}
             error={userEmail.length > 0 && !isValid}
             customClass="password-modal__input-el"
             type="email"
             placeholder={'Email Address'}
             value={userEmail}
             requestInput={setUserEmail}  />

      <span className="register-form__form-error-container">
        <CSSTransition
          classNames="slide-in-out-animation"
          timeout={250}
          in={!isValid && error.length > 0}
          nodeRef={errorRef}
          unmountOnExit
        >
          <span ref={errorRef} className="register-form__form-error">
           {error}
          </span>
        </CSSTransition>
      </span>

    </Modal>
  )
}
