import { IProfilePasswordModalAction } from "../../users/types";
import Modal, { IModalType } from "../../../components/ui/modal/Modal";
import Input from "../../../components/ui/inputs/Input";
import { STATIC_STRINGS } from "../../../redux/static/staticTexts";
import InputInlineError from "../../../components/ui/inputs/inline-error/Input.inline-error";
import PasswordStrength from "../../../components/ui/password-strength/PasswordStrength";
import { FormEvent, useCallback, useEffect, useMemo, useReducer } from "react";
import { goodRegEx } from "../../../components/specific/register/password/Password";
import { initialState, reducer } from "./state";
import {ReactComponent as ShowPasswordIcon } from './../../../assets/icons/eye.svg';
import {ReactComponent as HidePasswordIcon } from './../../../assets/icons/eye-closed.svg';
import InlineLoader from "../../../components/ui/inline-loader/InlineLoader";
import './profile.change-password.scss';

export interface IUpdatePasswordModalPayload {
  password: string,
  newPassword: string
}

export interface IProfileDeleteDeviceModal {
  isVisible: boolean,
  onToggle: (state: boolean) => void,
  isDisabled?: boolean,
  requestClick: (event: IProfilePasswordModalAction, payload? :IUpdatePasswordModalPayload) => void
}

export default function ProfileChangePasswordModal ({isVisible, onToggle, requestClick, isDisabled}: IProfileDeleteDeviceModal) {
  const [state, dispatch] = useReducer(reducer, {
    ...initialState
  });

  const checkPassError = useCallback((key: keyof typeof state) => {
    if (!(state[key] as string).length) {
      return {
        message: '',
        isValid: false
      }
    }

    if ((state[key] as string).length > 30) {
      return {
        message: 'Password length should be less than 30 chars',
        isValid: false
      }
    }

    if ((state[key] as string).length < 8) {
      return {
        message: 'Password length should be more than 8 chars',
        isValid: false
      }
    }

    if (!goodRegEx.test((state[key] as string))) {
      return {
        message: 'Password does not meet the required criteria',
        isValid: false
      }
    }

    return {
      message: '',
      isValid: true
    }
  }, [state])

  const arePassEqual = useMemo(() => {
    return state.password === state.rePassword
  }, [state.password, state.rePassword]);

  const passMeetsMinScore = useMemo(() => {
    return state.passwordStrength >= 2
  }, [state.passwordStrength]);

  const cb = useCallback((e: number) => {
    if (e === state.passwordStrength) {
      return;
    }

    dispatch({
      type: 'setPassStrength',
      payload: e
    })
  }, [dispatch, state.passwordStrength]);

  useEffect(() => {
    return () => {
      if (!isVisible) {
        dispatch({
          type: "resetState"
        });
      }
    }
  }, [isVisible])

  return (
    <Modal title="Update Account Password"
           toggleVisibility={isVisible}
           type={IModalType.NOTIFY}
           onToggle={onToggle}
           customClass={"update-account-password-modal add-user-modal profile-delete-modal profile-change-password-modal"}
           customBtns={
            <div className="modal__footer d-flex">
              {
                isDisabled && <InlineLoader />
              }

              <button type="button"
                      className={`change-pass-modal-footer__action modal__footer__action btn btn--mute`}
                      disabled={isDisabled}
                      onClick={() => {
                        return requestClick(IProfilePasswordModalAction.CANCEL);
                      }}>
                Cancel
              </button>

              <button type="button"
                      className={`change-pass-modal-footer__action modal__footer__action btn btn--default`}
                      disabled={isDisabled || !arePassEqual || !checkPassError('password').isValid || !checkPassError('rePassword').isValid || !checkPassError('currentPassword').isValid|| !passMeetsMinScore}
                      onClick={() => {
                        return requestClick(IProfilePasswordModalAction.SAVE, {
                          newPassword: state.password,
                          password: state.currentPassword
                        });
                      }}>
                Continue
              </button>
            </div>


           }>
      <form onSubmit={handleSubmit} className="sign-in sign-in__form sign-in--reset-pass sign-in--change-pass">
        <p>
          In order to change your account password, please make sure that your password contains at least:
        </p>

        <ul className="register-form__list list list--standard">
          <li>
            <strong>Minimum of 8</strong> or <strong>maximum of 30 characters</strong>
          </li>

          <li>
            At least <strong>1 uppercase letter</strong>
          </li>

          <li>
            At least <strong>1 lowercase letter</strong>
          </li>

          <li>
            At least <strong>1 special symbol character</strong>, ex: @ # / ! ? ; '
          </li>

          <li>
            At least <strong>1 number</strong>
          </li>

          <li>
            At least <strong>Medium</strong> strength
          </li>
        </ul>

        <Input autocomplete={false}
               type={state.currentPasswordType}
               error={state.currentPassword.length > 0 && (!checkPassError('currentPassword').isValid || (state.password.length > 0 && state.currentPassword === state.password))}
               id={'login__password'}
               placeholder={STATIC_STRINGS.SIGN_IN.FORM.CURRENT_PASSWORD_PLACEHOLDER}
               value={state.currentPassword}
               requestInput={(e) => dispatch({
                 type: 'setCurrentPassword',
                 payload: e
               })}>
          <button type="button" className="btn--reset sign-in__show-pass-btn" onClick={() => dispatch({
            type: 'setCurrentPasswordType',
            payload: state.currentPasswordType === 'password' ? 'text' : 'password'
          })}>
            {
              state.currentPasswordType === 'password' ? <ShowPasswordIcon /> : <HidePasswordIcon />
            }
          </button>

          <InputInlineError
            isVisible={(!checkPassError('currentPassword').isValid && checkPassError('currentPassword').message.length > 0) || ((state.currentPassword.length > 0 && state.password.length > 0 && state.currentPassword === state.password))}
            message={(checkPassError('currentPassword').message || (state.password.length > 0 && state.currentPassword === state.password ? 'Current password is the same with the new password' : ''))}
          />
        </Input>

        <Input autocomplete={false}
               type={state.passwordType}
               error={state.password.length > 0 && (!checkPassError('password').isValid || !passMeetsMinScore)}
               id={'login__password'}
               placeholder={STATIC_STRINGS.SIGN_IN.FORM.PASSWORD_PLACEHOLDER}
               value={state.password}
               requestInput={(e) => dispatch({
                 type: 'setPassword',
                 payload: e
               })}>
          <button type="button" className="btn--reset sign-in__show-pass-btn" onClick={() => dispatch({
            type: 'setPasswordType',
            payload: state.passwordType === 'password' ? 'text' : 'password'
          })}>
            {
              state.passwordType === 'password' ? <ShowPasswordIcon /> : <HidePasswordIcon />
            }
          </button>

          <InputInlineError
            isVisible={(!checkPassError('password').isValid && checkPassError('password').message.length > 0) || (state.password.length > 0 && !passMeetsMinScore)}
            message={(checkPassError('password').message || "") || (state.password.length > 0 && !passMeetsMinScore ? 'Password does not meet required strength' : '')}
          />

        </Input>

        <Input autocomplete={false}
               type={state.rePasswordType}
               error={state.rePassword.length > 0 && (!checkPassError('rePassword').isValid || !arePassEqual)}
               id={'re_login__password'}
               placeholder={STATIC_STRINGS.SIGN_IN.FORM.PASSWORD_RETYPE_PLACEHOLDER}
               value={state.rePassword}
               requestInput={(e) => dispatch({
                 type: 'setRePassword',
                 payload: e
               })}>
          <button type="button" className="btn--reset sign-in__show-pass-btn" onClick={() => dispatch({
            type: 'setRePasswordType',
            payload: state.rePasswordType === 'password' ? 'text' : 'password'
          })}>
            {
              state.rePasswordType === 'password' ? <ShowPasswordIcon /> : <HidePasswordIcon />
            }
          </button>

          <InputInlineError
            isVisible={(!checkPassError('rePassword').isValid && checkPassError('rePassword').message.length > 0) || !arePassEqual}
            message={(checkPassError('rePassword').message || "") || (!arePassEqual ? 'Passwords do not match' : '')}/>
        </Input>

        <PasswordStrength value={!checkPassError('password').isValid ? '' : state.password} requestStrengthUpdate={cb} />
      </form>
    </Modal>
  );

  function handleSubmit(event: FormEvent) {
    return event.preventDefault();
  }
}

