import {getCompanies} from "../../user/saga/vendor/get-companies";
import {useQuery} from "@tanstack/react-query";

export const IUseCompanyQuery = 'IUseCompanyQuery';

export function useQueryCompanies (condition: boolean) {
  return useQuery([IUseCompanyQuery], getCompanies, {
    enabled: condition
  });
}
