import {
  IGenericAuthResponse,
  IUser,
} from "../../../user";
import axios, {AxiosResponse} from "axios";
import {APIS} from "../../../../static/apis";

export async function addUserService (name: string, email: string): Promise<AxiosResponse<IGenericAuthResponse<IUser>>> {
  return axios.post(
    APIS.BASE_AUTH_MS_URL + APIS.ADD_REGISTRATION,
    {
      name,
      email
    },
    {
      withCredentials: true
    }
  )
}
