import {useMutation} from "@tanstack/react-query";
import {AxiosResponse} from "axios";
import {IGenericAuthResponse} from "../../user/user";
import {queryClient} from "../../../App";
import {startAddNotification} from "../../core/core";
import {INotificationType} from "../../../components/ui/notifications/item/NotificationItem";
import {useAppDispatch} from "../../hooks";
import {IUseGetDevices} from "../useQueryGetDevices";
import {IGetDevices} from "../../types";
import {STATIC_STRINGS} from "../../static/staticTexts";
import {deleteBiometricDeviceService} from "../../user/saga/auth/delete-biometric-device";
import {IUseGetBiometricDevices} from "../useQueryGetBiometricDevices";

export const IUseMutationDeleteBiometricDevice = 'IUseMutationDeleteBiometricDevice';

export function useMutationDeleteBiometricDevice() {
  const dispatchStore = useAppDispatch();

  return useMutation({
    mutationKey: [IUseMutationDeleteBiometricDevice],
    mutationFn: (id: string) => deleteBiometricDeviceService(id),
    onMutate: async (id) => {
      await queryClient.cancelQueries({ queryKey: [IUseGetBiometricDevices] });

      await queryClient.invalidateQueries({
        queryKey: [IUseGetBiometricDevices]
      });

      const previousBiometricDevices = queryClient.getQueryData<AxiosResponse<IGenericAuthResponse<IGetDevices[]>>>([IUseGetBiometricDevices]);

      queryClient.setQueryData<AxiosResponse<IGenericAuthResponse<IGetDevices[]>>>([IUseGetBiometricDevices], (prevData) => {
        if (!prevData) {
          return
        }

        const items = prevData.data.data.filter(dataItem => dataItem.deviceUuid.toLowerCase().trim() !== id)

        return {
          ...prevData,
          data: {
            ...prevData.data,
            data: items
          }
        };
      })

      dispatchStore(startAddNotification({
        title: STATIC_STRINGS.NOTIFICATIONS.DELETE_DEVICE.SUCCESS.TITLE,
        description: STATIC_STRINGS.NOTIFICATIONS.DELETE_DEVICE.SUCCESS.CONTENT,
        type: INotificationType.SUCCESS
      }));

      return { previousBiometricDevices };
    },
    onError: (err, newItem, context) => {
      if (!context) {
        return;
      }

      queryClient.setQueryData([IUseGetDevices], context.previousBiometricDevices)

      dispatchStore(startAddNotification({
        title: STATIC_STRINGS.NOTIFICATIONS.DELETE_DEVICE.ERROR.TITLE,
        description: STATIC_STRINGS.NOTIFICATIONS.DELETE_DEVICE.ERROR.CONTENT,
        type: INotificationType.ERROR
      }));
    },
    onSettled: () => {
      return queryClient.invalidateQueries({ queryKey: [IUseGetBiometricDevices] })
    },
  })
}
