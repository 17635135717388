import {CSSTransition} from "react-transition-group";
import {useEffect, useRef, useState} from "react";
import "./input-inline-error.scss";

export default function InputInlineError({isVisible, message, customClass}: {customClass?: string, isVisible: boolean, message: string}) {
  const errorRef = useRef<HTMLSpanElement>(null);

  const [isInternalVisible, setInternalVisibility] = useState(false);
  const [internalMessage, setInternalMessage] = useState(message);

  useEffect(() => {
    if (message.length > 0) {
      setInternalMessage(message);
    }
  }, [message]);

  useEffect(() => {
    setInternalVisibility(isVisible)
  }, [isVisible])

  return (
    <div className={`input__inline-error ${customClass || ''}`}>
        <CSSTransition
          classNames="slide-in-out-animation"
          timeout={250}
          in={isInternalVisible}
          nodeRef={errorRef}
          unmountOnExit
        >
          <span ref={errorRef} className="input__inline-element">
           {internalMessage}
          </span>
        </CSSTransition>
      </div>
  )
}
