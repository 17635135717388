import axios, {AxiosResponse} from "axios";
import {APIS} from "../../../../static/apis";

export interface IMsGetUsers {
  displayName: string,
  userPrincipalName: string,
  id: string
}

export function getUsers (item: string): Promise<AxiosResponse<{data : IMsGetUsers[]}>> {
  return axios.get(
    APIS.BASE_AUTH_MS_URL + APIS.GET_MS_USERS +"/" + item
  );
}
