import {IGenericAuthResponse, IUser} from "../../../user";
import axios, {AxiosResponse} from "axios";
import {APIS} from "../../../../static/apis";
import {IUsersQueryFilters} from "../../../../../pages/users/users";

export interface IGetUsersService {
  data: IUser[],
  totalResults: number,
  activePage: number,
  pages: number
}

export async function getUsersService (filters: IUsersQueryFilters): Promise<AxiosResponse<IGenericAuthResponse<IGetUsersService>>> {
  return axios.get(
    APIS.BASE_AUTH_MS_URL + APIS.GET_USERS,
    {
      params: {
        ...filters
      },
      withCredentials: true
    }
  )
}


