import { ICompany } from "../../../redux/companies/companies";
import { useMemo, useRef, useState } from "react";
import { RadioListItem } from "./list-items/RadioListItem";
import "./DropDownSearch.scss";
import { ReactComponent as ChevronDown } from "../../../assets/icons/chevron-left.svg";
import { ReactComponent as CloseIcon } from "../../../assets/icons/close-alt.svg";
import CompaniesSearch from "./search/companies-search";
import useClickOutside from "../../../tools/hooks/useClickOutside";

export interface IDropDownSearchProps {
  requestClick: (selected: string) => void;
  requestClearSelection: () => void;
  data: ICompany[];
  id: string;
  isDisabled: boolean;
  customClassName?: string;
}

export function DropDownSearch({
  data,
  requestClick,
  id,
  requestClearSelection,
  isDisabled,
  customClassName,
}: IDropDownSearchProps) {
  const [showList, setShowList] = useState(false);
  const [selectedItem, setSelectedItem] = useState<string>("");
  const [selectedItemLabel, setSelectedItemLabel] = useState("");

  const [search, setSearch] = useState("");

  const ref = useRef(null);
  useClickOutside(ref, () => {
    if (showList) {
      setShowList(false);
    }
  });

  const filteredCompaniesData = useMemo(() => {
    if (!search || search === "") {
      return data;
    }
    const filteredData = data.filter((company) =>
      company.name.toLowerCase().includes(search.toLowerCase())
    );
    if (filteredData && filteredData.length) {
      return filteredData;
    }
    return [];
  }, [data, search]);

  const handleItemSelection = (itemSelect: ICompany) => {
    setSelectedItem(itemSelect.id.toString());
    setSelectedItemLabel(itemSelect.name);
    setShowList(false);
    setSearch("");

    if (!requestClick) {
      return;
    }
    return requestClick(itemSelect.id.toString());
  };

  const handleClearSearch = () => {
    setSearch("");
  };

  const handleClearSelection = () => {
    setSelectedItem("");
    setSelectedItemLabel("");
    setShowList(false);

    if (!requestClearSelection) {
      return;
    }
    return requestClearSelection();
  };

  return (
    <div
      className={`drop-down-container ${
        customClassName ? customClassName : ""
      }`}
    >
      <div className={"drop-down-clickable"}>
        <button
          type={"button"}
          className={"btn--reset drop-down-clickable__select-company"}
          title={selectedItemLabel}
          disabled={isDisabled}
          onClick={() => {
            setShowList(!showList);
          }}
        >
          {selectedItemLabel !== "" ? selectedItemLabel : "Select company"}
        </button>

        {selectedItem && selectedItemLabel !== "" && (
          <button
            type="button"
            disabled={isDisabled}
            className="btn btn--reset drop-down-clickable__clear-all"
            onClick={handleClearSelection}
          >
            <CloseIcon />
          </button>
        )}
        <button
          type="button"
          disabled={isDisabled}
          className={` btn btn--reset ${
            showList
              ? "drop-down-clickable__icon-down"
              : "drop-down-clickable__icon-up"
          }`}
          onClick={() => {
            setShowList(!showList);
          }}
        >
          <ChevronDown />
        </button>
      </div>

      <div
        className={`drop-down-list ${showList ? "drop-down-list__expand" : ""}`}
        ref={ref}
      >
        {showList && (
          <aside>
            <div className={"drop-down-clickable__search"}>
              <CompaniesSearch
                isDisabled={false}
                value={search}
                requestSubmit={(event) => {
                  setSearch(event);
                }}
                requestChange={(event) => {
                  setSearch(event);
                }}
                requestClearAll={handleClearSearch}
              />
            </div>
            <ul>
              {filteredCompaniesData && filteredCompaniesData.length ? (
                filteredCompaniesData.map((item) => (
                  <RadioListItem
                    key={item.id}
                    item={item}
                    selected={selectedItem === item.id.toString()}
                    requestSelect={handleItemSelection}
                  />
                ))
              ) : (
                <div className={"empty-results"}> No companies found</div>
              )}
            </ul>
          </aside>
        )}
      </div>
    </div>
  );
}
