import {useQuery} from "@tanstack/react-query";
import {getBankDetails} from "../../user/saga/vendor/get-bank-details";

export const IUseQueryBankDetails = 'IUseQueryBankDetails';

export interface IUseQueryBankDetailsPayload {
  condition: boolean,
  payeeNumber: string
}

export function useQueryBankDetails ({payeeNumber, condition}: IUseQueryBankDetailsPayload) {
  return useQuery(
    [IUseQueryBankDetails, payeeNumber],
    () => getBankDetails(payeeNumber), {
      enabled: condition,
    });
}

