import {IGenericAuthResponse} from "../../../user";
import axios, {AxiosResponse} from "axios";
import {APIS} from "../../../../static/apis";
import { ICompanyUsersPayees } from "pages/companies/users-company-modal/types";

export async function getUsersCompanyService (filters: string): Promise<AxiosResponse<IGenericAuthResponse<ICompanyUsersPayees[]>>> {
  return axios.get(
    APIS.BASE_AUTH_MS_URL + APIS.USER_GET_USERS_BY_PAYEE_ID + '/' + filters,
    {
      withCredentials: true
    }
  )
}
