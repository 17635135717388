import Modal, { IModalType } from "../../../components/ui/modal/Modal";
import { IProfilePasswordModalAction } from "../../users/types";
import { useEffect, useMemo, useState } from "react";
import InlineLoader from "../../../components/ui/inline-loader/InlineLoader";
import InputInlineError from "../../../components/ui/inputs/inline-error/Input.inline-error";
import Input from "../../../components/ui/inputs/Input";
import { startAddNotification } from "../../../redux/core/core";
import { INotificationType } from "../../../components/ui/notifications/item/NotificationItem";
import { useAppDispatch } from "../../../redux/hooks";
import { ICompany } from "../../../redux/companies/companies";
import MultiSelect, {
  IMultiSelectOption,
} from "../../../components/ui/multiselect/multiselect";
import {
  CHECK_VENDOR_ID,
  CHECK_VENDOR_NAME,
  ISearchType,
} from "../add-company-modal/add-company-modal";
import { AxiosError } from "axios";
import { ReactComponent as TrashIcon } from "./../../../assets/icons/trash.svg";
import { useValidateCompanyVendorId } from "../../../redux/hooks/useMutationValidateCompanyVendorId";

export interface IEditCompanyModal {
  isVisible: boolean;
  id: number;
  onToggle: (state: boolean) => void;
  name: string;
  vendorId: string;
  related: boolean;
  overhead: boolean;
  existingCompanies: ICompany[];
  requestClick: (state: IProfilePasswordModalAction, payload?: any) => void;
}

export enum ICompanyStateOptions {
  overhead = "CMS Overhead",
  related = "CMS Related",
}

const initialMultiSelectState = [
  {
    name: ICompanyStateOptions.related,
    isSelected: false,
  },
  {
    name: ICompanyStateOptions.overhead,
    isSelected: false,
  },
];

export default function EditCompanyModal({
  id,
  isVisible,
  requestClick,
  onToggle,
  existingCompanies,
  name,
  vendorId,
  related,
  overhead,
}: IEditCompanyModal) {
  const [searchType, setSearchType] = useState<ISearchType>("check_vendor_id");

  const [acmSearchInput, setAcmSearchInput] = useState(vendorId);
  const [isDirty, setIsDirty] = useState(false);

  const [acmInput, setAcmInput] = useState(name);
  const [viInput, setViInput] = useState(vendorId);
  const [isViValid, setIsViValid] = useState(true);
  const [errorMessage, setErrorMessage] = useState("");
  const [multiSelectOptions, setMultiSelectOptions] = useState<
    IMultiSelectOption[]
  >(initialMultiSelectState);

  const dispatchStore = useAppDispatch();

  const {
    mutateAsync: checkCompanyVendorIdMutation,
    isLoading: isCheckCompanyVendorIdLoading,
  } = useValidateCompanyVendorId();

  const companyIdExisting = useMemo(() => {
    return (
      existingCompanies
        .filter((item) => item.vendorId !== acmSearchInput)
        .find((item) => item.vendorId === acmSearchInput) !== undefined
    );
  }, [acmSearchInput, existingCompanies]);

  const isSaveDisabled = useMemo(() => {
    const multiSelectDifferent = multiSelectOptions.some((item) => {
      if (
        item.name === ICompanyStateOptions.related &&
        item.isSelected !== related
      ) {
        return true;
      }

      return item.isSelected !== overhead;
    });

    if (isViValid && multiSelectDifferent) {
      return false;
    }

    if (isCheckCompanyVendorIdLoading || !isViValid) {
      return true;
    }

    return viInput === vendorId && name === acmInput;
  }, [
    multiSelectOptions,
    isViValid,
    isCheckCompanyVendorIdLoading,
    viInput,
    vendorId,
    name,
    acmInput,
    related,
    overhead,
  ]);

  useEffect(() => {
    if (related !== undefined) {
      setMultiSelectOptions((state) =>
        state.map((item) => ({
          ...item,
          isSelected:
            item.name === ICompanyStateOptions.related
              ? related
              : item.isSelected,
        }))
      );
    }

    if (overhead !== undefined) {
      setMultiSelectOptions((state) =>
        state.map((item) => ({
          ...item,
          isSelected:
            item.name === ICompanyStateOptions.overhead
              ? overhead
              : item.isSelected,
        }))
      );
    }
  }, [overhead, related]);

  useEffect(() => {
    return () => {
      if (!isVisible) {
        setAcmInput(name);
        setViInput(vendorId);
        setAcmSearchInput(vendorId);
        setIsDirty(false);
        setIsViValid(true);
        setErrorMessage("");
        setSearchType(CHECK_VENDOR_ID);
        setMultiSelectOptions(
          initialMultiSelectState.map((item) => ({
            ...item,
            isSelected:
              item.name === ICompanyStateOptions.overhead
                ? overhead
                : item.name === ICompanyStateOptions.related
                ? related
                : false,
          }))
        );
      }
    };
  }, [isVisible, name, overhead, related, vendorId]);

  return (
    <Modal
      title="Edit Company"
      type={IModalType.NOTIFY}
      toggleVisibility={isVisible}
      onToggle={onToggle}
      customClass={
        "add-user-modal profile-delete-modal acm-modal company-modal"
      }
      customBtns={
        <div className="modal__footer d-flex">
          <button
            type="button"
            className={`change-pass-modal-footer__action modal__footer__action btn--short btn btn--mute`}
            onClick={() => {
              return requestClick(IProfilePasswordModalAction.CANCEL);
            }}
          >
            Cancel
          </button>

          <button
            type="button"
            disabled={isSaveDisabled}
            className={`change-pass-modal-footer__action modal-buttons--continue btn--short btn btn--default`}
            onClick={() => {
              const overhead = multiSelectOptions.find(
                (item) => item.name === ICompanyStateOptions.overhead
              );
              const related = multiSelectOptions.find(
                (item) => item.name === ICompanyStateOptions.related
              );

              return requestClick(IProfilePasswordModalAction.SAVE, {
                name: acmInput,
                vendorId: viInput,
                overhead: overhead ? overhead.isSelected : false,
                related: related ? related.isSelected : false,
              });
            }}
          >
            Continue
          </button>
        </div>
      }
    >
      <p className="register-form__intro text-align-left">
        Edit the current company: <br />
        <strong>{name}</strong>
        <br />
      </p>

      <form
        onSubmit={(event) => event.preventDefault()}
        className="acm-form add-payee-form invoice__block--matter-number add-company-form"
      >
        <Input
          id="acm-form_search"
          showLabel={false}
          placeholder={`Validate Supplier ID`}
          requestInput={(event) => {
            if (!isDirty) {
              setIsDirty(true);
            }

            setErrorMessage(
              event.length < 3
                ? `${
                    searchType === CHECK_VENDOR_NAME
                      ? "Supplier Name"
                      : "Supplier ID"
                  } should contain at least 3 characters`
                : ""
            );

            setAcmSearchInput(event);
          }}
          requestSubmit={validateCompany}
          customClass={`matter-number`}
          value={acmSearchInput}
          autocomplete={false}
          type={"string"}
          disabled={isCheckCompanyVendorIdLoading}
          error={isDirty && (errorMessage ? errorMessage.length > 0 : false)}
        >
          <>
            <div className="matter-number--actions">
              {isCheckCompanyVendorIdLoading && <InlineLoader />}

              {acmSearchInput.length > 2 && (
                <button
                  type="button"
                  onClick={() => {
                    setAcmSearchInput("");
                    setIsDirty(false);
                    setAcmInput(name);
                    setViInput(vendorId);
                  }}
                  className="add-company-form__clear btn--reset history-clear-filters"
                >
                  <TrashIcon />
                </button>
              )}

              <button
                type="button"
                onClick={validateCompany}
                disabled={
                  isCheckCompanyVendorIdLoading ||
                  companyIdExisting ||
                  acmSearchInput.length < 3
                }
                className={`matter_number-button btn btn--short btn--default ${
                  isCheckCompanyVendorIdLoading ||
                  companyIdExisting ||
                  acmSearchInput.length < 3
                    ? "matter_number-button--disabled"
                    : ""
                }`}
              >
                Validate
              </button>
            </div>

            <InputInlineError
              isVisible={isDirty && errorMessage.length > 0}
              message={errorMessage || ""}
            />
          </>
        </Input>

        <hr />

        <div className="add-company-form__entries">
          <Input
            id="acm-form__vi"
            placeholder="Supplier ID"
            showLabel
            customClass={`matter-number ${
              viInput !== vendorId && !errorMessage.length
                ? "acm-form-valid"
                : ""
            }`}
            value={viInput}
            autocomplete={false}
            type={"string"}
            disabled={true}
          ></Input>

          <Input
            id="acm-form__cn"
            placeholder="Company Name"
            customClass={`matter-number ${
              acmInput !== name && !errorMessage.length ? "acm-form-valid" : ""
            }`}
            value={acmInput}
            showLabel
            autocomplete={false}
            type={"string"}
            disabled={true}
          ></Input>
        </div>

        <hr />

        <div className="company-multiselect-options">
          <span className="input__title">Company Access</span>

          <MultiSelect
            customClass="history-multiselect"
            isDisabled={false}
            requestChange={(event) => {
              setMultiSelectOptions(
                multiSelectOptions.map((item) => ({
                  ...item,
                  isSelected:
                    event.name === item.name
                      ? event.isSelected
                      : item.isSelected,
                }))
              );
            }}
            requestClearAll={() => {
              setMultiSelectOptions(
                multiSelectOptions.map((item) => ({
                  ...item,
                  isSelected: false,
                }))
              );
            }}
            placeholder={"No invoice type access"}
            options={multiSelectOptions}
          />
        </div>
      </form>
    </Modal>
  );

  async function validateCompany() {
    try {
      const result = await checkCompanyVendorIdMutation(acmSearchInput);

      setViInput(acmSearchInput);
      setAcmInput(result.data.data);

      const companyAlreadyExists = existingCompanies
        .filter((item) => item.id.toString() !== id.toString())
        .find(
          (item) =>
            item.name === result.data.data || item.vendorId === acmSearchInput
        );

      if (companyAlreadyExists) {
        setErrorMessage(
          `${
            searchType === CHECK_VENDOR_NAME
              ? "Supplier Company"
              : "Supplier Payee"
          } already exists`
        );

        return dispatchStore(
          startAddNotification({
            title: "Warning",
            description: `${
              searchType === CHECK_VENDOR_NAME
                ? "Supplier Company"
                : "Supplier Payee"
            } already exists`,
            type: INotificationType.WARNING,
          })
        );
      }
    } catch (error) {
      const currentError = error as AxiosError;
      if (currentError && currentError?.response?.status === 400) {
        setErrorMessage(
          `${
            searchType === CHECK_VENDOR_NAME
              ? "Supplier Company"
              : "Supplier Payee"
          } already exists`
        );

        return dispatchStore(
          startAddNotification({
            title: "Warning",
            description: `${
              searchType === CHECK_VENDOR_NAME
                ? "Supplier Company"
                : "Supplier Payee"
            } already exists`,
            type: INotificationType.WARNING,
          })
        );
      }

      if (currentError && currentError?.response?.status === 404) {
        setErrorMessage(
          `${
            searchType === CHECK_VENDOR_NAME
              ? "Supplier Company"
              : "Supplier ID"
          } not found`
        );

        return dispatchStore(
          startAddNotification({
            title: "Warning",
            description: `${
              searchType === CHECK_VENDOR_NAME
                ? "Supplier Company"
                : "Supplier ID"
            } not found`,
            type: INotificationType.WARNING,
          })
        );
      }

      setErrorMessage(
        `${
          searchType === CHECK_VENDOR_NAME
            ? "Supplier Company"
            : "Supplier Payee"
        } not found`
      );

      return dispatchStore(
        startAddNotification({
          title: "Error",
          description: `Unknown api error occurred`,
          type: INotificationType.ERROR,
        })
      );
    }
  }
}
