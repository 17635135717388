import Modal from "components/ui/modal/Modal";
import { useCallback, useEffect, useState, useRef } from "react";
import { IUser } from "../../../redux/user/user";
import "./addusermodal.scss";
import Input from "../../../components/ui/inputs/Input";
import CheckBox from "../../../components/ui/checkbox/checkbox";
import { STATIC_STRINGS } from "../../../redux/static/staticTexts";
import { CSSTransition } from "react-transition-group";
import { IProfilePasswordModalAction } from "../types";
import AddUserModalButtons from "./addUserModalButtons";
import InlineLoader from "../../../components/ui/inline-loader/InlineLoader";
import InputInlineError from "../../../components/ui/inputs/inline-error/Input.inline-error";
import {
  getUsers,
  IMsGetUsers,
} from "../../../redux/user/saga/vendor/get-users";
import { useAppDispatch } from "../../../redux/hooks";
import { startAddNotification } from "../../../redux/core/core";
import { INotificationType } from "../../../components/ui/notifications/item/NotificationItem";
import useClickOutside from "../../../tools/hooks/useClickOutside";

export interface IUsersMetaAddUserModalPayload {
  sendInvite: boolean;
  email: string;
  userName: string;
  isMsAccount: boolean;
}

export interface IUsersMetaAddUserModal {
  isVisible: boolean;
  existingUsers: IUser[];
  onToggle: (state: boolean) => void;
  requestClick: (
    event: IProfilePasswordModalAction,
    payload?: IUsersMetaAddUserModalPayload
  ) => void;
}

// const emailRegexStr = "^\\w+([\\.-]?\\w+)*@\\w+([\\.-]?\\w+)*(\\.\\w{2,3})+$";
// export const emailRegex = new RegExp(emailRegexStr);

// const emailRegexStr = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
// export const emailRegex = new RegExp(emailRegexStr);

// const emailRegexStr = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
// export const emailRegex = new RegExp(emailRegexStr);

// const emailRegexStr = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
// export const emailRegex = new RegExp(emailRegexStr);

const emailRegexStr = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9._-]+\.[a-zA-Z]{2,}$/;
export const emailRegex = new RegExp(emailRegexStr);

export default function AddUserModal({
  isVisible,
  existingUsers,
  requestClick,
  onToggle,
}: IUsersMetaAddUserModal) {
  const errorRef = useRef<HTMLSpanElement>(null);
  const suggestionRef = useRef(null);

  const [sendInvite, setSendInvite] = useState(false);
  const [isMsAccountChecked, setIsMsAccountChecked] = useState(false);
  const [userName, setUserName] = useState("");
  const [email, setEmail] = useState("");

  const [isDirty, setIsDirty] = useState(false);
  const [acmInput, setAcmInput] = useState("");
  const [acmErrorMessage, setAcmErrorMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [suggestionsVisible, setSuggestionsVisible] = useState(false);
  const [usersSuggestions, setUsersSuggestions] = useState<IMsGetUsers[]>([]);
  const [selectedSuggestion, setSelectedSuggestion] = useState<
    IMsGetUsers | undefined
  >();
  const dispatchStore = useAppDispatch();

  const [isValid, setIsValid] = useState(false);
  const [isAcmInputValid, setIsAcmInputValid] = useState(false);

  const [error, setErrorMessage] = useState("");

  const checkErrors = useCallback(
    (email, username) => {
      const isEmailValid = emailRegex.test(email);

      const isUsernameValid = username.length > 2 && username.length < 30;
      const domainEmailExists = !isEmailValid
        ? ""
        : email.split("@")[1].toLowerCase();
      const isManualEmailAllowed = isMsAccountChecked
        ? true
        : domainEmailExists && domainEmailExists.length
        ? !domainEmailExists.includes("cmck") &&
          !domainEmailExists.includes("cms") &&
          !domainEmailExists.includes("cms-cmno") &&
          !domainEmailExists.includes("microsoft")
        : false;

      if (!email.length && !username.length) {
        return {
          isValid: false,
          errorMessage: "",
        };
      }

      const existingEmail = existingUsers.find((item) => email === item.email);

      return {
        isValid:
          isEmailValid &&
          isUsernameValid &&
          !existingEmail &&
          isManualEmailAllowed,
        errorMessage:
          !isEmailValid && !isUsernameValid && !isManualEmailAllowed
            ? "Please use a valid email address and username"
            : !isEmailValid
            ? "Please use a valid email address"
            : !isUsernameValid
            ? "Username should be between 3 and 30 characters"
            : existingEmail
            ? "Email address already exists"
            : !isManualEmailAllowed
            ? "Email address appears to be CMS account"
            : "",
      };
    },
    [existingUsers, isMsAccountChecked]
  );

  useClickOutside(suggestionRef, () => {
    if (suggestionsVisible) {
      setSuggestionsVisible(false);
    }
  });

  useEffect(() => {
    const { errorMessage, isValid } = checkErrors(email, userName);

    setIsValid(isValid);
    setErrorMessage(errorMessage);
  }, [checkErrors, email, userName]);

  useEffect(() => {
    return () => {
      if (!isVisible) {
        setIsValid(false);
        setIsAcmInputValid(false);
        setIsLoading(false);
        setAcmErrorMessage("");
        setAcmInput("");
        setIsMsAccountChecked(false);
        setSuggestionsVisible(false);
        setUsersSuggestions([]);
        setSelectedSuggestion(undefined);
        setEmail("");
        setErrorMessage("");
        setUserName("");
        setSendInvite(false);
      }
    };
  }, [userName, email, isVisible]);

  return (
    <Modal
      title="Add User"
      toggleVisibility={isVisible}
      onToggle={onToggle}
      customClass={"add-user-modal"}
      customBtns={
        <AddUserModalButtons
          isLoading={/*isUserRegistrationNewAccountRunningState*/ false}
          isDisabled={
            isMsAccountChecked
              ? !isAcmInputValid || !selectedSuggestion
              : !isValid
          }
          requestClick={(event) => {
            switch (event) {
              case IProfilePasswordModalAction.SAVE:
                return requestClick(IProfilePasswordModalAction.SAVE, {
                  email,
                  userName,
                  sendInvite,
                  isMsAccount: isMsAccountChecked,
                });

              case IProfilePasswordModalAction.CANCEL:
                return requestClick(IProfilePasswordModalAction.CANCEL);

              default:
                return;
            }
          }}
        />
      }
    >
      <p className="register-form__intro">
        In order for the user to activate the new account, a email invitation
        will have to be sent. The invitation can be sent while creating the
        account, or at a later time, from the <strong>Manage Users</strong>{" "}
        page.
        <br />
      </p>

      <div className="ms-account acm-form invoice__block--matter-number">
        <div className="ms-checkbox">
          <CheckBox
            id={"ms-users"}
            checked={isMsAccountChecked}
            value={"Add CMS Account"}
            requestChecked={(event) => {
              setIsMsAccountChecked(event);
              setAcmInput("");
              setIsDirty(false);
              setErrorMessage("");
              setIsValid(false);
              setIsAcmInputValid(false);
              setEmail("");
              setUserName("");
            }}
          />
        </div>

        <Input
          id="acm-form__cn"
          placeholder="Name or Email Address"
          requestInput={(event) => {
            if (selectedSuggestion !== undefined) {
              setSelectedSuggestion(undefined);
            }

            if (userName.length > 0) {
              setUserName("");
            }

            if (email.length > 0) {
              setEmail("");
            }

            if (suggestionsVisible) {
              setSuggestionsVisible(false);
              setUsersSuggestions([]);
            }

            if (!isDirty) {
              setIsDirty(true);
            }

            const isEmailValid = event.includes("@")
              ? emailRegex.test(event)
              : true;

            setIsAcmInputValid(event.trim().length > 2 && isEmailValid);
            setAcmErrorMessage(
              event.trim().length < 3
                ? "Name or email should contain at least 3 characters"
                : !isEmailValid
                ? "Invalid email address used"
                : ""
            );

            setAcmInput(event);
          }}
          requestFocus={() => {
            if (suggestionsVisible || !usersSuggestions.length) {
              return;
            }

            setSuggestionsVisible(true);
          }}
          customClass={`matter-number ${
            isMsAccountChecked ? "matter-number--visible" : ""
          }`}
          value={acmInput}
          showLabel
          autocomplete={false}
          requestSubmit={handleAcmValidate}
          type={"string"}
          disabled={isLoading}
          error={
            !isAcmInputValid &&
            (acmErrorMessage ? acmErrorMessage.length > 0 : false)
          }
        >
          <>
            <div className="matter-number--actions">
              {isLoading && <InlineLoader />}

              <button
                type="submit"
                disabled={
                  !isAcmInputValid ||
                  isLoading ||
                  selectedSuggestion !== undefined
                }
                onClick={() => handleAcmValidate()}
                className={`matter_number-button btn btn--short btn--default ${
                  !isAcmInputValid ||
                  isLoading ||
                  selectedSuggestion !== undefined
                    ? "matter_number-button--disabled"
                    : ""
                }`}
              >
                Validate
              </button>
            </div>

            <InputInlineError
              isVisible={isDirty && !isAcmInputValid}
              message={acmErrorMessage || ""}
            />
          </>
        </Input>

        {!isLoading && suggestionsVisible && usersSuggestions.length > 0 && (
          <div className="suggestions" ref={suggestionRef}>
            {usersSuggestions.map((item) => (
              <button
                key={item.id}
                type="button"
                onClick={() => {
                  handleSuggestion(item);
                }}
                className="suggestions__item"
              >
                <span className="suggestions__item-entry">
                  {item.displayName}
                </span>
              </button>
            ))}
          </div>
        )}
      </div>

      <br />

      <Input
        autocomplete={true}
        error={userName.length > 0 && !isValid}
        type={"username"}
        disabled={isMsAccountChecked || isLoading}
        id={"username"}
        placeholder={STATIC_STRINGS.LOGIN.LOGIN_FORM.USERNAME_PLACEHOLDER}
        value={userName}
        requestInput={setUserName}
      />

      <Input
        autocomplete={true}
        error={email.length > 0 && !isValid}
        disabled={isMsAccountChecked || isLoading}
        type={"email"}
        id={"email"}
        placeholder={STATIC_STRINGS.LOGIN.LOGIN_FORM.EMAIL_ADDRESS}
        value={email}
        requestInput={setEmail}
      />

      <CheckBox
        id={"setup-finished"}
        checked={sendInvite}
        value={"Send invitation email"}
        requestChecked={setSendInvite}
      />

      <span className="create-account-request__form-error-container">
        <CSSTransition
          classNames="slide-in-out-animation"
          timeout={250}
          in={!isValid && error.length > 0}
          nodeRef={errorRef}
          unmountOnExit
        >
          <span ref={errorRef} className="create-account-request__form-error">
            {error}
          </span>
        </CSSTransition>
      </span>
    </Modal>
  );

  function handleSuggestion({
    userPrincipalName,
    displayName,
    id,
  }: IMsGetUsers) {
    setSelectedSuggestion({
      userPrincipalName,
      id,
      displayName,
    });
    setSuggestionsVisible(false);
    setUserName(displayName);
    setEmail(userPrincipalName);
  }

  async function handleAcmValidate() {
    if (isLoading) {
      return;
    }

    setUserName("");
    setEmail("");
    setSuggestionsVisible(false);
    setIsLoading(true);
    setUsersSuggestions([]);

    try {
      const users = await getUsers(acmInput);
      const isValid = users.data.data.length > 0;

      setIsAcmInputValid(isValid);
      setSuggestionsVisible(isValid);
      setAcmErrorMessage(!isValid ? "No users found for current criteria" : "");
      setUsersSuggestions(users.data.data || []);
    } catch (error) {
      console.log(error);
      dispatchStore(
        startAddNotification({
          title: "Error",
          description: "Unknown api error occurred",
          type: INotificationType.ERROR,
        })
      );
    }

    setIsLoading(false);
  }
}
