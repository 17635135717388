import {
  IGenericAuthResponse,
} from "../../../user";
import axios, {AxiosResponse} from "axios";
import {APIS} from "../../../../static/apis";

export async function getAccessTokenService (): Promise<AxiosResponse<IGenericAuthResponse<object>>> {
  return axios.post(
    APIS.BASE_AUTH_MS_URL + APIS.AUTH_GET_ACCESS_TOKEN,
    {},
    {
      withCredentials: true,
    }
  )
}