import {IGenericAuthResponse} from "../../../user";
import axios, {AxiosResponse} from "axios";
import {APIS} from "../../../../static/apis";

export async function logoutService (): Promise<AxiosResponse<IGenericAuthResponse<object>>> {
  return axios.patch(
    APIS.BASE_AUTH_MS_URL + APIS.AUTH_LOGOUT,
    {
    },
    {
      withCredentials: true
    }
  );
};