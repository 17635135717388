import moment from "moment";

export interface IBankDetailPreloadedDate {
  iban: string;
  accountName: string;
  startDate: moment.Moment | null | string;
  bic: string;
  detailsOfCharges: string;
  intBic: string;
  endDate: moment.Moment | null | string;
}

export const initialState: ISetValueAction = {
  accountName: {
    id: "accountName",
    value: "",
    isValid: false,
    label: "Account Name",
    dirty: false,
  },
  iban: {
    id: "iban",
    value: "",
    isValid: false,
    label: "ACCOUNT NUMBER/IBAN",
    dirty: false,
  },
  startDate: {
    id: "startDate",
    value: null,
    isValid: false,
    label: "Start Date",
    dirty: false,
  },
  bic: {
    id: "bic",
    value: "",
    isValid: false,
    label: "BIC",
    dirty: false,
  },
  detailsOfCharges: {
    id: "detailsOfCharges",
    value: "",
    isValid: false,
    label: "DETAILS OF CHARGES",
    dirty: false,
  },
  intBic: {
    id: "intBic",
    value: "",
    isValid: false,
    label: "INTERMEDIARY BIC",
    dirty: false,
  },
  endDate: {
    id: "endDate",
    value: null,
    isValid: false,
    label: "End Date",
    dirty: false,
  },
};

export interface ISetValueActionPayload {
  id: keyof ISetValueAction;
  value: string | null | moment.Moment;
  label: string;
  isLoading?: boolean;
  errorMessage?: string;
  isOld?: boolean;
  isValid: boolean;
  dirty: boolean;
}

export interface ISetValueAction {
  iban: ISetValueActionPayload;
  accountName: ISetValueActionPayload;
  bic: ISetValueActionPayload;
  startDate: ISetValueActionPayload;
  detailsOfCharges: ISetValueActionPayload;
  intBic: ISetValueActionPayload;
  endDate: ISetValueActionPayload;
}

export type Action =
  | { type: "preloadState"; payload: IBankDetailPreloadedDate }
  | { type: "setSelectedContact"; payload: string | null }
  | { type: "resetState" }
  | { type: "setValue"; payload: ISetValueActionPayload }
  | { type: "setInputTouched"; payload: ISetValueActionPayload }
  | { type: "setState"; payload: ISetValueAction };

export function reducer(
  state: ISetValueAction,
  action: Action
): ISetValueAction {
  switch (action.type) {
    case "preloadState": {
      return {
        ...state,
        accountName: {
          ...state.accountName,
          value: action.payload.accountName || "",
          isValid: (action.payload.accountName || "").length > 2,
        },
        iban: {
          ...state.iban,
          value: action.payload.iban || "",
          isValid: (action.payload.iban || "").length > 7,
        },
        startDate: {
          ...state.startDate,
          value: action.payload.startDate,
          isValid: action.payload.startDate !== null,
        },
        bic: {
          ...state.bic,
          value: action.payload.bic || "",
          //    isValid: (action.payload.bic || "").length > 7,
          isValid: true,
        },
        detailsOfCharges: {
          ...state.detailsOfCharges,
          value: action.payload.detailsOfCharges || "",
          //   isValid: (action.payload.detailsOfCharges || "").length > 9,
          isValid: true,
        },
        intBic: {
          ...state.intBic,
          value: action.payload.intBic || "",
          //  isValid: (action.payload.intBic || "").length > 7,
          isValid: true,
        },
        endDate: {
          ...state.endDate,
          value: action.payload.endDate,
          isValid: action.payload.endDate !== null,
        },
      };
    }
    case "resetState": {
      return {
        ...state,
        ...initialState,
      };
    }

    case "setInputTouched": {
      return {
        ...state,
        [action.payload.id]: {
          ...state[action.payload.id],
          ...action.payload,
        },
      };
    }

    case "setValue": {
      return {
        ...state,
        [action.payload.id]: {
          ...state[action.payload.id],
          dirty:
            action.payload.dirty !== undefined ? action.payload.dirty : true,
          value: action.payload.value,
          isValid: action.payload.isValid,
          errorMessage: action.payload.errorMessage,
          isLoading:
            "isLoading" in action.payload ? action.payload.isLoading : false,
          isOld: "isOld" in action.payload ? action.payload.isOld : false,
        },
      };
    }

    case "setState": {
      return {
        ...state,
        ...action.payload,
      };
    }

    default:
      return {
        ...state,
      };
  }
}
