import InlineLoader from "../../../components/ui/inline-loader/InlineLoader";
import {IProfilePasswordModalAction, IProfilePasswordModalButtons} from "../types";

export default function InviteModalButtons({isDisabled, isLoading, requestClick}: IProfilePasswordModalButtons) {
  return (
    <div className="change-pass-modal-footer modal__footer d-flex">
      {
        isLoading && <InlineLoader />
      }

      <button type="button"
              disabled={isLoading}
              className={`modal-buttons modal-buttons--cancel ${isLoading ? 'change-pass-modal-footer__action--disabled' : ''} change-pass-modal-footer__action modal__footer__action btn btn--mute`}
              onClick={() => {
                if (isLoading) {
                  return;
                }

                return requestClick(IProfilePasswordModalAction.CANCEL);
              }}>
        Cancel
      </button>

      <button type="button"
              disabled={isDisabled || isLoading}
              className={`modal-buttons modal-buttons--continue ${isDisabled || isLoading ? 'change-pass-modal-footer__action--disabled' : ''} change-pass-modal-footer__action modal__footer__action btn btn--default`}
              onClick={() => {
                if (isDisabled || isLoading) {
                  return;
                }

                return requestClick(IProfilePasswordModalAction.SAVE);
              }}>
        Continue
      </button>
    </div>
  )
}
