import "./acceptable-use-policy-content.scss";

export default function AcceptableUsePolicyContent() {
  return (
    <div className="acceptable-use-policy-content">
      <h2>Acceptable Use Policy for CMS Supplier Portal</h2>

      <p>
        This Acceptable Use Policy (AUP) outlines the terms and conditions
        governing the use of the CMS Supplier Portal shared by CMS CMNO and its
        suppliers, hereinafter referred to as the "Portal." The Portal provides
        a platform for suppliers to enter their information, submit documents,
        view transaction history, and communicate with CMS CMNO Accounts Payable
        department. By accessing and using the Portal, all users agree to comply
        with this AUP.
      </p>

      <h3>1. Access and Use</h3>

      <p>
        1.1. Users are responsible for maintaining the security of their login
        credentials and ensuring they are not shared with unauthorized
        individuals.
      </p>
      <p>
        1.2. Access to the Portal is permitted 24x7, but scheduled maintenance
        may occasionally affect availability. Users are encouraged to plan
        activities accordingly. There will be a maintenance notification on the
        Portal when scheduled maintenance occurs.
      </p>

      <h3>2. Data Entry and Submission</h3>

      <p>
        2.1. Users must accurately enter and maintain supplier information in
        the Portal.
      </p>

      <p>
        2.2. Submission of electronic purchase orders, supplier invoices, and
        related documents must be complete, accurate, and compliant with
        relevant regulations and company policies.
      </p>

      <h3>3. Viewing and Communication</h3>

      <p>
        3.1. Users may view real-time data on the Portal and communicate with
        CMS CMNO as required for legitimate business purposes.
      </p>

      <p>
        3.2. All communication must be professional, respectful, and related to
        the business relationship.
      </p>

      <h3>4. Transaction History and Inquiry</h3>

      <p>
        4.1. Users may access and review their transaction history on the
        Portal. The history will include all recorded paid transactions from the
        last 6 months and any still unpaid.
      </p>

      <p>
        4.2. Electronic invoice submission, invoice, and payment inquiries,
        including payment history and details, are permitted for tracking and
        reconciliation purposes.
      </p>

      <h3>5. Payment Disputes</h3>

      <p>5.1. Users may submit payment disputes through the Portal.</p>

      <p>
        5.2. Disputes must be legitimate and supported by relevant
        documentation.
      </p>

      <h3>6. Prohibited Activities</h3>

      <p>
        6.1. Users shall not engage in any of the following activities:
        <br></br>
        <span>a) Unauthorized access or use of the Portal.</span>
        <br></br>
        <span>b) Intentional disruption or damage to the Portal.</span>
        <br></br>
        <span>c) Unauthorized disclosure of sensitive information.</span>
        <br></br>
        <span>d) Any illegal, fraudulent, or malicious activities.</span>
        <br></br>
        <span>e) Impersonation of others.</span>
        <br></br>
        <span>f) Violation of intellectual property rights.</span>
      </p>

      <h3>7. Compliance with Laws</h3>

      <p>
        7.1. Users must comply with all applicable laws, regulations, and
        industry standards.
      </p>

      <h3>8. Consequences of Violation</h3>

      <p>
        8.1. Violations of this AUP may result in temporary or permanent
        suspension of access to the Portal.
      </p>

      <h3>9. Reporting Violations</h3>

      <p>
        9.1. Users are encouraged to report any suspected violations of this AUP
        to the platform administrators or the designated authority.
      </p>

      <h3>10. Amendments</h3>

      <p>
        10.1. This AUP may be amended as needed to reflect changes in the Portal
        or relevant laws. Users will be notified of any updates.
      </p>
      <br></br>
      <p>
        By using the Portal, you acknowledge that you have read, understood, and
        agreed to abide by this Acceptable Use Policy. Failure to comply with
        this policy may result in disciplinary actions, including termination of
        access to the Portal.
      </p>
    </div>
  );
}
