import {deleteCompanyService} from "../../user/saga/vendor/delete-company";
import {useMutation} from "@tanstack/react-query";
import {AxiosResponse} from "axios";
import {IGenericAuthResponse} from "../../user/user";
import {ICompany} from "../../companies/companies";
import {queryClient} from "../../../App";
import {startAddNotification} from "../../core/core";
import {INotificationType} from "../../../components/ui/notifications/item/NotificationItem";
import {useAppDispatch} from "../../hooks";
import {IUseMutationUpdateCompany} from "../useMutationUpdateCompany";
import {IUseCompanyQuery} from "../useQueryCompanies";

export const IUseMutationDeleteCompany = 'IUseMutationDeleteCompany';

export function useMutationDeleteCompany() {
  const dispatchStore = useAppDispatch();

  return useMutation({
    mutationKey: [IUseMutationDeleteCompany],
    mutationFn: (id: string) => deleteCompanyService(id),
    onMutate: async (item) => {
      await queryClient.cancelQueries({ queryKey: [IUseCompanyQuery, IUseMutationUpdateCompany, IUseMutationDeleteCompany] });

      await queryClient.invalidateQueries({
        queryKey: [IUseCompanyQuery]
      });

      const previousCompanies = queryClient.getQueryData<AxiosResponse<IGenericAuthResponse<ICompany[]>>>([IUseCompanyQuery]);

      queryClient.setQueryData<AxiosResponse<IGenericAuthResponse<ICompany[]>>>([IUseCompanyQuery], (prevData) => {
        if (!prevData) {
          return
        }

        const items = prevData.data.data.filter(dataItem => dataItem.id.toString() !== item)

        return {
          ...prevData,
          data: {
            ...prevData.data,
            data: items
          }
        };
      })

      dispatchStore(startAddNotification({
        title: "Success",
        description: `Company successfully updated`,
        type: INotificationType.SUCCESS
      }));

      return { previousCompanies };
    },
    onError: (err, newItem, context) => {
      if (!context) {
        return;
      }

      queryClient.setQueryData([IUseCompanyQuery], context.previousCompanies)

      dispatchStore(startAddNotification({
        title: "Error",
        description: `Company updates rolled back`,
        type: INotificationType.ERROR
      }));
    },
    onSettled: () => {
      return queryClient.invalidateQueries({ queryKey: [IUseCompanyQuery] })
    },
  })
}
