import axios, {AxiosResponse} from "axios";
import {APIS} from "../../../../static/apis";

export async function sendInvitationService (email: string, inviterEmail: string, uuid: string): Promise<AxiosResponse<Object>> {
  return axios.put(
    APIS.BASE_AUTH_MS_URL + APIS.SEND_ACTIVATION,
    {
      email,
      inviterEmail,
      uuid
    },
  )
}