import {IUserProvider} from "../../redux/user/user";
import {ReactComponent as CMSShortLogo} from "../../assets/icons/logo_short.svg";
import {ReactComponent as MicrosoftLogo} from "../../assets/icons/microsoft.svg";

export interface IDetermineIcon {
  type: IUserProvider | null
}

export default function DetermineIcon({type}: IDetermineIcon) {
  switch (type) {
    case IUserProvider.DEFAULT:
      return <CMSShortLogo />

    case IUserProvider.MICROSOFT:
      return <MicrosoftLogo />

    default: return null;
  }
}
