import AcceptableUsePolicyContent from "./content/acceptable-use-policy-content";

export default function AcceptableUsePolicyPage() {
  return (
    <div className="history">
      <header>
        <h1 className="history__title">Acceptable Use Policy</h1>
      </header>

      <AcceptableUsePolicyContent />
    </div>
  );
}
