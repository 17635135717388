import ContactUsContent from "./content/contact-us-content";

export default function ContactUsPage() {
  return (
    <div className="history">
      <header>
        <h1 className="history__title">Contact Us</h1>
      </header>

      <ContactUsContent />
    </div>
  )
}
