import {useQuery} from "@tanstack/react-query";
import {getCompanyAccess} from "../../user/saga/vendor/get-company";

export const IUseCompanyAccess = 'IUseCompanyAccess';

export function useQueryCompanyAccess (vendorId: number, condition: boolean) {
  return useQuery([IUseCompanyAccess, {
    companyId: vendorId.toString()
  }], () => getCompanyAccess(vendorId.toString()), {
    enabled: condition,
    refetchInterval: false,
    refetchIntervalInBackground: false,
    refetchOnWindowFocus: false,
    staleTime: 0,
    refetchOnMount: false
  });
}
