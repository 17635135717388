import {RootState} from "../store";
import {createSelector} from "@reduxjs/toolkit";
import {IUserProvider, IUserRole} from "./user";

const userState = (state: RootState) => state.user;

export const selectUserName = createSelector(
  [userState],
  (state) => state.name
)

export const selectDarkModeState = createSelector(
  [userState],
  (state) => state.darkMode
);

export const selectUuid = createSelector(
  [userState],
  (state) => state.uuid
)

export const selectCurrentPayee = createSelector(
  [userState],
  (state) => state.currentPayee
)

export const selectAvailablePayees = createSelector(
  [userState],
  (state) => state.payees
)

export const arePayeesAvailable = createSelector(
  [selectAvailablePayees],
  (state) => state && state.length > 0
)

export const isPayeeSelected = createSelector(
  [selectCurrentPayee],
  (state) => {
    return state && Object.keys(state) && Object.keys(state).length > 0
  }
)

export const selectUserEmail = createSelector(
  [userState],
  (state) => state.email
)

export const selectUserVendorId = createSelector(
  [userState],
  (state) => state.vendorId
)

export const selectUserVendorName = createSelector(
  [userState],
  (state) => state.vendorName
)

export const selectUserProvider = createSelector(
  [userState],
  (state) => state.provider
)

export const selectUserRole = createSelector(
  [userState],
  (state) => state.role
)

export const selectUserAvatar = createSelector(
  [userState],
  (state) => state.userAvatar
)

export const selectUserCreatedOn = createSelector(
  [userState],
  (state) => state.createdOn
)

export const isMicrosoftAccount = createSelector(
  [selectUserProvider],
  (state) => state === IUserProvider.MICROSOFT
)

export const isAdminAccount = createSelector(
  [selectUserRole],
  (state) => state === IUserRole.admin
)

export const isSupervisorAccount = createSelector(
  [selectUserRole],
  (state) => state === IUserRole.supervisor
)

export const isUserLoggedIn  = createSelector(
  [selectUserName, selectUserEmail],
  (name, emailAddress) =>
    emailAddress && emailAddress.length > 0 && name && name.length > 0
)

export const accountHasBiometricDevices = createSelector(
  [userState],
  (state) => state.accountHasBiometricDevices
)
