import Input from "../../../components/ui/inputs/Input";
import {ReactComponent as SearchIcon} from "../../../assets/icons/search.svg";

export interface IUsersSearch {
  isDisabled: boolean,
  value: string,
  requestSubmit: (event: string) => void
  requestChange: (value: string) => void
}

export default function UsersSearch({requestSubmit, isDisabled, value, requestChange}: IUsersSearch) {
  return (
    <form className="history-search-form users-search-form" onSubmit={(event) => {
      event.preventDefault();

      return requestSubmit(value);
    }}>
      <Input
        autocomplete={false}
        customClass={`matter-number history-search users-search users-search--alt`}
        placeholder="Name or email address"
        disabled={isDisabled}
        id="search-history"
        requestInput={requestChange}
        requestClear={() => {
          requestSubmit("")
        }}
        value={value}>

        <button type="submit" className="btn btn--reset history-search__action">
          <span className="visually-hidden">Search invoice no</span>
          <SearchIcon height="20" width="20"/>
        </button>
      </Input>
    </form>
  )
}
