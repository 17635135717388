import axios, {AxiosResponse} from "axios";
import {APIS} from "../../../../static/apis";
import {ICompany} from "../../../../companies/companies";
import {IGenericAuthResponse} from "../../../user";

export interface IAddCompany {
  name: string,
  vendorId: string,
  related: boolean,
  overhead: boolean
}

export function addCompany ({name, vendorId, related, overhead}: IAddCompany): Promise<AxiosResponse<IGenericAuthResponse<ICompany>>> {
  return axios.put(
    APIS.BASE_AUTH_MS_URL + APIS.ADD_COMPANY,
    {
      name,
      vendorId,
      related,
      overhead
    }
  );
}
