import {RootState} from "../store";
import {createSelector} from "@reduxjs/toolkit";
import {IStatus} from "../types";

const coreState = (state: RootState) => state.core;

export const selectNotifications = createSelector(
  [coreState],
  (state) => state.notifications
)

export const areNotificationsPresent = createSelector(
  [selectNotifications],
  (notifications) => notifications && notifications.length && notifications.length > 0
)

export const selectFullWidthState  = createSelector(
  [coreState],
  (state) =>
    state.isFullWidth
);

export const selectAppBoostrapState = createSelector(
  [coreState],
  (state) =>
    state.isAppBootstrapped
);

export const selectPendingState = createSelector(
  [coreState],
  (state) => state.pending
)

export const isCoreRunningAuth = createSelector(
  [selectPendingState],
  (state) => state === IStatus.RUNNING_AUTH
)

export const hasInitiatedSignIn = createSelector(
  [coreState],
  (state) => state.initiatedSignIn
)

export const selectIsOverlayVisible  = createSelector(
  [coreState],
  (state) =>
    state.isOverlayVisible
);