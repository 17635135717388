import { useMutation } from "@tanstack/react-query";
import { AxiosResponse } from "axios";
import { IGenericAuthResponse, IUser } from "../../user/user";
import { queryClient } from "../../../App";
import { startAddNotification } from "../../core/core";
import { INotificationType } from "../../../components/ui/notifications/item/NotificationItem";
import { useAppDispatch } from "../../hooks";
import { deleteUserService } from "../../user/saga/auth/delete-user";
import { IUseQueryUsers } from "../useQueryUsers";
import { IUsersQueryFilters } from "../../../pages/users/users";

export const IUseMutationDeleteUser = "IUseMutationDeleteUser";

export interface IUseMutationDeleteUserPayload {
  uuid: string;
}

export function useMutationDeleteUser() {
  const dispatchStore = useAppDispatch();

  return useMutation({
    mutationKey: [IUseMutationDeleteUser],
    mutationFn: ({
      uuid,
      ...filter
    }: IUseMutationDeleteUserPayload & IUsersQueryFilters) =>
      deleteUserService(uuid),
    onMutate: async ({ uuid, ...filters }) => {
      await Promise.all([
        queryClient.cancelQueries({ queryKey: [IUseQueryUsers] }),
        queryClient.invalidateQueries({
          queryKey: [IUseQueryUsers, filters],
        }),
      ]);

      const previousUsers = queryClient.getQueryData<
        AxiosResponse<IGenericAuthResponse<IUser[]>>
      >([IUseQueryUsers]);

      queryClient.setQueryData<AxiosResponse<IGenericAuthResponse<IUser[]>>>(
        [IUseQueryUsers],
        (prevData) => {
          if (!prevData) {
            return;
          }

          const items = prevData.data.data.filter(
            (dataItem) => dataItem.uuid !== uuid
          );

          return {
            ...prevData,
            data: {
              ...prevData.data,
              data: items,
            },
          };
        }
      );

      dispatchStore(
        startAddNotification({
          title: "Success",
          description: `Users successfully updated`,
          type: INotificationType.SUCCESS,
        })
      );

      return { previousUsers };
    },
    onError: (err, newItem, context) => {
      if (!context) {
        return;
      }

      queryClient.setQueryData([IUseQueryUsers], context.previousUsers);

      dispatchStore(
        startAddNotification({
          title: "Error",
          description: `User updates rolled back - USERERE`,
          type: INotificationType.ERROR,
        })
      );
    },
    onSettled: (_, __, { uuid, ...filters }) => {
      return queryClient.invalidateQueries({
        queryKey: [IUseQueryUsers, filters],
      });
    },
  });
}
