import Modal, {IModalType} from "../../../components/ui/modal/Modal";
import DeleteModalButtons from "./deleteModalButtons";
import {IProfilePasswordModalAction} from "../types";
import Input from "../../../components/ui/inputs/Input";
import {CSSTransition} from "react-transition-group";
import {useEffect, useRef, useState} from "react";
/*import {useAppSelector} from "../../../redux/hooks";
import {isAppDeactivatingAccount} from "../../../redux/core/selectors";*/

export interface IDeleteModal {
  isVisible: boolean,
  emailToCheck: string,
  requestClick: (event: IProfilePasswordModalAction, payload?: string) => void
}

export default function DeleteModal({isVisible, requestClick, emailToCheck}: IDeleteModal) {
  const errorRef = useRef<HTMLSpanElement>(null);

  /*const isAppDeactivatingAccountState = useAppSelector(isAppDeactivatingAccount);*/

  const [userEmail, setUserEmail] = useState('');

  const [isValid, setIsValid] = useState(false);

  const [error, setErrorMessage] = useState('');

  useEffect(() => {
    setIsValid(userEmail === emailToCheck);
    setErrorMessage(!userEmail || !userEmail.length ? '' : userEmail !== emailToCheck ? 'Email address is incorrect' : '');

  }, [userEmail, emailToCheck]);

  useEffect(() => {
    return () => {
      if (isVisible) {
        setErrorMessage('');
        setUserEmail('');
        setIsValid(false);
      }
    }
  }, [isVisible])

  return (
    <Modal title={'Delete Account'}
           type={IModalType.ERROR}
           customClass={'password-modal deactivate-modal delete-modal'}
           toggleVisibility={isVisible}
           customBtns={ <DeleteModalButtons requestClick={(event) => {
             switch (event) {
               case IProfilePasswordModalAction.SAVE:
                 return requestClick(IProfilePasswordModalAction.SAVE, userEmail);

               case IProfilePasswordModalAction.CANCEL:
                 return requestClick(IProfilePasswordModalAction.CANCEL);

               default: return;
             }
           }}
                                                isDisabled={!isValid}
                                                isLoading={/*isAppDeactivatingAccountState*/false} /> }
           onToggle={(event) => {
             requestClick(IProfilePasswordModalAction.CANCEL)
           }}>

      <p className="register-form__intro">
        This action will sign out the user from all devices, and the account will be <strong>deleted</strong>,
        meaning that the user will <strong>not</strong> be able to authenticate after this process is completed,
        and all the user data <strong>will be removed</strong>.

        <br />
        <br />

        Please confirm this action by adding the users email address below

        <br />
        <br />
      </p>

      <Input id={'current_email'}
             error={userEmail.length > 0 && !isValid}
             customClass="password-modal__input-el"
             type="email"
             placeholder={'Email Address'}
             value={userEmail}
             requestInput={setUserEmail}  />

      <span className="register-form__form-error-container">
        <CSSTransition
          classNames="slide-in-out-animation"
          timeout={250}
          in={!isValid && error.length > 0}
          nodeRef={errorRef}
          unmountOnExit
        >
          <span ref={errorRef} className="register-form__form-error">
           {error}
          </span>
        </CSSTransition>
      </span>

    </Modal>
  )
}
