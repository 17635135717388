import axios, {AxiosResponse} from "axios";
import {APIS} from "../../../../static/apis";
import {IPayeeAccounts} from "../../../../companies/companies";

export function addEntitiesToUser(companyId: string, payees: string[], email: string): Promise<AxiosResponse<{ data: IPayeeAccounts[] }>> {
  return axios.put(
    APIS.BASE_AUTH_MS_URL + APIS.ADD_ENTITIES_TO_USER, {
      companyId,
      payees,
      email
    }
  );
}
