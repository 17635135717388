export const IMsAuthId = 'currentMsLoginUuid';

export const IHeaderAccessToken = "x-access-token";
export const IHeaderRegistrationUuid = "x-registration-uuid";
export const IHeaderBiometricRegistrationUuid = "x-biometric-registration-uuid";
export const IHeaderChallenge = "x-challenge";
export const IHeaderCodeChallenge = "x-code-challenge";
export const IHeaderUuid = "x-uuid";
export const IEmail = "email";
export const ICodeVerifier = "code_verifier";
export const IHeaderSessionUuid = "x-session-uuid";
export const IHeaderEmail = "x-email";

export const IAuthId = "id";
export const IAuthCode = "code";

