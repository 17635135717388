import {deletePayeeService} from "../../user/saga/vendor/delete-payee";
import {useMutation} from "@tanstack/react-query";
import {AxiosResponse} from "axios";
import {IGenericAuthResponse} from "../../user/user";
import {IPayeeAccounts} from "../../companies/companies";
import {queryClient} from "../../../App";
import {startAddNotification} from "../../core/core";
import {INotificationType} from "../../../components/ui/notifications/item/NotificationItem";
import {useAppDispatch} from "../../hooks";
import {IUseMutationUpdateCompany} from "../useMutationUpdateCompany";
import {IUseMutationDeleteCompany} from "../useMutationDeleteCompany";
import {IUseQueryPayees} from "../useQueryPayees";
import {IUseCompanyQuery} from "../useQueryCompanies";

export const IUseMutationDeletePayee = 'IUseMutationDeletePayee';

export interface IUseMutationDeletePayeePayload {
  companyId: string,
  payeeId: string
}

export function useMutationDeletePayee() {
  const dispatchStore = useAppDispatch();

  return useMutation({
    mutationKey: [IUseMutationDeletePayee],
    mutationFn: ({companyId, payeeId}: IUseMutationDeletePayeePayload) => deletePayeeService(companyId, payeeId),
    onMutate: async (item) => {
      await queryClient.cancelQueries({ queryKey: [IUseCompanyQuery, IUseMutationUpdateCompany, IUseMutationDeleteCompany] });

      await queryClient.invalidateQueries({
        queryKey: [IUseCompanyQuery, IUseQueryPayees]
      });

      const previousPayees = queryClient.getQueryData<AxiosResponse<IGenericAuthResponse<IPayeeAccounts[]>>>([IUseQueryPayees, parseInt(item.companyId)]);

      queryClient.setQueryData<AxiosResponse<IGenericAuthResponse<IPayeeAccounts[]>>>([IUseQueryPayees, parseInt(item.companyId)], (prevData) => {
        if (!prevData) {
          return
        }

        const items = prevData.data.data.filter(dataItem => dataItem.id.toString() !== item.payeeId);

        return {
          ...prevData,
          data: {
            ...prevData.data,
            data: items
          }
        };
      })

      dispatchStore(startAddNotification({
        title: "Success",
        description: `Company successfully updated`,
        type: INotificationType.SUCCESS
      }));

      return { previousPayees, companyId: item.companyId };
    },
    onError: (err, newItem, context) => {
      if (!context) {
        return;
      }

      queryClient.setQueryData([IUseQueryPayees, parseInt(context.companyId)], context.previousPayees)

      dispatchStore(startAddNotification({
        title: "Error",
        description: `Company updates rolled back`,
        type: INotificationType.ERROR
      }));
    },
    onSettled: (_, __, context) => {
      return queryClient.invalidateQueries({ queryKey: [IUseQueryPayees, parseInt(context.companyId)] })
    },
  })
}
