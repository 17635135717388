import {useCallback, useEffect, useState} from "react";
import {validate as uuidValidate, version as uuidVersion} from "uuid";

export default function useActionsQueryHook(params?: URLSearchParams) {
  const [action, setAction] = useState<string | null>(null);
  const [uuid, setUuid] = useState<string | null>(null);

  const validateUuid = useCallback((value: string) => {
    return uuidValidate(value) && uuidVersion(value) === 4;
  }, []);

  useEffect(() => {
    if (params &&
        params.get('action') &&
        params.get('uuid') &&
        validateUuid(params.get('uuid') || "")) {
      setAction(params.get('action'));
      setUuid(params.get('uuid'));

      return;
    }

    setAction(null);
    setUuid(null);
  }, [params, validateUuid]);

  return {action, uuid}
}
