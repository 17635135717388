import {useMutation} from "@tanstack/react-query";
import {addCompany as addCompanyService, IAddCompany} from "../../user/saga/vendor/add-company";
import {queryClient} from "../../../App";
import {IUseCompanyQuery} from "../useQueryCompanies";
import {AxiosResponse} from "axios";
import {IGenericAuthResponse} from "../../user/user";
import {ICompany} from "../../companies/companies";
import {startAddNotification} from "../../core/core";
import {INotificationType} from "../../../components/ui/notifications/item/NotificationItem";
import {useAppDispatch} from "../../hooks";

export const IUseMutationAddCompany = 'IUseMutationAddCompany';

export function useMutationAddCompany() {
  const dispatchStore = useAppDispatch();

  return useMutation({
    mutationKey: [IUseMutationAddCompany],
    mutationFn: ({name, vendorId, related, overhead}: IAddCompany) => addCompanyService({ name, vendorId, related, overhead }),
    onMutate: async ({name, vendorId, related, overhead}) => {
      await queryClient.cancelQueries({ queryKey: [IUseCompanyQuery, IUseMutationAddCompany] });

      await queryClient.invalidateQueries({
        queryKey: [IUseCompanyQuery]
      });

      const previousCompanies = queryClient.getQueryData<AxiosResponse<IGenericAuthResponse<ICompany[]>>>([IUseCompanyQuery]);

      const existingIds = previousCompanies?.data.data.map(item => item.id);

      const id = generateId(existingIds || []);

      queryClient.setQueryData<AxiosResponse<IGenericAuthResponse<ICompany[]>>>([IUseCompanyQuery], (prevData) => {
        if (!prevData) {
          return
        }

        const items = prevData?.data.data.map(dataItem => dataItem);

        items.push({
          name,
          vendorId,
          id,
          overhead,
          related
        })

        return {
          ...prevData,
          data: {
            ...prevData.data,
            data: items
          }
        };
      });

      dispatchStore(startAddNotification({
        title: "Success",
        description: `Company successfully updated`,
        type: INotificationType.SUCCESS
      }));

      return { previousCompanies };
    },
    onError: (err, newItem, context) => {
      if (!context) {
        return;
      }

      queryClient.setQueryData([IUseCompanyQuery], context.previousCompanies)

      dispatchStore(startAddNotification({
        title: "Error",
        description: `Company updates rolled back`,
        type: INotificationType.ERROR
      }));
    },
    onSettled: () => {
      return queryClient.invalidateQueries({ queryKey: [IUseCompanyQuery] })
    },
  })
}

function generateId(ids: number[]): number {
  const newId = Math.floor(Math.random() * 100);

  if (ids.includes(newId)) {
    return generateId(ids);
  }

  return newId;
}
