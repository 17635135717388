import {all, takeLatest, select, put} from "redux-saga/effects";
import {addNotification, startAddNotification} from "./core";
import {INotification, INotificationData} from "../../components/ui/notifications/item/NotificationItem";
import {selectNotifications} from "./selectors";
import {PayloadAction} from "@reduxjs/toolkit";

function generateId(currentId: number, data: number[]): number {
  if (!data ||
      !data.length) {
    return currentId;
  }

  if (data.some(item => item === currentId)) {
    return generateId(Math.floor(Math.random() * 1000), data);
  }

  return currentId
}

function* handleNotificationsSaga(action: PayloadAction<INotificationData>) {
  const currentItems  = (yield select(selectNotifications)) as (INotificationData & INotification)[];

  const id = generateId(1, currentItems.map(item => item.id));

  yield put(addNotification({
    id,
    ...action.payload
  }))
}

export default function* coreSaga() {
  yield all([
    takeLatest(startAddNotification, handleNotificationsSaga)])
}
