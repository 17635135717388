import {Configuration} from "@azure/msal-browser";
import {APIS} from "../../static/apis";

export const msalConfig: Configuration = {
  auth: {
    clientId: APIS.APP_ID,
    authority: `https://login.microsoftonline.com/${APIS.TENANT_ID}`,
    redirectUri: `${APIS.BASE_URL}/microsoft-auth`,
    navigateToLoginRequestUrl: true
  },
  cache: {
    cacheLocation: "sessionStorage", // This configures where your cache will be stored
    storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
  }
};

export const baseLoginRequest = {
  scopes: ["User.Read"]
};

