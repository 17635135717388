import { useMutation } from "@tanstack/react-query";
import { queryClient } from "../../../App";
import { IUseCompanyQuery } from "../useQueryCompanies";
import { AxiosResponse } from "axios";
import { IGenericAuthResponse } from "../../user/user";
import { IPayeeAccounts } from "../../companies/companies";
import { startAddNotification } from "../../core/core";
import { INotificationType } from "../../../components/ui/notifications/item/NotificationItem";
import { useAppDispatch } from "../../hooks";
import { addPayeeToCompanyService } from "../../user/saga/vendor/add-payee-to-company";
import { IUseQueryPayees } from "../useQueryPayees";

export const IUseMutationAddPayeeToCompany = "IUseMutationAddPayeeToCompany";

export interface IUseMutationAddPayeeToCompanyPayload {
  name: string;
  payeeNumber: string;
  companyId: string;
  payeeCurrency: string;
}

export function useMutationAddPayeeToCompany() {
  const dispatchStore = useAppDispatch();

  return useMutation({
    mutationKey: [IUseMutationAddPayeeToCompany],
    mutationFn: ({
      name,
      payeeNumber,
      companyId,
      payeeCurrency,
    }: IUseMutationAddPayeeToCompanyPayload) =>
      addPayeeToCompanyService(name, payeeNumber, companyId, payeeCurrency),
    onMutate: async ({ name, payeeNumber, companyId, payeeCurrency }) => {
      await queryClient.cancelQueries({
        queryKey: [IUseCompanyQuery, IUseQueryPayees],
      });

      await queryClient.invalidateQueries({
        queryKey: [IUseCompanyQuery, IUseQueryPayees],
      });

      const previousPayees = queryClient.getQueryData<
        AxiosResponse<IGenericAuthResponse<IPayeeAccounts[]>>
      >([IUseQueryPayees, parseInt(companyId)]);

      const existingIds = previousPayees?.data.data.map((item) => item.id);

      const id = generateId(existingIds || []);

      queryClient.setQueryData<
        AxiosResponse<IGenericAuthResponse<IPayeeAccounts[]>>
      >([IUseQueryPayees, parseInt(companyId)], (prevData) => {
        if (!prevData) {
          return;
        }

        const items = prevData.data.data.map((dataItem) => dataItem);

        items.push({
          name,
          id,
          payeeNumber,
          companyId: id,
          payeeCurrency,
        });

        return {
          ...prevData,
          data: {
            ...prevData.data,
            data: items,
          },
        };
      });

      dispatchStore(
        startAddNotification({
          title: "Success",
          description: `Company successfully updated`,
          type: INotificationType.SUCCESS,
        })
      );

      return { previousPayees, companyId };
    },
    onError: (err, newItem, context) => {
      if (!context) {
        return;
      }

      queryClient.setQueryData(
        [IUseQueryPayees, parseInt(context.companyId)],
        context.previousPayees
      );

      dispatchStore(
        startAddNotification({
          title: "Error",
          description: `Company updates rolled back`,
          type: INotificationType.ERROR,
        })
      );
    },
    onSettled: (_, __, context) => {
      return queryClient.invalidateQueries({
        queryKey: [IUseQueryPayees, parseInt(context.companyId)],
      });
    },
  });
}

function generateId(ids: number[]): number {
  const newId = Math.floor(Math.random() * 100);

  if (ids.includes(newId)) {
    return generateId(ids);
  }

  return newId;
}
