import Modal, {IModalType} from "../../../components/ui/modal/Modal";
import { ICompanyUsersPayees } from "./types";
import "../companies.scss";
import InlineLoader from "../../../components/ui/inline-loader/InlineLoader";

export interface IUsersCompanyModal {
  isVisible: boolean
  onToggle: (state: boolean) => void,
  isLoading: boolean,
  data: ICompanyUsersPayees[]
}

export default function UsersCompanyModal({isVisible, onToggle, data, isLoading}: IUsersCompanyModal) {

  return (
    <>
    <Modal title="Users"
           type={IModalType.DEFAULT}
           toggleVisibility={isVisible}
           onToggle={onToggle}
           customClass={"add-user-modal profile-delete-modal"}
           customBtns={
             <div className={`modal__footer d-flex ${data.length !==0 ? 'users-modal-footer' : ''}`}>
                {data.length !==0 &&
                <span className="users-modal-number ">
                  Total Users: {data.length}
                </span>
                }
                <button type="button"
                        className={`change-pass-modal-footer__action modal-buttons--continue btn--short btn btn--default`}
                        onClick={() => onToggle(false)}>
                  Close
                </button>
             </div>
           }>

        {
        isLoading ? 
          <InlineLoader/> 
        :
        <>
          {data.length !== 0 ?
              <div className="users-modal-container">
                    <table className="users-table">
                      <tbody>
                          <tr>
                            <th className="users-table-head">Name</th>
                            <th className="users-table-head">E-mail</th>
                          </tr>
                      {data.map((item, index)=> {
                        return (
                          <tr key={index} className="user-list">
                              <td className="user-highlight users-table-data ellipsis-text">
                               <span title={item.name}>
                                  {item.name} 
                               </span> 
                              </td>
                              <td className="user-highlight users-table-data ellipsis-text">
                                <span title={item.emailAddress}>
                                  {item.emailAddress}
                                </span>
                              </td>
                          </tr>
                        )
                      })}
                      </tbody>
                    </table>
              </div>
          :  
            <div className="acm-form add-payee-form invoice__block--matter-number add-company-form">
                No users found
            </div>
          }   
        </>
        }
    </Modal>
    
    </>
  )
}
