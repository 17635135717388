import Modal, { IModalType } from "../../../components/ui/modal/Modal";
import RoleModalButtons from "./roleModalButtons";
import { IProfilePasswordModalAction } from "../types";
import Input from "../../../components/ui/inputs/Input";
import { CSSTransition } from "react-transition-group";
import { useEffect, useRef, useState } from "react";
import { IUserRole } from "../../../redux/user/user";
import Select from "../../../components/ui/select/Select";

export interface IRoleModal {
  isVisible: boolean;
  emailToCheck: string;
  roleToCheck: IUserRole | undefined;
  availableRoles: IUserRole[];
  requestClick: (event: IProfilePasswordModalAction, payload?: string) => void;
}

export default function RoleModal({
  isVisible,
  requestClick,
  emailToCheck,
  roleToCheck,
  availableRoles,
}: IRoleModal) {
  const errorRef = useRef<HTMLSpanElement>(null);

  const [userEmail, setUserEmail] = useState("");

  const [isValid, setIsValid] = useState(false);

  const [error, setErrorMessage] = useState("");

  const [selectedOption, setSelectedOption] = useState("");

  useEffect(() => {
    setIsValid(userEmail === emailToCheck && roleToCheck !== selectedOption);
    setErrorMessage(
      !userEmail || !userEmail.length
        ? ""
        : userEmail !== emailToCheck
        ? "Email address is incorrect"
        : roleToCheck === selectedOption
        ? "Role not changed"
        : ""
    );
  }, [userEmail, emailToCheck, roleToCheck, selectedOption]);

  useEffect(() => {
    if (roleToCheck) {
      setSelectedOption(IUserRole.admin);
    }
  }, [roleToCheck]);

  useEffect(() => {
    return () => {
      if (!isVisible) {
        setErrorMessage("");
        setIsValid(false);
        setUserEmail("");
        setSelectedOption(IUserRole.admin);
      }
    };
  }, [isVisible, roleToCheck]);

  return (
    <Modal
      title={"Account Role"}
      type={IModalType.DEFAULT}
      customClass={"password-modal deactivate-modal"}
      toggleVisibility={isVisible}
      customBtns={
        <RoleModalButtons
          requestClick={(event) => {
            switch (event) {
              case IProfilePasswordModalAction.SAVE:
                return requestClick(
                  IProfilePasswordModalAction.SAVE,
                  selectedOption
                );

              case IProfilePasswordModalAction.CANCEL:
                return requestClick(IProfilePasswordModalAction.CANCEL);

              default:
                return;
            }
          }}
          isDisabled={!isValid}
          isLoading={/*isUserUpdatingRoleState*/ false}
        />
      }
      onToggle={(event) => {
        requestClick(IProfilePasswordModalAction.CANCEL);
      }}
    >
      <p className="register-form__intro">
        User roles provide different functionality for the app:
        <br />
        <br />
      </p>

      <h4 className="role-modal__heading">User Role</h4>

      <ul className="list list--standard role-modal__list">
        <li>Can update personal profile data</li>
        <li>Can add CMS Related / CMS Overhead invoices</li>
      </ul>

      <h4 className="role-modal__heading">Supervisor Role</h4>

      <ul className="list list--standard role-modal__list">
        <li>Can update personal profile data</li>
        <li>Can add CMS Related / CMS Overhead invoices</li>
        <li>Can update bank details</li>
      </ul>

      {availableRoles.includes(IUserRole.admin) && (
        <>
          <h4 className="role-modal__heading">Admin Role</h4>

          <ul className="list list--standard role-modal__list">
            <li>Can add CMS Related / CMS Overhead invoices</li>
            <li>Can update bank details</li>
            <li>Can manage users</li>
            <li>Can manage companies</li>
          </ul>
          <br />
        </>
      )}

      <p className="register-form__intro">
        Current user role: <strong>{roleToCheck}</strong>
        <br />
      </p>

      <Select
        id={"role_select"}
        customClassName={"role-modal__select"}
        defaultValue={roleToCheck}
        data={availableRoles}
        requestChange={setSelectedOption}
        selectedItem={selectedOption}
      />

      <p className="register-form__intro">
        Please confirm this action by adding the users email address below
        <br />
        <br />
      </p>

      <Input
        id={"current_email"}
        error={userEmail.length > 0 && !isValid}
        customClass="password-modal__input-el"
        type="email"
        placeholder={"Email Address"}
        value={userEmail}
        requestInput={setUserEmail}
      />

      <span className="register-form__form-error-container">
        <CSSTransition
          classNames="slide-in-out-animation"
          timeout={250}
          in={!isValid && error.length > 0}
          nodeRef={errorRef}
          unmountOnExit
        >
          <span ref={errorRef} className="register-form__form-error">
            {error}
          </span>
        </CSSTransition>
      </span>
    </Modal>
  );
}
