import moment from "moment";

export interface IBankDetailPreloadedDate {
  accountNumber: string;
  accountName: string;
  sortCode: string;
  startDate: moment.Moment | null | string;
}

export const initialState: ISetValueAction = {
  accountNumber: {
    id: "accountNumber",
    value: "",
    isValid: false,
    label: "Account Number",
    dirty: false,
  },
  accountName: {
    id: "accountName",
    value: "",
    isValid: false,
    label: "Account Name",
    dirty: false,
  },
  startDate: {
    id: "startDate",
    value: null,
    isValid: false,
    label: "Start Date",
    dirty: false,
  },
  sortCode: {
    id: "sortCode",
    value: "",
    isValid: false,
    label: "Sort Code",
    dirty: false,
  },
};

export interface ISetValueActionPayload {
  id: keyof ISetValueAction;
  value: string | null | moment.Moment;
  label: string;
  isLoading?: boolean;
  errorMessage?: string;
  isOld?: boolean;
  isValid: boolean;
  dirty: boolean;
}

export interface ISetValueAction {
  accountNumber: ISetValueActionPayload;
  accountName: ISetValueActionPayload;
  sortCode: ISetValueActionPayload;
  startDate: ISetValueActionPayload;
}

export type Action =
  | { type: "preloadState"; payload: IBankDetailPreloadedDate }
  | { type: "setSelectedContact"; payload: string | null }
  | { type: "resetState" }
  | { type: "setValue"; payload: ISetValueActionPayload }
  | { type: "setInputTouched"; payload: ISetValueActionPayload }
  | { type: "setState"; payload: ISetValueAction };

export function reducer(
  state: ISetValueAction,
  action: Action
): ISetValueAction {
  switch (action.type) {
    case "preloadState": {
      return {
        ...state,
        accountName: {
          ...state.accountName,
          value: action.payload.accountName || "",
          isValid: (action.payload.accountName || "").length > 2,
        },
        accountNumber: {
          ...state.accountNumber,
          value: action.payload.accountNumber || "",
          isValid: (action.payload.accountNumber || "").length > 7,
        },
        startDate: {
          ...state.startDate,
          value: action.payload.startDate,
          isValid:
            action.payload.startDate !== null &&
            action.payload.startDate !== undefined,
        },
        sortCode: {
          ...state.sortCode,
          isValid: (action.payload.sortCode || "").length > 5,
          value: action.payload.sortCode || "",
        },
      };
    }
    case "resetState": {
      return {
        ...state,
        ...initialState,
      };
    }

    case "setInputTouched": {
      return {
        ...state,
        [action.payload.id]: {
          ...state[action.payload.id],
          ...action.payload,
        },
      };
    }

    case "setValue": {
      return {
        ...state,
        [action.payload.id]: {
          ...state[action.payload.id],
          dirty:
            action.payload.dirty !== undefined ? action.payload.dirty : true,
          value: action.payload.value,
          isValid: action.payload.isValid,
          errorMessage: action.payload.errorMessage,
          isLoading:
            "isLoading" in action.payload ? action.payload.isLoading : false,
          isOld: "isOld" in action.payload ? action.payload.isOld : false,
        },
      };
    }

    case "setState": {
      return {
        ...state,
        ...action.payload,
      };
    }

    default:
      return {
        ...state,
      };
  }
}
