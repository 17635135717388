export const initialState: ISetValueAction = {
  password: '',
  passwordStrength: 0,
  passwordType: "password",
  currentPassword: '',
  currentPasswordType: "password",
  rePassword: '',
  rePasswordType: "password",
  qrCode: "",
  qrCodeImg: null,
  step: 1
};

export type ISetValueAction = {
  currentPassword: string,
  currentPasswordType: 'password' | 'text',
  password: string,
  passwordType: 'password' | 'text',
  rePassword: string,
  rePasswordType: 'password' | 'text',
  passwordStrength: number
  qrCode: string,
  qrCodeImg: string | null,
  step: number
};

export type Action =
  | { type: "setCurrentPassword"; payload: string }
  | { type: "setCurrentPasswordType"; payload: 'password' | 'text' }
  | { type: "setPassword"; payload: string }
  | { type: "setPasswordType"; payload: 'password' | 'text' }
  | { type: "setRePassword"; payload: string }
  | { type: "setRePasswordType"; payload: 'password' | 'text' }
  | { type: "setPassStrength"; payload: number }
  | { type: "setQrCode"; payload: string }
  | { type: "setQrCodeImg"; payload: string }
  | { type: "setStep"; payload: number }
  | { type: "resetState"}

export function reducer(
  state: ISetValueAction,
  action: Action
): ISetValueAction {
  switch (action.type) {
    case "resetState": {
      return {
        ...state,
        qrCode: "",
        password: "",
        passwordType: "password",
        rePasswordType: "password",
        rePassword: "",
        passwordStrength: 0,
        step: 1
      }
    }

    case "setStep": {
      return {
        ...state,
        step: action.payload
      }
    }

    case "setQrCodeImg": {
      return {
        ...state,
        qrCodeImg: action.payload
      }
    }

    case "setPassword": {
      return {
        ...state,
        password: action.payload
      }
    }

    case "setCurrentPassword": {
      return {
        ...state,
        currentPassword: action.payload
      }
    }

    case "setCurrentPasswordType": {
      return {
        ...state,
        currentPasswordType: action.payload
      }
    }

    case "setQrCode": {
      return {
        ...state,
        qrCode: action.payload
      }
    }

    case "setPasswordType": {
      return {
        ...state,
        passwordType: action.payload
      }
    }

    case "setRePasswordType": {
      return {
        ...state,
        rePasswordType: action.payload
      }
    }

    case "setPassStrength": {
      return {
        ...state,
        passwordStrength: action.payload
      }
    }

    case "setRePassword": {
      return {
        ...state,
        rePassword: action.payload
      }
    }

    default:
      return {
        ...state,
      };
  }
}
