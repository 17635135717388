import {useQuery} from "@tanstack/react-query";
import {getUsersService} from "../../user/saga/auth/get-users";
import {IUsersQueryFilters} from "../../../pages/users/users";

export const IUseQueryUsers = 'IUseQueryUsers';

export function useQueryUsers (condition: boolean, filters: IUsersQueryFilters) {
  return useQuery([IUseQueryUsers, filters], () => getUsersService(filters), {
    enabled: condition,
    keepPreviousData: true,
  });
}
