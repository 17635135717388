import {IEntriesServiceData} from "./generics/contracts/entries/baseGet.service.interface";

export interface IPagesState {
  error: boolean,
  errorMessage: string,
  entries: IOverviewData<IEntriesServiceData[]>,
}

export interface IOverviewData<T> {
  pending: IStatus,
  page?: number,
  hasNextPage?: boolean,
  finishedBootstrapping?: boolean,
  data: T
}

export enum IGetDeviceType {
  DESKTOP = 'desktop',
  TABLET = 'tablet',
  MOBILE = 'mobile'
}

export interface IGetDevices {
  deviceUuid: string,
  deviceInfo: string,
  deviceType: IGetDeviceType
}

export enum IStatus {
  BOOTSTRAPPING = 'bootstrapping',
  TRANSITION_BOOTSTRAPPING = 'transition_bootstrapping',
  IDLE= 'idle',
  LOAD_EMPTY = 'empty',
  RUNNING = 'running',
  CANCELED = 'canceled',
  FAILED = 'failed',
  UPDATING = 'updating',
  SEARCH_INACTIVE = 'search_inactive',
  SEARCH_DRY_RUN = 'search_dry_run',
  SEARCH_IDLE = 'search_idle',
  SEARCHING = 'searching',
  SEARCH_ERROR = 'search_error',
  SEARCH_DONE = 'search_done',
  GENERIC_ERROR='generic_error',
  RUNNING_AUTH = 'running_auth',
  RENEW_ACCESS_TOKEN = 'renew_access_token'
}

export interface IContactCard{
  id: string,
  name: string,
  title: string,
  phone: string,
  email: string,
  photo?: string
}

export interface ISearchState {
  searchTerm: string,
  pending: IStatus,
  tags: string[],
  entries: IEntriesServiceData[],
  searchCount: number
}
