import { useAppDispatch } from "../../hooks";
import { useMutation } from "@tanstack/react-query";
import { queryClient } from "../../../App";
import { AxiosResponse } from "axios";
import { IGenericAuthResponse, IUserStatus } from "../../user/user";
import { startAddNotification } from "../../core/core";
import { INotificationType } from "../../../components/ui/notifications/item/NotificationItem";
import { IUseQueryUsers } from "../useQueryUsers";
import { updateUserStatusService } from "../../user/saga/auth/update-user-status";
import { IUsersQueryFilters } from "../../../pages/users/users";
import { IGetUsersService } from "../../user/saga/auth/get-users";

export interface IUseMutationUpdateUserStatusPayload {
  email: string;
  status: IUserStatus;
  uuid: string;
  filters: IUsersQueryFilters;
}

export const IUseMutationUpdateUserStatus = "IUseMutationUpdateUserStatus";

export function useMutationUpdateUserStatus() {
  const dispatchStore = useAppDispatch();

  return useMutation({
    mutationKey: [IUseMutationUpdateUserStatus],
    mutationFn: ({
      email,
      status,
      uuid,
    }: IUseMutationUpdateUserStatusPayload) =>
      updateUserStatusService({ email, status, uuid }),
    onMutate: async ({ email, status, uuid, filters }) => {
      await queryClient.invalidateQueries({
        queryKey: [IUseQueryUsers, filters],
      });

      const previousUsers = queryClient.getQueryData<
        AxiosResponse<IGenericAuthResponse<IGetUsersService>>
      >([IUseQueryUsers, filters]);

      queryClient.setQueryData<
        AxiosResponse<IGenericAuthResponse<IGetUsersService>>
      >([IUseQueryUsers, filters], (prevData) => {
        if (!prevData) {
          return;
        }

        const items = prevData?.data.data.data.map((dataItem) => {
          if (uuid === dataItem.uuid) {
            return {
              ...dataItem,
              status,
            };
          }

          return {
            ...dataItem,
          };
        });

        return {
          ...prevData,
          data: {
            ...prevData.data,
            data: {
              ...prevData.data.data,
              data: items,
            },
          },
        };
      });

      dispatchStore(
        startAddNotification({
          title: "Success",
          description: `Company successfully updated`,
          type: INotificationType.SUCCESS,
        })
      );

      return { previousUsers };
    },
    onError: (err, newItem, context) => {
      if (!context) {
        return;
      }

      queryClient.setQueryData([IUseQueryUsers], context.previousUsers);

      dispatchStore(
        startAddNotification({
          title: "Error",
          description: `User updates rolled back`,
          type: INotificationType.ERROR,
        })
      );
    },
    onSettled: () => {
      return queryClient.invalidateQueries({ queryKey: [IUseQueryUsers] });
    },
  });
}
