import { IAddCompanyModal } from "../add-company-modal/add-company-modal";
import Modal, { IModalType } from "../../../components/ui/modal/Modal";
import { IProfilePasswordModalAction } from "../../users/types";
import { ICompany } from "../../../redux/companies/companies";

interface IDeleteCompanyModal extends IAddCompanyModal {
  company: ICompany;
}

export default function DeleteCompanyModal({
  isVisible,
  company,
  requestClick,
  onToggle,
}: IDeleteCompanyModal) {
  return (
    <Modal
      title="Delete Company"
      type={IModalType.ERROR}
      toggleVisibility={isVisible}
      onToggle={onToggle}
      customClass={"add-user-modal profile-delete-modal"}
      customBtns={
        <div className="modal__footer d-flex">
          <button
            type="button"
            className={`change-pass-modal-footer__action modal__footer__action btn--short btn btn--mute`}
            onClick={() => {
              return requestClick(IProfilePasswordModalAction.CANCEL);
            }}
          >
            Cancel
          </button>

          <button
            type="button"
            className={`change-pass-modal-footer__action modal-buttons--continue btn--short btn btn--default`}
            onClick={() => {
              return requestClick(IProfilePasswordModalAction.SAVE);
            }}
          >
            Continue
          </button>
        </div>
      }
    >
      <p className="register-form__intro text-align-left">
        The following company and payee accounts associated with it, will be
        removed: <br />
        <strong>{company.name}</strong>
        <br /> <br />
        Are you sure you want to continue?
      </p>
    </Modal>
  );
}
