import {IGenericAuthResponse, IUpdateUserStatus, IUser} from "../../../user";
import axios, {AxiosResponse} from "axios";
import {APIS} from "../../../../static/apis";

export async function updateUserStatusService ({ email, status, uuid}: IUpdateUserStatus): Promise<AxiosResponse<IGenericAuthResponse<IUser>>> {
  return axios.patch(
    APIS.BASE_AUTH_MS_URL + APIS.UPDATE_USER_STATUS,
    {
      status,
      uuid,
      email,
    },
    {
      withCredentials: true
    }
  );
};
