export const APIS = {
  STORAGE_PATH: process.env.REACT_APP_STORAGE_PATH || "",
  DOCUMENTS: process.env.REACT_APP_DOCUMENTS || "",

  BASE_URL: process.env.REACT_APP_BASE_URL || "",
  BASE_AUTH_MS_URL: process.env.REACT_APP_AUTH_BASE_URL || "",

  TENANT_ID: process.env.REACT_APP_TENANT_ID || "",
  APP_ID: process.env.REACT_APP_APPLICATION_ID || "",

  AUTH_GET_ACCESS_TOKEN: process.env.REACT_APP_AUTH_GET_ACCESS_TOKEN || "",

  AUTH_EXTERNAL_PASS: process.env.REACT_APP_AUTH_EXTERNAL_PASS || "",
  AUTH_QR_CODE: process.env.REACT_APP_AUTH_EXTERNAL_QR_CODE || "",
  AUTH_RECOVERY: process.env.REACT_APP_AUTH_EXTERNAL_RECOVERY || "",
  UPDATE_PASSWORD: process.env.REACT_APP_UPDATE_PASSWORD || "",

  AUTH_LOGOUT: process.env.REACT_APP_AUTH_LOGOUT || "",

  REGISTER_MS_ACCOUNT: process.env.REACT_APP_REGISTER_MS_ACCOUNT || "",
  AUTH_MS_ACCOUNT: process.env.REACT_APP_AUTH_MS_ACCOUNT || "",

  AUTH_BIOMETRIC: process.env.REACT_APP_AUTH_BIOMETRIC || "",
  AUTH_BIOMETRIC_VERIFY: process.env.REACT_APP_AUTH_BIOMETRIC_VERIFY || "",

  APP_RESET_PASSWORD: process.env.REACT_APP_RESET_PASSWORD || "",
  CHECK_PASSWORD_AVAILABILITY:
    process.env.REACT_APP_CHECK_PASSWORD_AVAILABILITY || "",
  SET_NEW_PASSWORD: process.env.REACT_APP_SET_NEW_PASSWORD || "",
  SEND_RECOVERY_KEY: process.env.REACT_APP_SEND_RECOVERY_KEY || "",

  PRE_AUTH_REGULAR: process.env.REACT_APP_PRE_AUTH_REGULAR || "",

  ADD_REGISTRATION: process.env.REACT_APP_ADD_REGISTRATION || "",
  MANUAL_REGISTRATION: process.env.REACT_APP_MANUAL_REGISTRATION || "",

  AUTH_GET_REGISTRATION: process.env.REACT_APP_AUTH_GET_REGISTRATION || "",

  AUTH_START_ACCOUNT_SETUP:
    process.env.REACT_APP_AUTH_START_ACCOUNT_SETUP || "",
  AUTH_FINISH_ACCOUNT_SETUP:
    process.env.REACT_APP_AUTH_FINISH_ACCOUNT_SETUP || "",

  AUTH_GENERATE_PKCE: process.env.REACT_APP_AUTH_GENERATE_PKCE || "",

  AUTH_START_AUTH: process.env.REACT_APP_AUTH_START_ACCOUNT_SIGN_IN || "",
  AUTH_FINISH_AUTH: process.env.REACT_APP_AUTH_FINISH_ACCOUNT_SIGN_IN || "",

  REGISTER_BIOMETRIC: process.env.REACT_APP_REGISTER_BIOMETRIC || "",
  REGISTER_BIOMETRIC_VERIFY:
    process.env.REACT_APP_REGISTER_BIOMETRIC_VERIFY || "",

  GET_DEVICES: process.env.REACT_APP_GET_DEVICES || "",
  GET_BIOMETRIC_DEVICES: process.env.REACT_APP_GET_BIOMETRIC_DEVICES || "",

  REMOVE_DEVICE: process.env.REACT_APP_DELETE_DEVICE || "",
  REMOVE_BIOMETRIC_DEVICE: process.env.REACT_APP_DELETE_BIOMETRIC_DEVICE || "",

  GET_USERS: process.env.REACT_APP_GET_USERS || "",
  GET_USER: process.env.REACT_APP_GET_USER || "",

  SEND_ACTIVATION: process.env.REACT_APP_SEND_INVITE_USER || "",
  SEND_PAYEES_REQUEST: process.env.REACT_APP_SEND_PAYEES_REQUEST || "",
  SEND_BANK_DETAILS: process.env.REACT_APP_SEND_BANK_DETAILS || "",

  BIOMETRIC_ADD_DEVICE: process.env.REACT_APP_BIOMETRIC_ADD || "",
  BIOMETRIC_CHECK_REGISTRATION:
    process.env.REACT_APP_CHECK_BIOMETRIC_REGISTRATION || "",
  BIOMETRIC_VERIFY_ADD_DEVICE: process.env.REACT_APP_BIOMETRIC_ADD_VERIFY || "",

  UPDATE_USER_ROLE: process.env.REACT_APP_UPDATE_USER_ROLE || "",
  UPDATE_USER_STATUS: process.env.REACT_APP_UPDATE_USER_STATUS || "",
  DELETE_USER: process.env.REACT_APP_DELETE_USER || "",

  GET_COMPANY_ACCESS: process.env.REACT_APP_GET_COMPANY_ACCESS || "",
  GET_VOUCHER_HISTORY: process.env.REACT_APP_GET_VOUCHER_HISTORY || "",
  GET_BANK_DETAILS: process.env.REACT_APP_GET_BANK_DETAILS || "",
  GET_GL_CODES: process.env.REACT_APP_GET_GL_CODES || "",
  VALIDATE_PAYEE: process.env.REACT_APP_VALIDATE_PAYEE || "",
  EXPORT_VOUCHER_HISTORY: process.env.REACT_APP_EXPORT_VOUCHER_HISTORY || "",
  EXPORT_ALL_USERS: process.env.REACT_APP_EXPORT_ALL_USERS || "",
  EXPORT_ALL_COMPANIES: process.env.REACT_APP_EXPORT_ALL_COMPANIES || "",

  DELETE_PAYEE: process.env.REACT_APP_DELETE_PAYEE || "",
  ADD_ENTITIES_TO_USER: process.env.REACT_APP_ADD_ENTITIES_TO_USER || "",
  REMOVE_ENTITIES_FROM_USER:
    process.env.REACT_APP_REMOVE_ENTITIES_FROM_USER || "",
  DELETE_COMPANY: process.env.REACT_APP_DELETE_COMPANY || "",
  GET_PAYEES: process.env.REACT_APP_GET_PAYEES || "",
  UPDATE_PAYEE: process.env.REACT_APP_UPDATE_PAYEE || "",
  UPDATE_COMPANY: process.env.REACT_APP_UPDATE_COMPANY || "",
  ADD_COMPANY: process.env.REACT_APP_ADD_COMPANY || "",
  ADD_PAYEE_TO_COMPANY: process.env.REACT_APP_ADD_PAYEE_TO_COMPANY || "",
  GET_COMPANIES: process.env.REACT_APP_GET_COMPANIES || "",
  CHECK_COMPANY: process.env.REACT_APP_CHECK_COMPANY || "",
  CHECK_COMPANY_VENDOR_ID: process.env.REACT_APP_CHECK_COMPANY_VENDOR_ID || "",
  VALIDATE_COMPANY_VENDOR_ID:
    process.env.REACT_APP_VALIDATE_COMPANY_VENDOR_ID || "",
  VALIDATE_PAYEE_VENDOR_ID:
    process.env.REACT_APP_VALIDATE_PAYEE_VENDOR_ID || "",
  CHECK_PAYEE: process.env.REACT_APP_CHECK_PAYEE || "",
  GET_CONTACTS: process.env.REACT_APP_GET_CONTACTS || "",
  GET_MS_USERS: process.env.REACT_APP_GET_MS_USERS || "",
  SEND_INVOICE: process.env.REACT_APP_SEND_INVOICE || "",
  SEND_RELATED_INVOICE: process.env.REACT_APP_SEND_RELATED_INVOICE || "",
  SIGN_IN_EXTERNAL: process.env.REACT_APP_SIGN_IN_EXTERNAL || "",
  CONTACT_US: process.env.REACT_APP_CONTACT_US || "",

  USER_GET_AVAILABLE_PAYEES:
    process.env.REACT_APP_USER_GET_AVAILABLE_PAYEES || "",
  USER_GET_USERS_BY_PAYEE_ID: process.env.REACT_APP_GET_USERS_BY_PAYEE_ID || "",

  UPDATE_USERNAME: process.env.REACT_APP_UPDATE_USERNAME || "",
};
