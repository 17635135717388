import { useCallback, useEffect } from "react";
import zxcvbn from 'zxcvbn';
import './password-strength.scss';

export enum IPasswordStrengthEntry {
  TOO_WEAK = 'Too weak',
  WEAK = 'Weak',
  MEDIUM = 'Medium',
  STRONG = 'Strong'
}

export enum IPasswordStrengthEntryDescription {
  TOO_WEAK = 'too-weak',
  WEAK = 'weak',
  MEDIUM = 'medium',
  STRONG = 'strong'
}

export const passStrengthState = {
  [IPasswordStrengthEntry.TOO_WEAK]: {
    name: IPasswordStrengthEntry.TOO_WEAK,
    value: 0,
    description: IPasswordStrengthEntryDescription.TOO_WEAK
  },
  [IPasswordStrengthEntry.WEAK]: {
    name: IPasswordStrengthEntry.WEAK,
    value: 1,
    description: IPasswordStrengthEntryDescription.WEAK
  },
  [IPasswordStrengthEntry.MEDIUM]: {
    name: IPasswordStrengthEntry.MEDIUM,
    value: 2,
    description: IPasswordStrengthEntryDescription.MEDIUM
  },
  [IPasswordStrengthEntry.STRONG]: {
    name: IPasswordStrengthEntry.STRONG,
    value: 3,
    description: IPasswordStrengthEntryDescription.STRONG
  }
}

export interface IPasswordStrength {
  value: string,
  requestStrengthUpdate: (score: number) => void
}

export default function PasswordStrength({value, requestStrengthUpdate}: IPasswordStrength) {
  const passStrength = zxcvbn(value || '');

  const shouldItemBeActive = useCallback((strength: number) => {
    if (passStrength === undefined || !passStrength.score) {
      return false
    }

    return passStrength.score >= strength
  }, [passStrength])

  useEffect(() => {
    requestStrengthUpdate(passStrength.score)
  }, [passStrength, requestStrengthUpdate])

  return (
    <div className="password-strength">
      <strong className="password-strength__title">
        Password Strength
      </strong>

      <div className="password-strength__bar">
        {
          Object.values(passStrengthState).map(item => (
            <div className={`password-strength__item ${shouldItemBeActive(item.value) ? 'password-strength__item--' + item.description : ''}`} key={item.value}>{item.name}</div>
          ))
        }
      </div>
    </div>
  )
}
