import Modal, { IModalType } from "../../../components/ui/modal/Modal";
import { useCallback, useEffect, useRef, useState } from "react";
import { IProfilePasswordModalAction } from "../types";
import "./updateUserModal.scss";
import { IUser } from "../../../redux/user/user";
import Input from "components/ui/inputs/Input";
import { CSSTransition } from "react-transition-group";

export interface IUpdateUserModal {
  isVisible: boolean;
  user?: IUser | undefined;
  requestClick: (event: IProfilePasswordModalAction, payload?: any) => void;
}

export default function UpdateUserModal({
  isVisible,
  requestClick,
  user,
}: IUpdateUserModal) {
  const [isFirstRun, setIsFirstRun] = useState(true);

  const [newUsername, setNewUsername] = useState("");

  const errorRef = useRef<HTMLSpanElement>(null);

  const [isValid, setIsValid] = useState(false);

  const [error, setErrorMessage] = useState("");

  const checkErrors = useCallback((newUsername) => {
    const isUsernameValid = newUsername.length > 2 && newUsername.length < 30;

    if (!newUsername.length) {
      return {
        isValid: false,
        errorMessage: "",
      };
    }

    return {
      isValid: isUsernameValid,
      errorMessage: !isUsernameValid
        ? "Username should be between 3 and 30 characters"
        : "",
    };
  }, []);

  useEffect(() => {
    const { errorMessage, isValid } = checkErrors(newUsername);

    setIsValid(isValid);
    setErrorMessage(errorMessage);
  }, [checkErrors, newUsername]);

  useEffect(() => {
    return () => {
      if (!isVisible) {
        setIsValid(false);
        setErrorMessage("");
        setNewUsername("");
      }
    };
  }, [newUsername, isVisible]);

  useEffect(() => {
    return () => {
      if (!isVisible) {
        setIsFirstRun(true);
      }
    };
  }, [isVisible]);

  return (
    <Modal
      title={"Update Username"}
      type={IModalType.DEFAULT}
      customClass={"password-modal deactivate-modal payee-modal"}
      toggleVisibility={isVisible}
      customBtns={
        <div className={`modal__footer d-flex`}>
          <button
            type="button"
            className={`change-pass-modal-footer__action modal__footer__action btn--short btn btn--mute`}
            onClick={() => {
              setNewUsername("");
              return requestClick(IProfilePasswordModalAction.CANCEL);
            }}
          >
            Cancel
          </button>

          <button
            type="button"
            className={`change-pass-modal-footer__action modal-buttons--continue btn--short btn btn--default`}
            disabled={!newUsername.trim() || !isValid}
            onClick={() => {
              return requestClick(IProfilePasswordModalAction.SAVE, {
                newUsername,
              });
            }}
          >
            Update User
          </button>
        </div>
      }
      onToggle={() => {
        setNewUsername("");
        requestClick(IProfilePasswordModalAction.CANCEL);
      }}
    >
      <p className="register-form__intro">
        Updating Username for <strong>{user ? user.name : "user"}</strong>
      </p>

      <p className="register-form__intro">
        This action will update the username for{" "}
        <strong>{user ? user.name : "user"}</strong>. The change will take
        effect immediately.
        <br />
        <br />
        Please confirm this action by adding the new username below
        <br />
        <br />
      </p>

      <Input
        id={"current_username"}
        customClass="password-modal__input-el"
        placeholder={"Username"}
        value={newUsername}
        requestInput={setNewUsername}
        error={newUsername.length > 0 && !isValid}
      />

      <div>
        <span className="create-account-request__form-error-container">
          <CSSTransition
            classNames="slide-in-out-animation"
            timeout={250}
            in={!isValid && error.length > 0}
            nodeRef={errorRef}
            unmountOnExit
          >
            <span ref={errorRef} className="create-account-request__form-error">
              {error}
            </span>
          </CSSTransition>
        </span>
      </div>
    </Modal>
  );
}
