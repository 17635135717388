import Modal, { IModalType } from "../../../components/ui/modal/Modal";
import { IProfilePasswordModalAction } from "../../users/types";
import { useCallback, useEffect, useState } from "react";
import InlineLoader from "../../../components/ui/inline-loader/InlineLoader";
import InputInlineError from "../../../components/ui/inputs/inline-error/Input.inline-error";
import Input from "../../../components/ui/inputs/Input";
import { startAddNotification } from "../../../redux/core/core";
import { INotificationType } from "../../../components/ui/notifications/item/NotificationItem";
import { useAppDispatch } from "../../../redux/hooks";
import "./add-payee-modal.scss";
import { IPayeeAccounts } from "../../../redux/companies/companies";
import { AxiosError } from "axios";
import { ReactComponent as TrashIcon } from "./../../../assets/icons/trash.svg";
import { useMutationCheckPayee } from "../../../redux/hooks/useMutationCheckPayee";

export interface IAddPayeeModal {
  isVisible: boolean;
  onToggle: (state: boolean) => void;
  requestClick: (state: IProfilePasswordModalAction, payload?: any) => void;
  vendorId: string;
  existingPayees: IPayeeAccounts[];
}

export const CHECK_PAYEE_ID = "check_payee_id";
export const CHECK_PAYEE_NAME = "check_payee_name";

const regexPattern = /^(\d+)-(\d+)-(\d+)$/;

export type ISearchType = typeof CHECK_PAYEE_ID | typeof CHECK_PAYEE_NAME;

export default function AddPayeeModal({
  isVisible,
  requestClick,
  onToggle,
  vendorId,
  existingPayees,
}: IAddPayeeModal) {
  const [searchType, setSearchType] = useState<ISearchType>(CHECK_PAYEE_ID);
  const [acmSearchInput, setAcmSearchInput] = useState("");

  const [acmInput, setAcmInput] = useState("");
  const [isDirty, setIsDirty] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const [viInput, setViInput] = useState("");

  const [payeeCurrency, setPayeeCurrency] = useState("");

  const dispatchStore = useAppDispatch();

  const {
    mutateAsync: checkCompanyVendorIdMutation,
    isLoading: isCheckCompanyVendorIdLoading,
  } = useMutationCheckPayee();

  const foundPayee = useCallback(
    (payeeNumber: string) => {
      return (
        existingPayees.find((item) => item.payeeNumber === payeeNumber) !==
        undefined
      );
    },
    [existingPayees]
  );

  const matchPattern = useCallback((payeeNumber) => {
    return !regexPattern.test(payeeNumber);
  }, []);

  const matchVendorId = useCallback(() => {
    const match = acmSearchInput.match(/-(\d+)-/);
    return match && match[1] !== vendorId;
  }, [acmSearchInput, vendorId]);

  useEffect(() => {
    return () => {
      if (!isVisible) {
        setViInput("");
        setAcmInput("");
        setIsDirty(false);
        setErrorMessage("");
        setAcmSearchInput("");
        setSearchType(CHECK_PAYEE_ID);
      }
    };
  }, [isVisible]);

  return (
    <Modal
      title="Add Payee Account"
      type={IModalType.DEFAULT}
      toggleVisibility={isVisible}
      onToggle={onToggle}
      customClass={"add-user-modal profile-delete-modal"}
      customBtns={
        <div className="modal__footer d-flex">
          <button
            type="button"
            className={`change-pass-modal-footer__action modal__footer__action btn--short btn btn--mute`}
            onClick={() => {
              return requestClick(IProfilePasswordModalAction.CANCEL);
            }}
          >
            Cancel
          </button>

          <button
            type="button"
            disabled={
              isCheckCompanyVendorIdLoading ||
              !viInput.length ||
              !acmInput.length
            }
            className={`change-pass-modal-footer__action modal-buttons--continue btn--short btn btn--default`}
            onClick={() => {
              return requestClick(IProfilePasswordModalAction.SAVE, {
                name: acmInput,
                payeeNumber: viInput,
                payeeCurrency: payeeCurrency,
              });
            }}
          >
            Continue
          </button>
        </div>
      }
    >
      <p className="register-form__intro text-align-left">
        The newly created payee account will be assigned to the current selected
        company
      </p>

      <form
        onSubmit={(event) => event.preventDefault()}
        className="acm-form add-payee-form invoice__block--matter-number add-company-form"
      >
        <Input
          id="acm-form_search"
          showLabel={false}
          placeholder={`Validate Payee ID`}
          requestKeyUp={(event) => {
            if (event === "Meta" || event === "Control") {
              setErrorMessage(
                acmSearchInput.length < 3
                  ? "Payee ID should contain at least 3 characters"
                  : foundPayee(acmSearchInput)
                  ? "Payee account already exists"
                  : matchPattern(acmSearchInput)
                  ? "Format should be xxxx-supplierId-xxxx"
                  : matchVendorId()
                  ? "Payee number does not correspond to this company"
                  : ""
              );
            }
          }}
          requestInput={(event) => {
            if (!isDirty) {
              setIsDirty(true);
            }

            setErrorMessage(
              event.length < 3
                ? "Payee ID should contain at least 3 characters"
                : foundPayee(event)
                ? "Payee account already exists"
                : matchPattern(event)
                ? "Format should be xxxx-supplierId-xxxx"
                : matchVendorId()
                ? "Payee number does not correspond to this company"
                : ""
            );

            setAcmSearchInput(event);
          }}
          requestSubmit={(event) => {
            if (matchVendorId()) {
            } else {
              validateCompany();
            }
          }}
          customClass={`matter-number ${
            viInput.length > 0 && acmInput.length > 0 ? "acm-form-valid" : ""
          }`}
          value={acmSearchInput}
          autocomplete={false}
          type={"string"}
          disabled={
            isCheckCompanyVendorIdLoading ||
            (viInput.length > 0 && acmInput.length > 0)
          }
          error={isDirty && (errorMessage ? errorMessage.length > 0 : false)}
        >
          <>
            <div className="matter-number--actions">
              {isCheckCompanyVendorIdLoading && <InlineLoader />}

              {viInput.length > 0 && acmInput.length > 0 && (
                <button
                  type="button"
                  onClick={() => {
                    setViInput("");
                    setAcmInput("");
                    setAcmSearchInput("");
                    setIsDirty(false);
                  }}
                  className="add-company-form__clear btn--reset history-clear-filters"
                >
                  <TrashIcon />
                </button>
              )}

              <button
                type="button"
                onClick={validateCompany}
                disabled={
                  isCheckCompanyVendorIdLoading ||
                  errorMessage.length > 0 ||
                  (viInput.length > 0 && acmInput.length > 0)
                }
                className={`matter_number-button btn btn--short btn--default ${
                  isCheckCompanyVendorIdLoading ||
                  (viInput.length > 0 && acmInput.length > 0)
                    ? "matter_number-button--disabled"
                    : ""
                }`}
              >
                Validate
              </button>
            </div>

            <InputInlineError
              isVisible={isDirty && errorMessage.length > 0}
              message={errorMessage || ""}
            />
          </>
        </Input>

        <hr />

        <div className="add-company-form__entries">
          <Input
            id="acm-form__vi"
            placeholder="Payee ID"
            showLabel
            customClass={`matter-number ${
              viInput.length > 0 ? "acm-form-valid" : ""
            }`}
            value={viInput}
            autocomplete={false}
            type={"string"}
            disabled={true}
          ></Input>

          <Input
            id="acm-form__cn"
            placeholder="Payee Name"
            customClass={`matter-number ${
              acmInput.length > 0 ? "acm-form-valid" : ""
            }`}
            value={acmInput}
            showLabel
            autocomplete={false}
            type={"string"}
            disabled={true}
          ></Input>
        </div>
      </form>
    </Modal>
  );

  async function validateCompany() {
    try {
      const result = await checkCompanyVendorIdMutation({
        item: acmSearchInput,
        vendorId: vendorId,
      });

      setViInput(acmSearchInput);
      setAcmInput(result.data.data);
      setPayeeCurrency(result.data.currency);
    } catch (error) {
      const currentError = error as AxiosError;

      if (
        currentError &&
        currentError?.response?.status === 400 &&
        currentError?.response?.data.error ===
          "[Supplier]Payee account already exists"
      ) {
        setErrorMessage(
          `${
            searchType === CHECK_PAYEE_NAME
              ? "Supplier Company"
              : "Payee account"
          } already exists`
        );

        return dispatchStore(
          startAddNotification({
            title: "Warning",
            description: `${
              searchType === CHECK_PAYEE_NAME
                ? "Supplier Company"
                : "Payee account"
            } already exists`,
            type: INotificationType.WARNING,
          })
        );
      }

      if (
        currentError &&
        (currentError?.response?.status === 404 ||
          (currentError?.response?.status === 400 &&
            currentError?.response?.data.error ===
              "[Supplier]Invalid params supplied"))
      ) {
        setErrorMessage(
          `${
            searchType === CHECK_PAYEE_NAME
              ? "Supplier Company"
              : "Payee account"
          } not found`
        );

        return dispatchStore(
          startAddNotification({
            title: "Warning",
            description: `${
              searchType === CHECK_PAYEE_NAME
                ? "Supplier Company"
                : "Payee account"
            } not found`,
            type: INotificationType.WARNING,
          })
        );
      }

      setErrorMessage(
        `${
          searchType === CHECK_PAYEE_NAME ? "Supplier Company" : "Payee account"
        } not found`
      );

      return dispatchStore(
        startAddNotification({
          title: "Error",
          description: `Unknown api error occurred`,
          type: INotificationType.ERROR,
        })
      );
    }
  }
}
