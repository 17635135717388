import Modal, { IModalType } from "../../../components/ui/modal/Modal";
import { IProfilePasswordModalAction } from "../../users/types";
import { useEffect, useState } from "react";
import "./add-company-modal.scss";
import InlineLoader from "../../../components/ui/inline-loader/InlineLoader";
import InputInlineError from "../../../components/ui/inputs/inline-error/Input.inline-error";
import Input from "../../../components/ui/inputs/Input";
import { startAddNotification } from "../../../redux/core/core";
import { INotificationType } from "../../../components/ui/notifications/item/NotificationItem";
import { useAppDispatch } from "../../../redux/hooks";
import { useMutationCheckCompanyVendorId } from "../../../redux/hooks/useMutationCheckCompanyVendorId";
import { ICompanyStateOptions } from "../edit-company-modal/edit-company-modal";
import MultiSelect, {
  IMultiSelectOption,
} from "../../../components/ui/multiselect/multiselect";
import { AxiosError } from "axios";
import { ReactComponent as TrashIcon } from "./../../../assets/icons/trash.svg";

export interface IAddCompanyModal {
  isVisible: boolean;
  onToggle: (state: boolean) => void;
  requestClick: (state: IProfilePasswordModalAction, payload?: any) => void;
}

const initialMultiSelectState = [
  {
    name: ICompanyStateOptions.related,
    isSelected: true,
  },
  {
    name: ICompanyStateOptions.overhead,
    isSelected: true,
  },
];

export const CHECK_VENDOR_ID = "check_vendor_id";
export const CHECK_VENDOR_NAME = "check_vendor_name";

export type ISearchType = typeof CHECK_VENDOR_ID | typeof CHECK_VENDOR_NAME;

export default function AddCompanyModal({
  isVisible,
  requestClick,
  onToggle,
}: IAddCompanyModal) {
  const [searchType, setSearchType] = useState<ISearchType>("check_vendor_id");

  const [acmInput, setAcmInput] = useState("");
  const [acmSearchInput, setAcmSearchInput] = useState("");
  const [isDirty, setIsDirty] = useState(false);

  const [viInput, setViInput] = useState("");

  const [errorMessage, setErrorMessage] = useState("");

  const [multiSelectOptions, setMultiSelectOptions] = useState<
    IMultiSelectOption[]
  >(initialMultiSelectState);

  const dispatchStore = useAppDispatch();

  const {
    mutateAsync: checkCompanyVendorIdMutation,
    isLoading: isCheckCompanyVendorIdLoading,
  } = useMutationCheckCompanyVendorId();

  useEffect(() => {
    return () => {
      if (!isVisible) {
        setAcmInput("");
        setViInput("");
        setIsDirty(false);
        setErrorMessage("");
        setSearchType(CHECK_VENDOR_ID);
        setMultiSelectOptions(
          initialMultiSelectState.map((item) => ({
            ...item,
            isSelected: true,
          }))
        );
        setAcmSearchInput("");
      }
    };
  }, [isVisible]);

  return (
    <Modal
      title="Create Company Profile"
      type={IModalType.DEFAULT}
      toggleVisibility={isVisible}
      onToggle={onToggle}
      customClass={
        "add-user-modal profile-delete-modal acm-modal company-modal"
      }
      customBtns={
        <div className="modal__footer d-flex">
          <button
            type="button"
            className={`change-pass-modal-footer__action modal__footer__action btn--short btn btn--mute`}
            onClick={() => {
              return requestClick(IProfilePasswordModalAction.CANCEL);
            }}
          >
            Cancel
          </button>

          <button
            type="button"
            disabled={
              isCheckCompanyVendorIdLoading ||
              !viInput.length ||
              !acmInput.length
            }
            className={`change-pass-modal-footer__action modal-buttons--continue btn--short btn btn--default`}
            onClick={() => {
              const overhead = multiSelectOptions.find(
                (item) => item.name === ICompanyStateOptions.overhead
              );
              const related = multiSelectOptions.find(
                (item) => item.name === ICompanyStateOptions.related
              );

              return requestClick(IProfilePasswordModalAction.SAVE, {
                acmInput,
                viInput,
                overhead: overhead ? overhead.isSelected : false,
                related: related ? related.isSelected : false,
              });
            }}
          >
            Continue
          </button>
        </div>
      }
    >
      <p className="register-form__intro text-align-left">
        After creating the new company profile, one or more{" "}
        <strong>payee</strong> accounts can be assigned to it.
      </p>

      <form
        onSubmit={(event) => event.preventDefault()}
        className="acm-form invoice__block--matter-number add-company-form"
      >
        <Input
          id="acm-form_search"
          showLabel={false}
          placeholder={`Validate ${
            searchType === CHECK_VENDOR_NAME ? "Supplier Name" : "Supplier ID"
          }`}
          requestInput={(event) => {
            if (!isDirty) {
              setIsDirty(true);
            }

            setErrorMessage(
              event.length < 3
                ? `${
                    searchType === CHECK_VENDOR_NAME
                      ? "Supplier Name"
                      : "Supplier ID"
                  } should contain at least 3 characters`
                : ""
            );

            setAcmSearchInput(event);
          }}
          requestSubmit={validateCompany}
          customClass={`matter-number ${
            viInput.length > 0 && acmInput.length > 0 ? "acm-form-valid" : ""
          }`}
          value={acmSearchInput}
          autocomplete={false}
          type={"string"}
          disabled={
            isCheckCompanyVendorIdLoading ||
            (viInput.length > 0 && acmInput.length > 0)
          }
          error={isDirty && (errorMessage ? errorMessage.length > 0 : false)}
        >
          <>
            <div className="matter-number--actions">
              {isCheckCompanyVendorIdLoading && <InlineLoader />}

              {viInput.length > 0 && acmInput.length > 0 && (
                <button
                  type="button"
                  onClick={() => {
                    setViInput("");
                    setAcmInput("");
                    setAcmSearchInput("");
                    setIsDirty(false);
                  }}
                  className="add-company-form__clear btn--reset history-clear-filters"
                >
                  <TrashIcon />
                </button>
              )}

              <button
                type="button"
                onClick={validateCompany}
                disabled={
                  isCheckCompanyVendorIdLoading ||
                  (viInput.length > 0 && acmInput.length > 0)
                }
                className={`matter_number-button btn btn--short btn--default ${
                  isCheckCompanyVendorIdLoading ||
                  (viInput.length > 0 && acmInput.length > 0)
                    ? "matter_number-button--disabled"
                    : ""
                }`}
              >
                Validate
              </button>
            </div>

            <InputInlineError
              isVisible={isDirty && errorMessage.length > 0}
              message={errorMessage || ""}
            />
          </>
        </Input>

        <hr />

        <div className="add-company-form__entries">
          <Input
            id="acm-form__vi"
            placeholder="Supplier ID"
            showLabel
            customClass={`matter-number ${
              viInput.length > 0 ? "acm-form-valid" : ""
            }`}
            value={viInput}
            autocomplete={false}
            type={"string"}
            disabled={true}
          ></Input>

          <Input
            id="acm-form__cn"
            placeholder="Company Name"
            customClass={`matter-number ${
              acmInput.length > 0 ? "acm-form-valid" : ""
            }`}
            value={acmInput}
            showLabel
            autocomplete={false}
            type={"string"}
            disabled={true}
          ></Input>
        </div>

        <hr />

        <div className="company-multiselect-options">
          <span className="input__title">Company Access</span>

          <MultiSelect
            customClass="history-multiselect"
            isDisabled={
              isCheckCompanyVendorIdLoading ||
              !viInput.length ||
              !acmInput.length
            }
            requestChange={(event) => {
              setMultiSelectOptions(
                multiSelectOptions.map((item) => ({
                  ...item,
                  isSelected:
                    event.name === item.name
                      ? event.isSelected
                      : item.isSelected,
                }))
              );
            }}
            requestClearAll={() => {
              setMultiSelectOptions(
                multiSelectOptions.map((item) => ({
                  ...item,
                  isSelected: false,
                }))
              );
            }}
            placeholder={"No invoice type access"}
            options={multiSelectOptions}
          />
        </div>
      </form>
    </Modal>
  );

  async function validateCompany() {
    try {
      const result = await checkCompanyVendorIdMutation(acmSearchInput);

      setViInput(
        searchType === CHECK_VENDOR_NAME ? result.data.data : acmSearchInput
      );
      setAcmInput(
        searchType === CHECK_VENDOR_NAME ? acmSearchInput : result.data.data
      );
    } catch (error) {
      const currentError = error as AxiosError;
      if (currentError && currentError?.response?.status === 400) {
        setErrorMessage(
          `${
            searchType === CHECK_VENDOR_NAME
              ? "Supplier Company"
              : "Supplier ID"
          } already exists`
        );

        return dispatchStore(
          startAddNotification({
            title: "Warning",
            description: `${
              searchType === CHECK_VENDOR_NAME
                ? "Supplier Company"
                : "Supplier ID"
            } already exists`,
            type: INotificationType.WARNING,
          })
        );
      }

      if (currentError && currentError?.response?.status === 404) {
        setErrorMessage(
          `${
            searchType === CHECK_VENDOR_NAME
              ? "Supplier Company"
              : "Supplier ID"
          } not found`
        );

        return dispatchStore(
          startAddNotification({
            title: "Warning",
            description: `${
              searchType === CHECK_VENDOR_NAME
                ? "Supplier Company"
                : "Supplier ID"
            } not found`,
            type: INotificationType.WARNING,
          })
        );
      }

      setErrorMessage(
        `${
          searchType === CHECK_VENDOR_NAME ? "Supplier Company" : "Supplier ID"
        } not found`
      );

      return dispatchStore(
        startAddNotification({
          title: "Error",
          description: `Unknown api error occurred`,
          type: INotificationType.ERROR,
        })
      );
    }
  }
}
