import {addUserService} from "../../user/saga/auth/add-user";
import {useMutation} from "@tanstack/react-query";
import {queryClient} from "../../../App";
import {AxiosResponse} from "axios";
import {IGenericAuthResponse, IUser, IUserProvider, IUserRole, IUserStatus} from "../../user/user";
import {startAddNotification} from "../../core/core";
import {INotificationType} from "../../../components/ui/notifications/item/NotificationItem";
import {useAppDispatch} from "../../hooks";
import {IUseQueryUsers} from "../useQueryUsers";

export const IUseMutationAddUser = 'IUseMutationAddUser';

export interface IUseMutationAddUserPayload {
  name: string,
  email: string,
  isMsAccount: boolean
}

export function useMutationAddUser() {
  const dispatchStore = useAppDispatch();

  return useMutation({
    mutationKey: [IUseMutationAddUser],
    mutationFn: ({name, email, isMsAccount}: IUseMutationAddUserPayload) => addUserService(name, email),
    onMutate: async ({name, email, isMsAccount}) => {
      await queryClient.cancelQueries({ queryKey: [IUseQueryUsers] });

      const previousUsers = queryClient.getQueryData<AxiosResponse<IGenericAuthResponse<IUser[]>>>([IUseQueryUsers]);

      const id = generateId([]);

      queryClient.setQueryData<AxiosResponse<IGenericAuthResponse<IUser[]>>>([IUseQueryUsers], (prevData) => {
        if (!prevData) {
          return
        }

        const items = prevData.data.data.map(dataItem => dataItem);

        items.push({
          name,
          email,
          uuid: id.toString(),
          createdOn: '',
          devices: [],
          userAvatar: null,
          provider: isMsAccount ? IUserProvider.MICROSOFT : IUserProvider.DEFAULT,
          status: IUserStatus.PENDING,
          role: IUserRole.user,
          vendorId: null,
          vendorName: null,
          availablePayees: []
        })

        return {
          ...prevData,
          data: {
            ...prevData.data,
            data: items
          }
        };
      });

      dispatchStore(startAddNotification({
        title: "Success",
        description: `Users successfully updated`,
        type: INotificationType.SUCCESS
      }));

      return { previousUsers };
    },
    onError: (err, newItem, context) => {
      if (!context) {
        return;
      }

      queryClient.setQueryData([IUseQueryUsers], context.previousUsers)

      dispatchStore(startAddNotification({
        title: "Error",
        description: `User updates rolled back`,
        type: INotificationType.ERROR
      }));
    },
    onSettled: () => {
      return queryClient.invalidateQueries({ queryKey: [IUseQueryUsers] })
    },
  })
}

function generateId(ids: number[]): number {
  const newId = Math.floor(Math.random() * 100);

  if (ids.includes(newId)) {
    return generateId(ids);
  }

  return newId;
}
