import {
  IGenericAuthResponse,
} from "../../../user";
import axios, {AxiosResponse} from "axios";
import {APIS} from "../../../../static/apis";

export async function deleteUserService (uuid: string): Promise<AxiosResponse<IGenericAuthResponse<object>>> {
  return axios.delete(
    APIS.BASE_AUTH_MS_URL + APIS.DELETE_USER + '/' + uuid,
    {
      withCredentials: true
    }
  )
}
