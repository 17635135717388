import "./footer.scss";
// import {ReactComponent as EmailIcon} from "./../../../assets/icons/email.svg";
import { Link } from "react-router-dom";

export default function Footer() {
  return (
    <footer className="app-footer">
      <div>
        <div className="row">
          <div className="col">
            <footer className="login-footer app-footer__container">
              <p className="login-footer__item login-footer__item--copyright">
                © {new Date().getFullYear()} CMS Cameron McKenna Nabarro Olswang
                LLP
              </p>

              <p className="login-footer__item login-footer__item--teaser">
                This material contains and/or is the product of privileged legal
                advice and is confidential, legally privileged and protected by
                copyright.
              </p>

              <div className="login-footer__item d-flex justify-content-space-between f-column-sm f-column-md">
                <ul className="list login-footer__list f-column-xs">
                  <li className="login-footer__list-item login-footer__list-item--userterms">
                    <Link
                      to="/acceptable-use-policy"
                      className="login-footer__link"
                    >
                      <span className="login-footer__link-description">
                        Acceptable Use Policy
                      </span>
                    </Link>
                  </li>

                  {/* <li className="login-footer__list-item login-footer__list-item--userterms">
                    <Link to="/privacy-policy" className="login-footer__link">
                      <span className="login-footer__link-description">
                        Privacy Policy
                      </span>
                    </Link>
                  </li>

                  <li className="login-footer__list-item login-footer__list-item--userterms">
                    <Link
                      to="/terms-and-conditions"
                      className="login-footer__link"
                    >
                      <span className="login-footer__link-description">
                        Terms and Conditions
                      </span>
                    </Link>
                  </li> */}
                </ul>

                {/*<ul className='list login-footer__list f-column-xs'>*/}
                {/*  <li className='login-footer__list-item login-footer__list-item--email'>*/}
                {/*    <Link to="/contact-us" className='login-footer__link'>*/}
                {/*      <span className='login-footer__link-icon'>*/}
                {/*        <EmailIcon />*/}
                {/*      </span>*/}

                {/*      <span className='login-footer__link-description'>*/}
                {/*        Contact Us*/}
                {/*      </span>*/}
                {/*    </Link>*/}
                {/*  </li>*/}
                {/*</ul>*/}
              </div>
            </footer>
          </div>
        </div>
      </div>
    </footer>
  );
}
