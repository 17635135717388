import {useCallback, useEffect} from "react";
import store from "../../redux/store";
import {IUserDarkMode} from "../../redux/core/core";
import {useAppDispatch, useAppSelector} from "../../redux/hooks";
import {updateDarkMode} from "../../redux/user/user";
import {selectDarkModeState} from "../../redux/user/selectors";

export default function useDarkModeSetup() {
  const dispatch = useAppDispatch();

  const currentStoreDarkMode = useAppSelector(selectDarkModeState);

  // use a callback for good measure
  const callback = useCallback((event: MediaQueryListEvent | MediaQueryList) => {
    const currentDarkModeState = store.getState().user.darkMode;

    if (currentDarkModeState === IUserDarkMode.USER_SET ||
        currentDarkModeState === IUserDarkMode.NOT_SET_USER ||
        !event) {
      return;
    }

    if (event.matches) {
      return dispatch(updateDarkMode(IUserDarkMode.SYSTEM_SET));
    }

    return dispatch(updateDarkMode(IUserDarkMode.NOT_SET));
  }, [dispatch]);

  callback(window.matchMedia('(prefers-color-scheme: dark)'));

  // handle system changes
  useEffect(() => {
    window
      .matchMedia('(prefers-color-scheme: dark)')
      .addEventListener('change', callback);

  // Remove listener
  return () => {
    window
      .matchMedia('(prefers-color-scheme: dark)')
      .removeEventListener('change', callback);
    }
  });

  // update document page state
  useEffect(() => {
    if (currentStoreDarkMode === IUserDarkMode.USER_SET ||
        currentStoreDarkMode === IUserDarkMode.SYSTEM_SET) {
      document.body.setAttribute('data-theme', 'dark');
    }

    if (currentStoreDarkMode === IUserDarkMode.NOT_SET_USER ||
        currentStoreDarkMode === IUserDarkMode.NOT_SET) {
      document.body.removeAttribute('data-theme');
    }
  }, [currentStoreDarkMode])
}
