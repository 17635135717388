import {IGenericAuthResponse, IUpdateUserRole, IUser} from "../../../user";
import axios, {AxiosResponse} from "axios";
import {APIS} from "../../../../static/apis";

export async function updateUserRoleService ({ email, role, uuid}: IUpdateUserRole): Promise<AxiosResponse<IGenericAuthResponse<IUser>>> {
  return axios.patch(
    APIS.BASE_AUTH_MS_URL + APIS.UPDATE_USER_ROLE,
    {
      role,
      uuid,
      email,
    },
    {
      withCredentials: true
    }
  );
};
