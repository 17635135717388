import "./input.scss";
import { ForwardedRef, forwardRef, PropsWithChildren } from "react";

interface InputComponentProps {
  autoUkStyle?: boolean;
  type?: string;
  id: string;
  placeholder: string;
  altLayout?: boolean;
  value: string;
  requestInput?: (value: string) => void;
  customClass?: string;
  defaultValue?: string;
  autocomplete?: boolean;
  maxChar?: number;
  requestClear?: () => void;
  disabled?: boolean;
  error?: boolean;
  showLabel?: boolean;
  requestBlur?: (value: string) => void;
  requestFocus?: (value: string) => void;
  requestSubmit?: (value: string) => void;
  requestKeyUp?: (value: string) => void;
  customLabelClass?: boolean;
  customLabelClassOverhead?: boolean;
}

function Input(
  {
    id,
    type,
    autoUkStyle,
    showLabel,
    altLayout,
    placeholder,
    requestBlur,
    requestSubmit,
    children,
    customClass,
    requestFocus,
    defaultValue,
    requestInput,
    autocomplete,
    value,
    maxChar,
    requestClear,
    error,
    disabled,
    requestKeyUp,
    customLabelClass,
    customLabelClassOverhead,
  }: PropsWithChildren<InputComponentProps>,
  ref: ForwardedRef<HTMLInputElement>
) {
  return (
    <label
      className={`input input__label ${altLayout ? "input__label--alt" : ""} ${
        showLabel ? "input__label--with-title" : ""
      } ${customClass && customClass.length > 0 ? customClass : ""}`}
      htmlFor={id}
    >
      {!altLayout && showLabel && (
        <span
          className={`input__title ${
            customLabelClass
              ? "marginBottomClass"
              : customLabelClassOverhead
              ? "marginBottomClassOverhead"
              : ""
          }`}
        >
          {placeholder}
        </span>
      )}

      <input
        ref={ref}
        onBlur={() => {
          if (!requestBlur) {
            return;
          }

          return requestBlur(value);
        }}
        onFocus={() => {
          if (!requestFocus) {
            return;
          }

          return requestFocus(value);
        }}
        type={type || "text"}
        autoComplete={autocomplete !== undefined ? "off" : ""}
        id={id}
        onKeyDown={(event) => {
          if (event.key !== "Enter" || !requestSubmit || disabled) {
            return;
          }

          return requestSubmit(value);
        }}
        onKeyUp={(event) => {
          if (!requestKeyUp) {
            return;
          }

          return requestKeyUp(event.key);
        }}
        defaultValue={defaultValue}
        className={`input__element 
                    ${altLayout ? "input__element--alt" : ""}
                    ${value.length > 0 ? "input__element--has-value" : ""}
                    ${error ? "input__element--error" : ""}
                    ${disabled ? "input__element--disabled" : ""}
                   `}
        placeholder={showLabel ? "" : placeholder || ""}
        value={value}
        maxLength={maxChar}
        disabled={disabled}
        onChange={(e) => {
          if (!requestInput) {
            return;
          }

          if (autoUkStyle) {
            return requestInput(e.target.value);
          }

          return requestInput(e.target.value);
        }}
      />

      {altLayout && showLabel && (
        <span className="input__title input__title--alt">{placeholder}</span>
      )}

      {children ? children : null}
    </label>
  );
}

export default forwardRef<
  HTMLInputElement,
  PropsWithChildren<InputComponentProps>
>(Input);
