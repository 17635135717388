import Modal, { IModalType } from "../../../components/ui/modal/Modal";
import { useEffect, useMemo, useState } from "react";
import { IProfilePasswordModalAction } from "../types";
import { ICompany, IPayeeAccounts } from "../../../redux/companies/companies";
import Select from "../../../components/ui/select/Select";
import MultiSelect, {
  IMultiSelectOption,
} from "../../../components/ui/multiselect/multiselect";
import { useAppDispatch } from "../../../redux/hooks";
import { startAddNotification } from "../../../redux/core/core";
import { INotificationType } from "../../../components/ui/notifications/item/NotificationItem";
import "./payeesModal.scss";
import InlineLoader from "../../../components/ui/inline-loader/InlineLoader";
import { Link } from "react-router-dom";
import { useQueryPayees } from "../../../redux/hooks/useQueryPayees";
import { IUser } from "../../../redux/user/user";
import AddCompanyToUserModal from "../add-company-to-user-modal/add-company-to-user-modal";

export interface IPayeesModal {
  isVisible: boolean;
  companies: ICompany[];
  defaultCompany?: ICompany | undefined;
  user?: IUser | undefined;
  defaultPayees?: IPayeeAccounts[] | undefined;
  requestClick: (event: IProfilePasswordModalAction, payload?: any) => void;
}

export default function PayeesModal({
  isVisible,
  companies,
  defaultPayees,
  requestClick,
  defaultCompany,
  user,
}: IPayeesModal) {
  const dispatchStore = useAppDispatch();

  const [isFirstRun, setIsFirstRun] = useState(true);

  const [isAddUserToCompanyModalVisible, setAddUserToCompanyModalVisible] =
    useState(false);

  const handleDataFromChild = (data: any) => {
    setAddUserToCompanyModalVisible(false);
    requestClick(IProfilePasswordModalAction.SAVE, {
      companyId: company
        ? company.id.toString()
        : defaultCompany
        ? defaultCompany.id
        : "",
      payees: payeesData
        ? payeesData.data.data.filter((item) => {
            const currentItem = multiSelectOptions.find(
              (multiSelectItem) => multiSelectItem.name === item.name
            );

            return !(!currentItem || !currentItem.isSelected);
          })
        : [],
    });
  };

  const [multiSelectOptions, setMultiSelectOptions] = useState<
    IMultiSelectOption[]
  >([]);
  const [company, setSelectedCompany] = useState<ICompany | undefined>(
    defaultCompany
  );

  const {
    data: payeesData,
    isLoading: isPayeesDataLoading,
    isError: isPayeesDataError,
  } = useQueryPayees(company ? company.id : 0, company !== undefined);

  useEffect(() => {
    if (isVisible && defaultCompany !== undefined) {
      setSelectedCompany(defaultCompany);
    }
  }, [defaultCompany, isVisible]);

  useEffect(() => {
    if (isPayeesDataLoading) {
      setMultiSelectOptions([]);
    }

    if (!isPayeesDataLoading && payeesData) {
      setMultiSelectOptions(
        payeesData.data.data.map((item) => ({
          name: item.name,
        }))
      );
    }
  }, [defaultCompany, isPayeesDataLoading, payeesData]);

  useEffect(() => {
    if (isVisible && defaultPayees && payeesData && isFirstRun) {
      setMultiSelectOptions(
        payeesData.data.data.map((item) => ({
          name: item.name,
          isSelected: defaultPayees.some(
            (defaultItem) => defaultItem.name === item.name
          ),
        }))
      );
    }
  }, [company, defaultPayees, isFirstRun, isVisible, payeesData]);

  useEffect(() => {
    if (isPayeesDataError) {
      dispatchStore(
        startAddNotification({
          title: "Error",
          description: "Unknown Payees api error occurred",
          type: INotificationType.ERROR,
        })
      );
    }
  }, [dispatchStore, isPayeesDataError]);

  useEffect(() => {
    return () => {
      if (!isVisible) {
        setSelectedCompany(defaultCompany || undefined);
        setMultiSelectOptions([]);
        setSelectedCompany(undefined);
        setIsFirstRun(true);
      }
    };
  }, [defaultCompany, isVisible]);

  const isPayeesSelected = useMemo(() => {
    // if there are no payees for this company
    if (
      !isPayeesDataLoading &&
      (!multiSelectOptions || !multiSelectOptions.length)
    ) {
      return true;
    }
    return multiSelectOptions.some(
      (item) => "isSelected" in item && item.isSelected === true
    );
  }, [isPayeesDataLoading, multiSelectOptions]);

  return (
    <Modal
      title={"User Company & Payee Accounts"}
      type={IModalType.DEFAULT}
      customClass={"password-modal deactivate-modal payee-modal"}
      toggleVisibility={isVisible}
      customBtns={
        <div
          className={`modal__footer d-flex ${
            defaultCompany !== undefined ? "modal__footer--left" : ""
          }`}
        >
          {defaultCompany !== undefined ? (
            <button
              type="button"
              className={`change-pass-modal-footer__action modal__footer__action btn--short btn  btn--error`}
              disabled={!company ? isPayeesDataLoading : false}
              onClick={() => {
                return requestClick(IProfilePasswordModalAction.DELETE);
              }}
            >
              Clear Company Data
            </button>
          ) : null}

          <button
            type="button"
            className={`change-pass-modal-footer__action modal__footer__action btn--short btn btn--mute`}
            onClick={() => {
              return requestClick(IProfilePasswordModalAction.CANCEL);
            }}
          >
            Cancel
          </button>

          <button
            type="button"
            className={`change-pass-modal-footer__action modal-buttons--continue btn--short btn btn--default`}
            disabled={isPayeesDataLoading || !isPayeesSelected}
            onClick={() => {
              setAddUserToCompanyModalVisible(true);
              return;
              // return requestClick(IProfilePasswordModalAction.SAVE, {
              //   companyId: company
              //     ? company.id.toString()
              //     : defaultCompany
              //     ? defaultCompany.id
              //     : "",
              //   payees: payeesData
              //     ? payeesData.data.data.filter((item) => {
              //         const currentItem = multiSelectOptions.find(
              //           (multiSelectItem) => multiSelectItem.name === item.name
              //         );

              //         return !(!currentItem || !currentItem.isSelected);
              //       })
              //     : [],
              // });
            }}
          >
            {defaultCompany !== undefined ? "Update User" : "Continue"}
          </button>
        </div>
      }
      onToggle={() => {
        requestClick(IProfilePasswordModalAction.CANCEL);
      }}
    >
      <p className="register-form__intro">
        Assign <strong>{user ? user.name : "user"}</strong> to a company, and
        select one or more payees accounts belonging to the selected company.
      </p>

      {defaultCompany !== undefined && (
        <p>
          Clearing the user companies will remove <strong>company</strong> and{" "}
          <strong>payee account(s)</strong> currently assigned to the user.
        </p>
      )}

      <p>
        A user can be assigned to <strong>one</strong> company:
      </p>

      <Select
        id={"role_select"}
        isDisabled={isPayeesDataLoading && company !== undefined}
        defaultValue={{
          id: defaultCompany ? defaultCompany.id.toString() : "",
          content: defaultCompany ? defaultCompany.name : "",
        }}
        customClassName={"gl-ref-select"}
        placeholder={"Select Company"}
        requestChange={handleChange}
        data={companies.map((item) => ({
          id: item.id.toString(),
          content: item.name,
        }))}
      />

      <p>
        A user can be assigned to <strong>one or multiple</strong> payees, on
        the same company:
      </p>

      <br />

      <MultiSelect
        isDisabled={
          isPayeesDataLoading ||
          !company ||
          !payeesData ||
          !payeesData.data.data.length
        }
        requestChange={handleMultiSelectChange}
        placeholder={
          isPayeesDataLoading && company
            ? "Loading Payee Account(s)"
            : !company
            ? "No company selected"
            : !payeesData || !payeesData.data.data.length
            ? "No data available"
            : "Select Payee Account(s)"
        }
        options={multiSelectOptions}
      />

      <br />

      <p>
        <small>
          *Companies and Payee Accounts can be managed from{" "}
          <strong>
            <Link to={"/companies"}>Companies Page</Link>
          </strong>
        </small>
      </p>

      <br />

      {isPayeesDataLoading && company && (
        <span className="acm--loader">
          <InlineLoader />
        </span>
      )}

      <br />

      <AddCompanyToUserModal
        isVisible={isAddUserToCompanyModalVisible}
        user={user}
        requestClick={handleAddUserToCompanyActions}
        requestClickCancel={handleAddUserToCompanyCancelActions}
        company={company}
        sendDataToParent={handleDataFromChild}
      />
    </Modal>
  );

  async function handleAddUserToCompanyActions() {
    setAddUserToCompanyModalVisible(false);
  }

  async function handleAddUserToCompanyCancelActions() {
    setAddUserToCompanyModalVisible(false);
  }
  async function handleMultiSelectChange(selection: IMultiSelectOption) {
    setMultiSelectOptions(
      multiSelectOptions.map((item) => ({
        ...item,
        isSelected:
          selection.name === item.name ? selection.isSelected : item.isSelected,
      }))
    );
  }

  async function handleChange(event: string) {
    const company = companies.find(
      (item) => item.id.toString() === event.trim().toLowerCase()
    );
    setMultiSelectOptions([]);

    if (isFirstRun) {
      setIsFirstRun(false);
    }

    if (!company) {
      setSelectedCompany(undefined);

      return;
    }

    setSelectedCompany(company);
  }
}
