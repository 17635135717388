import Modal, {IModalType} from "components/ui/modal/Modal";
import {IProfilePasswordModalAction} from "../../../../pages/users/types";
import {IProfileDeleteDeviceModal} from "../../../../pages/profile/delete-device-modal/profile.delete-device";


export default function RemovePayeeModal ({isVisible, requestClick, onToggle}: IProfileDeleteDeviceModal) {
  return (
    <Modal title="Clear Payee Account"
           type={IModalType.NOTIFY}
           toggleVisibility={isVisible}
           onToggle={onToggle}
           customClass={"add-user-modal profile-delete-modal"}
           customBtns={
             <div className="modal__footer d-flex">
               <button type="button"
                       className={`change-pass-modal-footer__action modal__footer__action btn--short btn btn--mute`}
                       onClick={() => {
                         return requestClick(IProfilePasswordModalAction.CANCEL);
                       }}>
                 Cancel
               </button>

               <button type="button"
                       className={`change-pass-modal-footer__action modal-buttons--continue btn--short btn btn--error`}
                       onClick={() => {
                         return requestClick(IProfilePasswordModalAction.SAVE);
                       }}>
                 Continue
               </button>
             </div>
           }>
      <p className="register-form__intro text-align-left">
        This action will clear the <strong>current</strong> selected <strong>payee account</strong>, and all unsaved data will be lost. <br /><br />

        You will be redirected to the homepage, in order to select a <strong>payee account</strong>. <br /> <br />

        Are you sure you want to continue?
      </p>
    </Modal>
  );
}
