import {useMutation} from "@tanstack/react-query";
import {updateCompanyService} from "../../user/saga/vendor/update-company";
import {AxiosResponse} from "axios";
import {IGenericAuthResponse} from "../../user/user";
import {ICompany} from "../../companies/companies";
import {queryClient} from "../../../App";
import {startAddNotification} from "../../core/core";
import {INotificationType} from "../../../components/ui/notifications/item/NotificationItem";
import {useAppDispatch} from "../../hooks";
import {IUseCompanyQuery} from "../useQueryCompanies";
import {IUseCompanyAccess} from "../useQueryCompanyAccess";

export const IUseMutationUpdateCompany = 'IUseMutationUpdateCompany';

export function useMutationUpdateCompany() {
  const dispatchStore = useAppDispatch();

  return useMutation({
    mutationKey: [IUseMutationUpdateCompany],
    mutationFn: ({id, name, vendorId, overhead, related}: {id: number, name: string, vendorId: string, overhead: boolean, related: boolean}) => updateCompanyService(id, name, vendorId, overhead, related),
    onMutate: async (item) => {
      await Promise.all([
        queryClient.cancelQueries({ queryKey: [IUseCompanyQuery, IUseMutationUpdateCompany, IUseCompanyAccess] }),
        queryClient.invalidateQueries({
          queryKey: [IUseCompanyQuery]
        }),
        queryClient.invalidateQueries({
          queryKey: [IUseCompanyAccess, {
            companyId: item.id.toString()
          }]
        })
      ]);

      const previousCompanies = queryClient.getQueryData<AxiosResponse<IGenericAuthResponse<ICompany[]>>>([IUseCompanyQuery]);

      queryClient.setQueryData<AxiosResponse<IGenericAuthResponse<ICompany[]>>>([IUseCompanyQuery], (prevData) => {
        if (!prevData) {
          return
        }

        const items = prevData?.data.data.map(dataItem => {
          if (item.id === dataItem.id) {
            return {
              ...dataItem,
              ...item
            }
          }

          return {
            ...dataItem
          }
        });

        return {
          ...prevData,
          data: {
            ...prevData.data,
            data: items
          }
        };
      })

      dispatchStore(startAddNotification({
        title: "Success",
        description: `Company successfully updated`,
        type: INotificationType.SUCCESS
      }));

      return { previousCompanies, companyId: item.id };
    },
    onError: (err, newItem, context) => {
      if (!context) {
        return;
      }

      queryClient.setQueryData([IUseCompanyQuery], context.previousCompanies)

      dispatchStore(startAddNotification({
        title: "Error",
        description: `Company updates rolled back`,
        type: INotificationType.ERROR
      }));
    },
    onSettled: (data, error, variables, context) => {
      return Promise.all([
        queryClient.invalidateQueries({
          queryKey: [IUseCompanyQuery]
        }),
        queryClient.invalidateQueries({
          queryKey: [IUseCompanyAccess, {
            companyId: context ? context.companyId.toString() : ""
          }]
        })
      ]);
    },
  })
}
