import {useQuery} from "@tanstack/react-query";
import { getUsersCompanyService } from "redux/user/saga/auth/get-users-company";

export const IUseQueryUsersByPayeeId = 'IUseQueryUsersByPayeeId';

export function useQueryUsersByPayeeID (condition: boolean, filters: string) {
  return useQuery([IUseQueryUsersByPayeeId, filters], () => getUsersCompanyService(filters), {
    enabled: condition
  });
}
