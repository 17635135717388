import { IGenericAuthResponse } from "../../../user";
import axios, { AxiosResponse } from "axios";
import { APIS } from "../../../../static/apis";

export enum ISendBankDetailType {
  UK = "uk",
  INT = "int",
}

export interface ISendBankDetails {
  id: string;
  accountName: string;
  toDate: string;
  payee: string;
  accountNumber?: string;
  sortCode?: string;
  type: ISendBankDetailType;
  iban?: string;
  bic?: string;
  detailsOfCharges?: string;
  intBic?: string;
  endDate?: string;
}

export async function sendBankDetails(
  payload: ISendBankDetails
): Promise<AxiosResponse<IGenericAuthResponse<boolean>>> {
  return axios.post(
    APIS.BASE_AUTH_MS_URL + APIS.SEND_BANK_DETAILS,
    {
      ...payload,
    },
    {
      withCredentials: true,
    }
  );
}
