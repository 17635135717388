import axios, {AxiosResponse} from "axios";
import {APIS} from "../../../../static/apis";

export function updateCompanyService (id: number, name: string, vendorId: string, overhead: boolean, related: boolean): Promise<AxiosResponse<{data : boolean}>> {
  return axios.put(
    APIS.BASE_AUTH_MS_URL + APIS.UPDATE_COMPANY, {
      id,
      name,
      vendorId,
      overhead,
      related
    }
  );
}
