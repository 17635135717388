import { ReactComponent as ChevronDown } from "../../../assets/icons/chevron-down.svg";
import "./multiselect.scss";
import { useCallback, useEffect, useRef, useState } from "react";
import useClickOutside from "../../../tools/hooks/useClickOutside";
import { ReactComponent as CloseIcon } from "../../../assets/icons/close-alt.svg";

export interface IMultiselect {
  customClass?: string;
  options: IMultiSelectOption[];
  isDisabled?: boolean;
  placeholder?: string;
  requestClearAll?: () => void;
  requestChange?: (item: IMultiSelectOption) => void;
}

export interface IMultiSelectOption {
  name: string;
  isSelected?: boolean;
}

export default function MultiSelect({
  options,
  isDisabled,
  placeholder,
  requestChange,
  customClass,
  requestClearAll,
}: IMultiselect) {
  const [isCollapsed, setIsCollapsed] = useState(false);

  const [tooltipContent, setTooltipContent] = useState("");

  const ref = useRef(null);

  const getSelections = useCallback(() => {
    return options.map((item) => item).filter((item) => item.isSelected);
  }, [options]);

  useClickOutside(ref, () => {
    if (isCollapsed) {
      setIsCollapsed(false);
    }
  });

  useEffect(() => {
    setTooltipContent(
      getSelections()
        .map((item) => item.name)
        .join(", ")
    );
  }, [getSelections, options]);

  return (
    <div
      className={`multi-select ${
        customClass && customClass.length ? customClass : ""
      } ${isDisabled ? "multi-select--disabled" : ""}`}
      ref={ref}
    >
      <div
        className={`${tooltipContent !== "" ? "tooltip-trigger" : ""}`}
        data-tooltip={tooltipContent}
      >
        <button
          type="button"
          disabled={isDisabled}
          className={`multi-select__trigger ${
            isCollapsed ? "multi-select__trigger--collapsed" : ""
          }`}
          onClick={() => setIsCollapsed(!isCollapsed)}
          onMouseEnter={() => {
            setTooltipContent(
              getSelections()
                .map((item) => item.name)
                .join(", ")
            );
          }}
        >
          {!getSelections().length
            ? placeholder
            : getSelections().map((item, index) => (
                <strong
                  key={item.name}
                  className="multi-select__trigger--selected-option"
                >
                  {item.name}
                </strong>
              ))}

          <span className="multi-select__trigger-icon">
            <ChevronDown />
          </span>
        </button>
      </div>
      {!isDisabled &&
        getSelections().length > 0 &&
        requestClearAll !== undefined && (
          <button
            type="button"
            disabled={isDisabled}
            className="multi-select__trigger-clear-all btn--reset"
            onClick={() => {
              setIsCollapsed(false);
              return requestClearAll();
            }}
          >
            <CloseIcon />
          </button>
        )}

      <div
        className={`multi-select__options ${
          isCollapsed ? "multi-select__options--collapsed" : ""
        }`}
      >
        {options.map((item) => (
          <button
            type="button"
            key={item.name}
            disabled={isDisabled}
            onClick={() => {
              if (!requestChange) {
                return;
              }

              return requestChange({
                ...item,
                isSelected: !item.isSelected,
              });
            }}
            className={`multi-select__option ${
              item.isSelected ? "multi-select__option--selected" : ""
            }`}
          >
            {item.name}
          </button>
        ))}
      </div>
    </div>
  );
}
