import { ReactComponent as EditIcon } from "../../../assets/icons/edit.svg";
import { ReactComponent as DeleteIcon } from "../../../assets/icons/trash.svg";
import { IPayeeAccounts } from "../../../redux/companies/companies";
import InlineLoader from "../../../components/ui/inline-loader/InlineLoader";
import { useState } from "react";
import { ReactComponent as PayeeIcon } from "../../../assets/icons/exchange.svg";

export enum IPayeeTableAction {
  DELETE = "delete",
  EDIT = "edit",
  SHOW_USERS = "show_users",
}

export interface IPayeeTable {
  data: IPayeeAccounts[];
  isLoading: boolean;
  requestAction: (actionType: IPayeeTableAction, payee: IPayeeAccounts) => void;
}

export default function PayeeTable({
  data,
  requestAction,
  isLoading,
}: IPayeeTable) {
  const [mobileIdCollapsed, setMobileIdCollapsed] = useState("");

  return (
    <table
      className="users__table payees__table"
      cellPadding={0}
      cellSpacing={0}
    >
      <thead className="users__thead">
        <tr className="users__tr">
          <th className="users__cell users__cell--head users__cell--username payees__table-vendor-cell">
            <div className="users__entry">Name</div>
          </th>

          <th className="users__cell users__cell--head users__cell--username">
            <div className="users__entry">Payee Number</div>
          </th>

          <th className="users__cell users__cell--head users__cell--username">
            <div className="users__entry">Payee Currency</div>
          </th>

          <th className="users__cell users__cell--head users__cell--action">
            <div className="users__entry">Actions</div>
          </th>
        </tr>
      </thead>

      <tbody className="users__tbody">
        {data && data.length > 0 ? (
          data.map((item) => (
            <tr
              className={`users__tr ${
                mobileIdCollapsed === item.payeeNumber
                  ? "users__tr--collapsed"
                  : ""
              }`}
              key={item.payeeNumber}
            >
              <td
                className="users__cell"
                onClick={() => {
                  return setMobileIdCollapsed(
                    item.payeeNumber === mobileIdCollapsed
                      ? ""
                      : item.payeeNumber || ""
                  );
                }}
              >
                <div className="users__entry">{item.name}</div>
              </td>

              <td className="users__cell" data-cell-name="Payee Number">
                <div className="users__entry">{item.payeeNumber}</div>
              </td>

              <td className="users__cell" data-cell-name="Payee Currency">
                <div className="users__entry">{item.payeeCurrency}</div>
              </td>

              <td className="users__cell">
                <div className="users__entry">
                  <div className="users__actions payees__table-edit">
                    <button
                      type="button"
                      className="btn btn--reset users__action users__action--payees"
                      data-title={"Payee Accounts"}
                      onClick={() =>
                        requestAction(IPayeeTableAction.SHOW_USERS, item)
                      }
                    >
                      <PayeeIcon height="14" width="14" />
                    </button>
                    <button
                      type="button"
                      className="btn btn--reset users__action users__action--role"
                      disabled={isLoading}
                      title="Edit payee account"
                      onClick={() =>
                        requestAction(IPayeeTableAction.EDIT, item)
                      }
                    >
                      <EditIcon height="16" width="16" />
                    </button>

                    <button
                      type="button"
                      className="btn btn--reset users__action users__action--delete"
                      disabled={isLoading}
                      title="Remove payee account"
                      onClick={() =>
                        requestAction(IPayeeTableAction.DELETE, item)
                      }
                    >
                      <DeleteIcon height="20" width="20" />
                    </button>
                  </div>
                </div>
              </td>
            </tr>
          ))
        ) : isLoading ? (
          <tr className="users__tr">
            <td className="users__cell" colSpan={4}>
              <div className="users__entry">
                <InlineLoader />
              </div>
            </td>
          </tr>
        ) : (
          <tr className="users__tr">
            <td className="users__cell" colSpan={4}>
              <div className="users__entry">
                No payee accounts available for the selected company
              </div>
            </td>
          </tr>
        )}
      </tbody>
    </table>
  );
}
