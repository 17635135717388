import createSagaMiddleware from 'redux-saga';
import {createBrowserHistory, History} from 'history';
import { routerMiddleware } from 'connected-react-router'
import {combineReducers, configureStore, Middleware} from '@reduxjs/toolkit'
import logger from 'redux-logger';
import rootSaga from "./saga";
import coreReducer from "./core/core";
import userReducer from "./user/user";
import companiesReducer from "./companies/companies";
import { connectRouter } from 'connected-react-router';
import { persistStore, persistReducer } from 'redux-persist';
import storage from "redux-persist/lib/storage";
import {FLUSH, PAUSE, PERSIST, PURGE, REGISTER, REHYDRATE} from "redux-persist/es/constants";

const sagaMiddleware = createSagaMiddleware();

export const history = createBrowserHistory();
const connectedRouterMiddleware = routerMiddleware(history);

const middleWare: Middleware[] = [sagaMiddleware, connectedRouterMiddleware];

if (process.env.NODE_ENV !== 'production') {
  middleWare.push(logger);
}

const persistConfig = {
  key: 'user',
  storage: storage,
};

const createRootReducer = (history: History) => combineReducers({
  router: connectRouter(history),
  core: coreReducer,
  companies: companiesReducer,
  user: persistReducer(persistConfig, userReducer)
})

const store = configureStore({
  reducer: createRootReducer(history),
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({
    thunk: false,
    serializableCheck: {
      ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
    },
  }).concat(...middleWare),
  devTools: process.env.NODE_ENV !== 'production',
});

sagaMiddleware.run(rootSaga);

let persistor = persistStore(store);

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;

// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;

export default store;

export {persistor};
