import { ReactComponent as DeleteIcon } from "../../assets/icons/trash.svg";
import { ReactComponent as RoleIcon } from "../../assets/icons/role.svg";
import { ReactComponent as UserDisableIcon } from "../../assets/icons/user_disable.svg";
import { ReactComponent as InviteIcon } from "../../assets/icons/invite.svg";
import { ReactComponent as PayeeIcon } from "../../assets/icons/exchange.svg";
import { ReactComponent as UpdateIcon } from "../../assets/icons/edit.svg";

export enum IUserTableActions {
  DELETE = "delete",
  ROLE = "role",
  DEACTIVATE = "deactivate",
  INVITE = "invite",
  PAYEE = "payee",
  UPDATE = "update",
}

export interface IUsersTableButtons {
  requestClick: (event: IUserTableActions) => void;
  isDeleteDisabled?: boolean;
  isInviteDisabled?: boolean;
  isDeactivateDisabled?: boolean;
  isRoleDisabled?: boolean;
  isPayeeDisabled?: boolean;
  isUpdateDisabled?: boolean;
}

export default function UsersTableButtons({
  requestClick,
  isRoleDisabled,
  isPayeeDisabled,
  isDeleteDisabled,
  isInviteDisabled,
  isDeactivateDisabled,
  isUpdateDisabled,
}: IUsersTableButtons) {
  return (
    <div className="users__actions">
      <button
        type="button"
        className="inline-tooltip btn btn--reset users__action users__action--payees"
        data-title={"Payee Accounts"}
        disabled={isPayeeDisabled}
        onClick={() => {
          if (isPayeeDisabled) {
            return;
          }

          return requestClick(IUserTableActions.PAYEE);
        }}
      >
        <PayeeIcon height="14" width="14" />
      </button>

      <button
        type="button"
        className="inline-tooltip btn btn--reset users__action users__action--invite"
        data-title={"Send Invitation"}
        disabled={isInviteDisabled}
        onClick={() => {
          if (isInviteDisabled) {
            return;
          }

          return requestClick(IUserTableActions.INVITE);
        }}
      >
        <InviteIcon height="14" width="14" />
      </button>

      <button
        type="button"
        className="inline-tooltip btn btn--reset users__action users__action--role"
        data-title={"User Role"}
        disabled={isRoleDisabled}
        onClick={() => {
          if (isRoleDisabled) {
            return;
          }

          return requestClick(IUserTableActions.ROLE);
        }}
      >
        <RoleIcon height="20" width="20" />
      </button>

      <button
        type="button"
        className="inline-tooltip btn btn--reset users__action users__action--disable"
        data-title={"Change Account Status"}
        disabled={isDeactivateDisabled}
        onClick={() => {
          if (isDeactivateDisabled) {
            return;
          }

          return requestClick(IUserTableActions.DEACTIVATE);
        }}
      >
        <UserDisableIcon height="20" width="20" />
      </button>

      <button
        type="button"
        className="inline-tooltip btn btn--reset users__action users__action--delete"
        data-title={"Delete Account"}
        disabled={isDeleteDisabled}
        title="Delete user"
        onClick={() => {
          if (isDeleteDisabled) {
            return;
          }

          return requestClick(IUserTableActions.DELETE);
        }}
      >
        <DeleteIcon height="20" width="20" />
      </button>

      <button
        type="button"
        className="inline-tooltip btn btn--reset users__action users__action--update"
        data-title={"Update Account"}
        disabled={isUpdateDisabled}
        title="Update user"
        onClick={() => {
          if (isUpdateDisabled) {
            return;
          }

          return requestClick(IUserTableActions.UPDATE);
        }}
      >
        <UpdateIcon height="20" width="20" color="white" />
      </button>
    </div>
  );
}
