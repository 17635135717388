import Modal, { IModalType } from "../../../components/ui/modal/Modal";
import { IProfilePasswordModalAction } from "../types";
import "./addCompanyToUserModal.scss";
import { IUser } from "../../../redux/user/user";
import { ICompany } from "redux/companies/companies";

export interface IAddCompanyToUserModal {
  isVisible: boolean;
  user?: IUser | undefined;
  company?: ICompany | undefined;
  requestClick: (event: IProfilePasswordModalAction, payload?: any) => void;
  requestClickCancel: (
    event: IProfilePasswordModalAction,
    payload?: any
  ) => void;
  sendDataToParent?: any;
}

export default function AddCompanyToUserModal({
  isVisible,
  requestClick,
  user,
  company,
  requestClickCancel,
  sendDataToParent,
}: IAddCompanyToUserModal) {
  requestClick = (action, { sendData }) => {
    sendDataToParent(sendData);
  };

  const handleButtonClick = () => {
    const data = "updateUser";
    requestClick(IProfilePasswordModalAction.SAVE, { sendData: data });
  };

  return (
    <Modal
      title={"Adding Company To User"}
      type={IModalType.DEFAULT}
      customClass={"password-modal deactivate-modal payee-modal"}
      toggleVisibility={isVisible}
      customBtns={
        <div className={`modal__footer d-flex`}>
          <button
            type="button"
            className={`change-pass-modal-footer__action modal__footer__action btn--short btn btn--mute`}
            onClick={() => {
              return requestClickCancel(IProfilePasswordModalAction.CANCEL);
            }}
          >
            Cancel
          </button>

          <button
            type="button"
            className={`change-pass-modal-footer__action modal-buttons--continue btn--short btn btn--default`}
            // onClick={() => {
            //   return requestClick(IProfilePasswordModalAction.SAVE, {
            //   });
            // }}
            onClick={handleButtonClick}
          >
            Update User
          </button>
        </div>
      }
      onToggle={() => {
        requestClickCancel(IProfilePasswordModalAction.CANCEL);
      }}
    >
      <p className="register-form__intro">
        Assigning{" "}
        <strong>
          {user ? user.name : "user"} {" - "} Email:{" "}
          {user ? user.email : "email"}
        </strong>{" "}
        to <strong>Company: {company?.name}.</strong>
      </p>

      <p className="register-form__intro">
        <br />
        Please confirm this action by the action buttons below.
        <br />
      </p>
    </Modal>
  );
}
