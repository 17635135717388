import {useMutation} from "@tanstack/react-query";
import {contactUs} from "../../user/saga/vendor/contact-us";

export const IUseMutationContactUs = 'IUseMutationContactUs';

export function useMutationContactUs() {
  return useMutation({
    mutationKey: [IUseMutationContactUs],
    mutationFn: contactUs,
  })
}
