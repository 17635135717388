export const numberRegex = "^-?[0-9]+(?:\\.[0-9]+)?$";

//export const numberAndStringRegex = "^-?[0-9]+(?:.[0-9]+)?|[a-zA-Z]+$";
export const numberAndStringRegex = "^-?[0-9a-zA-Z]+(?:.[0-9]+)?|[a-zA-Z]+$";

export enum NumberRegExError {
  MISSING = "missing",
  INVALID = "invalid",
  VALID = "valid",
  MAX_LENGTH = "max_length",
  MAX_DECIMALS = "max_decimals",
}

export const MAX_ALLOWED_DOC_SIZE = 25 * 1024 * 1024;

export const ALLOWED_DOC_TYPES = [
  "application/doc",
  "application/ms-doc",
  "application/msword",
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  "application/vnd.ms-excel.sheet.macroenabled.12",
  "application/excel",
  "application/vnd.ms-excel",
  "application/x-excel",
  "application/x-msexcel",
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  "application/pdf",
  "image/png",
  "image/jpeg",
  "image/pjpeg",
  "image/webp",
  "text/csv",
];
