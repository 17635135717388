import {QueryClient, QueryClientProvider } from "@tanstack/react-query";
import Base from "./components/specific/base/Base";
import {AdapterMoment} from "@mui/x-date-pickers/AdapterMoment";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 15000
    },
  },
});

// Base Component renders the whole app
function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <Base />
      </LocalizationProvider>
    </QueryClientProvider>
  );
}

export default App;
