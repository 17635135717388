import {ReactComponent as SettingsIcon} from "./../../../assets/icons/settings.svg";
import "./advanced-search.scss";

export interface IAdvancedSearch {
  isCollapsed: boolean,
  requestCollapse: (state: boolean) => void
}

export default function AdvancedSearch({requestCollapse, isCollapsed}: IAdvancedSearch) {
  return (
    <button type="button"
            className={`adv-search btn btn--default ${isCollapsed ? 'adv-search--collapsed' : ''}`}
            onClick={() => requestCollapse(!isCollapsed)}>
      <SettingsIcon />

      <span className="adv-search__content">
        Advanced Search
      </span>
    </button>
  )
}
