import axios, { AxiosResponse } from "axios";
import { APIS } from "../../../../static/apis";

export async function exportAllCompanies(): Promise<AxiosResponse<BlobPart>> {
  return axios.post(
    APIS.BASE_AUTH_MS_URL + APIS.EXPORT_ALL_COMPANIES,
    {},
    {
      responseType: "blob",
    }
  );
}
