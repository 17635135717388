import moment from "moment";
import InlineLoader from "../../../components/ui/inline-loader/InlineLoader";
import { IBankDetailType } from "../item/bank-detail-item";

export interface IEditModeEntryAlt {
  iban: string;
  swift: string;
  name: string;
  date: null | moment.Moment;
}

export interface IBankDetailAltSaveItem {
  id: string;
  toDate: string;
  accountName: string;
  iban: string;
  bic: string;
  detailsOfCharges: string;
  intBic: string;
  endDate: string;
}

export interface IBankDetailPayloadAlt {
  id: string;
  isEditMode: boolean;
  isLoading: boolean;
  disabled: boolean;
  requestEditMode: (state: boolean, id: string) => void;
  requestSave: (payload: IBankDetailAltSaveItem) => void;
  swift: string;
  iban: string;
  name: string;
  title: string;
  date: null | moment.Moment;
  altAppearance?: boolean;
}

export interface IBankDetailAltItem {
  iban: string;
  accountName: string;
  startDate: null | moment.Moment | string;
  isLoading: boolean;
  isEditable: boolean;
  requestChange: (type: IBankDetailType) => void;
  bic: string;
  detailsOfCharges: string;
  intBic: string;
  endDate: null | moment.Moment | string;
}

export default function BankDetailItemAlt({
  iban,
  isEditable,
  isLoading,
  startDate,
  accountName,
  requestChange,
  bic,
  detailsOfCharges,
  intBic,
  endDate,
}: IBankDetailAltItem) {
  return (
    <div className="bank-container">
      <dl
        data-title={"INT Bank Details"}
        className={`bank-card bank-card--alt d-flex f-wrap f-column-xs`}
      >
        {isLoading && (
          <div className="bank-container__loader">
            <InlineLoader />
          </div>
        )}

        <dt>Account Name:</dt>
        <dd>
          <span className="bank-card__entry">{accountName}</span>
        </dd>

        <dt>Account Number/IBAN:</dt>
        <dd>
          <span className="bank-card__entry">{iban}</span>
        </dd>

        <dt>BIC:</dt>
        <dd>
          <span className="bank-card__entry">{bic}</span>
        </dd>

        <dt>Details Of Charges:</dt>
        <dd>
          <span className="bank-card__entry">{detailsOfCharges}</span>
        </dd>
        <dt>Intermediary BIC:</dt>
        <dd>
          <span className="bank-card__entry">{intBic}</span>
        </dd>
        <dt>Start Date:</dt>
        <dd>
          <span className="bank-card__entry">
            {startDate === "Invalid DateTime" ? "N/A" : startDate}
          </span>
        </dd>
        <dt>End Date:</dt>
        <dd>
          <span className="bank-card__entry">
            {endDate === "Invalid DateTime" ? "N/A" : endDate}
          </span>
        </dd>
      </dl>

      {isEditable && (
        <div className="bank-card__actions">
          <button
            type="button"
            className="btn btn--default bank-card--action btn--short"
            disabled={isLoading}
            onClick={() => {
              return requestChange(IBankDetailType.INT);
            }}
          >
            Update Entry
          </button>
        </div>
      )}
    </div>
  );
}
