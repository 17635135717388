import { useEffect, useMemo, useReducer } from "react";
import moment from "moment";
import { IBankDetailPreloadedDate, initialState, reducer } from "./state";
import Modal, { IModalType } from "../../../../components/ui/modal/Modal";
import { IProfilePasswordModalAction } from "../../../users/types";
import Input from "../../../../components/ui/inputs/Input";
import InputInlineError from "../../../../components/ui/inputs/inline-error/Input.inline-error";
import { DatePicker } from "@mui/x-date-pickers";
import TextField from "@mui/material/TextField";
import "./bank-detail-item-modal-alt.scss";
import BankDetailItemModalButtonsAlt from "./buttons/bank-detail-item-modal-buttons-alt";
import { NumberRegExError } from "../../../../tools/common/types";
import validateBankingCode from "tools/common/validateBankingCode";
import validateBicCode from "tools/common/validateBicCode";
import validateDetailsOfCharges from "tools/common/validateDetailsOfCharges";

export interface IRelatedInvoiceModal {
  isVisible: boolean;
  onToggle: (state: boolean) => void;
  existingData?: IBankDetailPreloadedDate;
  requestClick: (state: IProfilePasswordModalAction, payload?: any) => void;
}

export default function BankDetailItemModalAlt({
  isVisible,
  onToggle,
  requestClick,
  existingData,
}: IRelatedInvoiceModal) {
  const [state, dispatch] = useReducer(reducer, {
    ...initialState,
  });

  const currentDate = new Date();
  const maxDate = moment(
    new Date(currentDate.setMonth(currentDate.getMonth() - 12))
  );

  const isFormValid = useMemo(() => {
    return Object.values(state).every((item) => item.isValid);
  }, [state]);

  useEffect(() => {
    return () => {
      if (!isVisible) {
        dispatch({
          type: "resetState",
        });
      }
    };
  }, [isVisible]);

  useEffect(() => {
    if (existingData !== undefined && Object.keys(existingData).length > 0) {
      dispatch({
        type: "preloadState",
        payload: {
          ...existingData,
        },
      });
    }
  }, [existingData]);
  return (
    <Modal
      title="INT Bank Details"
      toggleVisibility={isVisible}
      onToggle={onToggle}
      type={IModalType.NOTIFY}
      customClass={"bank-detail-modal"}
      customBtns={
        <BankDetailItemModalButtonsAlt
          isLoading={false}
          isDisabled={!isFormValid}
          isUpdate={existingData && "id" in existingData}
          requestClick={(event) => {
            switch (event) {
              case IProfilePasswordModalAction.SAVE:
                return requestClick(IProfilePasswordModalAction.SAVE, {
                  toDate:
                    state.startDate.value instanceof moment
                      ? (state.startDate.value as moment.Moment)
                          .format("DD/MM/YYYY")
                          .toString()
                      : state.startDate.value,
                  accountName: state.accountName.value,
                  iban: state.iban.value,
                  bic: state.bic.value,
                  detailsOfCharges: state.detailsOfCharges.value,
                  intBic: state.intBic.value,
                  endDate:
                    state.endDate.value instanceof moment
                      ? (state.endDate.value as moment.Moment)
                          .format("DD/MM/YYYY")
                          .toString()
                      : "",
                });

              case IProfilePasswordModalAction.CANCEL:
                return requestClick(IProfilePasswordModalAction.CANCEL);

              default:
                return;
            }
          }}
        />
      }
    >
      <p>
        You can request an update on INT bank details which will be subject to
        approval from the CMS Team.
      </p>

      <div className="bank-detail-items">
        <Input
          id={state.accountName.id}
          placeholder={state.accountName.label}
          autocomplete={false}
          requestBlur={(event) => {
            if (state.accountName.dirty) {
              return;
            }

            return dispatch({
              type: "setInputTouched",
              payload: {
                ...state.accountName,
                dirty: true,
                value: event,
                isValid: event.length > 2 && event.length < 51,
                errorMessage:
                  event.length < 3 && event.length > 50
                    ? "Account name should contain at least 3 and max 50 chars"
                    : "",
              },
            });
          }}
          requestInput={(event) => {
            return dispatch({
              type: "setValue",
              payload: {
                ...state.accountName,
                dirty: true,
                value: event,
                isValid: event.length > 2 && event.length < 51,
                errorMessage:
                  event.length < 3 || event.length > 50
                    ? "Account name should contain at least 3 and max 50 chars"
                    : "",
              },
            });
          }}
          value={state.accountName.value as string}
          showLabel={true}
          error={state.accountName.dirty && !state.accountName.isValid}
        >
          <InputInlineError
            isVisible={state.accountName.dirty && !state.accountName.isValid}
            message={state.accountName.errorMessage || ""}
          />
        </Input>
        <Input
          id={state.iban.id}
          placeholder={state.iban.label}
          autocomplete={false}
          requestBlur={(event) => {
            if (state.iban.dirty) {
              return;
            }

            return dispatch({
              type: "setInputTouched",
              payload: {
                ...state.iban,
                dirty: true,
                value: event,
                isValid: event.length > 7 && event.length < 41,
                errorMessage:
                  event.length < 8 || event.length > 40
                    ? "IBAN account should contain at least 8 and max of 40 chars"
                    : "",
              },
            });
          }}
          requestInput={(event) => {
            return dispatch({
              type: "setValue",
              payload: {
                ...state.iban,
                dirty: true,
                value: event,
                isValid: event.length > 7 && event.length < 41,
                errorMessage:
                  event.length < 8 || event.length > 40
                    ? "IBAN account should contain at least 8 and max of 40 chars"
                    : "",
              },
            });
          }}
          value={state.iban.value as string}
          showLabel={true}
          error={state.iban.dirty && !state.iban.isValid}
        >
          <InputInlineError
            isVisible={state.iban.dirty && !state.iban.isValid}
            message={state.iban.errorMessage || ""}
          />
        </Input>

        <Input
          id={state.bic.id}
          placeholder={state.bic.label}
          autocomplete={false}
          requestBlur={(event) => {
            if (state.bic.dirty) {
              return;
            }

            const validation = validateBicCode(event);

            return dispatch({
              type: "setInputTouched",
              payload: {
                ...state.bic,
                dirty: true,
                value: event,
                isValid: true,
                // (event.length === 8 || event.length === 11) &&
                // validation === NumberRegExError.VALID,
                errorMessage: "",
                //  event.length !== 8 && event.length !== 11
                //</div>  ? "BIC should contain 8 or 11 chars"
                // : validation !== NumberRegExError.VALID
                //</Modal> ? "BIC should be a number"
                // : "",
              },
            });
          }}
          requestInput={(event) => {
            const validation = validateBicCode(event);

            return dispatch({
              type: "setInputTouched",
              payload: {
                ...state.bic,
                dirty: true,
                value: event,
                isValid: true,
                //  (event.length === 8 || event.length === 11) &&
                // validation === NumberRegExError.VALID,
                errorMessage: "",
                // event.length !== 8 && event.length !== 11
                //   ? "BIC should contain 8 or 11 chars"
                //   : validation !== NumberRegExError.VALID
                //   ? "BIC should be a number"
                //   : "",
              },
            });
          }}
          value={state.bic.value as string}
          showLabel={true}
          error={state.bic.dirty && !state.bic.isValid}
        >
          <InputInlineError
            isVisible={state.bic.dirty && !state.bic.isValid}
            message={state.bic.errorMessage || ""}
          />
        </Input>

        <Input
          id={state.detailsOfCharges.id}
          placeholder={state.detailsOfCharges.label}
          autocomplete={false}
          requestBlur={(event) => {
            if (state.detailsOfCharges.dirty) {
              return;
            }

            const validation = validateDetailsOfCharges(event);

            return dispatch({
              type: "setInputTouched",
              payload: {
                ...state.detailsOfCharges,
                dirty: true,
                value: event,
                isValid: true,
                //  (event.length >= 10 || event.length <= 18) &&
                // validation === NumberRegExError.VALID,
                errorMessage: "",
                // event.length <= 9 || event.length >= 19
                //   ? "Details Of Charges should be between 10 to 18 chars"
                //   : validation !== NumberRegExError.VALID
                //   ? "Details Of Charges should be a number"
                //   : "",
              },
            });
          }}
          requestInput={(event) => {
            const validation = validateDetailsOfCharges(event);

            return dispatch({
              type: "setInputTouched",
              payload: {
                ...state.detailsOfCharges,
                dirty: true,
                value: event,
                isValid: true,
                //  event.length >= 10 &&
                // event.length <= 18 &&
                // validation === NumberRegExError.VALID,
                errorMessage: "",
                // event.length <= 9 || event.length >= 19
                //   ? "Details Of Charges should be between 10 to 18 chars"
                //   : validation !== NumberRegExError.VALID
                //   ? "Details Of Charges should be a number"
                //   : "",
              },
            });
          }}
          value={state.detailsOfCharges.value as string}
          showLabel={true}
          error={
            state.detailsOfCharges.dirty && !state.detailsOfCharges.isValid
          }
        >
          <InputInlineError
            isVisible={
              state.detailsOfCharges.dirty && !state.detailsOfCharges.isValid
            }
            message={state.detailsOfCharges.errorMessage || ""}
          />
        </Input>

        <Input
          id={state.intBic.id}
          placeholder={state.intBic.label}
          autocomplete={false}
          requestBlur={(event) => {
            if (state.intBic.dirty) {
              return;
            }

            const validation = validateBicCode(event);

            return dispatch({
              type: "setInputTouched",
              payload: {
                ...state.intBic,
                dirty: true,
                value: event,
                isValid: true,
                //  (event.length === 8 || event.length === 11) &&
                //  validation === NumberRegExError.VALID,
                errorMessage: "",
                // event.length !== 8 && event.length !== 11
                //   ? "Intermediary BIC should contain 8 or 11 chars"
                //   : validation !== NumberRegExError.VALID
                //   ? "Intermediary BIC should be a number"
                //   : "",
              },
            });
          }}
          requestInput={(event) => {
            const validation = validateBicCode(event);

            return dispatch({
              type: "setInputTouched",
              payload: {
                ...state.intBic,
                dirty: true,
                value: event,
                isValid: true,
                //  (event.length === 8 || event.length === 11) &&
                //  validation === NumberRegExError.VALID,
                errorMessage: "",
                // event.length !== 8 && event.length !== 11
                //   ? "Intermediary BIC should contain 8 or 11 chars"
                //   : validation !== NumberRegExError.VALID
                //   ? "Intermediary BIC should be a number"
                //   : "",
              },
            });
          }}
          value={state.intBic.value as string}
          showLabel={true}
          error={state.intBic.dirty && !state.intBic.isValid}
        >
          <InputInlineError
            isVisible={state.intBic.dirty && !state.intBic.isValid}
            message={state.intBic.errorMessage || ""}
          />
        </Input>

        <div className="input input__label input__label--with-title input__label--datepicker">
          <span className="input__title">
            Effective Start Date for Bank Details Change
          </span>

          <DatePicker
            className="invoice-datepicker"
            desktopModeMediaQuery={"@media (min-width: 1px)"}
            inputFormat="DD/MM/YYYY"
            value={state.startDate.value || null}
            onOpen={async () => {
              if (state.startDate.dirty) {
                return;
              }

              dispatch({
                type: "setInputTouched",
                payload: {
                  ...state.startDate,
                  dirty: true,
                },
              });
            }}
            onChange={(event) => {
              dispatch({
                type: "setValue",
                payload: {
                  ...state.startDate,
                  value: event,
                  isValid: event !== null,
                  isOld: (event as moment.Moment).isBefore(maxDate),
                },
              });
            }}
            renderInput={(params: any) => <TextField {...params} />}
          />

          <InputInlineError
            customClass={
              state.startDate.isOld ? "input__inline-error--warning" : ""
            }
            isVisible={!state.startDate.isValid}
            message={
              !state.startDate.isValid && state.startDate.dirty
                ? "Effective Date for Bank Details Change not selected"
                : ""
            }
          />
        </div>

        <div className="input input__label input__label--with-title input__label--datepicker">
          <span className="input__title">
            Effective End Date for Bank Details Change
          </span>

          <DatePicker
            className="invoice-datepicker"
            desktopModeMediaQuery={"@media (min-width: 1px)"}
            inputFormat="DD/MM/YYYY"
            minDate={state.startDate.value}
            value={state.endDate.value || null}
            onOpen={async () => {
              if (state.endDate.dirty) {
                return;
              }

              dispatch({
                type: "setInputTouched",
                payload: {
                  ...state.endDate,
                  dirty: true,
                },
              });
            }}
            onChange={(event) => {
              dispatch({
                type: "setValue",
                payload: {
                  ...state.endDate,
                  value: event,
                  isValid: event !== null,
                  isOld: (event as moment.Moment).isBefore(maxDate),
                },
              });
            }}
            renderInput={(params: any) => <TextField {...params} />}
          />

          <InputInlineError
            customClass={
              state.endDate.isOld ? "input__inline-error--warning" : ""
            }
            isVisible={!state.endDate.isValid}
            message={
              !state.endDate.isValid && state.endDate.dirty
                ? "Effective Date for Bank Details Change not selected"
                : ""
            }
          />
        </div>
      </div>

      <strong>
        <small>
          *You will be notified via the email when this is completed.
        </small>
      </strong>
    </Modal>
  );
}
