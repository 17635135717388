export enum IProfilePasswordModalAction {
  CANCEL = 'cancel',
  SAVE = 'save',
  VALIDATE = 'validate',
  UPDATE = 'update',
  OPEN = 'open',
  DELETE = 'delete',
  NEXT = 'next'
}

export interface IProfilePasswordModalButtons {
  isDisabled: boolean,
  isLoading: boolean,
  requestClick: (action: IProfilePasswordModalAction) => void
}
