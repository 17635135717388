import {IProfilePasswordModalAction} from "../../users/types";
import Modal from "../../../components/ui/modal/Modal";

export interface IProfileDeleteDeviceModal {
  isVisible: boolean,
  onToggle: (state: boolean) => void,
  requestClick: (event: IProfilePasswordModalAction) => void
}

export default function ProfileDeleteDeviceModal ({isVisible, onToggle, requestClick}: IProfileDeleteDeviceModal) {
  return (
    <Modal title="Delete Device"
           toggleVisibility={isVisible}
           onToggle={onToggle}
           customClass={"add-user-modal profile-delete-modal"}
           customBtns={
            <div className="modal__footer d-flex">
              <button type="button"
                      className={`change-pass-modal-footer__action modal__footer__action btn btn--mute`}
                      onClick={() => {
                        return requestClick(IProfilePasswordModalAction.CANCEL);
                      }}>
                Cancel
              </button>

              <button type="button"
                      className={`change-pass-modal-footer__action modal__footer__action btn btn--default`}
                      onClick={() => {
                        return requestClick(IProfilePasswordModalAction.SAVE);
                      }}>
                Continue
              </button>
            </div>


           }>
      <p className="register-form__intro">
        This device was used to sign-in into this account. Removing it, would require to re-sign-in on the removed device. <br /><br />
        Are you sure you want to continue?
      </p>
    </Modal>
  );
}

