import {useMutation} from "@tanstack/react-query";
import { validatePayeeVendorId } from "../../user/saga/vendor/validate-payee-vendor-id";

interface IMutationValidatePayeeVendor {
  item: string; 
  vendorId: string;
}

export const IUseMutationValidatePayeeVendorId = 'IUseMutationValidatePayeeVendorId';

export function useValidatePayeeVendorId() {
  return useMutation({
    mutationKey: [IUseMutationValidatePayeeVendorId],
    mutationFn: ({ item, vendorId }: IMutationValidatePayeeVendor) => validatePayeeVendorId(item, vendorId),
  })
}
