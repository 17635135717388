import axios, {AxiosResponse} from "axios";
import {APIS} from "../../../../static/apis";
import {IUseMutationExportVoucherHistoryPayload} from "../../../../hooks/useMutationExportVoucherHistory";

export async function exportVoucherHistory (params: IUseMutationExportVoucherHistoryPayload): Promise<AxiosResponse<BlobPart>> {
  return axios.post(
    APIS.BASE_AUTH_MS_URL + APIS.EXPORT_VOUCHER_HISTORY, {
      ...params,
    },
    {
      responseType: "blob"
    }
  );
}
