import {addEntitiesToUser} from "../../user/saga/vendor/add-entities-to-user";
import {useMutation} from "@tanstack/react-query";
import {queryClient} from "../../../App";
import {AxiosResponse} from "axios";
import {IGenericAuthResponse, IUser} from "../../user/user";
import {startAddNotification} from "../../core/core";
import {INotificationType} from "../../../components/ui/notifications/item/NotificationItem";
import {useAppDispatch} from "../../hooks";
import {IUseQueryUsers} from "../useQueryUsers";
import {ICompany, IPayeeAccounts} from "../../companies/companies";

export const IUseMutationAddEntitiesToUser = 'IUseMutationAddEntitiesToUser';

export interface IUseMutationAddEntitiesToUserPayload {
  company: ICompany,
  payees: IPayeeAccounts[],
  email: string
}

export function useMutationAddEntitiesToUser() {
  const dispatchStore = useAppDispatch();

  return useMutation({
    mutationKey: [IUseMutationAddEntitiesToUser],
    mutationFn: ({company, email, payees}: IUseMutationAddEntitiesToUserPayload) => addEntitiesToUser(company.id.toString(), payees.map(item => item.id.toString()), email),
    onMutate: async ({company, payees, email}) => {
      await queryClient.cancelQueries({ queryKey: [IUseQueryUsers] });

      const previousUsers = queryClient.getQueryData<AxiosResponse<IGenericAuthResponse<IUser[]>>>([IUseQueryUsers]);

      queryClient.setQueryData<AxiosResponse<IGenericAuthResponse<IUser[]>>>([IUseQueryUsers], (prevData) => {
        if (!prevData) {
          return
        }

        const items = prevData.data.data.map(dataItem => {
          if (dataItem.email !== email) {
            return {
              ...dataItem
            }
          }

          return {
            ...dataItem,
            payees,
            company
          }
        });

        return {
          ...prevData,
          data: {
            ...prevData.data,
            data: items
          }
        };
      });

      dispatchStore(startAddNotification({
        title: "Success",
        description: `Users successfully updated`,
        type: INotificationType.SUCCESS
      }));

      return { previousUsers };
    },
    onError: (err, newItem, context) => {
      if (!context) {
        return;
      }

      queryClient.setQueryData([IUseQueryUsers], context.previousUsers)

      dispatchStore(startAddNotification({
        title: "Error",
        description: `User updates rolled back`,
        type: INotificationType.ERROR
      }));
    },
    onSettled: () => {
      return queryClient.invalidateQueries({ queryKey: [IUseQueryUsers] })
    },
  })
}
