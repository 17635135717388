import {ComponentType} from "react";

export default function retry(fn: Function, retriesLeft = 5, interval = 350): Promise<{default: ComponentType<any>}> {
  return new Promise((resolve, reject) => {
    fn()
      .then(resolve)
      .catch((error: Error) => {
        setTimeout(() => {
          if (retriesLeft === 1) {
            reject(error);
            return;
          }

          retry(fn, retriesLeft - 1, interval).then(resolve, reject);
        }, interval);
      });
  });
}
