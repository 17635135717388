import {ReactComponent as DesktopIcon} from "../../assets/icons/desktop.svg";
import {ReactComponent as TabletIcon} from "../../assets/icons/tablet.svg";
import {ReactComponent as MobileIcon} from "../../assets/icons/mobile.svg";
import {IGetDeviceType} from "../../redux/types";

export interface IDetermineDeviceIcon {
  deviceType: IGetDeviceType | null
}

export default function DetermineDeviceIcon({deviceType}: IDetermineDeviceIcon) {
  switch (deviceType) {
    case IGetDeviceType.DESKTOP:
      return (
        <div className="device-info">
          <DesktopIcon /> Desktop
        </div>
      )

    case IGetDeviceType.TABLET:
      return (
        <div className="device-info">
          <TabletIcon /> Tablet
        </div>
      )

    default: return (
      <div className="device-info">
        <MobileIcon /> Mobile
      </div>
    );
  }
}
