import {ICompany} from "../../../../redux/companies/companies";
import "./ListItems.scss"

export interface IListItemProps {
  item: ICompany,
  selected: boolean,
  requestSelect: (value: any) => void
}

export function RadioListItem({item, selected, requestSelect}: IListItemProps) {
  return (
    <div className={"list-item"}>
      <label className={`${selected ? 'list-item__selected' : ''}`}>
        <input
          type="radio"
          value={item.id}
          checked={selected}
          onChange={() => {
            if (!requestSelect) {
              return;
            }
            return requestSelect(item);
          }
          }
        />
        {item.name}
      </label>

    </div>
  )

}