import {ReactComponent as EnvelopeIcon} from "../../../assets/icons/envelope.svg";
import {startAddNotification} from "../../../redux/core/core";
import {INotificationType} from "../../../components/ui/notifications/item/NotificationItem";
import {session_request_name} from "../Home.page";
import {useState} from "react";
import {useAppDispatch, useAppSelector} from "../../../redux/hooks";
import {selectUserName, selectUuid} from "../../../redux/user/selectors";
import {sendPayeesRequest} from "../../../redux/user/saga/vendor/send-payees-request";

export default function HomePageNoPayees() {
  const dispatchStore = useAppDispatch();

  const [isLoading, setIsLoading] = useState(false);
  const [isRequestSent, setIsRequestSent] = useState(!!localStorage.getItem(session_request_name));
  const name = useAppSelector(selectUserName);
  const uuid = useAppSelector(selectUuid);

  return (
    <div className="content">
      <div className="content__wrapper content__wrapper--homepage">
        <h1 className="content__title--homepage content__title content__entry-title">
          Welcome back, <strong>{name}</strong>
        </h1>

        <div className="no-payees-available">
          <p>
            At this point in time, there are <strong>no payees accounts</strong> assigned to your user account. <br />
          </p>

          <p>
            Send system notification to admin accounts to assign payee accounts to current  <br/>  user account.
          </p>

          <button type="button"
                  disabled={isRequestSent || isLoading}
                  onClick={sendPayeeAccountRequest}
                  className={`no-payees-available--action ${isRequestSent || isLoading ? 'no-payees-available--action--disabled': ''} btn btn--default btn--short no-payees-available__action`}>
            Send Request

            <EnvelopeIcon />
          </button>

          {
            isRequestSent &&
            <small>
              *A request has been created, an admin is currently <br /> assigning payee accounts to you account.
            </small>
          }
        </div>
      </div>
    </div>
  )

  async function sendPayeeAccountRequest() {
    if (!uuid) {
      return;
    }

    setIsLoading(true);

    try {
      window.localStorage.setItem(session_request_name, "1")

      setIsRequestSent(true);

      dispatchStore(startAddNotification({
        title: "Success",
        description: "Request has been successfully created",
        type: INotificationType.SUCCESS
      }));

      return await sendPayeesRequest(uuid);
    } catch (error) {
      console.log(error);

      window.localStorage.removeItem(session_request_name)

      setIsRequestSent(false);

      dispatchStore(startAddNotification({
        title: "Error",
        description: "Unable to send request, please try again",
        type: INotificationType.ERROR
      }));
    }

    setIsLoading(false);
  }
}
