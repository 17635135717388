import axios, {AxiosResponse} from "axios";
import {APIS} from "../../../../static/apis";
import {IPayeeAccounts} from "../../../../companies/companies";
import {IGenericAuthResponse} from "../../../user";

export function getPayees (item: number): Promise<AxiosResponse<IGenericAuthResponse<IPayeeAccounts[]>>> {
  return axios.get(
    APIS.BASE_AUTH_MS_URL + APIS.GET_PAYEES +"/" + item
  );
}
