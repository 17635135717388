import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {Provider} from "react-redux";
import store, {persistor} from "./redux/store";
import {HelmetProvider} from "react-helmet-async";
import {BrowserRouter} from "react-router-dom";
import smoothscroll from 'smoothscroll-polyfill';
import {ErrorBoundary} from "react-error-boundary";
import Boundary from "./components/specific/boundary/Boundary";
import {PublicClientApplication} from "@azure/msal-browser";
import {msalConfig} from "./redux/user/microsoft";
import {MsalProvider} from "@azure/msal-react";
import {setupInterceptors} from "./tools/interceptors";
import axios from "axios";
import {PersistGate} from "redux-persist/integration/react";
import OverlayLoader from "./components/ui/overlay-loader/OverlayLoader";

const msalInstance = new PublicClientApplication(msalConfig);

ReactDOM.render(
  <React.StrictMode>
    <ErrorBoundary FallbackComponent={Boundary}>
      <Provider store={store}>
        <PersistGate persistor={persistor} loading={<OverlayLoader />}>
          <BrowserRouter>
            <HelmetProvider>
              <MsalProvider instance={msalInstance}>
                <App />
              </MsalProvider>
            </HelmetProvider>
          </BrowserRouter>
        </PersistGate>
      </Provider>
    </ErrorBoundary>
  </React.StrictMode>,
  document.getElementById('root')
);

// polyfill smooth scroll behavior for browsers not supporting this
smoothscroll.polyfill();

reportWebVitals();

setupInterceptors(axios);

console.log(`


 _    __               __           ____             __        __
| |  / /__  ____  ____/ /___  _____/ __ \\____  _____/ /_____ _/ /
| | / / _ \\/ __ \\/ __  / __ \\/ ___/ /_/ / __ \\/ ___/ __/ __ \`/ / 
| |/ /  __/ / / / /_/ / /_/ / /  / ____/ /_/ / /  / /_/ /_/ / /  
|___/\\___/_/ /_/\\__,_/\\____/_/  /_/    \\____/_/   \\__/\\__,_/_/   
                                                                 

`);