import {ReactComponent as CheckedIcon} from "./../../../assets/icons/checked.svg";
import "./checkbox.scss";

export interface ICheckbox {
  id: string,
  value: string,
  disabled?: boolean,
  customClass?: string,
  checked: boolean,
  requestChecked: (state: boolean) => void
}

export default function CheckBox({id, checked, requestChecked, customClass, value, disabled}: ICheckbox) {
  return (
    <label
      className={`input input--checkbox input__label ${customClass && customClass.length > 0 ? customClass : ''} ${disabled ? 'input--checkbox--disabled' : ''}`} htmlFor={id}>
      <input
        className="input__element--checkbox"
        type="checkbox"
        id={id}
        name={id}
        checked={checked}
        onChange={() => {
          if (disabled) {
            return;
          }

          return requestChecked(!checked)
        }}
      />

      <span className="input__checkbox-value">
        <CheckedIcon />

        {value}
      </span>
    </label>
  )
}
