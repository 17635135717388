import { ReactComponent as SearchIcon } from "../../../../assets/icons/search.svg";
import { ReactComponent as CloseIcon } from "../../../../assets/icons/close-alt.svg";
import Input from "../../../ui/inputs/Input";
import "./CompaniesSearch.scss";

export interface IUsersSearch {
  isDisabled: boolean;
  value: string;
  requestSubmit: (value: string) => void;
  requestChange: (value: string) => void;
  requestClearAll: () => void;
}

export default function CompaniesSearch({
  requestSubmit,
  isDisabled,
  value,
  requestChange,
  requestClearAll,
}: IUsersSearch) {
  return (
    <form
      className="company-search-form"
      onSubmit={(event) => {
        event.preventDefault();

        return requestSubmit(value);
      }}
    >
      <Input
        autocomplete={false}
        customClass={`matter-number company-search company-search--alt`}
        placeholder="Company name"
        disabled={isDisabled}
        id="search-company"
        requestInput={requestChange}
        requestClear={() => {
          requestClearAll();
        }}
        value={value}
      >
        {value && value !== "" && (
          <button
            type="submit"
            className="btn btn--reset company-search__clear-all"
            onClick={() => {
              if (!requestClearAll) {
                return;
              }
              requestClearAll();
            }}
          >
            <span className="visually-hidden">Search company</span>
            <CloseIcon height="20" width="20" />
          </button>
        )}

        <button
          type="submit"
          className="btn btn--reset company-search__action"
          onClick={() => {
            if (!requestSubmit) return;

            return requestSubmit(value);
          }}
        >
          <span className="visually-hidden">Search company</span>
          <SearchIcon height="20" width="20" />
        </button>
      </Input>
    </form>
  );
}
