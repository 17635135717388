import "./bank-detail-item.scss";
import moment from "moment";
import InlineLoader from "../../../components/ui/inline-loader/InlineLoader";

export interface IBankDetailPayload {
  id: string;
  isEditMode: boolean;
  disabled: boolean;
  isLoading: boolean;
  requestEditMode: (state: boolean, id: string) => void;
  requestSave: (payload: IBankDetailSavePayload) => void;
  bankAccount: string;
  iban: string;
  bankCode: string;
  title: string;
  accountName: string;
  toDate: null | moment.Moment;
  altAppearance?: boolean;
}

export interface IBankDetailSavePayload {
  id: string;
  bankAccount: string;
  accountName: string;
  bankCode: string;
  accountNumber: string;
  sortCode: string;
  toDate: string;
}

export interface IEditModeEntry {
  bankCode: string;
  iban: string;
  bankAccount: string;
  accountName: string;
  toDate: null | moment.Moment;
}

export enum IBankDetailType {
  UK = "uk",
  INT = "int",
}

export interface IBankDetailItem {
  accountNumber: string;
  accountName: string;
  sortCode: string;
  startDate: null | moment.Moment | string;
  isLoading: boolean;
  isEditable: boolean;
  requestChange: (type: IBankDetailType) => void;
}

export default function BankDetailItem({
  accountName,
  isEditable,
  isLoading,
  startDate,
  accountNumber,
  sortCode,
  requestChange,
}: IBankDetailItem) {
  return (
    <div className="bank-container">
      <dl data-title={"UK Bank Details"} className={`bank-card d-flex`}>
        {isLoading && (
          <div className="bank-container__loader">
            <InlineLoader />
          </div>
        )}

        <dt>Account Number:</dt>
        <dd>
          <span className="bank-card__entry">{accountNumber}</span>
        </dd>

        <dt>Account Name:</dt>
        <dd>
          <span className="bank-card__entry">{accountName}</span>
        </dd>

        <dt>Sort Code:</dt>
        <dd>
          <span className="bank-card__entry">{sortCode}</span>
        </dd>

        <dt>Start Date:</dt>
        <dd>
          <span className="bank-card__entry">
            {startDate === "Invalid DateTime" ? "N/A" : startDate}
          </span>
        </dd>
      </dl>

      {isEditable && (
        <div className="bank-card__actions">
          <button
            type="button"
            className="btn btn--default bank-card--action btn--short"
            disabled={isLoading}
            onClick={() => {
              return requestChange(IBankDetailType.UK);
            }}
          >
            Update Entry
          </button>
        </div>
      )}
    </div>
  );
}
