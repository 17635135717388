import MultiSelect, {IMultiSelectOption} from "../../../components/ui/multiselect/multiselect";
import "./history-multiselect.scss";

export interface IHistoryMultiSelect {
  requestChange: (event: IMultiSelectOption) => void,
  requestClearAll: () => void,
  options: IMultiSelectOption[],
  placeholder: string,
  label: string,
  customClass?: string,
  isDisabled?: boolean
}

export default function HistoryMultiSelect({requestClearAll, requestChange, options, placeholder, label, customClass, isDisabled}: IHistoryMultiSelect) {
  return (
    <div className="currency-selectors d-flex">
      <span className="input__title">
          {label}
        </span>

      <MultiSelect
        customClass={`history-multiselect ${customClass}`}
        isDisabled={isDisabled}
        requestChange={requestChange}
        requestClearAll={requestClearAll}
        placeholder={placeholder}
        options={options}
      />
    </div>
  )
}
