import {
  IGenericAuthResponse,
} from "../../../user";
import axios, {AxiosResponse} from "axios";
import {APIS} from "../../../../static/apis";
import {IGetDevices} from "../../../../types";

export async function getDevicesService (): Promise<AxiosResponse<IGenericAuthResponse<IGetDevices[]>>> {
  return axios.get(
    APIS.BASE_AUTH_MS_URL + APIS.GET_DEVICES
  )
}

