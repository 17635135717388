import {PropsWithChildren, ReactNode} from "react";
import "./users-header.scss";

export default function UsersHeader({children}: PropsWithChildren<ReactNode>) {
  return (
    <header className="users__header d-flex">
      {children}
    </header>
    )
}
