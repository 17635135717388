import { numberAndStringRegex, NumberRegExError } from "./types";

export default function validateDetailsOfCharges(item: string) {
  if (!item || !item.length) {
    return NumberRegExError.MISSING;
  }

  const numberRegexTester = new RegExp(numberAndStringRegex);

  const result = numberRegexTester.test(item);

  if (result && item.length > 18) {
    return NumberRegExError.MAX_LENGTH;
  }

  return result ? NumberRegExError.VALID : NumberRegExError.INVALID;
}
