import {useQuery} from "@tanstack/react-query";
import {getGlCodes} from "../../user/saga/vendor/get-gl-codes";

export const IUseGlCodeQuery = 'IUseGlCodeQuery';

export function useQueryGlCodes (condition: boolean, payeeNumber: string) {
  return useQuery([IUseGlCodeQuery, payeeNumber], () => getGlCodes(payeeNumber), {
    enabled: condition,
    refetchInterval: false,
    refetchIntervalInBackground: false,
    refetchOnWindowFocus: false,
    staleTime: 0,
    refetchOnMount: false
  });
}
