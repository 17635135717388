export enum INavPaths {
  CORE_DOCUMENTS = '/core-documents',
  INQUIRY_DOCUMENTS = '/call-in-documents'
}

export enum IRoutesItems {
  CATEGORY = 'catId',
  SUBCATEGORYID = 'subCatId',
  '*' = '*'
}

const NavItems = [
  {
    path: '/',
    name: 'Home'
  },
  {
    path: '/invoice-upload',
    name: 'Invoice Upload'
  },
  {
    path: '/history',
    name: 'History'
  },
  {
    path: '/contact-us',
    name: 'Contact Us'
  }
]

export default NavItems;
