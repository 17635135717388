import axios, { AxiosResponse } from "axios";
import { APIS } from "../../../../static/apis";
import { IGenericAuthResponse, IUser } from "../../../user";

export function getUser(
  item: string
): Promise<AxiosResponse<IGenericAuthResponse<IUser>>> {
  return axios.get(APIS.BASE_AUTH_MS_URL + APIS.GET_USER + "/" + item, {
    withCredentials: true,
  });
}
