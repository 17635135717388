import axios, { AxiosResponse } from "axios";
import { APIS } from "../../../../static/apis";
import { IGenericAuthResponse } from "../../../user";

export interface IGetBankDetails {
  id: string;
  bankAccount: string;
  bankCode: string;
  iban: string;
  bankName: string;
  startDate: string;
}

export function getBankDetails(
  payeeNumber: string
): Promise<AxiosResponse<IGenericAuthResponse<IGetBankDetails[]>>> {
  return axios.get(
    APIS.BASE_AUTH_MS_URL + APIS.GET_BANK_DETAILS + "/" + payeeNumber
  );
}
