import "./generic-not-found.scss";
import { ReactComponent as ArrowLeftIcon } from "../../../assets/icons/arrow-left.svg";
import { ReactComponent as Logo } from "../../../assets/icons/logo.svg";
import { Link, useNavigate } from "react-router-dom";

export interface IGenericNotFound {
  requestClick?: () => void;
  customClass?: string;
  customTitle?: string;
  customErrorNumber?: number;
  customContent?: string;
  hideLogo?: boolean;
}

export default function GenericNotFound({
  requestClick,
  hideLogo,
  customClass,
  customTitle,
  customErrorNumber,
  customContent,
}: IGenericNotFound) {
  const navigate = useNavigate();

  return (
    <div className={`generic-not-found ${customClass || ""}`}>
      {!hideLogo && (
        <Link
          to={"/"}
          onClick={handleClick}
          className="generic-not-found__logo"
        >
          <Logo />
        </Link>
      )}

      <div className="generic-not-found__meta">
        <span className="generic-not-found__subtitle">
          {customTitle && customTitle.length
            ? customTitle
            : "Error " +
              (customErrorNumber !== undefined ? customErrorNumber : 404) +
              ": Page not found"}
        </span>

        <h3 className="generic-not-found__title">
          {customErrorNumber !== undefined ? customErrorNumber : 404}
        </h3>
      </div>

      <p className="generic-not-found__intro">
        {customContent && customContent.length ? (
          customContent
        ) : (
          <>
            This may be a mistake or something went terribly wrong. <br />{" "}
            <br />
            If this error persists, please contact us at{" "}
            <a href="mailto:accounts.payable@cms-cmno.com">
              accounts.payable@cms-cmno.com.
            </a>
          </>
        )}
      </p>

      <div className="generic-not-found__actions">
        <button
          type="button"
          className="generic-not-found__action btn btn--mute"
          onClick={() => {
            handleClick();

            navigate("/");
          }}
        >
          <span className="generic-not-found__action-icon">
            <ArrowLeftIcon />
          </span>
          Back Home
        </button>

        <button
          type="button"
          className="generic-not-found__action btn btn--default"
          onClick={() => window.location.replace(window.location.origin)}
        >
          Restart Application
        </button>
      </div>
    </div>
  );

  function handleClick() {
    if (!requestClick) {
      return;
    }

    return requestClick();
  }
}
