import axios, { AxiosResponse } from "axios";
import { APIS } from "../../../../static/apis";

export function checkPayee(
  item: string,
  vendorId: string
): Promise<AxiosResponse<{ data: string; currency: string }>> {
  return axios.get(
    APIS.BASE_AUTH_MS_URL + APIS.CHECK_PAYEE + "/" + item + "/" + vendorId
  );
}
