import {useQuery} from "@tanstack/react-query";
import {getPayees} from "../../user/saga/vendor/get-payees";

export const IUseQueryPayees = 'IUseQueryPayees';

export function useQueryPayees (input: number, condition: boolean) {
  return useQuery([IUseQueryPayees, input], () => getPayees(input), {
    enabled: condition
  });
}

