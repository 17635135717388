import {ReactComponent as ErrorIcon} from "../../../../assets/icons/error.svg";
import {ReactComponent as WarningIcon} from "../../../../assets/icons/warning.svg";
import {ReactComponent as ApprovedIcon} from "../../../../assets/icons/approved.svg";
import {ReactComponent as InfoAltIcon} from "../../../../assets/icons/info-alt.svg";
import {INotificationType} from "../item/NotificationItem";

export interface INotificationIcon {
  type: INotificationType
}

export default function NotificationIcon({type}: INotificationIcon) {
  if (type === INotificationType.ERROR) {
    return <ErrorIcon />
  }

  if (type === INotificationType.WARNING) {
    return <WarningIcon />
  }

  if (type === INotificationType.INFO) {
    return <InfoAltIcon />
  }

  if (type === INotificationType.SUCCESS) {
    return <ApprovedIcon />
  }

  return null;
}
