import axios, {AxiosResponse} from "axios";
import {APIS} from "../../../../static/apis";
import {IGenericAuthResponse} from "../../../user";

export interface IGetGlCodes {
  friendlyName: string
  alias: string
}

export function getGlCodes (item: string): Promise<AxiosResponse<IGenericAuthResponse<IGetGlCodes[]>>> {
  return axios.get(
    APIS.BASE_AUTH_MS_URL + APIS.GET_GL_CODES +"/" + item
  );
}
