import Modal, {IModalType} from "../../../components/ui/modal/Modal";
import InviteModalButtons from "./inviteModalButtons";
import {IProfilePasswordModalAction} from "../types";
import Input from "../../../components/ui/inputs/Input";
import {CSSTransition} from "react-transition-group";
import {useEffect, useRef, useState} from "react";

export interface IInviteModal {
  isVisible: boolean,
  emailToCheck: string,
  requestClick: (event: IProfilePasswordModalAction, payload?: string) => void
}

export default function InviteModal({isVisible, requestClick, emailToCheck}: IInviteModal) {
  const errorRef = useRef<HTMLSpanElement>(null);

  const [userEmail, setUserEmail] = useState('');

  const [isValid, setIsValid] = useState(false);

  const [error, setErrorMessage] = useState('');

  useEffect(() => {
    setIsValid(userEmail === emailToCheck);
    setErrorMessage(!userEmail || !userEmail.length ? '' : userEmail !== emailToCheck ? 'Email address is incorrect' : '');

  }, [userEmail, emailToCheck]);

  useEffect(() => {
    return () => {
      if (!isVisible) {
        setIsValid(false);
        setUserEmail('');
        setErrorMessage('');
      }
    }
  }, [isVisible])

  return (
    <Modal title={'Invite User'}
           type={IModalType.NOTIFY}
           customClass={'password-modal deactivate-modal'}
           toggleVisibility={isVisible}
           customBtns={ <InviteModalButtons requestClick={(event) => {
             switch (event) {
               case IProfilePasswordModalAction.SAVE:
                 return requestClick(IProfilePasswordModalAction.SAVE, userEmail);

               case IProfilePasswordModalAction.CANCEL:
                 return requestClick(IProfilePasswordModalAction.CANCEL);

               default: return;
             }
           }}
          isDisabled={!isValid}
          isLoading={/*isUserSendingActivationState*/false} /> }
           onToggle={(event) => {
             requestClick(IProfilePasswordModalAction.CANCEL)
           }}>

      <p className="register-form__intro">
        This action will send the user a invitation link for completing the accounts registration process, available for the
        next <strong>30 minutes</strong>. Any other existing invitation links will not be available afterwards.

        <br />
        <br />

        Please confirm this action by adding the users email address below

        <br />
        <br />
      </p>

      <Input id={'current_email'}
             error={userEmail.length > 0 && !isValid}
             customClass="password-modal__input-el"
             type="email"
             placeholder={'Email Address'}
             value={userEmail}
             requestInput={setUserEmail}  />

      <span className="register-form__form-error-container">
        <CSSTransition
          classNames="slide-in-out-animation"
          timeout={250}
          in={!isValid && error.length > 0}
          nodeRef={errorRef}
          unmountOnExit
        >
          <span ref={errorRef} className="register-form__form-error">
           {error}
          </span>
        </CSSTransition>
      </span>

    </Modal>
  )
}
