import {useQuery} from "@tanstack/react-query";
import {getUser} from "../../user/saga/vendor/get-user";

export const IUseGetUser = 'IUseGetUser';

export function useQueryGetUser (condition: boolean, uuid: string) {
  return useQuery([IUseGetUser, uuid], () => getUser(uuid), {
    enabled: condition
  });
}
