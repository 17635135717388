import "./header.meta.scss";
import {IUserDarkMode} from "../../../../redux/core/core";
import {ReactComponent as MoonIcon} from "../../../../assets/icons/moon.svg";
import {ReactComponent as SunIcon} from "../../../../assets/icons/sun.svg";
import {ReactComponent as UserIcon} from "../../../../assets/icons/user.svg";
import {useState, useEffect, MouseEvent} from "react";
import {useAppDispatch, useAppSelector} from "../../../../redux/hooks";
import {Link, useNavigate} from "react-router-dom";
import {
  isAdminAccount,
  isPayeeSelected, selectDarkModeState, selectUserAvatar,
  selectUserEmail,
  selectUserName,
} from "../../../../redux/user/selectors";
import {logoutService} from "../../../../redux/user/saga/auth/logout";
import OverlayLoader from "../../../ui/overlay-loader/OverlayLoader";
import {clearUserData, updateDarkMode} from "../../../../redux/user/user";

export default function HeaderMeta() {
  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();

  const dispatch = useAppDispatch();
  const darkModeState = useAppSelector(selectDarkModeState);
  const userAvatar = useAppSelector(selectUserAvatar);
  const [isProfilePopupShown, setIsProfilePopupShown] = useState(false);

  const currentUserName = useAppSelector(selectUserName);
  const currentEmailAddress = useAppSelector(selectUserEmail);

  const isAccountAdmin = useAppSelector(isAdminAccount);

  const isPayeeSelectedState = useAppSelector(isPayeeSelected);

  useEffect(() => {
    function handleClick () {
      if (isProfilePopupShown) {
        setIsProfilePopupShown(false);
      }
    }

    window.addEventListener("click", handleClick);

    return () => {
      window.removeEventListener("click", handleClick);
    }
  })

  return (
    <div className="header-meta d-flex">
      <div className="header-actions d-flex">
        <button type="button" className="btn--reset header-action d-flex header-action__toggle-modes" onClick={() => {
          if (darkModeState === IUserDarkMode.NOT_SET ||
            darkModeState === IUserDarkMode.NOT_SET_USER) {
            return dispatch(updateDarkMode(IUserDarkMode.USER_SET));
          }

          return dispatch(updateDarkMode(IUserDarkMode.NOT_SET_USER));
        }}>
          <span className="header-action__icon d-flex">
            <MoonIcon className={`header-action__icon-moon header-action__icon-animate ${darkModeState === IUserDarkMode.NOT_SET || darkModeState === IUserDarkMode.NOT_SET_USER ? 'header-action__icon-animate--visible' : ''}`}/>

            <SunIcon className={`header-action__icon-sun header-action__icon-animate ${darkModeState === IUserDarkMode.SYSTEM_SET || darkModeState === IUserDarkMode.USER_SET ? 'header-action__icon-animate--visible' : ''}`}/>
          </span>
        </button>

        <button type="button" className="btn--reset header-action d-flex header-action__help" onClick={(event) => {
          event.stopPropagation();
          setIsProfilePopupShown(!isProfilePopupShown)
        }}>

        <span className="header-action__icon d-flex">
          {
            !userAvatar ?
              <UserIcon/>: <img src={userAvatar} alt="user avatar" />
          }
        </span>
        </button>
      </div>
      <div className="profile-popup" role="dialog" hidden={!isProfilePopupShown}>
        <header className="profile-popup__header">
          {currentUserName}

          <p>{currentEmailAddress}</p>
        </header>
        <nav className="profile-popup__links">
          <Link to="/invoice-upload"
                className={`profile-popup__link profile-popup__link--mobile profile-popup__link--profile ${!isPayeeSelectedState ? 'profile-popup__link--company-profile--disabled' : ''}`}>
            Invoice Upload
          </Link>

          <Link to="/history"
                className={`profile-popup__link profile-popup__link--mobile profile-popup__link--profile ${!isPayeeSelectedState ? 'profile-popup__link--company-profile--disabled' : ''}`}>
            History
          </Link>

          <hr />

          <Link to="/profile" className="profile-popup__link profile-popup__link--profile">
            Profile
          </Link>

          <Link to="/bank-details"
                onClick={(event) => {
                  if (isPayeeSelectedState) {
                    return;
                  }

                  return event.preventDefault();
                }}
                className={`profile-popup__link ${!isPayeeSelectedState ? 'profile-popup__link--company-profile--disabled' : ''}`}>
            Bank Details
          </Link>

          {
            isAccountAdmin &&
              <>
                <Link to="/companies" className="profile-popup__link">
                  Manage Companies
                </Link>

                <Link to="/users" className="profile-popup__link">
                  Manage Users
                </Link>
              </>
          }

          <Link to="/sign-in" onClick={handleSignOut} className="profile-popup__link profile-popup__link--logout">
            Logout
          </Link>
        </nav>
      </div>

      {
        isLoading && <OverlayLoader />
      }
    </div>
  )

  async function handleSignOut(event: MouseEvent<HTMLAnchorElement>) {
    event.preventDefault();

    setIsLoading(true);

    try {
      await logoutService();
    } catch (error) {
      console.log('[FE] Unable to sign out')
    }

    dispatch(clearUserData());

    localStorage.clear();

    navigate('/sign-in');
  }
}
