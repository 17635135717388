import {DOTS, IUsePagination, usePagination} from "../hooks/usePagination";
import "./pagination.scss";
import {ReactComponent as ChevronRightIcon} from "../../../assets/icons/chevron-right.svg";
import {ReactComponent as ChevronLeftIcon} from "../../../assets/icons/chevron-left.svg";

export interface IHistoryPagination extends IUsePagination {
  requestClick: (page: number) => void,
  disabled?: boolean,
  customClass?: string
}

export default function HistoryPagination({totalCount, customClass, siblingCount, pageSize, currentPage, requestClick, disabled}: IHistoryPagination) {
  const paginationRange = usePagination({
    totalCount,
    pageSize,
    siblingCount,
    currentPage
  });

  if (!paginationRange || currentPage === 0) {
    return null;
  }

  return (
    <div className={`pagination d-flex ${customClass && customClass.length > 0 ? customClass : ''}`}>
      <ul className="list d-flex">
        <li>
          <button type="button"
                  disabled={((currentPage - 1) < 1) || disabled}
                  className="btn pagination__quick-nav pagination__quick-nav--left"
                  onClick={() => {
                    if ((currentPage - 1) < 1) {
                      return;
                    }

                    return requestClick(currentPage - 1);
                  }}>
            <ChevronLeftIcon />
          </button>
        </li>
        {
          paginationRange.map(pageNumber => {
            if (pageNumber.toString().includes(DOTS)) {
              return (
                <li key={pageNumber}>
                  <span className="pagination__nav-item--placeholder">
                    &#8230;
                  </span>
                </li>
              )
            }


            return (
              <li key={pageNumber}>
                <button type="button"
                        className={`btn pagination__nav-item ${pageNumber === currentPage ? 'pagination__nav-item--active' : ''}`}
                        disabled={(pageNumber === currentPage) || disabled}
                        onClick={() => {
                          if (pageNumber === currentPage) {
                            return;
                          }

                          return requestClick(pageNumber as number)
                        }}>
                  {pageNumber}
                </button>
              </li>
            );
          })
        }
        <li>
          <button type="button"
                  className="pagination__quick-nav btn pagination__quick-nav--right"
                  disabled={(Math.ceil(totalCount / pageSize) === currentPage) || disabled}
                  onClick={() => {
                    if (currentPage === totalCount) {
                      return;
                    }

                    return requestClick(currentPage + 1);
                  }}>
            <ChevronRightIcon />
          </button>
        </li>
      </ul>
    </div>
  )
}
