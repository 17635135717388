import {useMutation} from "@tanstack/react-query";
import { validateCompanyVendorId } from "../../user/saga/vendor/validate-company-vendor-id";

export const IUseMutationValidateCompanyVendorId = 'IUseMutationValidateCompanyVendorId';

export function useValidateCompanyVendorId() {
  return useMutation({
    mutationKey: [IUseMutationValidateCompanyVendorId],
    mutationFn: validateCompanyVendorId,
  })
}
