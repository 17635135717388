import {useMutation} from "@tanstack/react-query";
import {AxiosResponse} from "axios";
import {IGenericAuthResponse, IUser} from "../../user/user";
import {queryClient} from "../../../App";
import {startAddNotification} from "../../core/core";
import {INotificationType} from "../../../components/ui/notifications/item/NotificationItem";
import {useAppDispatch} from "../../hooks";
import {IUseQueryUsers} from "../useQueryUsers";
import {removeEntitiesFromUser} from "../../user/saga/vendor/remove-entities-from-user";

export const IUseMutationRemoveEntitiesFromUser = 'IUseMutationRemoveEntitiesFromUser';

export interface IUseMutationRemoveEntitiesFromUserPayload {
  email: string
}

export function useMutationRemoveEntitiesFromUser() {
  const dispatchStore = useAppDispatch();

  return useMutation({
    mutationKey: [IUseMutationRemoveEntitiesFromUser],
    mutationFn: ({email}: IUseMutationRemoveEntitiesFromUserPayload) => removeEntitiesFromUser(email),
    onMutate: async (item) => {
      await queryClient.cancelQueries({ queryKey: [IUseQueryUsers] });

      const previousUsers = queryClient.getQueryData<AxiosResponse<IGenericAuthResponse<IUser[]>>>([IUseQueryUsers]);

      queryClient.setQueryData<AxiosResponse<IGenericAuthResponse<IUser[]>>>([IUseQueryUsers], (prevData) => {
        if (!prevData) {
          return;
        }

        const items = prevData.data.data.map(dataItem => {
          if (item.email !== dataItem.email) {
            return {
              ...dataItem,
            }
          }

          return {
            ...dataItem,
            company: undefined,
            payees: undefined
          }
        });

        return {
          ...prevData,
          data: {
            ...prevData.data,
            data: items
          }
        };
      })

      dispatchStore(startAddNotification({
        title: "Success",
        description: `Users successfully updated`,
        type: INotificationType.SUCCESS
      }));

      return { previousUsers };
    },
    onError: (err, newItem, context) => {
      if (!context) {
        return;
      }

      queryClient.setQueryData([IUseQueryUsers], context.previousUsers)

      dispatchStore(startAddNotification({
        title: "Error",
        description: `User updates rolled back`,
        type: INotificationType.ERROR
      }));
    },
    onSettled: (_, __, context) => {
      return queryClient.invalidateQueries({ queryKey: [IUseQueryUsers] })
    },
  })
}
