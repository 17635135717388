import NotificationItem from "./item/NotificationItem";
import {TransitionGroup} from "react-transition-group";
import {useAppSelector} from "../../../redux/hooks";
import {selectNotifications} from "../../../redux/core/selectors";
import "./notifications.scss";

export default function Notifications() {
  const notifications = useAppSelector(selectNotifications);

  return (
      <TransitionGroup className="notifications">
        {
          notifications.map(({type, title, id, description}) => (
            <NotificationItem
              key={id}
              id={id}
              type={type}
              title={title}
              description={description}
            />
          ))
        }
      </TransitionGroup>
  )
}
