import "./profile.scss";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import {
  isMicrosoftAccount,
  selectUserCreatedOn,
  selectUserEmail,
  selectUserName,
  selectUserProvider,
  selectUserRole,
} from "../../redux/user/selectors";
import DetermineIcon from "../../tools/common/determineProviderIcon";
import { useState } from "react";
import InlineLoader from "../../components/ui/inline-loader/InlineLoader";
import { ReactComponent as DeleteIcon } from "./../../assets/icons/trash.svg";
import DetermineDeviceIcon from "../../tools/common/determineDeviceIcon";
import ProfileDeleteDeviceModal from "./delete-device-modal/profile.delete-device";
import { IProfilePasswordModalAction } from "../users/types";
import { useQueryGetDevices } from "../../redux/hooks/useQueryGetDevices";
import { useMutationDeleteDevice } from "../../redux/hooks/useMutationDeleteDevice";
import { useMutationDeleteBiometricDevice } from "../../redux/hooks/useMutationDeleteBiometricDevice";
import ProfileChangePasswordModal, {
  IUpdatePasswordModalPayload,
} from "./profile-change-password/profile.change-password";
import { useMutationUpdatePassword } from "../../redux/hooks/useMutationUpdatePassword";
import { updatePendingState } from "../../redux/core/core";
import { IStatus } from "../../redux/types";
import {
  clearUserData,
  IUserProvider,
  startLogout,
} from "../../redux/user/user";

export default function Profile() {
  const [deviceUuid, setDeviceUuid] = useState<string | null>(null);
  const [biometricDeviceUuid, setBiometricDeviceUuid] = useState<string | null>(
    null
  );
  const [isChangePassVisibile, setIsChangePassVisible] = useState(false);

  const dispatch = useAppDispatch();

  const [isLoading, setIsLoading] = useState(false);

  const userName = useAppSelector(selectUserName);
  const userEmail = useAppSelector(selectUserEmail);
  const userProvider = useAppSelector(selectUserProvider);
  const userRole = useAppSelector(selectUserRole);
  const userCreatedOn = useAppSelector(selectUserCreatedOn);
  const isMicrosoftAccountState = useAppSelector(isMicrosoftAccount);

  const { mutate: deleteDeviceMutation, isLoading: isDeletingDevice } =
    useMutationDeleteDevice();
  const {
    mutate: deleteBiometricDeviceMutation,
    isLoading: isDeletingBiometricDevice,
  } = useMutationDeleteBiometricDevice();
  const { mutateAsync: updatePassword, isLoading: isUpdatingPassword } =
    useMutationUpdatePassword();

  const { isRefetching: devicesLoading, data: devicesApiData } =
    useQueryGetDevices();

  return (
    <div className="profile">
      <div className="content__wrapper">
        <h1 className="profile__title">Manage Profile</h1>

        <section className="profile__section profile__section--personal-details">
          <h2 className="profile__section-title">Personal Details</h2>

          <dl className="d-flex f-wrap f-column-xs">
            <dt>User name:</dt>
            <dd>{userName}</dd>

            <dt>Email address:</dt>
            <dd>{userEmail}</dd>

            <dt>Roles:</dt>
            <dd>
              <ul className="list roles d-flex">
                <li>
                  <span className="role">{userRole}</span>
                </li>
              </ul>
            </dd>

            <dt>Account created on:</dt>
            <dd>{userCreatedOn}</dd>

            <dt>Account type</dt>
            <dd className="account-type d-flex align-items-center">
              <DetermineIcon type={userProvider} />

              {isMicrosoftAccountState ? "Microsoft" : "Default"}
            </dd>
          </dl>
        </section>

        {!devicesLoading && devicesApiData ? (
          <>
            {devicesApiData.data.data.length > 0 && (
              <>
                <h2 className="profile__title">Connected Devices</h2>

                <section className="profile__section profile__section--devices">
                  <table className="profile__table-devices">
                    <thead>
                      <tr>
                        <th>Device Type</th>

                        <th>Device Information</th>

                        <th></th>
                      </tr>
                    </thead>

                    <tbody>
                      {devicesApiData.data.data.map((item) => (
                        <tr key={item.deviceUuid}>
                          <td data-cell-name="Device">
                            <DetermineDeviceIcon deviceType={item.deviceType} />
                          </td>

                          <td>{item.deviceInfo}</td>

                          <td>
                            <button
                              type="button"
                              disabled={
                                isDeletingDevice || isDeletingBiometricDevice
                              }
                              className="btn btn--error btn-device-delete"
                              onClick={() => {
                                setDeviceUuid(item.deviceUuid);
                              }}
                            >
                              <DeleteIcon /> Delete Device
                            </button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </section>
              </>
            )}
          </>
        ) : null}

        {/*{*/}
        {/*  !isMicrosoftAccountState && !biometricDevicesLoading && biometricDevicesApiData &&*/}
        {/*  <>*/}
        {/*    <h2 className="profile__title">*/}
        {/*      Biometric Devices*/}
        {/*    </h2>*/}

        {/*    <div className="biometrics-actions">*/}
        {/*      <button type="button"*/}
        {/*              disabled={isDeletingDevice || isNewBiometricLoading || isDeletingBiometricDevice}*/}
        {/*              className="register__fingerprint btn--reset" onClick={handleNewBiometricDevice}>*/}
        {/*        <FingerPrintIcon/>*/}

        {/*        <span className="register__fingerprint-content">*/}
        {/*          {*/}
        {/*            STATIC_STRINGS.REGISTER.FORM.EXTRA_BIOMETRIC*/}
        {/*          }*/}
        {/*        </span>*/}
        {/*      </button>*/}

        {/*      {*/}
        {/*        isNewBiometricLoading && <CountDown animationEnd={handleExpiredLink}/>*/}
        {/*      }*/}
        {/*    </div>*/}

        {/*    {*/}
        {/*      isNewBiometricLoading && newBiometricQrCode && newBiometricUrl &&*/}
        {/*      <div className={"biometrics-actions--content"}>*/}
        {/*        <p>*/}
        {/*          A biometric registration has been created, active for*/}
        {/*          <strong> 180 seconds</strong> duration. In order to use it, either*/}
        {/*          scan the qr code or use the link bellow, on the desired device.*/}
        {/*        </p>*/}

        {/*        <div className="profile__add-biometric-action-content">*/}
        {/*          <img src={newBiometricQrCode} alt="qr code biometric registration"/>*/}

        {/*          <a href={newBiometricUrl} rel="noreferrer" target="_blank"><ExternalLink/> Biometric Registration</a>*/}
        {/*        </div>*/}
        {/*      </div>*/}
        {/*    }*/}

        {/*    {*/}
        {/*      biometricDevicesApiData.data.data.length > 0 &&*/}
        {/*      <section className="profile__section profile__section--devices">*/}
        {/*        <table className="profile__table-devices">*/}
        {/*          <thead>*/}
        {/*          <tr>*/}
        {/*            <th>*/}
        {/*              Device Type*/}
        {/*            </th>*/}

        {/*            <th>*/}
        {/*              Device Information*/}
        {/*            </th>*/}

        {/*            <th>*/}

        {/*            </th>*/}
        {/*          </tr>*/}
        {/*          </thead>*/}

        {/*          <tbody>*/}
        {/*          {*/}
        {/*            biometricDevicesApiData.data.data.map(item => (*/}
        {/*              <tr key={item.deviceUuid}>*/}
        {/*                <td>*/}
        {/*                  <DetermineDeviceIcon deviceType={item.deviceType}/>*/}
        {/*                </td>*/}

        {/*                <td>*/}
        {/*                  {*/}
        {/*                    item.deviceInfo*/}
        {/*                  }*/}
        {/*                </td>*/}

        {/*                <td>*/}
        {/*                  <button type="button"*/}
        {/*                          disabled={isDeletingDevice || isNewBiometricLoading || isDeletingBiometricDevice}*/}
        {/*                          className="btn btn--error btn-device-delete"*/}
        {/*                          onClick={() => {*/}
        {/*                            setBiometricDeviceUuid(item.deviceUuid);*/}
        {/*                          }}>*/}
        {/*                    <DeleteIcon/> Delete Device*/}
        {/*                  </button>*/}
        {/*                </td>*/}
        {/*              </tr>*/}
        {/*            ))*/}
        {/*          }*/}
        {/*          </tbody>*/}
        {/*        </table>*/}
        {/*      </section>*/}
        {/*    }*/}
        {/*  </>*/}
        {/*}*/}

        {userProvider === IUserProvider.DEFAULT && (
          <button
            type="button"
            className="btn btn--default update-profile-password-btn"
            onClick={() => setIsChangePassVisible(true)}
          >
            Update account password
          </button>
        )}

        {(isLoading ||
          isDeletingDevice ||
          isDeletingBiometricDevice ||
          devicesLoading) &&
          !isMicrosoftAccountState && <InlineLoader />}
      </div>

      <ProfileDeleteDeviceModal
        isVisible={deviceUuid !== null}
        onToggle={() => setDeviceUuid(null)}
        requestClick={handleDeleteDeviceConfirmation}
      />

      <ProfileDeleteDeviceModal
        isVisible={biometricDeviceUuid !== null}
        onToggle={() => setBiometricDeviceUuid(null)}
        requestClick={handleDeleteBiometricDeviceConfirmation}
      />

      <ProfileChangePasswordModal
        isDisabled={isUpdatingPassword}
        isVisible={isChangePassVisibile}
        onToggle={() => setIsChangePassVisible(false)}
        requestClick={handleUpdatePasswordConfirmation}
      />
    </div>
  );

  async function handleUpdatePasswordConfirmation(
    event: IProfilePasswordModalAction,
    payload?: IUpdatePasswordModalPayload
  ) {
    switch (event) {
      case IProfilePasswordModalAction.CANCEL:
        return setIsChangePassVisible(false);

      case IProfilePasswordModalAction.SAVE:
        try {
          await updatePassword({
            newPassword: payload?.newPassword ?? "",
            password: payload?.password ?? "",
          });

          return await handleLogout();
        } catch (e) {
          console.log("[FE] Error occurred on delete device", e);

          setIsChangePassVisible(false);
        }

        return;

      default:
        throw new Error("Unknown action provided");
    }
  }

  async function handleDeleteBiometricDeviceConfirmation(
    event: IProfilePasswordModalAction
  ) {
    switch (event) {
      case IProfilePasswordModalAction.CANCEL:
        return setBiometricDeviceUuid(null);

      case IProfilePasswordModalAction.SAVE:
        if (!biometricDeviceUuid) {
          return setBiometricDeviceUuid(null);
        }

        try {
          const currentUuid = biometricDeviceUuid;

          setBiometricDeviceUuid(null);

          deleteBiometricDeviceMutation(currentUuid);

          setIsLoading(false);

          break;
        } catch (e) {
          console.log("[FE] Error occurred on delete device", e);

          setBiometricDeviceUuid(null);
        }

        return;

      default:
        throw new Error("Unknown action provided");
    }
  }

  async function handleDeleteDeviceConfirmation(
    event: IProfilePasswordModalAction
  ) {
    switch (event) {
      case IProfilePasswordModalAction.CANCEL:
        return setDeviceUuid(null);

      case IProfilePasswordModalAction.SAVE:
        if (!deviceUuid) {
          return setDeviceUuid(null);
        }

        try {
          const currentUuid = deviceUuid;

          setDeviceUuid(null);

          deleteDeviceMutation(currentUuid);

          setDeviceUuid(null);

          break;
        } catch (e) {
          console.log("[FE] Error occurred on delete device", e);

          setDeviceUuid(null);
        }

        return;
      default:
        throw new Error("Unknown action provided");
    }
  }

  async function handleLogout() {
    dispatch(updatePendingState(IStatus.RUNNING_AUTH));

    dispatch(startLogout());

    dispatch(clearUserData());

    dispatch(updatePendingState(IStatus.IDLE));

    await new Promise((resolve) => {
      setTimeout(() => {
        resolve(true);
      }, 50);
    });

    localStorage.clear();
  }
}
