import { useMutation } from "@tanstack/react-query";
import { startAddNotification } from "../../core/core";
import { INotificationType } from "../../../components/ui/notifications/item/NotificationItem";
import { useAppDispatch } from "../../hooks";
import {
  IUpdateUsernameService,
  updateUsernameService,
} from "redux/user/saga/auth/update-username";
import { queryClient } from "App";
import { IUseQueryUsers } from "../useQueryUsers";

export const IUseMutationUpdateUsername = "IUseMutationUpdateUsername";

export function useMutationUpdateUsername() {
  const dispatchStore = useAppDispatch();

  return useMutation({
    mutationKey: [IUseMutationUpdateUsername],
    mutationFn: (data: IUpdateUsernameService) => updateUsernameService(data),
    onSuccess: async () => {
      dispatchStore(
        startAddNotification({
          title: "Success",
          description: `Username successfully updated`,
          type: INotificationType.SUCCESS,
        })
      );

      return {};
    },
    onError: () => {
      dispatchStore(
        startAddNotification({
          title: "Error",
          description: `Unable to update Username`,
          type: INotificationType.ERROR,
        })
      );
    },
    onSettled: () => {
      return queryClient.invalidateQueries({ queryKey: [IUseQueryUsers] });
    },
  });
}
