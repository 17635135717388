import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {INotification, INotificationData} from "../../components/ui/notifications/item/NotificationItem";
import {IStatus} from "../types";

export enum IUserDarkMode {
  NOT_SET = 'not_set',
  NOT_SET_USER = 'not_set_user',
  USER_SET = 'user_set',
  SYSTEM_SET = 'system_set'
}

export interface ICoreState {
  isAppBootstrapped: boolean,
  isFullWidth: boolean,
  notifications: (INotification & INotificationData)[],
  pending: IStatus,
  initiatedSignIn: boolean,
  isOverlayVisible: boolean,
}

const initialState: ICoreState = {
  isAppBootstrapped: false,
  isFullWidth: false,
  initiatedSignIn: false,
  notifications: [],
  pending: IStatus.IDLE,
  isOverlayVisible: false
}

// bootstrap stuff
const coreSlice = createSlice({
  name: "core",
  initialState,
  reducers: {
    updateInitiatedSignInState(state, action: PayloadAction<boolean>) {
      state.initiatedSignIn = action.payload;
    },
    updateBoostrapState(state, action: PayloadAction<boolean>) {
      state.isAppBootstrapped = action.payload;
    },
    updatePendingState(state, action: PayloadAction<IStatus>) {
      state.pending = action.payload;
    },
    updateFullWidthState(state, action: PayloadAction<boolean>) {
      state.isFullWidth = action.payload;
    },
    startAddNotification(state, action: PayloadAction<INotificationData>) {},
    addNotification(state, action: PayloadAction<INotification & INotificationData>) {
      state.notifications.push(action.payload);
    },
    removeNotification(state, action: PayloadAction<number>) {
      const currentNotification = state.notifications.map(item => item);

      state.notifications = currentNotification.filter(item => item.id !== action.payload).map(item => item);
    },
    updateIsOverlayVisible(state, action: PayloadAction<boolean>) {
      state.isOverlayVisible = action.payload;
    },
  }
});

// prepare actions
export const { updateBoostrapState, updateInitiatedSignInState, updatePendingState, updateFullWidthState, addNotification, removeNotification, startAddNotification, updateIsOverlayVisible } = coreSlice.actions;

// prepare reducers
export default coreSlice.reducer;
