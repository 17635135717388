import {useQuery} from "@tanstack/react-query";
import {getBiometricDevicesService} from "../../user/saga/auth/get-biometric-devices";

export const IUseGetBiometricDevices = 'IUseGetBiometricDevices';

export function useQueryGetBiometricDevices (condition: boolean) {
  return useQuery([IUseGetBiometricDevices], getBiometricDevicesService, {
    enabled: condition
  });
}
