import "./boundary.scss";
import {useEffect, useState} from "react";
import {ReactComponent as Logo} from "../../../assets/icons/logo.svg";

export function setWithExpiry(key: string, value: string, ttl: number) {
  const item = {
    value: value,
    expiry: new Date().getTime() + ttl
  };
  localStorage.setItem(key, JSON.stringify(item));
}

export function getWithExpiry(key: string) {
  const itemString = window.localStorage.getItem(key);
  if (!itemString) return null;

  const item = JSON.parse(itemString);
  const isExpired = new Date().getTime() > item.expiry;

  if (isExpired) {
    localStorage.removeItem(key);
    return null;
  }

  return item.value;
}

export interface IBoundary {
  error?: Error
}

export default function Boundary({error}: IBoundary) {
  const [currentError, setCurrentError] = useState('');

  useEffect(() => {
    const chunkFailedMessage = /Loading chunk [\d]+ failed/;
    if (error?.message && chunkFailedMessage.test(error.message)) {
      if (!getWithExpiry("chunk_failed")) {
        setWithExpiry("chunk_failed", "true", 10000);
        window.location.reload();
      }
    }

    if (!error ||
        !error.message) {
      setCurrentError('Unknown Error');
    }

    if (error &&
        error.message) {
      setCurrentError(error.message);
    }

  }, [error]);

  return (
    <div className="error-boundary">
      <div className="error-boundary__header">
        <Logo />
      </div>

      <h3>Ooops! Something went terribly wrong :(</h3>

      <p>
        Try to refresh the app or if the error persists, please contact an administrator.
      </p>

      <button type="button" className="error-boundary__action" onClick={() => window.location.reload()}>
        Refresh App
      </button>

      <p>
        Output Error Log:
      </p>

      <pre>{currentError}</pre>
    </div>
  );
}
