import "./../../../assets/styles/theme.scss";
import { lazy, Suspense, useEffect, useLayoutEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import {
  Navigate,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from "react-router-dom";
import PrimaryLayout from "../primary-layout/PrimaryLayout";
import HomePage from "../../../pages/home/Home.page";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import {
  IUserDarkMode,
  updateBoostrapState,
  updatePendingState,
} from "../../../redux/core/core";
import useDarkModeSetup from "../../../tools/hooks/darkMode.hook";
import Notifications from "../../ui/notifications/Notifications";
import OverlayLoader from "../../ui/overlay-loader/OverlayLoader";
import {
  hasInitiatedSignIn,
  selectAppBoostrapState,
} from "../../../redux/core/selectors";
import InvoiceUpload from "../../../pages/invoice-upload/invoice-upload";
import History from "../../../pages/history/history";
import Profile from "../../../pages/profile/profile";
import Users from "../../../pages/users/users";
import {
  clearUserData,
  IUserProvider,
  IUserRole,
  IUserStatus,
  startLogout,
  updateUserAccessToken,
  updateUserAvailablePayeeAccounts,
  updateUserCredentialsData,
} from "redux/user/user";
import CompanyProfile from "../../../pages/company-profile/company-profile";
import retry from "../../../tools/retry";
import {
  isAdminAccount,
  isPayeeSelected,
  isUserLoggedIn,
} from "../../../redux/user/selectors";
import GenericNotFound from "../../ui/generic-not-found/GenericNotFound";
import { getAccessTokenService } from "../../../redux/user/saga/auth/get-access-token";
import { IHeaderAccessToken } from "../../../redux/static/static";
import Companies from "../../../pages/companies/companies";
import { IPayeeAccounts } from "../../../redux/companies/companies";
import { getAvailablePayees } from "../../../redux/user/saga/vendor/get-available-payees";
import BankDetails from "../../../pages/bank-details/bank-details";
import PrivacyPage from "../../../pages/privacy/privacy";
import TermsPage from "../../../pages/terms/terms";
import ContactUsPage from "../../../pages/contact-us/contact-us";
import AcceptableUsePolicyPage from "pages/acceptable-use-policy/acceptable-use-policy";
import { useIdle } from "react-use";
import { logoutService } from "redux/user/saga/auth/logout";
import { IStatus } from "redux/types";

const AuthLayout = lazy(() =>
  retry(() => import("../../specific/auth/Auth.layout"))
);
const SignIn = lazy(() => retry(() => import("../../specific/sign-in/SignIn")));
const SignInVerifyQrCode = lazy(() =>
  retry(
    () => import("../../specific/sign-in-verify-qr-code/SignInVerifyQrCode")
  )
);
const ForgotPass = lazy(() =>
  retry(() => import("../../specific/forgot-pass/ForgotPass"))
);
const ResetPass = lazy(() =>
  retry(() => import("../../specific/reset-pass/ResetPass"))
);
const MicrosoftAuth = lazy(() =>
  retry(() => import("../../specific/microsoft/MicrosoftAuth"))
);
const Register = lazy(() =>
  retry(() => import("../../specific/register/Register"))
);
const SignInMethod = lazy(() =>
  retry(() => import("../../specific/sing-in-method/SignInMethod"))
);
const SignInVerify = lazy(() =>
  retry(() => import("../../specific/sign-in-verify/SignInVerify"))
);
const AddNewBiometric = lazy(() =>
  retry(() => import("../../specific/add-new-biometric/AddNewBiometric"))
);
const CmsRelated = lazy(() =>
  retry(() => import("../../../pages/invoice-upload/related/related"))
);
const CmsOverhead = lazy(() =>
  retry(() => import("../../../pages/invoice-upload/overhead/overhead"))
);

const bypassAuth = !!process.env.REACT_APP_BYPASS_AUTH;

export default function Base() {
  const boostrapState = useAppSelector(selectAppBoostrapState);

  const location = useLocation();

  const currentPathRoute = location.pathname.split("/")[1];

  const isAdminAccountState = useAppSelector(isAdminAccount);

  const isPayeeAccountSelected = useAppSelector(isPayeeSelected);

  useDarkModeSetup();

  const dispatch = useAppDispatch();

  const isUserSignedIn = useAppSelector(isUserLoggedIn);
  const hasInitiatedSignInState = useAppSelector(hasInitiatedSignIn);

  if (bypassAuth && !isUserSignedIn) {
    const availablePayees: IPayeeAccounts[] = [];

    dispatch(
      updateUserCredentialsData({
        createdOn: "July 26, 2022",
        devices: [],
        email: "this.should.no.be.on.prod@cms-cmno.com",
        name: "John Doe",
        provider: IUserProvider.MICROSOFT,
        role: IUserRole.admin,
        userAvatar: null,
        status: IUserStatus.ACTIVATED,
        uuid: "9db05c48-df31-49ee-9d57-df0ce66b5712",
        vendorId: "100614",
        vendorName: "BRITISH TELECOMMUNICATIONS",
        availablePayees,
        darkMode: IUserDarkMode.NOT_SET,
      })
    );
  }

  const navigate = useNavigate();

  // 60 mins
  //const idleTime = 60 * 60000; // 60 minutes in milliseconds

  //  const isIdle = useIdle(3.6e6);
  // 60 mins logout
  const isIdle = useIdle(3.6e6);

  useEffect(() => {
    function listenForStorage() {
      if (document.visibilityState === "hidden" && isUserSignedIn) {
        navigate("/sign-in");
      }

      if (document.visibilityState === "hidden") {
        window.location.reload();
      }
    }
    window.addEventListener("storage", listenForStorage);
    return () => {
      window.removeEventListener("storage", listenForStorage);
    };
  }, [isUserSignedIn, navigate]);

  useEffect(() => {
    async function handleSignOut() {
      try {
        await logoutService();
      } catch (error) {
        console.log("[FE] Unable to sign out");
      }

      dispatch(clearUserData());

      localStorage.clear();

      navigate("/sign-in");
    }

    if (isIdle && isUserSignedIn) {
      handleSignOut();
    }
  }, [dispatch, isIdle, isUserSignedIn, navigate]);

  useEffect(() => {
    if (!isUserSignedIn || bypassAuth) {
      dispatch(updateBoostrapState(true));
    }

    if (isUserSignedIn && !bypassAuth) {
      Promise.all([getAccessTokenService(), getAvailablePayees()])
        .then(([authResponse, payeeResponse]) => {
          dispatch(updateUserAvailablePayeeAccounts(payeeResponse.data.data));
          dispatch(
            updateUserAccessToken(authResponse.headers[IHeaderAccessToken])
          );
        })
        .catch((error) => {
          //  console.error("Auth Error on: ", error);

          localStorage.clear();
          dispatch(clearUserData());
        })
        .finally(() => {
          dispatch(updateBoostrapState(true));
        });
    }
  }, [dispatch, isUserSignedIn]);

  if (!boostrapState) {
    return <OverlayLoader />;
  }

  return (
    <div className="app-container">
      <Helmet>
        <title>Supplier Portal</title>
        <meta httpEquiv="cache-control" content="no-cache" />
        <meta httpEquiv="expires" content="0" />
        <meta httpEquiv="pragma" content="no-cache" />
      </Helmet>

      <Routes>
        <Route path={"/"}>
          {/**
               === Auth Layout
               */}
          <Route
            element={
              <Suspense fallback={<OverlayLoader />}>
                <AuthLayout customClass={currentPathRoute} />
              </Suspense>
            }
          >
            <Route
              path={"/microsoft-auth"}
              element={
                <Suspense fallback={<OverlayLoader />}>
                  <MicrosoftAuth />
                </Suspense>
              }
            />

            <Route
              path={"/sign-in"}
              element={
                <Suspense fallback={<OverlayLoader />}>
                  {isUserSignedIn ? <Navigate to={"/"} /> : <SignIn />}
                </Suspense>
              }
            />

            <Route
              path={"/sign-in/qr-code"}
              element={
                <Suspense fallback={<OverlayLoader />}>
                  {isUserSignedIn ? (
                    <Navigate to={"/"} />
                  ) : (
                    <SignInVerifyQrCode />
                  )}
                </Suspense>
              }
            />

            <Route
              path={"/sign-in/forgot-password"}
              element={
                <Suspense fallback={<OverlayLoader />}>
                  {isUserSignedIn ? <Navigate to={"/"} /> : <ForgotPass />}
                </Suspense>
              }
            />

            <Route
              path={"/sign-in/reset-password/:registrationUuid"}
              element={
                <Suspense fallback={<OverlayLoader />}>
                  {isUserSignedIn ? <Navigate to={"/"} /> : <ResetPass />}
                </Suspense>
              }
            />

            <Route
              path={"/sign-in/method"}
              element={
                <Suspense fallback={<OverlayLoader />}>
                  {isUserSignedIn || !hasInitiatedSignInState ? (
                    <GenericNotFound hideLogo />
                  ) : (
                    <SignInMethod />
                  )}
                </Suspense>
              }
            />

            <Route
              path={"/sign-in/verify"}
              element={
                <Suspense fallback={<OverlayLoader />}>
                  {isUserSignedIn ? (
                    <GenericNotFound hideLogo />
                  ) : (
                    <SignInVerify />
                  )}
                </Suspense>
              }
            />

            <Route
              path={"/register/:registrationUuid"}
              element={
                <Suspense fallback={<OverlayLoader />}>
                  {isUserSignedIn ? <GenericNotFound hideLogo /> : <Register />}
                </Suspense>
              }
            />

            <Route
              path={"/add-biometric-device/:registrationUuid"}
              element={
                <Suspense fallback={<OverlayLoader />}>
                  {isUserSignedIn ? (
                    <GenericNotFound hideLogo />
                  ) : (
                    <AddNewBiometric />
                  )}
                </Suspense>
              }
            />
          </Route>

          {/**
             === App Layout
             */}
          <Route
            element={
              isUserSignedIn ? <PrimaryLayout /> : <Navigate to={"sign-in"} />
            }
          >
            {/**
               ===Home Routing
               */}
            <Route index element={<HomePage />} />

            {/**
               ===Invoice Upload Routing
               */}
            <Route path="/invoice-upload" element={<InvoiceUpload />}>
              <Route
                path="cms-related"
                element={
                  <Suspense fallback={<OverlayLoader />}>
                    {isPayeeAccountSelected ? (
                      <CmsRelated />
                    ) : (
                      <Navigate to={"/"} />
                    )}
                  </Suspense>
                }
              />

              <Route
                path="cms-overhead"
                element={
                  <Suspense fallback={<OverlayLoader />}>
                    {isPayeeAccountSelected ? (
                      <CmsOverhead />
                    ) : (
                      <Navigate to={"/"} />
                    )}
                  </Suspense>
                }
              />
            </Route>

            {/**
               ===History Routing
               */}
            <Route
              path="/history"
              element={
                isPayeeAccountSelected ? <History /> : <Navigate to={"/"} />
              }
            />

            {/**
               ===Bank History Routing
               */}
            <Route
              path="/bank-details"
              element={
                isPayeeAccountSelected ? <BankDetails /> : <Navigate to={"/"} />
              }
            />

            {/**
               ===Profile Routing
               */}
            <Route path="/profile" element={<Profile />} />

            {/**
               ===Acceptable Use Policy Routing
               */}
            <Route
              path="/acceptable-use-policy"
              element={<AcceptableUsePolicyPage />}
            />

            {/**
               ===Privacy policy Routing
               */}
            {/* <Route path="/privacy-policy" element={<PrivacyPage />} /> */}

            {/**
               ===Terms Conditions Routing
               */}
            {/* <Route path="/terms-and-conditions" element={<TermsPage />} /> */}

            {/**
               ===Contact Page Routing
               */}
            <Route path="/contact-us" element={<ContactUsPage />} />

            {/**
               ===Users Routing
               */}
            <Route
              path="/users"
              element={
                !isAdminAccountState ? (
                  <GenericNotFound hideLogo={true} />
                ) : (
                  <Users />
                )
              }
            />

            {/**
               ===Users Routing
               */}
            <Route
              path="/companies"
              element={
                !isAdminAccountState ? (
                  <GenericNotFound hideLogo={true} />
                ) : (
                  <Companies />
                )
              }
            />

            {/**
               ===Vendor Profile Routing
               */}
            <Route
              path="/company-profile"
              element={
                isPayeeAccountSelected ? (
                  <CompanyProfile />
                ) : (
                  <Navigate to={"/"} />
                )
              }
            />
          </Route>

          {/**
             ===404 Routing
            */}
          <Route path="*" element={<GenericNotFound />} />
        </Route>
      </Routes>

      {/**
         Notifications wrapper
         */}
      <Notifications />
    </div>
  );
}
