import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface ICompanyState {
  companies: {
    [id: number]: ICompany;
  };
}

export interface IPayeeState {
  payees: {
    [companyId: number]: IPayeeAccounts[];
  };
}

export interface IPayeeAccounts {
  payeeNumber: string;
  name: string;
  id: number;
  companyId: number;
  isSelected?: boolean;
  companyName?: string;
  payeeCurrency?: string;
}

export interface ICompany {
  name: string;
  vendorId?: string;
  id: number;
  related?: boolean;
  overhead?: boolean;
}

const initialState: ICompanyState & IPayeeState = {
  companies: {},
  payees: {},
};

export interface IGenericAuthResponse<T> {
  data: T;
}

// bootstrap stuff
const companySlice = createSlice({
  name: "companies",
  initialState,
  reducers: {
    addCompany(state, action: PayloadAction<ICompany>) {
      if (action.payload.id in state.companies) {
        return;
      }

      state.companies = {
        ...state.companies,
        [action.payload.id]: {
          name: action.payload.name,
          id: action.payload.id,
          vendorId: action.payload.vendorId,
          overhead: action.payload.overhead,
          related: action.payload.related,
        },
      };
    },
    removeCompany(state, action: PayloadAction<ICompany>) {
      const currentState = JSON.parse(JSON.stringify(state.companies));
      const currentPayees = JSON.parse(JSON.stringify(state.payees));

      delete currentState[action.payload.id];
      delete currentPayees[action.payload.id];

      state.companies = {
        ...currentState,
      };
    },
    addPayeeAccountToCompany(
      state,
      action: PayloadAction<IPayeeAccounts & { companyId: number }>
    ) {
      let currentPayees = [];

      if (state.payees && action.payload.companyId in state.payees) {
        currentPayees = state.payees[action.payload.companyId].map((item) => ({
          ...item,
        }));

        currentPayees.push({
          id: action.payload.id,
          payeeNumber: action.payload.payeeNumber,
          name: action.payload.name,
          companyId: action.payload.companyId,
          companyName: action.payload.companyName,
          payeeCurrency: action.payload.payeeCurrency,
        });

        state.payees = {
          ...state.payees,
          [action.payload.companyId]: currentPayees,
        };

        return;
      }

      state.payees = {
        ...state.payees,
        [action.payload.companyId]: [
          {
            id: action.payload.id,
            payeeNumber: action.payload.payeeNumber,
            name: action.payload.name,
            companyId: action.payload.companyId,
            companyName: action.payload.companyName,
            payeeCurrency: action.payload.payeeCurrency,
          },
        ],
      };
    },
    updatePayeeAccount(
      state,
      action: PayloadAction<IPayeeAccounts & { companyId: number }>
    ) {
      const currentState = JSON.parse(JSON.stringify(state.payees));

      const updatePayees = currentState[action.payload.companyId].map(
        (item: IPayeeAccounts) => {
          if (item.id === action.payload.id) {
            return {
              ...item,
              name: action.payload.name,
              payeeNumber: action.payload.payeeNumber,
            };
          }

          return {
            ...item,
          };
        }
      );

      state.payees = {
        ...state.payees,
        [action.payload.companyId]: updatePayees,
      };
    },
    updateCompany(
      state,
      action: PayloadAction<{
        companyId: number;
        name: string;
        vendorId: string;
      }>
    ) {
      state.companies[action.payload.companyId] = {
        ...state.companies[action.payload.companyId],
        name: action.payload.name,
        vendorId: action.payload.vendorId,
      };
    },
    removePayeesAccountFromCompany(
      state,
      action: PayloadAction<{ companyId: number }>
    ) {
      if (!(action.payload.companyId in state.payees)) {
        return;
      }

      state.payees[action.payload.companyId] = [];
    },
    removePayeeAccountFromCompany(
      state,
      action: PayloadAction<IPayeeAccounts & { companyId: number }>
    ) {
      const currentState = JSON.parse(JSON.stringify(state.payees));

      const updatePayees = currentState[action.payload.companyId]
        .filter((item: IPayeeAccounts) => item.id !== action.payload.id)
        .map((item: IPayeeAccounts) => item);

      state.payees = {
        ...state.payees,
        [action.payload.companyId]: updatePayees,
      };
    },
  },
});

// prepare actions
export const {
  updatePayeeAccount,
  updateCompany,
  removePayeeAccountFromCompany,
  removePayeesAccountFromCompany,
  addPayeeAccountToCompany,
  removeCompany,
  addCompany,
} = companySlice.actions;

// prepare reducers
export default companySlice.reducer;
