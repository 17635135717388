import {useMutation} from "@tanstack/react-query";
import {checkCompanyVendorId} from "../../user/saga/vendor/check-company-vendor-id";

export const IUseMutationCheckCompanyVendorId = 'IUseMutationCheckCompanyVendorId';

export function useMutationCheckCompanyVendorId() {
  return useMutation({
    mutationKey: [IUseMutationCheckCompanyVendorId],
    mutationFn: checkCompanyVendorId,
  })
}
