import "./users.scss";
import { useEffect, useMemo, useState } from "react";
import { ReactComponent as UserAddIcon } from "../../assets/icons/user_add.svg";
import DetermineIcon from "./determineProviderIcon";
import {
  IUser,
  IUserProvider,
  IUserRole,
  IUserStatus,
} from "../../redux/user/user";
import UsersTableButtons, { IUserTableActions } from "./usersTableButtons";
import AddUserModal, {
  IUsersMetaAddUserModalPayload,
} from "./add-user-modal/addUserModal";
import { IProfilePasswordModalAction } from "./types";
import DeleteModal from "./delete-modal/deleteModal";
import UpdateStatusModal from "./update-status-modal/updateStatusModal";
import InviteModal from "./invite-modal/inviteModal";
import RoleModal from "./role-modal/roleModal";
import { useAppSelector } from "../../redux/hooks";
import { selectUserEmail, selectUserRole } from "../../redux/user/selectors";
import GenericNotFound from "../../components/ui/generic-not-found/GenericNotFound";
import InlineLoader from "../../components/ui/inline-loader/InlineLoader";
import PayeesModal from "./payees-modal/payeesModal";
import { ICompany, IPayeeAccounts } from "../../redux/companies/companies";
import { useQueryCompanies } from "redux/hooks/useQueryCompanies";
import { useQueryUsers } from "../../redux/hooks/useQueryUsers";
import { useMutationAddUser } from "../../redux/hooks/useMutationAddUser";
import { useMutationDeleteUser } from "../../redux/hooks/useMutationDeleteUser";
import { useQuerySendInvite } from "../../redux/hooks/useQuerySendInvite";
import { useMutationAddEntitiesToUser } from "../../redux/hooks/useMutationAddEntitiesToUser";
import { useMutationRemoveEntitiesFromUser } from "../../redux/hooks/useMutationRemoveEntitiesFromUser";
import { useMutationUpdateUserRole } from "../../redux/hooks/useMutationUpdateUserRole";
import UsersSearch from "./search/users-search";
import UsersHeader from "./header/users-header";
import HistoryPagination from "../history/pagination/pagination";
import { HISTORY_RESULTS_PER_PAGE } from "../history/history";
import { ReactComponent as SortArrowIcon } from "../../assets/icons/arrow-down-long.svg";
import AdvancedSearch from "../history/advanced-search/advanced-search";
import HistoryMultiSelect from "../history/history-multiselect/history-multiselect";
import { ReactComponent as CheckedIcon } from "../../assets/icons/checked.svg";
import { ReactComponent as TrashIcon } from "../../assets/icons/trash.svg";
import useActionsQueryHook from "./query/useActionsQuery.hook";
import useQuery from "../../tools/hooks/useQuery.hook";
import { useNavigate } from "react-router-dom";
import { useQueryGetUser } from "../../redux/hooks/useQueryGetUser";
import { useMutationUpdateUserStatus } from "../../redux/hooks/useMutationUpdateUserStatus";
import { useMutationExportAllUsers } from "redux/hooks/useMutationExportAllUsers";
import UpdateUserModal from "./update-user-modal/updateUserModal";
import { useMutationUpdateUsername } from "redux/hooks/useMutationUpdateUsername";

export interface IUsersQueryFilters {
  page: number;
  searchTerm: string;
  order: string;
  orderColumn: string;
  status: string;
  role: string;
  provider: string;
  company: string;
}

export const IUsersQueryInitialState: IUsersQueryFilters = {
  page: 1,
  searchTerm: "",
  order: "ASC",
  orderColumn: "name",
  status: "",
  role: "",
  provider: "",
  company: "",
};

export const USERS_STATUS_DEFAULT_OPTIONS = [
  {
    name: IUserStatus.DISABLED,
    isSelected: false,
  },
  {
    name: IUserStatus.ACTIVATED,
    isSelected: false,
  },
  {
    name: IUserStatus.PENDING,
    isSelected: false,
  },
];

export const USERS_ROLE_DEFAULT_OPTIONS = [
  {
    name: IUserRole.admin,
    isSelected: false,
  },
  {
    name: IUserRole.user,
    isSelected: false,
  },
  {
    name: IUserRole.supervisor,
    isSelected: false,
  },
];

export const USERS_PROVIDER_DEFAULT_OPTIONS = [
  {
    name: IUserProvider.DEFAULT,
    isSelected: false,
  },
  {
    name: IUserProvider.MICROSOFT,
    isSelected: false,
  },
];

export const USERS_COMPANY_DEFAULT_OPTIONS = [
  {
    name: "Uber",
    isSelected: false,
  },
];

export default function Users() {
  const params = useQuery();

  const { action, uuid } = useActionsQueryHook(params);

  const [queryFilters, setQueryFilters] = useState<IUsersQueryFilters>({
    ...IUsersQueryInitialState,
  });

  const [searchTerm, setSearchTerm] = useState("");
  const [statusSelections, setStatusSelection] = useState(
    USERS_STATUS_DEFAULT_OPTIONS
  );
  const [rolesSelections, setRolesSelection] = useState(
    USERS_ROLE_DEFAULT_OPTIONS
  );
  const [providerSelections, setProviderSelection] = useState(
    USERS_PROVIDER_DEFAULT_OPTIONS
  );
  const [companySelections, setCompanySelection] = useState<
    { name: string; isSelected: boolean }[]
  >([]);

  const [advSearchCollapsed, setAdvSearchCollapsed] = useState(false);

  const [isAppUpdating, setIsAppUpdating] = useState(false);

  const [isStatusModalVisible, setStatusModalVisibility] = useState(false);
  const [emailToDeactivate, setEmailToDeactivate] = useState("");

  const [isRoleModalVisible, setRoleModalVisibility] = useState(false);
  const [roleToCheck, setRoleToCheck] = useState<IUserRole | undefined>(
    undefined
  );
  const [statusToCheck, setStatusToCheck] = useState<IUserStatus | undefined>(
    undefined
  );

  const [isInviteModalVisible, setIsInviteModalVisibility] = useState(false);
  const [isPayeesModalVisible, setIsPayeesModalVisible] = useState(false);
  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
  const [isAddUserModalVisible, setAddUserModalVisibility] = useState(false);
  const [isUpdateUserModalVisible, setIsUpdateUserModalVisible] =
    useState(false);

  const { mutate, isLoading } = useMutationExportAllUsers();

  const [mobileIdCollapsed, setMobileIdCollapsed] = useState("");

  const areFiltersChanged = useMemo(() => {
    const sameStatuses = !statusSelections
      .filter((item) => item.isSelected)
      .every((item) => queryFilters.status.includes(item.name));
    const sameRoles = !rolesSelections
      .filter((item) => item.isSelected)
      .every((item) => queryFilters.role.includes(item.name));
    const sameProvider = !providerSelections
      .filter((item) => item.isSelected)
      .every((item) => queryFilters.provider.includes(item.name));
    const sameCompany = !companySelections
      .filter((item) => item.isSelected)
      .every((item) => queryFilters.provider.includes(item.name));

    return sameStatuses || sameRoles || sameProvider || sameCompany;
  }, [
    providerSelections,
    queryFilters.provider,
    queryFilters.role,
    queryFilters.status,
    rolesSelections,
    statusSelections,
    companySelections,
  ]);

  const areFiltersSet = useMemo(() => {
    if (!queryFilters) {
      return false;
    }

    return (
      queryFilters.provider.length > 0 ||
      queryFilters.role.length > 0 ||
      queryFilters.status.length > 0 ||
      queryFilters.searchTerm.length > 0
    );
  }, [queryFilters]);

  const currentUserEmail = useAppSelector(selectUserEmail);

  const [selectedUser, setSelectedUser] = useState<IUser | undefined>();
  const [company, setSelectedCompany] = useState<ICompany | undefined>();
  const [payees, setSelectedPayees] = useState<IPayeeAccounts[] | undefined>();

  const role = useAppSelector(selectUserRole);

  const { mutateAsync: addUserMutation } = useMutationAddUser();

  const { mutate: deleteUserMutation } = useMutationDeleteUser();

  const { mutate: sendUserInviteMutation } = useQuerySendInvite();

  const { mutate: updateUsernameMutation } = useMutationUpdateUsername();

  const {
    data: companiesData,
    isLoading: areCompaniesLoading,
    error: companiesDataError,
  } = useQueryCompanies(role !== undefined && role === IUserRole.admin);

  useEffect(() => {
    if (companiesData && companiesData.data) {
      setCompanySelection(
        companiesData.data.data.map((item) => ({
          name: item.name,
          isSelected: false,
        }))
      );
    }
  }, [companiesData]);

  const {
    data: usersData,
    isLoading: areUsersDataLoading,
    isRefetching: areUsersRefetching,
    error: usersDataError,
  } = useQueryUsers(role !== undefined && role === IUserRole.admin, {
    ...queryFilters,
  });

  const { mutate: removeEntitiesFromUser } =
    useMutationRemoveEntitiesFromUser();

  const { mutate: addEntitiesToUserMutation } = useMutationAddEntitiesToUser();

  const { mutate: updateUserRoleMutation } = useMutationUpdateUserRole();

  const { mutate: updateUserStatusMutation } = useMutationUpdateUserStatus();

  const { data: userData, isInitialLoading: isUserDataLoading = false } =
    useQueryGetUser(
      action !== null &&
        action === "payees" &&
        uuid !== null &&
        uuid.length > 0,
      uuid || ""
    );

  const navigate = useNavigate();

  useEffect(() => {
    if (userData && !isUserDataLoading && usersData && uuid && action) {
      setEmailToDeactivate(userData.data.data.email || "");
      setSelectedUser({ ...(userData.data.data as IUser) });
      setSelectedCompany(
        userData && userData.data.data.company
          ? userData.data.data.company
          : undefined
      );
      setSelectedPayees(
        userData &&
          userData.data.data.payees &&
          userData.data.data.payees.length > 0
          ? userData.data.data.payees.map((item) => item)
          : undefined
      );

      setIsPayeesModalVisible(true);

      navigate("");
    }
  }, [action, isUserDataLoading, navigate, userData, usersData, uuid]);

  const getAvailableRoles = useMemo(() => {
    if (!emailToDeactivate || !usersData) {
      return [];
    }

    const currentUser = usersData.data.data.data.find(
      (user) => user.email === emailToDeactivate
    );

    if (!currentUser) {
      return [];
    }

    return currentUser.provider === IUserProvider.MICROSOFT
      ? [
          IUserRole.user,
          //  IUserRole.contributor,
          IUserRole.supervisor,
          IUserRole.admin,
        ]
      : [
          IUserRole.user,
          //IUserRole.contributor,
          IUserRole.supervisor,
        ];
  }, [emailToDeactivate, usersData]);

  if (usersDataError || companiesDataError) {
    return (
      <div className="users">
        <GenericNotFound hideLogo={true} />
      </div>
    );
  }

  return (
    <div className="users">
      <h1 className="users__title">Manage Users</h1>

      <UsersHeader>
        <div className="users__search-wrapper">
          <div className="users__search--meta">
            <UsersSearch
              isDisabled={
                areUsersRefetching || areCompaniesLoading || isUserDataLoading
              }
              requestChange={(event) => {
                return setSearchTerm(event);
              }}
              requestSubmit={(event) => {
                return setQueryFilters({
                  ...queryFilters,
                  searchTerm: event,
                  order: "ASC",
                  orderColumn: "name",
                  page: 1,
                });
              }}
              value={searchTerm}
            />

            <AdvancedSearch
              isCollapsed={advSearchCollapsed}
              requestCollapse={setAdvSearchCollapsed}
            />

            <button
              type="button"
              className={`btn--reset users-clear-filters history-clear-filters ${
                areFiltersSet ? "history-clear-filters--active" : ""
              }`}
              onClick={() => {
                setSearchTerm("");
                setProviderSelection(
                  providerSelections.map((item) => ({
                    ...item,
                    isSelected: false,
                  }))
                );
                setRolesSelection(
                  rolesSelections.map((item) => ({
                    ...item,
                    isSelected: false,
                  }))
                );
                setStatusSelection(
                  statusSelections.map((item) => ({
                    ...item,
                    isSelected: false,
                  }))
                );
                setCompanySelection(
                  companySelections.map((item) => ({
                    ...item,
                    isSelected: false,
                  }))
                );

                return setQueryFilters(() => ({
                  ...IUsersQueryInitialState,
                }));
              }}
            >
              <TrashIcon />

              <span className="users-clear-filters__content">
                Clear Filters
              </span>
            </button>
          </div>

          <div
            className={`adv-search-container ${
              advSearchCollapsed ? "adv-search-container--opened" : ""
            }`}
          >
            <HistoryMultiSelect
              isDisabled={
                areCompaniesLoading ||
                areUsersDataLoading ||
                areUsersRefetching ||
                isUserDataLoading
              }
              customClass={"users-search-multiselect"}
              label="User Status"
              requestChange={(event) => {
                setStatusSelection(
                  statusSelections.map((item) => ({
                    ...item,
                    isSelected:
                      item.name === event.name
                        ? event.isSelected || false
                        : item.isSelected,
                  }))
                );
              }}
              requestClearAll={() => {
                setQueryFilters({
                  ...queryFilters,
                  page: 1,
                  status: "",
                });

                setStatusSelection(
                  statusSelections.map((item) => ({
                    ...item,
                    isSelected: false,
                  }))
                );
              }}
              placeholder={"Select User Status"}
              options={statusSelections}
            />

            <HistoryMultiSelect
              isDisabled={
                areCompaniesLoading ||
                areUsersDataLoading ||
                areUsersRefetching ||
                isUserDataLoading
              }
              customClass={"users-search-multiselect"}
              label="Role Status"
              requestChange={(event) => {
                setRolesSelection(
                  rolesSelections.map((item) => ({
                    ...item,
                    isSelected:
                      item.name === event.name
                        ? event.isSelected || false
                        : item.isSelected,
                  }))
                );
              }}
              requestClearAll={() => {
                setQueryFilters({
                  ...queryFilters,
                  page: 1,
                  role: "",
                });

                setRolesSelection(
                  rolesSelections.map((item) => ({
                    ...item,
                    isSelected: false,
                  }))
                );
              }}
              placeholder={"Select User Role(s)"}
              options={rolesSelections}
            />

            <HistoryMultiSelect
              isDisabled={
                areCompaniesLoading ||
                areUsersDataLoading ||
                areUsersRefetching ||
                isUserDataLoading
              }
              customClass={"users-search-multiselect"}
              label="Provider"
              requestChange={(event) => {
                setProviderSelection(
                  providerSelections.map((item) => ({
                    ...item,
                    isSelected:
                      item.name === event.name
                        ? event.isSelected || false
                        : item.isSelected,
                  }))
                );
              }}
              requestClearAll={() => {
                setQueryFilters({
                  ...queryFilters,
                  page: 1,
                  provider: "",
                });

                setProviderSelection(
                  providerSelections.map((item) => ({
                    ...item,
                    isSelected: false,
                  }))
                );
              }}
              placeholder={"Select Role(s)"}
              options={providerSelections}
            />

            <HistoryMultiSelect
              isDisabled={
                areCompaniesLoading ||
                areUsersDataLoading ||
                areUsersRefetching ||
                isUserDataLoading
              }
              customClass={"users-search-multiselect"}
              label="Company"
              requestChange={(event) => {
                setCompanySelection(
                  companySelections.map((item) => ({
                    ...item,
                    isSelected:
                      item.name === event.name
                        ? event.isSelected || false
                        : item.isSelected,
                  }))
                );
              }}
              requestClearAll={() => {
                setQueryFilters({
                  ...queryFilters,
                  page: 1,
                  company: "",
                });

                setCompanySelection(
                  companySelections.map((item) => ({
                    ...item,
                    isSelected: false,
                  }))
                );
              }}
              placeholder={"Select Company(s)"}
              options={companySelections}
            />

            <button
              type="button"
              disabled={
                areCompaniesLoading ||
                areUsersDataLoading ||
                areUsersRefetching ||
                isUserDataLoading
              }
              className={`history-apply-filters btn ${
                areFiltersChanged ? "history-apply-filters--active" : ""
              }`}
              onClick={() => {
                const status = statusSelections.some((item) => item.isSelected)
                  ? statusSelections
                      .filter((item) => item.isSelected)
                      .map((item) => item.name)
                      .join(",")
                  : "";
                const provider = providerSelections.some(
                  (item) => item.isSelected
                )
                  ? providerSelections
                      .filter((item) => item.isSelected)
                      .map((item) => item.name)
                      .join(",")
                  : "";
                const role = rolesSelections.some((item) => item.isSelected)
                  ? rolesSelections
                      .filter((item) => item.isSelected)
                      .map((item) => item.name)
                      .join(",")
                  : "";

                const company = companySelections.some(
                  (item) => item.isSelected
                )
                  ? companySelections
                      .filter((item) => item.isSelected)
                      .map((item) => item.name)
                      .join(",")
                  : "";

                setQueryFilters({
                  ...queryFilters,
                  order: "ASC",
                  orderColumn: "name",
                  page: 1,
                  status,
                  provider,
                  role,
                  company,
                });
              }}
            >
              <CheckedIcon /> Apply Filters
            </button>
          </div>
        </div>

        <div
          style={{
            display: "flex",
            gap: "10px",
            position: "absolute",
            right: "0",
          }}
        >
          <button
            type="button"
            className="btn excel-btn"
            disabled={
              !usersData || !usersData.data.data.data.length || isLoading
            }
            onClick={handleExport}
          >
            Export All Users
          </button>
          <button
            type="button"
            className="btn btn--default users__header--action btn--short d-flex align-items-center"
            disabled={
              areCompaniesLoading ||
              areUsersDataLoading ||
              areUsersRefetching ||
              isUserDataLoading
            }
            onClick={() => setAddUserModalVisibility(!isAddUserModalVisible)}
          >
            <UserAddIcon
              height="20"
              width="20"
              style={{ marginRight: ".5em" }}
            />

            <span className="users__header--action-content">Add User</span>
          </button>
        </div>

        <AddUserModal
          existingUsers={usersData ? usersData.data.data.data : []}
          isVisible={isAddUserModalVisible}
          onToggle={(state) => setAddUserModalVisibility(state)}
          requestClick={handleAddUserClick}
        />
      </UsersHeader>

      <div className="users__table-wrapper">
        <div
          className={`users__table-loader ${
            areUsersRefetching ? "users__table-loader--visible" : ""
          }`}
        >
          <InlineLoader />
        </div>

        <table
          className={`users__table users__table--regular users__table--admin ${
            areUsersRefetching ? "users__table--loading" : ""
          }`}
          cellPadding={0}
          cellSpacing={0}
        >
          <thead className="users__thead history__thead">
            <tr className="users__tr">
              <th className="users__cell users__cell--head users__cell--username">
                <div className="users__entry">
                  <button
                    type="button"
                    className="btn history-sort-btn"
                    onClick={() => {
                      setQueryFilters({
                        ...queryFilters,
                        order: queryFilters.order === "ASC" ? "DESC" : "ASC",
                        orderColumn: "name",
                      });
                    }}
                    title="Sort by invoice number"
                  >
                    <span className="visually-hidden">
                      Sort by invoice number
                    </span>
                    Name
                    <SortArrowIcon
                      width="5"
                      height="12"
                      className={
                        "is-" +
                        (queryFilters.orderColumn === "name" &&
                        queryFilters.order === "ASC"
                          ? "ascending"
                          : "descending")
                      }
                    />
                  </button>
                </div>
              </th>

              <th className="users__cell users__cell--head users__cell--email">
                <div className="users__entry">
                  <button
                    type="button"
                    className="btn history-sort-btn"
                    onClick={() => {
                      setQueryFilters({
                        ...queryFilters,
                        order: queryFilters.order === "ASC" ? "DESC" : "ASC",
                        orderColumn: "email",
                      });
                    }}
                    title="Sort by invoice number"
                  >
                    <span className="visually-hidden">
                      Sort by invoice number
                    </span>
                    Email Address
                    <SortArrowIcon
                      width="5"
                      height="12"
                      className={
                        "is-" +
                        (queryFilters.orderColumn === "email" &&
                        queryFilters.order === "ASC"
                          ? "ascending"
                          : "descending")
                      }
                    />
                  </button>
                </div>
              </th>

              <th className="users__cell users__cell--head users__cell--status">
                <div className="users__entry">
                  <button
                    type="button"
                    className="btn history-sort-btn"
                    onClick={() => {
                      setQueryFilters({
                        ...queryFilters,
                        order: queryFilters.order === "ASC" ? "DESC" : "ASC",
                        orderColumn: "status",
                      });
                    }}
                    title="Sort by invoice number"
                  >
                    <span className="visually-hidden">
                      Sort by invoice number
                    </span>
                    Status
                    <SortArrowIcon
                      width="5"
                      height="12"
                      className={
                        "is-" +
                        (queryFilters.orderColumn === "status" &&
                        queryFilters.order === "ASC"
                          ? "ascending"
                          : "descending")
                      }
                    />
                  </button>
                </div>
              </th>

              <th className="users__cell users__cell--head users__cell--provider">
                <div className="users__entry">
                  <button
                    type="button"
                    className="btn history-sort-btn"
                    onClick={() => {
                      setQueryFilters({
                        ...queryFilters,
                        order: queryFilters.order === "ASC" ? "DESC" : "ASC",
                        orderColumn: "provider",
                      });
                    }}
                    title="Sort by invoice number"
                  >
                    <span className="visually-hidden">
                      Sort by invoice number
                    </span>
                    Provider
                    <SortArrowIcon
                      width="5"
                      height="12"
                      className={
                        "is-" +
                        (queryFilters.orderColumn === "provider" &&
                        queryFilters.order === "ASC"
                          ? "ascending"
                          : "descending")
                      }
                    />
                  </button>
                </div>
              </th>

              <th className="users__cell users__cell--head users__cell--role">
                <div className="users__entry">
                  <button
                    type="button"
                    className="btn history-sort-btn"
                    onClick={() => {
                      setQueryFilters({
                        ...queryFilters,
                        order: queryFilters.order === "ASC" ? "DESC" : "ASC",
                        orderColumn: "role",
                      });
                    }}
                    title="Sort by invoice number"
                  >
                    <span className="visually-hidden">
                      Sort by invoice number
                    </span>
                    Role
                    <SortArrowIcon
                      width="5"
                      height="12"
                      className={
                        "is-" +
                        (queryFilters.orderColumn === "role" &&
                        queryFilters.order === "ASC"
                          ? "ascending"
                          : "descending")
                      }
                    />
                  </button>
                </div>
              </th>

              <th className="users__cell users__cell--head users__cell--company">
                <div className="users__entry">
                  <button
                    type="button"
                    className="btn history-sort-btn"
                    onClick={() => {
                      setQueryFilters({
                        ...queryFilters,
                        order: queryFilters.order === "ASC" ? "DESC" : "ASC",
                        orderColumn: "company",
                      });
                    }}
                    title="Sort by invoice number"
                  >
                    <span className="visually-hidden">
                      Sort by invoice number
                    </span>
                    Company
                    <SortArrowIcon
                      width="5"
                      height="12"
                      className={
                        "is-" +
                        (queryFilters.orderColumn === "company" &&
                        queryFilters.order === "ASC"
                          ? "ascending"
                          : "descending")
                      }
                    />
                  </button>
                </div>
              </th>

              <th className="users__cell users__cell--head users__cell--payee">
                <div className="users__entry">
                  <button
                    type="button"
                    className="btn history-sort-btn"
                    onClick={() => {
                      setQueryFilters({
                        ...queryFilters,
                        order: queryFilters.order === "ASC" ? "DESC" : "ASC",
                        orderColumn: "payees",
                      });
                    }}
                    title="Sort by invoice number"
                  >
                    <span className="visually-hidden">
                      Sort by invoice number
                    </span>
                    Payees
                    <SortArrowIcon
                      width="5"
                      height="12"
                      className={
                        "is-" +
                        (queryFilters.orderColumn === "payees" &&
                        queryFilters.order === "ASC"
                          ? "ascending"
                          : "descending")
                      }
                    />
                  </button>
                </div>
              </th>

              <th className="users__cell users__cell--head users__cell--action">
                <div className="users__entry">Actions</div>
              </th>
            </tr>
          </thead>

          <tbody className="users__tbody">
            {usersData !== undefined && usersData.data.data.data.length > 0 ? (
              usersData.data.data.data.map((user) => (
                <tr
                  key={user.uuid}
                  className={`users__tr ${
                    mobileIdCollapsed === user.uuid
                      ? "users__tr--collapsed"
                      : ""
                  }`}
                >
                  <td
                    className="users__cell"
                    onClick={() => {
                      return setMobileIdCollapsed(
                        user.uuid === mobileIdCollapsed ? "" : user.uuid || ""
                      );
                    }}
                  >
                    <div
                      className="users__entry users__entry--small"
                      title={user.name as string}
                    >
                      {user.name}
                    </div>
                  </td>

                  <td
                    className="users__cell users__cell--email"
                    data-cell-name="Email Address"
                  >
                    <div
                      className="users__entry  users__entry--small"
                      title={user.email as string}
                    >
                      {user.email}
                    </div>
                  </td>

                  <td className="users__cell" data-cell-name="Status">
                    <div className="users__entry">
                      <span
                        className={`users__entry--status users__entry--status-${user.status}`}
                        title={user.status as string}
                      >
                        {user.status}
                      </span>
                    </div>
                  </td>

                  <td className="users__cell" data-cell-name="Provider">
                    <div className="users__entry">
                      <span
                        className={`users__entry--provider users__entry--provider-${user.provider}`}
                        title={user.provider as string}
                      >
                        <DetermineIcon type={user.provider} />

                        {user.provider}
                      </span>
                    </div>
                  </td>

                  <td className="users__cell" data-cell-name="Role">
                    <div className="users__entry">
                      <span
                        className={`users__entry--status users__entry--status-${user.role}`}
                      >
                        {user.role}
                      </span>
                    </div>
                  </td>

                  <td className="users__cell" data-cell-name="Company">
                    <div className="users__entry">
                      {user.role === IUserRole.admin ? (
                        <strong>N/A</strong>
                      ) : (
                        <strong>{user.company?.name || "N/A"}</strong>
                      )}
                    </div>
                  </td>

                  <td className="users__cell" data-cell-name="Payee Accounts">
                    <div className="users__entry">
                      {user.role === IUserRole.admin
                        ? "N/A"
                        : user.payees && user.payees.length
                        ? user.payees.map((item) => item.name).join(", ")
                        : "N/A"}
                    </div>
                  </td>

                  <td className="users__cell">
                    <div className="users__entry users__entry--actions">
                      <UsersTableButtons
                        isRoleDisabled={
                          isAppUpdating ||
                          user.role === IUserRole.admin ||
                          user.status !== IUserStatus.ACTIVATED
                        }
                        isDeactivateDisabled={
                          isAppUpdating ||
                          user.role === IUserRole.admin ||
                          (user.status !== IUserStatus.ACTIVATED &&
                            user.status !== IUserStatus.DISABLED)
                        }
                        isDeleteDisabled={
                          isAppUpdating || user.role === IUserRole.admin
                        }
                        isInviteDisabled={
                          isAppUpdating || user.status !== IUserStatus.PENDING
                        }
                        isPayeeDisabled={
                          isAppUpdating || user.role === IUserRole.admin
                          //|| user.status !== IUserStatus.ACTIVATED
                        }
                        isUpdateDisabled={
                          isAppUpdating ||
                          user.provider === IUserProvider.MICROSOFT
                        }
                        requestClick={(event) => {
                          if (!user.email || !user.role) {
                            return;
                          }

                          return handleTableAction(
                            event,
                            user.email,
                            user.role,
                            user.status as IUserStatus
                          );
                        }}
                      />
                    </div>
                  </td>
                </tr>
              ))
            ) : (
              <tr
                className={`users__tr ${
                  areFiltersSet && !areUsersRefetching
                    ? "users__tr--no-data"
                    : "users__tr--loading"
                }`}
              >
                <td colSpan={8}>
                  <div className="history-no-results">
                    {areCompaniesLoading ||
                    areUsersDataLoading ||
                    isUserDataLoading ? (
                      <InlineLoader />
                    ) : areFiltersSet && !areUsersRefetching ? (
                      "No results found for current search criteria"
                    ) : (
                      "No data available"
                    )}
                  </div>
                </td>
              </tr>
            )}
          </tbody>
        </table>

        {usersData !== undefined && usersData.data.data.data.length > 0 && (
          <HistoryPagination
            customClass="users-pagination"
            disabled={
              areCompaniesLoading ||
              areUsersDataLoading ||
              areUsersRefetching ||
              isUserDataLoading
            }
            totalCount={usersData ? usersData.data.data.totalResults : 0}
            pageSize={HISTORY_RESULTS_PER_PAGE}
            siblingCount={1}
            currentPage={usersData ? usersData.data.data.activePage : 0}
            requestClick={(event) => {
              setQueryFilters({
                ...queryFilters,
                page: event,
              });
            }}
          />
        )}
      </div>

      <DeleteModal
        requestClick={handleDeleteAccount}
        emailToCheck={emailToDeactivate}
        isVisible={isDeleteModalVisible}
      />

      <UpdateStatusModal
        requestClick={handleUpdateStatus}
        emailToCheck={emailToDeactivate}
        isVisible={isStatusModalVisible}
        statusToCheck={statusToCheck}
      />

      <InviteModal
        requestClick={handleInviteAccount}
        emailToCheck={emailToDeactivate}
        isVisible={isInviteModalVisible}
      />

      <RoleModal
        isVisible={isRoleModalVisible}
        availableRoles={getAvailableRoles}
        emailToCheck={emailToDeactivate}
        roleToCheck={roleToCheck}
        requestClick={handleRoleChangeAccount}
      />

      <PayeesModal
        defaultPayees={payees}
        defaultCompany={company}
        user={selectedUser}
        isVisible={isPayeesModalVisible}
        companies={companiesData ? companiesData.data.data : []}
        requestClick={handlePayeeModalActions}
      />

      <UpdateUserModal
        isVisible={isUpdateUserModalVisible}
        user={selectedUser}
        requestClick={handleUpdateUserModalActions}
      />
    </div>
  );

  async function handleUpdateUserModalActions(
    event: IProfilePasswordModalAction,
    payload: any
  ) {
    const email = emailToDeactivate;
    const newUsername = payload ? payload.newUsername : "";

    const currentInviteUserId = usersData?.data.data.data.find(
      (user) => user.email === email
    );
    const uuid = currentInviteUserId ? currentInviteUserId.uuid : undefined;

    setEmailToDeactivate("");
    setRoleToCheck(undefined);
    setIsUpdateUserModalVisible(false);

    await new Promise((resolve) => {
      setTimeout(() => {
        resolve(true);
      }, 250);
    });

    setEmailToDeactivate("");
    setSelectedPayees(undefined);
    setSelectedCompany(undefined);
    setSelectedUser(undefined);

    switch (event) {
      case IProfilePasswordModalAction.SAVE:
        return updateUsernameMutation({
          email,
          uuid,
          newUsername,
        });
    }
  }

  async function handlePayeeModalActions(
    event: IProfilePasswordModalAction,
    payload: any
  ) {
    const email = emailToDeactivate;

    setIsPayeesModalVisible(false);

    await new Promise((resolve) => {
      setTimeout(() => {
        resolve(true);
      }, 250);
    });

    setEmailToDeactivate("");
    setSelectedPayees(undefined);
    setSelectedCompany(undefined);
    setSelectedUser(undefined);

    switch (event) {
      case IProfilePasswordModalAction.SAVE:
        return handleSaveEntities({
          ...payload,
          email,
        });

      case IProfilePasswordModalAction.DELETE:
        return removeEntitiesFromUser({ email });
    }
  }

  async function handleSaveEntities({
    companyId,
    payees,
    email,
  }: {
    companyId: string | undefined;
    payees: IPayeeAccounts[] | [];
    email: string;
  }) {
    if (!companiesData || companyId === undefined) {
      return;
    }

    const company = companiesData.data.data.find(
      (company) => company.id.toString() === companyId.toString()
    );

    if (!company) {
      return;
    }

    return addEntitiesToUserMutation({
      company,
      email,
      payees,
    });
  }

  async function handleInviteAccount(event: IProfilePasswordModalAction) {
    const email = emailToDeactivate;

    setIsInviteModalVisibility(false);

    await new Promise((resolve) => {
      setTimeout(() => {
        resolve(true);
      }, 250);
    });

    setEmailToDeactivate("");

    if (
      event !== IProfilePasswordModalAction.SAVE ||
      !usersData ||
      !currentUserEmail
    ) {
      return;
    }

    const currentInviteUserId = usersData.data.data.data.find(
      (item) => item.email === email
    );
    const uuid = currentInviteUserId ? currentInviteUserId.uuid : undefined;

    if (!uuid) {
      return;
    }

    return sendUserInviteMutation({
      email,
      uuid,
      inviterEmail: currentUserEmail,
    });
  }

  async function handleRoleChangeAccount(
    event: IProfilePasswordModalAction,
    payload?: string
  ) {
    setRoleModalVisibility(false);

    await new Promise((resolve) => {
      setTimeout(() => {
        resolve(true);
      }, 300);
    });

    const email = emailToDeactivate;
    const role = (payload as IUserRole) || undefined;

    setEmailToDeactivate("");
    setRoleToCheck(undefined);

    switch (event) {
      case IProfilePasswordModalAction.SAVE: {
        const currentInviteUserId = usersData?.data.data.data.find(
          (user) => user.email === email
        );
        const uuid = currentInviteUserId ? currentInviteUserId.uuid : undefined;

        if (!role || !uuid) {
          return console.error("Unable to find user");
        }

        return updateUserRoleMutation({
          email,
          role,
          uuid,
        });
      }
    }
  }

  async function handleDeleteAccount(event: IProfilePasswordModalAction) {
    setIsDeleteModalVisible(false);

    await new Promise((resolve) => {
      setTimeout(() => {
        resolve(true);
      }, 300);
    });

    setEmailToDeactivate("");

    switch (event) {
      case IProfilePasswordModalAction.SAVE: {
        const email = emailToDeactivate;
        const currentInviteUserId = usersData?.data.data.data.find(
          (user) => user.email === email
        );
        const uuid = currentInviteUserId ? currentInviteUserId.uuid || "" : "";

        return deleteUserMutation({
          uuid,
          ...queryFilters,
        });
      }
    }
  }

  async function handleUpdateStatus(
    event: IProfilePasswordModalAction,
    payload?: string
  ) {
    setStatusModalVisibility(false);

    await new Promise((resolve) => {
      setTimeout(() => {
        resolve(true);
      }, 300);
    });

    switch (event) {
      case IProfilePasswordModalAction.SAVE: {
        const email = emailToDeactivate;
        const status = (payload as IUserStatus) || undefined;

        const currentInviteUserId = usersData?.data.data.data.find(
          (user) => user.email === email
        );
        const uuid = currentInviteUserId ? currentInviteUserId.uuid : undefined;

        setEmailToDeactivate("");
        setStatusToCheck(undefined);

        if (!status || !uuid || !status) {
          return console.error("Unable to find user");
        }

        setIsAppUpdating(true);

        await updateUserStatusMutation({
          email,
          uuid,
          status,
          filters: queryFilters,
        });

        setIsAppUpdating(false);
      }
    }
  }

  function handleTableAction(
    event: IUserTableActions,
    email: string,
    role: IUserRole,
    status: IUserStatus
  ) {
    if (!usersData || !email) {
      return;
    }

    setEmailToDeactivate(email);

    switch (event) {
      case IUserTableActions.PAYEE: {
        const currentUsers = usersData.data.data.data.map((item) => item);
        const currentUser = currentUsers.find((item) => item.email === email);

        setSelectedUser({ ...(currentUser as IUser) });
        setSelectedCompany(
          currentUser && currentUser.company ? currentUser.company : undefined
        );
        setSelectedPayees(
          currentUser && currentUser.payees && currentUser.payees.length > 0
            ? currentUser.payees.map((item) => item)
            : undefined
        );

        setIsPayeesModalVisible(true);

        return;
      }

      case IUserTableActions.DEACTIVATE: {
        setStatusToCheck(status);

        return setStatusModalVisibility(true);
      }

      case IUserTableActions.INVITE: {
        return setIsInviteModalVisibility(true);
      }

      case IUserTableActions.ROLE: {
        setRoleToCheck(role);

        return setRoleModalVisibility(true);
      }

      case IUserTableActions.DELETE: {
        return setIsDeleteModalVisible(true);
      }

      case IUserTableActions.UPDATE: {
        const currentUsers = usersData.data.data.data.map((item) => item);
        const currentUser = currentUsers.find((item) => item.email === email);

        setSelectedUser({ ...(currentUser as IUser) });

        return setIsUpdateUserModalVisible(true);
      }

      default:
        return;
    }
  }

  async function handleAddUserClick(
    event: IProfilePasswordModalAction,
    payload?: IUsersMetaAddUserModalPayload
  ) {
    setAddUserModalVisibility(false);

    switch (event) {
      case IProfilePasswordModalAction.SAVE: {
        if (!payload) {
          return;
        }

        const res = await addUserMutation({
          name: payload.userName,
          email: payload.email,
          isMsAccount: payload.isMsAccount,
        });

        if (!payload.sendInvite || !res.data.data.uuid || !currentUserEmail) {
          return;
        }

        return sendUserInviteMutation({
          email: payload.email,
          inviterEmail: currentUserEmail,
          uuid: res.data.data.uuid,
        });
      }

      default: {
        throw new Error("Action not implemented");
      }
    }
  }

  function handleExport() {
    return mutate();
  }
}
