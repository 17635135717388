import { NavLink } from "react-router-dom";
import "./header.nav.scss";
import NavItems from "../../../../redux/static/navItems";
import { useAppSelector } from "../../../../redux/hooks";
import { isPayeeSelected } from "../../../../redux/user/selectors";

export default function HeaderNav() {
  const isPayeeSelectedState = useAppSelector(isPayeeSelected);

  return (
    <nav role="navigation" className="header-navigation">
      <ul className="list d-flex">
        {NavItems.map((item, index) => (
          <li key={item.path}>
            <NavLink
              to={item.path}
              onClick={(event) => {
                if (item.name === "Contact Us") {
                  return;
                }

                if (isPayeeSelectedState || !index) {
                  return;
                }

                return event.preventDefault();
              }}
              className="header-navigation--target"
              style={({ isActive }) => {
                if (item.name === "Contact Us") {
                  return {};
                }

                if (!index) {
                  return {};
                }

                return {
                  pointerEvents: !isPayeeSelectedState ? "none" : "auto",
                  opacity: !isPayeeSelectedState ? "0.4" : "1",
                };
              }}
            >
              <span className="header-navigation__description">
                {item.name}
              </span>
            </NavLink>
          </li>
        ))}
      </ul>
    </nav>
  );
}
