import "./companies.scss";
import { ReactComponent as BuildingIcon } from "../../assets/icons/building.svg";
import AddCompanyModal from "./add-company-modal/add-company-modal";
import { useCallback, useEffect, useState } from "react";
import { IProfilePasswordModalAction } from "../users/types";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { startAddNotification } from "../../redux/core/core";
import { INotificationType } from "../../components/ui/notifications/item/NotificationItem";
import { ICompany, IPayeeAccounts } from "../../redux/companies/companies";
import DeletePayeeModal from "./delete-payee-modal/delete-payee-modal";
import PayeeTable, { IPayeeTableAction } from "./payee-table/payee-table";
import { ReactComponent as AddIcon } from "../../assets/icons/add.svg";
import AddPayeeModal from "./add-payee-modal/add-payee-modal";
import EditPayeeModal from "./edit-payee-modal/edit-payee-modal";
import { ReactComponent as EditIcon } from "../../assets/icons/edit.svg";
import { ReactComponent as DeleteIcon } from "../../assets/icons/trash.svg";
import EditCompanyModal from "./edit-company-modal/edit-company-modal";
import DeleteCompanyModal from "./delete-company-modal/delete-company-modal";
import { IUserRole } from "../../redux/user/user";
import { selectUserRole } from "../../redux/user/selectors";
import { useQueryPayees } from "../../redux/hooks/useQueryPayees";
import { useMutationUpdateCompany } from "../../redux/hooks/useMutationUpdateCompany";
import { useMutationAddCompany } from "../../redux/hooks/useMutationAddCompany";
import { useMutationDeleteCompany } from "../../redux/hooks/useMutationDeleteCompany";
import { useMutationAddPayeeToCompany } from "../../redux/hooks/useMutationAddPayeeToCompany";
import { useMutationUpdatePayee } from "../../redux/hooks/useMutationUpdatePayee";
import { useMutationDeletePayee } from "../../redux/hooks/useMutationDeletePayee";
import { useQueryCompanies } from "../../redux/hooks/useQueryCompanies";
import UsersCompanyModal from "./users-company-modal/users-company-modal";
import { useQueryUsersByPayeeID } from "redux/hooks/useQueryUsersByPayeeID";
import { DropDownSearch } from "../../components/specific/drop-down-search/DropDownSearch";
import { useMutationExportAllCompanies } from "redux/hooks/useMutationExportAllCompanies";

export default function Companies() {
  const [isAcmModalVisible, setIsAcmModalVisible] = useState(false);
  const [isPayeeAddModalVisible, setIsPayeeAddModalVisible] = useState(false);
  const [isEditCompanyModalVisible, setIsEditCompanyModalVisible] =
    useState(false);
  const [isDeleteCompanyModalVisible, setIsDeleteCompanyModalVisible] =
    useState(false);
  const [deletePayeeModalVisible, setDeletePayeeModalVisible] = useState(false);
  const [editPayeeModalVisible, setEditPayeeModalVisible] = useState(false);
  const [usersCompanyModalVisible, setUsersCompanyModalVisible] =
    useState(false);
  const role = useAppSelector(selectUserRole);

  const dispatchStore = useAppDispatch();

  const [selectedCompany, setSelectedCompany] = useState<ICompany | undefined>(
    undefined
  );
  const [selectedPayee, setSelectedPayee] = useState<
    IPayeeAccounts | undefined
  >(undefined);

  const {
    data: companiesData,
    isLoading: areCompaniesLoading,
    error: companiesError,
  } = useQueryCompanies(role !== undefined && role === IUserRole.admin);

  const {
    data: payeesData,
    isLoading: arePayeesLoading,
    error: payeesError,
  } = useQueryPayees(
    selectedCompany ? selectedCompany.id : 0,
    selectedCompany !== undefined
  );

  const {
    data: usersData,
    isLoading: areCompanyUsersLoading,
    error: companyUsersError,
  } = useQueryUsersByPayeeID(
    selectedPayee !== undefined,
    selectedPayee?.id.toString() || ""
  );

  const { mutate: updateCompanyMutation } = useMutationUpdateCompany();

  const { mutate: addCompanyMutation } = useMutationAddCompany();

  const { mutate: deleteCompanyMutation } = useMutationDeleteCompany();

  const { mutate: addPayeeToCompanyMutation } = useMutationAddPayeeToCompany();

  const { mutate: updatePayeeMutation } = useMutationUpdatePayee();

  const { mutate: deletePayeeMutation } = useMutationDeletePayee();

  const { mutate, isLoading } = useMutationExportAllCompanies();

  const companiesPlaceholder = useCallback(() => {
    if (areCompaniesLoading) {
      return "Loading companies";
    }

    if (!companiesData || companiesError) {
      return "No companies available";
    }

    return "Select Company";
  }, [areCompaniesLoading, companiesData, companiesError]);

  useEffect(() => {
    if (payeesError) {
      dispatchStore(
        startAddNotification({
          title: "Error",
          description: `Unable to retrieve payee accounts`,
          type: INotificationType.ERROR,
        })
      );
    }

    if (companiesError) {
      dispatchStore(
        startAddNotification({
          title: "Error",
          description: `Unable to retrieve companies`,
          type: INotificationType.ERROR,
        })
      );
    }

    if (companyUsersError) {
      dispatchStore(
        startAddNotification({
          title: "Error",
          description: `Unable to retrieve company users`,
          type: INotificationType.ERROR,
        })
      );
    }
  }, [companiesError, dispatchStore, payeesError, companyUsersError]);

  return (
    <div className="companies users">
      <h1 className="users__title">Manage Companies</h1>

      <header className="users__header d-flex justify-content-space-between">
        {!areCompaniesLoading &&
        (!companiesData || !companiesData.data.data.length) ? (
          <strong>No companies available at this point in time.</strong>
        ) : null}

        <div className="acm-wrapper d-flex">
          {/*{*/}
          {/*  companiesData && companiesData.data.data.length > 0 &&*/}
          {/*    <Select id={'role_select'}*/}
          {/*      isDisabled={areCompaniesLoading}*/}
          {/*      customClassName={'gl-ref-select'}*/}
          {/*      placeholder={companiesPlaceholder()}*/}
          {/*      requestChange={handleItemSelection}*/}
          {/*      data={companiesData ? companiesData.data.data.map(({id, name}) => ({*/}
          {/*        id: id.toString(),*/}
          {/*        content: name*/}
          {/*      })) : []} />*/}
          {/*}*/}

          {companiesData && companiesData.data.data.length > 0 && (
            <DropDownSearch
              isDisabled={areCompaniesLoading}
              requestClick={handleItemSelection}
              requestClearSelection={handleClearSelection}
              data={companiesData ? companiesData.data.data : []}
              id={"drop-down-search"}
            />
          )}

          {selectedCompany &&
            selectedCompany.name &&
            selectedCompany.name.length > 0 && (
              <>
                <button
                  type="button"
                  className="btn btn--reset users__action users__action--role"
                  disabled={arePayeesLoading}
                  title="Edit company profile"
                  onClick={() => {
                    setIsEditCompanyModalVisible(true);
                  }}
                >
                  <EditIcon height="16" width="16" />
                </button>

                <button
                  type="button"
                  className="btn btn--reset users__action users__action--delete"
                  disabled={arePayeesLoading}
                  title="Remove payee account"
                  onClick={() => setIsDeleteCompanyModalVisible(true)}
                >
                  <DeleteIcon height="20" width="20" />
                </button>

                <EditCompanyModal
                  isVisible={isEditCompanyModalVisible}
                  existingCompanies={
                    companiesData ? companiesData.data.data : []
                  }
                  onToggle={() => setIsEditCompanyModalVisible(false)}
                  name={selectedCompany?.name || ""}
                  vendorId={selectedCompany?.vendorId || ""}
                  id={selectedCompany?.id || 0}
                  related={selectedCompany?.related || false}
                  overhead={selectedCompany?.overhead || false}
                  requestClick={handleCompanyEditActions}
                />

                <DeleteCompanyModal
                  isVisible={isDeleteCompanyModalVisible}
                  company={selectedCompany}
                  onToggle={() => setIsDeleteCompanyModalVisible(false)}
                  requestClick={handleDeleteCompanyActions}
                />
              </>
            )}
        </div>
        <div style={{ display: "flex", gap: "10px" }}>
          <button
            type="button"
            className="btn  excel-btn  btn--short "
            disabled={
              !companiesData || !companiesData.data.data.length || isLoading
            }
            onClick={handleExport}
          >
            Export All Companies
          </button>
          <button
            type="button"
            className="btn btn--default btn--short d-flex align-items-center add-company-btn"
            disabled={
              areCompaniesLoading ||
              (selectedCompany !== undefined ? arePayeesLoading : false)
            }
            onClick={() => setIsAcmModalVisible(true)}
          >
            <BuildingIcon
              height="20"
              width="20"
              style={{ marginRight: ".5em" }}
            />

            <span className="add-company-btn__content">Add Company</span>
          </button>
        </div>

        <AddCompanyModal
          isVisible={isAcmModalVisible}
          onToggle={() => setIsAcmModalVisible(false)}
          requestClick={handleModalActions}
        />
      </header>

      <div className="payees__entries">
        {selectedCompany !== undefined && (
          <>
            <button
              type="button"
              disabled={arePayeesLoading}
              onClick={() => {
                setIsPayeeAddModalVisible(true);
              }}
              className="add-payee invoice-upload--add btn btn--short btn--alt"
            >
              <AddIcon /> Add Payee Account
            </button>

            <AddPayeeModal
              existingPayees={payeesData ? payeesData.data.data : []}
              onToggle={() => setIsPayeeAddModalVisible(false)}
              requestClick={handlePayeeActions}
              vendorId={
                selectedCompany.vendorId ? selectedCompany.vendorId : ""
              }
              isVisible={isPayeeAddModalVisible}
            />

            <PayeeTable
              data={payeesData ? payeesData.data.data : []}
              requestAction={handleTableActions}
              isLoading={arePayeesLoading}
            />

            <UsersCompanyModal
              data={usersData ? usersData.data.data : []}
              isLoading={areCompanyUsersLoading}
              onToggle={() => {
                setUsersCompanyModalVisible(false);
                setSelectedPayee(undefined);
              }}
              isVisible={usersCompanyModalVisible}
            />

            <EditPayeeModal
              existingPayees={payeesData ? payeesData.data.data : []}
              payeeNumber={selectedPayee?.payeeNumber || ""}
              name={selectedPayee?.name || ""}
              vendorId={
                selectedCompany.vendorId ? selectedCompany.vendorId : ""
              }
              onToggle={() => {
                setEditPayeeModalVisible(false);
                setSelectedPayee(undefined);
              }}
              requestClick={handleEditPayee}
              isVisible={editPayeeModalVisible}
            />
          </>
        )}
      </div>

      <DeletePayeeModal
        isVisible={deletePayeeModalVisible}
        payee={selectedPayee as IPayeeAccounts}
        onToggle={() =>
          handleDeleteModalActions(IProfilePasswordModalAction.CANCEL)
        }
        requestClick={handleDeleteModalActions}
      />
    </div>
  );

  async function handleDeleteCompanyActions(type: IProfilePasswordModalAction) {
    if (!selectedCompany) {
      return;
    }

    const currentSelectedCompany = selectedCompany;

    setIsDeleteCompanyModalVisible(false);

    switch (type) {
      case IProfilePasswordModalAction.SAVE:
        setSelectedCompany(undefined);

        return deleteCompanyMutation(currentSelectedCompany.id.toString());

      case IProfilePasswordModalAction.CANCEL:
        return;

      default:
        throw Error("Not implemented");
    }
  }

  async function handleCompanyEditActions(
    type: IProfilePasswordModalAction,
    payload: any
  ) {
    if (!selectedCompany) {
      return;
    }

    switch (type) {
      case IProfilePasswordModalAction.CANCEL:
        return setIsEditCompanyModalVisible(false);

      case IProfilePasswordModalAction.SAVE:
        setIsEditCompanyModalVisible(false);

        setSelectedCompany({
          name: payload.name,
          vendorId: payload.vendorId,
          id: selectedCompany.id,
          overhead: payload.overhead,
          related: payload.related,
        });

        return updateCompanyMutation({
          id: selectedCompany.id,
          name: payload.name,
          vendorId: payload.vendorId,
          overhead: payload.overhead,
          related: payload.related,
        });

      default:
        throw Error("Not implemented");
    }
  }

  async function handleTableActions(
    type: IPayeeTableAction,
    item: IPayeeAccounts
  ) {
    setSelectedPayee(item);

    switch (type) {
      case IPayeeTableAction.DELETE:
        setDeletePayeeModalVisible(true);

        return;

      case IPayeeTableAction.EDIT:
        setEditPayeeModalVisible(true);

        return;

      case IPayeeTableAction.SHOW_USERS:
        setUsersCompanyModalVisible(true);

        return;

      default:
        throw Error("Not implemented");
    }
  }

  async function handleEditPayee(
    type: IProfilePasswordModalAction,
    payload?: any
  ) {
    if (!selectedPayee || !selectedCompany) {
      return;
    }
    const currentSelectedPayee = {
      ...selectedPayee,
    };

    setEditPayeeModalVisible(false);
    setSelectedPayee(undefined);

    switch (type) {
      case IProfilePasswordModalAction.SAVE:
        return updatePayeeMutation({
          companyId: selectedCompany.id.toString(),
          name: payload.name,
          id: currentSelectedPayee.id,
          payeeNumber: payload.payeeNumber,
        });
    }
  }

  async function handleDeleteModalActions(type: IProfilePasswordModalAction) {
    if (!selectedPayee || !selectedCompany) {
      return;
    }

    const currentSelectedPayee = {
      ...selectedPayee,
    };

    setSelectedPayee(undefined);
    setDeletePayeeModalVisible(false);

    await new Promise((resolve) => {
      setTimeout(() => {
        resolve(true);
      }, 300);
    });

    switch (type) {
      case IProfilePasswordModalAction.SAVE:
        return deletePayeeMutation({
          companyId: selectedCompany.id.toString(),
          payeeId: currentSelectedPayee.id.toString(),
        });

      case IProfilePasswordModalAction.CANCEL:
        return;

      default:
        throw new Error("Not implemented");
    }
  }

  async function handlePayeeActions(
    type: IProfilePasswordModalAction,
    payload?: any
  ) {
    if (!selectedCompany) {
      return;
    }

    setIsPayeeAddModalVisible(false);

    switch (type) {
      case IProfilePasswordModalAction.SAVE:
        return addPayeeToCompanyMutation({
          name: payload.name,
          payeeNumber: payload.payeeNumber,
          companyId: selectedCompany.id.toString(),
          payeeCurrency: payload.payeeCurrency,
        });

      case IProfilePasswordModalAction.CANCEL:
        return setIsPayeeAddModalVisible(false);

      default:
        throw new Error("Not implemented");
    }
  }

  async function handleModalActions(
    type: IProfilePasswordModalAction,
    payload?: any
  ) {
    switch (type) {
      case IProfilePasswordModalAction.SAVE:
        setIsAcmModalVisible(false);

        return addCompanyMutation({
          name: payload.acmInput,
          vendorId: payload.viInput,
          overhead: payload.overhead,
          related: payload.related,
        });

      case IProfilePasswordModalAction.CANCEL:
        return setIsAcmModalVisible(false);

      default:
        throw new Error("Not implemented");
    }
  }

  async function handleItemSelection(item: string) {
    if (!companiesData) {
      return;
    }

    const company = companiesData.data.data.find(
      (companyItem) => companyItem.id.toString() === item
    );

    if (!company) {
      return setSelectedCompany(undefined);
    }

    setSelectedCompany(company);
  }

  function handleClearSelection() {
    return setSelectedCompany(undefined);
  }

  function handleExport() {
    return mutate();
  }
}
