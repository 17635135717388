import {useMutation} from "@tanstack/react-query";
import {checkPayee} from "../../user/saga/vendor/check-payee";

interface IMutationCheckPayeeData {
  item: string; 
  vendorId: string;
}

export const IUseMutationCheckPayee = 'IUseMutationCheckPayee';

export function useMutationCheckPayee() {
  return useMutation({
    mutationKey: [IUseMutationCheckPayee],
    mutationFn: ({ item, vendorId }: IMutationCheckPayeeData) => checkPayee(item, vendorId),
  })
}
