import {IAddCompanyModal} from "../add-company-modal/add-company-modal";
import Modal, {IModalType} from "../../../components/ui/modal/Modal";
import {IProfilePasswordModalAction} from "../../users/types";
import {IPayeeAccounts} from "../../../redux/companies/companies";

interface IDeletePayeeModal extends IAddCompanyModal {
  payee: IPayeeAccounts
}

export default function DeletePayeeModal({isVisible, payee, requestClick, onToggle}: IDeletePayeeModal) {
  return (
    <Modal title="Delete Payee Account"
           type={IModalType.ERROR}
           toggleVisibility={isVisible}
           onToggle={onToggle}
           customClass={"add-user-modal profile-delete-modal"}
           customBtns={
             <div className="modal__footer d-flex">
               <button type="button"
                       className={`change-pass-modal-footer__action modal__footer__action btn--short btn btn--mute`}
                       onClick={() => {
                         return requestClick(IProfilePasswordModalAction.CANCEL);
                       }}>
                 Cancel
               </button>

               <button type="button"
                       className={`change-pass-modal-footer__action modal-buttons--continue btn--short btn btn--default`}
                       onClick={() => {
                         return requestClick(IProfilePasswordModalAction.SAVE);
                       }}>
                 Continue
               </button>
             </div>
           }>

      <p className="register-form__intro text-align-left">
        The following payee account and data associated with it, will be removed: <br />
        <strong>{payee?.payeeNumber}</strong>

        <br /> <br />

        Are you sure you want to continue?
      </p>
    </Modal>
  )
}
