import "./select.scss";
import {
  ChangeEvent,
  ForwardedRef,
  forwardRef,
  PropsWithChildren,
  useEffect,
  useState,
} from "react";

export enum ISelectType {
  DEFAULT = "default",
  ADVANCED = "advanced",
}

export interface ISelect {
  id: string;
  altAppearance?: boolean;
  data: string[] | { id: string; content: string }[];
  label?: string;
  requestChange: (e: string) => void;
  selectedItem?: string;
  customClassName?: string;
  placeholder?: string;
  isDisabled?: boolean;
  defaultValue?: string | { id: string; content: string };
  requestClick?: () => void;
  error?: boolean;
  value?: string | { id: string; content: string };
  valueText?: string;
}

function Select(
  {
    id,
    defaultValue,
    requestClick,
    error,
    label,
    value,
    altAppearance,
    children,
    selectedItem,
    data,
    requestChange,
    customClassName,
    placeholder,
    isDisabled,
    valueText,
  }: PropsWithChildren<ISelect>,
  ref: ForwardedRef<HTMLSelectElement>
) {
  const [currentSelectedItem, setCurrentSelectedItem] = useState(
    defaultValue && typeof defaultValue === "string"
      ? defaultValue
      : defaultValue
      ? (defaultValue as any).id
      : ""
  );

  const [currentSelectedText, setCurrentSelectedText] = useState(
    valueText || ""
  );

  const [tooltipContent, setTooltipContent] = useState("");

  useEffect(() => {
    setTooltipContent(currentSelectedText);
  }, [currentSelectedText]);

  useEffect(() => {
    if (selectedItem !== undefined) {
      setCurrentSelectedItem(selectedItem);

      if (requestChange) {
        requestChange(selectedItem);
      }
    }
  }, [requestChange, selectedItem]);

  useEffect(() => {
    setCurrentSelectedItem(value);
    setCurrentSelectedText(valueText || "");
  }, [value, valueText]);

  return (
    <div
      className={`${tooltipContent !== "" ? "tooltip-trigger" : ""}`}
      data-tooltip={tooltipContent}
    >
      <div
        className={`select ${altAppearance ? "select--alt" : ""} ${
          label && label.length ? "select--with-label" : ""
        } ${isDisabled ? "select--disabled" : ""} ${
          customClassName ? customClassName : ""
        }`}
      >
        {label && label.length && <span className="input__title">{label}</span>}

        <select
          id={id}
          ref={ref}
          className={`select__el ${error ? "select__el--error" : ""}`}
          onClick={() => {
            if (!requestClick) {
              return;
            }

            return requestClick();
          }}
          value={currentSelectedItem}
          disabled={isDisabled}
          onChange={handleChange}
          onMouseEnter={() => {
            // setTooltipContent(currentSelectedText || (defaultValue && "content" in defaultValue ? defaultValue.content : ""));

            if (
              typeof defaultValue === "object" &&
              defaultValue !== null &&
              "content" in defaultValue
            ) {
              setTooltipContent(
                currentSelectedText ? currentSelectedText : defaultValue.content
              );
            } else {
              // Handle the case when defaultValue is not an object with a content property
              // You can set a default value or handle it according to your requirements
              setTooltipContent(currentSelectedText ? currentSelectedText : "");
            }
            //  setTooltipContent(currentSelectedText);
          }}
        >
          {placeholder && placeholder.length ? (
            <option value="">{placeholder}</option>
          ) : null}

          {data.map((item) =>
            typeof item === "string" ? (
              <option value={item} key={item}>
                {item}
              </option>
            ) : (
              <option value={item.id} key={item.id}>
                {item.content}
              </option>
            )
          )}
        </select>
      </div>
      {children}
    </div>
  );

  function handleChange(event: ChangeEvent<HTMLSelectElement>) {
    if (!requestChange || isDisabled) {
      return;
    }

    const selectedValue = event.target.value || "";
    setCurrentSelectedItem(selectedValue);

    const typedData = data as (string | { id: string; content: string })[];

    const selectedItem = typedData.find((item) =>
      typeof item === "string"
        ? item === selectedValue
        : (item as { id: string; content: string }).id === selectedValue
    );

    const itemName =
      selectedItem && typeof selectedItem !== "string"
        ? selectedItem.content
        : selectedItem;

    setCurrentSelectedText(itemName || "");

    return requestChange(event.target.value);
  }
}

export default forwardRef<HTMLSelectElement, PropsWithChildren<ISelect>>(
  Select
);
