import Modal, { IModalType } from "../../../components/ui/modal/Modal";
import { IProfilePasswordModalAction } from "../../users/types";
import { useCallback, useEffect, useMemo, useState } from "react";
import InlineLoader from "../../../components/ui/inline-loader/InlineLoader";
import InputInlineError from "../../../components/ui/inputs/inline-error/Input.inline-error";
import Input from "../../../components/ui/inputs/Input";
import { startAddNotification } from "../../../redux/core/core";
import { INotificationType } from "../../../components/ui/notifications/item/NotificationItem";
import { useAppDispatch } from "../../../redux/hooks";
import { IPayeeAccounts } from "../../../redux/companies/companies";
import {
  CHECK_VENDOR_ID,
  CHECK_VENDOR_NAME,
  ISearchType,
} from "../add-company-modal/add-company-modal";
import { AxiosError } from "axios";
import { ReactComponent as TrashIcon } from "./../../../assets/icons/trash.svg";
import { useValidatePayeeVendorId } from "../../../redux/hooks/useMutationValidatePayeeVendorId";

export interface IEditPayeeModal {
  isVisible: boolean;
  onToggle: (state: boolean) => void;
  name: string;
  payeeNumber: string;
  vendorId: string;
  existingPayees: IPayeeAccounts[];
  requestClick: (state: IProfilePasswordModalAction, payload?: any) => void;
}

export default function EditPayeeModal({
  isVisible,
  requestClick,
  onToggle,
  existingPayees,
  payeeNumber,
  vendorId,
  name,
}: IEditPayeeModal) {
  const [searchType, setSearchType] = useState<ISearchType>("check_vendor_id");

  const [acmSearchInput, setAcmSearchInput] = useState(payeeNumber);
  const [acmInput, setAcmInput] = useState(payeeNumber);
  const [nameInput, setNameInput] = useState(name);

  const [isDirty, setIsDirty] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [isAvailable, setIsAvailable] = useState(false);

  const matchVendorId = useCallback(() => {
    const match = acmSearchInput.match(/-(\d+)-/);
    return match && match[1] !== vendorId;
  }, [acmSearchInput, vendorId]);

  const dispatchStore = useAppDispatch();

  const isSaveDisabled = useMemo(() => {
    if (acmInput === name && nameInput === payeeNumber) {
      return true;
    }

    if (!acmInput || !nameInput) {
      return true;
    }

    // if (acmInput === payeeNumber && nameInput === name) {
    //   return true;
    // }

    // if (nameInput !== name || payeeNumber !== acmInput) {
    //   return false;
    // }

    return !isAvailable;
  }, [acmInput, isAvailable, name, nameInput, payeeNumber]);

  const companyIdExisting = useMemo(() => {
    return (
      existingPayees
        .filter((item) => item.payeeNumber !== acmSearchInput)
        .find((item) => item.payeeNumber === acmSearchInput) !== undefined
    );
  }, [acmSearchInput, existingPayees]);

  const {
    mutateAsync: checkCompanyVendorIdMutation,
    isLoading: isCheckCompanyVendorIdLoading,
  } = useValidatePayeeVendorId();

  useEffect(() => {
    setAcmInput(name);
    setAcmSearchInput(name);
    setNameInput(payeeNumber);

    return () => {
      if (!isVisible) {
        setAcmInput(name);
        setAcmSearchInput(name);
        setNameInput(payeeNumber);
        setIsDirty(false);
        setErrorMessage("");
        setIsAvailable(false);
        setSearchType(CHECK_VENDOR_ID);
      }
    };
  }, [isVisible, name, payeeNumber]);

  return (
    <Modal
      title="Edit Payee Account"
      type={IModalType.NOTIFY}
      toggleVisibility={isVisible}
      onToggle={onToggle}
      customClass={"add-user-modal profile-delete-modal"}
      customBtns={
        <div className="modal__footer d-flex">
          <button
            type="button"
            className={`change-pass-modal-footer__action modal__footer__action btn--short btn btn--mute`}
            onClick={() => {
              return requestClick(IProfilePasswordModalAction.CANCEL);
            }}
          >
            Cancel
          </button>

          <button
            type="button"
            disabled={isSaveDisabled}
            className={`change-pass-modal-footer__action modal-buttons--continue btn--short btn btn--default`}
            onClick={() => {
              return requestClick(IProfilePasswordModalAction.SAVE, {
                name: nameInput,
                payeeNumber: acmInput,
              });
            }}
          >
            Continue
          </button>
        </div>
      }
    >
      <p className="register-form__intro text-align-left">
        Edit the current payee account: <br />
        <strong>
          {name} : {payeeNumber}
        </strong>
        <br />
      </p>

      <form
        onSubmit={(event) => event.preventDefault()}
        className="acm-form add-payee-form invoice__block--matter-number add-company-form"
      >
        <Input
          id="acm-form_search"
          showLabel={false}
          placeholder={`Validate Payee ID`}
          requestInput={(event) => {
            if (!isDirty) {
              setIsDirty(true);
            }

            setErrorMessage(
              event.length < 3
                ? `${
                    searchType === CHECK_VENDOR_NAME
                      ? "Supplier Name"
                      : "Payee ID"
                  } should contain at least 3 characters`
                : ""
            );

            setAcmSearchInput(event);
          }}
          requestSubmit={(event) => {
            if (matchVendorId()) {
            } else {
              validateCompany();
            }
          }}
          customClass={`matter-number`}
          value={acmSearchInput}
          autocomplete={false}
          type={"string"}
          disabled={
            isCheckCompanyVendorIdLoading ||
            (nameInput.length > 0 && nameInput.length > 0)
          }
          error={isDirty && (errorMessage ? errorMessage.length > 0 : false)}
        >
          <>
            <div className="matter-number--actions">
              {isCheckCompanyVendorIdLoading && <InlineLoader />}

              {acmSearchInput.length > 2 && (
                <button
                  type="button"
                  onClick={() => {
                    setAcmSearchInput("");
                    setIsDirty(false);
                    setAcmInput("");
                    setNameInput("");
                    setIsAvailable(true);
                  }}
                  className="add-company-form__clear btn--reset history-clear-filters"
                >
                  <TrashIcon />
                </button>
              )}

              <button
                type="button"
                onClick={validateCompany}
                disabled={
                  (isCheckCompanyVendorIdLoading ||
                    companyIdExisting ||
                    acmSearchInput.length < 3 ||
                    matchVendorId()) ??
                  false
                }
                className={`matter_number-button btn btn--short btn--default ${
                  isCheckCompanyVendorIdLoading ||
                  companyIdExisting ||
                  acmSearchInput.length < 3
                    ? "matter_number-button--disabled"
                    : ""
                }`}
              >
                Validate
              </button>
            </div>

            <InputInlineError
              isVisible={isDirty && errorMessage.length > 0}
              message={errorMessage || ""}
            />
          </>
        </Input>

        <hr />

        <div className="add-company-form__entries">
          <Input
            id="acm-form__vi"
            placeholder="Payee ID"
            showLabel
            customClass={`matter-number ${
              !errorMessage.length ? "acm-form-valid" : ""
            }`}
            value={acmInput}
            autocomplete={false}
            type={"string"}
            disabled={true}
          ></Input>

          <Input
            id="acm-form__cn"
            placeholder="Payee Name"
            customClass={`matter-number ${
              acmInput !== name && !errorMessage.length ? "acm-form-valid" : ""
            }`}
            value={nameInput}
            showLabel
            autocomplete={false}
            type={"string"}
            disabled={true}
          ></Input>
        </div>
      </form>
    </Modal>
  );

  async function validateCompany() {
    try {
      const result = await checkCompanyVendorIdMutation({
        item: acmSearchInput,
        vendorId: vendorId,
      });

      setAcmInput(acmSearchInput);
      setNameInput(result.data.data);

      const companyAlreadyExists = existingPayees
        .filter(
          (item) => item.payeeNumber.toString() !== payeeNumber.toString()
        )
        .find(
          (item) =>
            item.name === result.data.data ||
            item.payeeNumber === acmSearchInput
        );

      if (companyAlreadyExists) {
        setErrorMessage(
          `${
            searchType === CHECK_VENDOR_NAME
              ? "Supplier Company"
              : "Supplier Payee"
          } already exists`
        );

        return dispatchStore(
          startAddNotification({
            title: "Warning",
            description: `${
              searchType === CHECK_VENDOR_NAME
                ? "Supplier Company"
                : "Supplier Payee"
            } already exists`,
            type: INotificationType.WARNING,
          })
        );
      }
      setIsAvailable(true);
    } catch (error) {
      const currentError = error as AxiosError;
      if (currentError && currentError?.response?.status === 400) {
        setErrorMessage(
          `${
            searchType === CHECK_VENDOR_NAME
              ? "Supplier Company"
              : "Supplier Payee"
          } already exists`
        );

        return dispatchStore(
          startAddNotification({
            title: "Warning",
            description: `${
              searchType === CHECK_VENDOR_NAME
                ? "Supplier Company"
                : "Supplier Payee"
            } already exists`,
            type: INotificationType.WARNING,
          })
        );
      }

      if (currentError && currentError?.response?.status === 404) {
        setErrorMessage(
          `${
            searchType === CHECK_VENDOR_NAME
              ? "Supplier Company"
              : "Payee account"
          } not found`
        );

        return dispatchStore(
          startAddNotification({
            title: "Warning",
            description: `${
              searchType === CHECK_VENDOR_NAME
                ? "Supplier Company"
                : "Payee account"
            } not found`,
            type: INotificationType.WARNING,
          })
        );
      }

      setErrorMessage(
        `${
          searchType === CHECK_VENDOR_NAME
            ? "Supplier Company"
            : "Payee account"
        } not found`
      );

      return dispatchStore(
        startAddNotification({
          title: "Error",
          description: `Unknown api error occurred`,
          type: INotificationType.ERROR,
        })
      );
    }
  }
}
