import {ReactComponent as SearchIcon} from "../../../assets/icons/search.svg";
import Input from "../../../components/ui/inputs/Input";
import "./history-search.scss";

interface HistorySearchFormProps {
  isDisabled: boolean,
  value: string;
  requestChange: (event: string) => void;
  requestSubmit: (event: string) => void;
}

export default function HistorySearchForm({value, requestChange, requestSubmit, isDisabled}: HistorySearchFormProps) {
  return (
    <form className="history-search-form" onSubmit={(event) => {
      event.preventDefault();

      return requestSubmit(value);
    }}>
      <Input
        autocomplete={false}
        customClass={`matter-number history-search`}
        placeholder="Search invoice No."
        disabled={isDisabled}
        id="search-history"
        requestInput={requestChange}
        value={value}>

        <button type="submit" className="btn btn--reset history-search__action">
          <span className="visually-hidden">Search invoice no</span>
          <SearchIcon height="20" width="20"/>
        </button>
      </Input>
    </form>
  );
}
